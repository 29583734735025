import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { List } from "immutable";

import {
  getCurrentJourneyFromState,
  getPlayerAndTeamDataFromState,
  hasUpcomingJourney,
} from "../../containers/Community/stateGetterHelpers";
import { GridContainer, Flex, ApiError, Box } from "../Utility";
import { Button, GridBox, Header, Modal, Paragraph } from "../../Storybook";
import {
  joinJourneyWave,
  fetchTeamsWithActiveJourneys,
  getCommunityInfo,
  startJourneyWave,
} from "../../containers/Community/actions";
import WaveParticipants from "./WaveParticipants";
import moment from "moment";
import UpcomingJourneys from "./UpcomingJourneys";
import CurrentJourneys from "./CurrentJourneys";

const CommunityPoolPage = ({
  activeCommunityId,
  authPlayerHasCurrentJourney,
  authedPlayerHasUpcomingJourney,
  fetchTeamsWithActiveJourneys,
  upcomingJourneyTeams,
  ongoingJourneyTeams,
  joinJourneyWave,
  journeyWave,
  getCommunityInfo,
  playerData,
  startJourneyWave,
}) => {
  const [error, setError] = useState("");
  const [joinedWave, setJoinedWave] = useState(false);
  const [teams, setTeams] = useState(null);

  useEffect(() => {
    if (!journeyWave && !!activeCommunityId)
      getCommunityInfo(activeCommunityId);
  }, [activeCommunityId, journeyWave, getCommunityInfo]);

  useEffect(() => {
    (async function () {
      try {
        if (!upcomingJourneyTeams.size && !ongoingJourneyTeams.size) {
          const numOfTeams = await fetchTeamsWithActiveJourneys(
            activeCommunityId
          );
          setTeams(numOfTeams);
        }
      } catch (e) {
        setError(e);
      }
    })();
  }, [
    upcomingJourneyTeams,
    ongoingJourneyTeams,
    activeCommunityId,
    fetchTeamsWithActiveJourneys,
  ]);

  const authedParticipant = journeyWave
    ?.get("participants")
    ?.find((p) => p.get("user") === playerData.get("user_id") && p);

  const waveStartString =
    !!journeyWave &&
    moment(journeyWave.get("stop"), "YYYY-MM-DD").format("MMM Do");

  const playerCanJoinWave =
    !authPlayerHasCurrentJourney && !authedPlayerHasUpcomingJourney;

  const canStartWave = playerCanJoinWave || playerData.get("is_admin");

  const startANewWaveString = canStartWave
    ? "Start a wave by joining"
    : "Starting soon";

  const startWave = async () => {
    try {
      // select a future Monday at least 5 days from today
      const dateForNewWave = moment()
        .add(5, "days")
        .weekday(1)
        .format("YYYY-MM-DD");
      await startJourneyWave(activeCommunityId, dateForNewWave);
    } catch (e) {
      throw e;
    }
  };

  const joinWave = async () => {
    if (playerCanJoinWave) {
      try {
        if (!journeyWave) await startWave();
        else {
          await joinJourneyWave(activeCommunityId, journeyWave?.get("id"));
        }
        setJoinedWave(true);
      } catch (e) {
        if (e.includes("Too Many Requests"))
          setError("Only one request per minute is allowed.");
        else setError(e);
      }
    } else if (authedPlayerHasUpcomingJourney)
      setError("You cannot join a wave if you have another journey scheduled.");
    else if (authPlayerHasCurrentJourney)
      setError(
        "You cannot join a wave if you are in a journey that ends after the wave starts."
      );
  };
  if (teams < upcomingJourneyTeams.size + ongoingJourneyTeams.size)
    return <></>;
  return (
    <>
      {!!error?.length && (
        <Modal oncClose={() => setError("")}>
          <Box py={2}>
            <ApiError msg={error} />
          </Box>
        </Modal>
      )}
      {joinedWave && (
        <Modal oncClose={() => setJoinedWave(false)}>
          <Header h3 mb={3} label="Success!" />
          <Paragraph
            label={`You have have been added to the list. Next, you will be placed in a team. Get excited, your journey will begin ${waveStartString}!`}
          />
        </Modal>
      )}
      <GridContainer alignItems={"flex-start"} alignContent="flex-start">
        <GridBox alignItems={"flex-start"}>
          <Header h1 label="Pool Page" />
        </GridBox>
        <GridBox alignItems={"flex-start"}>
          <Flex justifyContent="space-between" alignContent="flex-start">
            <Header h3 fontSize={["16px", "22px"]} label="Active Journeys" />
            <Paragraph mono>Reporting 📈 </Paragraph>
          </Flex>
        </GridBox>
        <CurrentJourneys
          authPlayerHasCurrentJourney={authPlayerHasCurrentJourney}
          authPlayerTeamId={playerData.get("team_id")}
          ongoingJourneyTeams={ongoingJourneyTeams}
        />
        <GridBox
          mt={3}
          display="flex"
          justifyContent="space-between"
          alignItems={"flex-end"}
        >
          <Header h3 fontSize={["16px", "22px"]} mr={2}>
            {!!journeyWave
              ? `Starting ${waveStartString}`
              : startANewWaveString}
          </Header>
          <Button
            primary
            small
            thin
            label="Join Wave"
            height="100%"
            onClick={(e) => joinWave(e)}
            disabled={!!authedParticipant || !canStartWave}
          />
        </GridBox>
        {!!journeyWave && (
          <WaveParticipants
            joinedWave={joinedWave}
            authedParticipant={authedParticipant}
          />
        )}
        <UpcomingJourneys
          authedPlayerHasUpcomingJourney={authedPlayerHasUpcomingJourney}
          authPlayerTeamId={playerData.get("team_id")}
          upcomingJourneyTeams={upcomingJourneyTeams}
          activeCommunityId={activeCommunityId}
          journeyWave={journeyWave}
        />
      </GridContainer>
    </>
  );
};

export default connect(
  (state, props) => {
    const { playerData } = getPlayerAndTeamDataFromState(state);
    const activeCommunityId = Number(props.match.params.communityId);
    const currentJourney = getCurrentJourneyFromState(state);
    return {
      activeCommunityId,
      playerData,
      currentJourney,
      upcomingJourneyTeams:
        state.community.getIn(["upcomingJourneyTeams", activeCommunityId]) ||
        new List(),
      ongoingJourneyTeams:
        state.community.getIn(["ongoingJourneyTeams", activeCommunityId]) ||
        new List(),
      authPlayerHasCurrentJourney:
        !!playerData.get("current_journey") &&
        !currentJourney?.getIn([
          "player_totals",
          playerData.get("user_id").toString(),
          "quit_date",
        ]),
      authedPlayerHasUpcomingJourney: hasUpcomingJourney(state),
      journeyWave: state.community.getIn([
        "communityInfo",
        activeCommunityId,
        "active_wave",
      ]),
    };
  },
  {
    fetchTeamsWithActiveJourneys,
    joinJourneyWave,
    getCommunityInfo,
    startJourneyWave,
  }
)(CommunityPoolPage);
