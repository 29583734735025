import React from "react";

import { Flex } from ".";
import { WarningLarge } from "../../img/svg/icons";
import { Paragraph } from "../../Storybook";

const ApiError = ({ msg, mb, justifyContent }) => (
  <Flex
    mb={mb}
    justifyContent={justifyContent || "center"}
    alignItems="baseline"
  >
    <WarningLarge color="darkNegative500" />
    <Paragraph ml={2} label={msg} color="darkNegative500" />
  </Flex>
);

export default ApiError;
