import React from "react";
import PropTypes from "prop-types";

import StyledModal from "../../components/Utility/Modal";
import darkTheme from "../../styles/theme";

const Modal = ({ theme, onClose, noClose, children, ...props }) => {
  const closeModal = () => {
    if (noClose) return;
    if (onClose) onClose();
  };

  return (
    <StyledModal
      noClose={noClose}
      theme={theme}
      onClose={closeModal}
      children={children}
      {...props}
    >
      {children}
    </StyledModal>
  );
};

Modal.propTypes = {
  /**
   * Pass an onclose function to run once the modal is closed
   */
  onClose: PropTypes.func,
  /**
   * Optional pass another theme once we have Light
   */
  theme: PropTypes.object,
  /**
   * Optional parameter to allow the modal to be closed by clicking outside the modal
   */
  noClose: PropTypes.bool,
};

Modal.defaultProps = {
  theme: darkTheme,
  noClose: false,
};

export default Modal;
