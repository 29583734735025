import styled from "styled-components";

const Anchor = styled.a`
  color: ${(props) => props.theme.color.blue};
  border-bottom: 1px solid ${(props) => props.theme.color.blue};
  text-decoration: none;
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
`;

export default Anchor;
