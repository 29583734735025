import React, { useState, useRef, useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect, useSelector } from "react-redux";
// import useSWR from "swr";

// import fetcher from "../../config/fetcher";
import { GridBox, Header } from "../../Storybook";
import { findLatestPearls, genNewId } from "../../utils/mochiHelpers";
import TeamPearl from "./TeamPearl";
import { Box } from "../Utility";
import { getTeamsLatestJourneyId } from "../../containers/Community/stateGetterHelpers";
import { fetchJourneyDetails } from "../../containers/Community/actions";

const LatestTeamPearls = ({ fetchJourneyDetails }) => {
  // 4- Pass the rendered height to all reports
  const [longestContainerHeight, setLongestContainerHeight] = useState();
  // 2- Pass a reference to the container of user id with the longest report
  const longestContainer = useRef(null);
  let { teamId } = useParams();
  teamId = Number(teamId);
  const latestJourneyId = useSelector((state) => getTeamsLatestJourneyId(state, teamId));
  const activeCommunityId = useSelector((state) => state.community.get("activeCommunityId"));
  const teamData = useSelector((state) =>
    state.community.getIn(["teams", activeCommunityId, teamId])
  );
  const latestTeamJourney = useSelector((state) =>
    state.community.getIn(["journeys", activeCommunityId, latestJourneyId])
  );
  // const dispatch = useDispatch();
  // const { teamResData, fetchTeamError, isLoading } = useSWR(`/teams/${teamId}/`, fetcher);
  // const { journeyData, fetchJourneyError, isJourneyLoading } = useSWR(
  //   `/community/${activeCommunityId}/journeys/team/${teamId}/`,
  //   fetcher
  // );
  // useEffect(() => {
  //   if (teamResData && journeyData)
  //     dispatch(setTeamInfo(teamId, activeCommunityId, teamResData, journeyData));
  // }, [activeCommunityId, communityId, dispatch, journeyData, teamId, teamResData]);

  const teamPlayers = teamData.get("members");
  const { latestPearls, latestPearlDate } = findLatestPearls(latestTeamJourney, teamPlayers);

  // 1- Find the longest report, and retain the user's id
  const longestPearlUser = useMemo(() => {
    if (!latestPearls) return null;
    let longestLength = 0,
      longestPearlUserId;
    teamPlayers.forEach((p) => {
      let totalLength = 0;
      if (latestPearls[p.get("user_id")]?.conversation?.length) {
        latestPearls[p.get("user_id")]["conversation"].forEach((p) => (totalLength += p.length));
        if (totalLength > longestLength) {
          longestLength = totalLength;
          longestPearlUserId = p.get("user_id");
        }
      }
    });
    return longestPearlUserId;
  }, [latestPearls, teamPlayers]);

  // 3- Retreieve the rendered height of the longest report
  useEffect(() => {
    if (longestContainer.current?.clientHeight)
      setLongestContainerHeight(longestContainer.current?.clientHeight);
  }, []);

  useEffect(() => {
    if (!latestTeamJourney && !!latestJourneyId) {
      fetchJourneyDetails(latestJourneyId, activeCommunityId);
    }
  }, [latestJourneyId, activeCommunityId, latestTeamJourney, fetchJourneyDetails]);

  // if (fetchTeamError) return <div>failed to load</div>;
  // if (fetchJourneyError) return <div>failed to load</div>;
  // if (isLoading) return <div>loading...</div>;

  if (!latestPearls || !Object.keys(latestPearls)) return null;
  return (
    <>
      <GridBox m={0}>
        <Header h4 color="darkFGSecondary" label="Latest Pearls" />
      </GridBox>
      {!!latestPearls &&
        teamPlayers.map((m) => (
          <GridBox
            key={genNewId(`player-pearl`)}
            span={[4, 4, 3]}
            alignItems="stretch"
            alignContent="stretch"
            alignSelf="flex-start"
            justifyContent="center"
            data-testid="team-pearl"
          >
            <Box ref={m.get("user_id") === longestPearlUser ? longestContainer : null}>
              <TeamPearl
                pearl={latestPearls[m.get("user_id")]}
                playerId={m.get("user_id")}
                pearlDate={latestPearlDate}
                height={longestContainerHeight}
              />
            </Box>
          </GridBox>
        ))}
    </>
  );
};

export default connect(() => {}, { fetchJourneyDetails })(LatestTeamPearls);
