import React from "react";
import { Fill, Box, Header } from "../Utility";

const ProfileCard = ({ children, heading, bgColor, testId }) => (
  <Fill
    bgColor={bgColor || "darkBGSecondary"}
    borderRadius="4px"
    mb={2}
    data-testid={testId}
    id={`#${heading}`}
  >
    <Box p={3}>
      <Header
        h5
        mb={2}
        color={!!bgColor ? "darkBGSecondary" : "darkFGSecondary"}
      >
        {heading}
      </Header>
      {children}
    </Box>
  </Fill>
);

export default ProfileCard;
