const communityInitialState = {
  authedUserId: null,
  activeCommunityId: null,
  communityInvitation: null,
  communityInfo: {},
  players: {},
  teams: {},
  ongoingJourneyTeams: {},
  upcomingJourneyTeams: {},
  journeys: {},
};

export default communityInitialState;
