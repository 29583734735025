import React from "react";

import "./loading.css";

const LoadingAni = () => (
  <div style={{ textAlign: "center" }}>
    <div className="stack" style={{ height: "300px", top: "330px", position: "relative" }}>
      <svg
        id="stack1"
        width="16"
        height="12"
        viewBox="0 0 16 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 8C16 12 12.4183 12 8 12C3.58172 12 0 12 0 8C0 3.58172 4 0 8 0C12 0 16 3.58172 16 8ZM6 7C6 7.55228 5.55228 8 5 8C4.44772 8 4 7.55228 4 7C4 6.44772 4.44772 6 5 6C5.55228 6 6 6.44772 6 7ZM11 8C11.5523 8 12 7.55228 12 7C12 6.44772 11.5523 6 11 6C10.4477 6 10 6.44772 10 7C10 7.55228 10.4477 8 11 8Z"
          fill="white"
        />
      </svg>
      <svg
        id="stack2"
        width="16"
        height="12"
        viewBox="0 0 16 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 8C16 12 12.4183 12 8 12C3.58172 12 0 12 0 8C0 3.58172 4 0 8 0C12 0 16 3.58172 16 8ZM6 7C6 7.55228 5.55228 8 5 8C4.44772 8 4 7.55228 4 7C4 6.44772 4.44772 6 5 6C5.55228 6 6 6.44772 6 7ZM11 8C11.5523 8 12 7.55228 12 7C12 6.44772 11.5523 6 11 6C10.4477 6 10 6.44772 10 7C10 7.55228 10.4477 8 11 8Z"
          fill="white"
        />
      </svg>
      <svg
        id="stack3"
        width="16"
        height="12"
        viewBox="0 0 16 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 8C16 12 12.4183 12 8 12C3.58172 12 0 12 0 8C0 3.58172 4 0 8 0C12 0 16 3.58172 16 8ZM6 7C6 7.55228 5.55228 8 5 8C4.44772 8 4 7.55228 4 7C4 6.44772 4.44772 6 5 6C5.55228 6 6 6.44772 6 7ZM11 8C11.5523 8 12 7.55228 12 7C12 6.44772 11.5523 6 11 6C10.4477 6 10 6.44772 10 7C10 7.55228 10.4477 8 11 8Z"
          fill="white"
        />
      </svg>
      <svg
        id="stack4"
        width="16"
        height="12"
        viewBox="0 0 16 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 8C16 12 12.4183 12 8 12C3.58172 12 0 12 0 8C0 3.58172 4 0 8 0C12 0 16 3.58172 16 8ZM6 7C6 7.55228 5.55228 8 5 8C4.44772 8 4 7.55228 4 7C4 6.44772 4.44772 6 5 6C5.55228 6 6 6.44772 6 7ZM11 8C11.5523 8 12 7.55228 12 7C12 6.44772 11.5523 6 11 6C10.4477 6 10 6.44772 10 7C10 7.55228 10.4477 8 11 8Z"
          fill="white"
        />
      </svg>
      <svg
        id="stack5"
        width="16"
        height="12"
        viewBox="0 0 16 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 8C16 12 12.4183 12 8 12C3.58172 12 0 12 0 8C0 3.58172 4 0 8 0C12 0 16 3.58172 16 8ZM6 7C6 7.55228 5.55228 8 5 8C4.44772 8 4 7.55228 4 7C4 6.44772 4.44772 6 5 6C5.55228 6 6 6.44772 6 7ZM11 8C11.5523 8 12 7.55228 12 7C12 6.44772 11.5523 6 11 6C10.4477 6 10 6.44772 10 7C10 7.55228 10.4477 8 11 8Z"
          fill="white"
        />
      </svg>
    </div>
  </div>
);

export default LoadingAni;
