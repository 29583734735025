import React, { useState } from "react";
import { PropTypes } from "prop-types";

import darkTheme from "../../../styles/theme";
import StyledDropdownSelect from "./StyledDropdownSelect";
import StyledOption from "./StyledOption";
import { Flex } from "..";
import { Header } from "../../../Storybook";
import EndEllipsisText from "../EndEllipsisText";
import { genNewId } from "../../../utils/mochiHelpers";

const DropdownSelect = ({
  menuItems,
  ariaLabel,
  selectAction,
  colored,
  restrictedWidth,
  modal,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(0);
  const rotateStyle = {
    transform: open ? "rotate(180deg)" : "",
    transition: "transform .1s ease-in",
  };

  const scrollOverflow = menuItems.length > 5;
  const selectedTextOverflows = menuItems[selected].length > 14;

  const styling = {
    position: "absolute",
    height: scrollOverflow && "300px",
    flexDirection: "column",
    zIndex: modal ? "11" : "auto",
    top: restrictedWidth ? "48.5px" : "47px",
    overflowX: "hidden",
    overflowY: menuItems.length > 5 && "scroll",
    borderRadius: "0 0 8px 8px",
  };

  return (
    <>
      <StyledDropdownSelect
        selected={selected}
        role="menu"
        ariaLabel={ariaLabel}
        id={ariaLabel}
        onClick={() => setOpen(!open)}
        restrictedWidth={restrictedWidth}
        {...props}
      >
        <StyledOption
          theme={darkTheme}
          open={open}
          role="menuitem"
          colored={colored}
          selected={true}
          {...props}
        >
          <Flex justifyContent="space-between" alignItems="center">
            {restrictedWidth && selectedTextOverflows ? (
              <EndEllipsisText originalText={menuItems[selected]} length={17} />
            ) : (
              menuItems[selected]
            )}
            <img
              src={colored ? darkTheme.icons.foldDownBlack : darkTheme.icons.foldDown}
              role="button"
              alt={open ? "Fold in" : "Fold out"}
              width="18px"
              style={rotateStyle}
            />
          </Flex>
        </StyledOption>
        {open && (
          <Flex style={styling} width={restrictedWidth ? "100%" : "calc(100% + 20px)"}>
            {menuItems.map(
              (item, ind) =>
                ind !== selected && (
                  <StyledOption
                    position="absolute"
                    key={genNewId("menu-item")}
                    role="menuitem"
                    ariaLabel={item}
                    theme={darkTheme}
                    colored={colored}
                    open={open}
                    selected={selected === ind}
                    last={ind === menuItems.length - 1}
                    onClick={() => {
                      setSelected(ind);
                      selectAction && selectAction(ind);
                    }}
                    {...props}
                  >
                    {item}
                  </StyledOption>
                )
            )}
          </Flex>
        )}
        <label htmlFor={ariaLabel}>
          <Header
            h5
            label={ariaLabel}
            color={colored ? "colorFGPrimary" : "darkFGPrimary"}
            textAlign="left"
            mt={1}
            theme={darkTheme}
          />
        </label>
      </StyledDropdownSelect>
    </>
  );
};

DropdownSelect.propTypes = {
  /**
   * Pass an array of strings with the name of each selection
   */
  menuItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  /**
   * Pass a function to be triggered when one of the menu items is selected.
   * The function will be run with the index of the selected item in the array as an argument.
   */
  selectAction: PropTypes.func.isRequired,
  /**
   * Optional pass another theme once we have Light
   */
  theme: PropTypes.object,
  /**
   * Optional, if the dropdown is in a colored container, pass a color from the theme
   */
  colored: PropTypes.string,
  /**
   * Optional, pass a menu description
   */
  ariaLabel: PropTypes.string,
  /**
   * Optional, pass restrictedWidth when the dropdown is inside a container that affects the width of the options, like a modal or a notification
   */
  restrictedWidth: PropTypes.bool,
  /**
   * Optional, pass modal if the dropdown menu is inside a modal and neds an elevated z-index
   */
  modal: PropTypes.bool,
};

DropdownSelect.defaultProps = {
  theme: darkTheme,
  menuItems: [],
  selectAction: null,
  colored: null,
  ariaLabel: "Dropdown element",
  restrictedWidth: false,
  modal: false,
};

export default DropdownSelect;
