import React from "react";

import { Paragraph } from "../../Storybook";
import { BorderedWrapper } from "../Utility";

const EmptyJourneyState = ({ label }) => (
  <BorderedWrapper>
    <Paragraph
      fontSize="fz2"
      color="darkFGTertiary"
      label={label}
      textAlign="center"
    />
  </BorderedWrapper>
);

export default EmptyJourneyState;
