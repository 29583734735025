import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAccount, useDisconnect, useSignMessage } from "wagmi";
import { useWeb3Modal } from "@web3modal/react";
// import { verifyMessage } from "ethers/lib/utils";

import { Button } from "../../Storybook";
import { SelectConnector } from "./SelectConnector";
import { getAuthToken, authenticateUser } from "../../containers/Web3/actions";
import { shortenAddress } from "../../utils/mochiHelpers";

const Web3Auth = ({ authenticateUser, prompt, primary, communityInvitationId, wide }) => {
  let history = useHistory();
  const [displayConnectors, setDisplayConnectors] = useState(false);
  const { disconnect } = useDisconnect();
  const authenticated = useSelector((state) => state.auth.get("auth"));
  const activeCommunityId = useSelector((state) => state.community.get("activeCommunityId"));
  const { isOpen } = useWeb3Modal();
  const { isConnected, address } = useAccount();
  const { signMessage } = useSignMessage({
    onSuccess: async (data, variables) => {
      // Verify signature when sign message succeeds
      // const address = verifyMessage(variables.message, data);
      let auth_token = variables.message.split(" ~ ")[1];
      const activeCommunityIdOrNull = activeCommunityId ? activeCommunityId : null;
      authenticateUser(communityInvitationId, activeCommunityIdOrNull, data, auth_token);
      const authData = await authenticateUser(
        communityInvitationId,
        activeCommunityIdOrNull,
        data,
        auth_token
      );
      if (!communityInvitationId)
        history.push(`/${authData.communityId}/profile/${authData.userId}`);
    },
    onError(error, variables) {
      // console.log("error.msg", error.message); // "User rejected request"
      disconnect();
    },
  });

  const buttonAction = async () => {
    if (!isConnected) setDisplayConnectors(true);
    else {
      const message = await getAuthToken();
      signMessage({ message });
    }
  };

  const buttonText =
    isConnected && !authenticated
      ? `Sign in as ${shortenAddress(address)}`
      : prompt || "Connect Wallet";

  return (
    <>
      {displayConnectors && !isConnected && !isOpen && (
        <SelectConnector setModalClosed={() => setDisplayConnectors(false)} />
      )}
      <Button
        onClick={() => buttonAction(true)}
        secondary={!primary}
        label={buttonText}
        width={wide && "100%"}
      />
    </>
  );
};

export default connect(() => ({}), {
  authenticateUser,
})(Web3Auth);
