import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { Modal, Button, Paragraph, Header } from "../../Storybook";
import { Flex, Box } from "../Utility";
import { acceptInvitation, declineInvitation } from "../../containers/Community/actions";
import { getPlayerAndTeamDataFromState } from "../../containers/Community/stateGetterHelpers";

const TeamInvitation = ({
  invitation,
  acceptInvitation,
  declineInvitation,
  activeCommunityId,
  playerClaimedAccount,
  communityInvitationLink,
  communityPlatform,
  communityName,
  email,
}) => {
  let history = useHistory();
  const [accepted, setAccepted] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    if (accepted && !error) history.push(`/${activeCommunityId}/team/${invitation.get("team_id")}`);
  });

  const acceptTeamInvitation = (e) => {
    e.preventDefault();
    try {
      acceptInvitation(invitation.get("invitation_id"), invitation.get("team_id"));
      setAccepted(true);
    } catch (error) {
      setAccepted(false);
      setError(`There was an error accepting your invitation to the team: ${error}`);
    }
  };

  const declineTeamInvitation = (e) => {
    e.preventDefault();
    try {
      declineInvitation(invitation.get("invitation_id"));
    } catch (error) {
      setError(`There was an error declining your invitation: ${error}`);
    }
  };

  const platform = communityPlatform === "S" ? "Slack" : "Discord";

  return (
    <>
      <Modal key={invitation.get("invitation_id")} hideClose>
        {error && <Paragraph mb={3} label={error} color="darkNegative700" />}
        {playerClaimedAccount ? (
          <>
            <Paragraph>
              You’ve been invited to join team{" "}
              <span style={{ fontWeight: "800" }}>{invitation.get("team_name")}</span>! You were
              invited to join by{" "}
              <span style={{ fontWeight: "800" }}>{invitation.get("inviter_email")}</span>.
            </Paragraph>
            <Flex mt={20}>
              <Button onClick={(e) => acceptTeamInvitation(e)} primary label="Accept" />
              <Button primary onClick={(e) => declineTeamInvitation(e)} label="Decline" />
            </Flex>
          </>
        ) : (
          <Box mt={3}>
            <Header h3 label="Claim Your Handle" mb={3} />
            <Paragraph
              weight={400}
              fontSize="fz2"
              mb={4}
              label={`To accept your invitation, you must first claim your ${platform} handle for ${communityName}. To claim it, head to ${platform} and type "/claim ${email}" in any channel.`}
            />
            <Button
              primary
              arrow
              label={`Go to ${platform}`}
              onClick={() => window.open(communityInvitationLink, "_blank")}
            />
          </Box>
        )}
      </Modal>
    </>
  );
};

export default connect(
  (state) => {
    const activeCommunityId = state.community.get("activeCommunityId");
    const { playerData } = getPlayerAndTeamDataFromState(state);
    const communityData = state.community.getIn(["communityInfo", activeCommunityId]);
    return {
      activeCommunityId,
      playerClaimedAccount: !!playerData?.getIn(["details", "id"]),
      communityInvitationLink: communityData?.get("invite_link"),
      communityPlatform: communityData?.get("community_type"),
      communityName: communityData?.get("name"),
      email: playerData.get("email"),
    };
  },
  { acceptInvitation, declineInvitation }
)(TeamInvitation);
