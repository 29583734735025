/* eslint-disable no-undef */
import React from "react";
import moment from "moment";
import { parseEther } from "viem";

import { Box, Flex, Text } from "../Utility";
import { JourneyProgressIndicator } from "../Profile/index";
import { TokenIndicator } from "../Profile";
import { isViewingJourneyInprogress } from "../../utils/mochiHelpers";
import { getReportRate, setReportRateMotivation } from "../../utils/reportRateHelpers";
import { Header } from "../../Storybook";

export const JourneyInfo = ({
  goalText,
  selectedJourney,
  isAnotherPlayersProfile,
  currentPlayerId,
}) => {
  const journeyIsInProgress = !!selectedJourney && isViewingJourneyInprogress(selectedJourney);

  const subJourneyText =
    !!selectedJourney && journeyIsInProgress
      ? "Current"
      : moment().isBefore(moment(selectedJourney?.get("start"), "MM/DD/YYYY"))
      ? "Upcoming"
      : "A Previous";

  // journey specific report rate
  const reportRate = getReportRate(selectedJourney, currentPlayerId);
  const tokensEarnedInJourney =
    selectedJourney
      ?.getIn(["player_totals", currentPlayerId.toString(), "total_tokens_earned"])
      .toString() || "0";

  const { motivation, motivationColor } = setReportRateMotivation(reportRate);

  return (
    <Box mb={2}>
      <Flex justifyContent="left" alignItems="center">
        {subJourneyText && (
          <Text small muted mr={1}>
            {subJourneyText} Journey
          </Text>
        )}
        {!!journeyIsInProgress && !!selectedJourney && (
          <Text small muted icon>
            <JourneyProgressIndicator startDate={selectedJourney.get("start")} />
          </Text>
        )}
      </Flex>
      {!!goalText && (
        <Header
          fontSize={["30px", "30px", "42px"]}
          label={goalText.charAt(0).toUpperCase() + goalText.slice(1)}
          mb={2}
        />
      )}
      <Flex justifyContent="left" alignItems="center" mt={16} mb={1}>
        {typeof reportRate === "number" && (
          <Text color={motivationColor}>
            {`${reportRate}% report rate`}
            {!!journeyIsInProgress && !isAnotherPlayersProfile && ` — ${motivation}`}!
          </Text>
        )}
      </Flex>
      <TokenIndicator amountOfTokensBN={BigInt(parseEther(tokensEarnedInJourney))} />
    </Box>
  );
};

export default JourneyInfo;
