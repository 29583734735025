import React, { useEffect, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import AuthorizedHOC from "../components/Utility/AuthorizedHOC";
import { GridContainer, Loading } from "../components/Utility";
import { GridBox, Header } from "../Storybook";
import { getPlayerAndTeamDataFromState } from "../containers/Community/stateGetterHelpers";
import moment from "moment";
import { JourneyStatus } from "../components/Profile";
import {
  fetchJourneyDetails,
  getExternalPlayerInfo,
  getTeamInfo,
} from "../containers/Community/actions";

export const JourneyPage = ({ fetchJourneyDetails, getTeamInfo, getExternalPlayerInfo }) => {
  const { playerId, journeyId, goalId, teamId } = useParams();
  const activeCommunityId = useSelector((state) => state.community.get("activeCommunityId"));
  const teamData = useSelector((state) =>
    state.community.getIn(["teams", activeCommunityId, Number(teamId)])
  );
  const { playerData } = useSelector((state) => getPlayerAndTeamDataFromState(state, playerId));
  const authUserId = useSelector((state) => state.auth.get("authedUserId"));
  const isAnotherPlayersJourney = playerId !== authUserId;
  const playerJourney = useSelector((state) =>
    state.community.getIn(["journeys", activeCommunityId, Number(journeyId), playerId.toString()])
  );

  const location = useLocation();
  // extract the journey number from the URL
  const journeyCount = new URLSearchParams(location.search).get("journeyNumber");

  // In the instances when player is navigating from an external site or refreshes the page and there is no player information on state, fetch it
  useEffect(() => {
    if (!playerData) getExternalPlayerInfo(playerId, activeCommunityId);
  }, [getExternalPlayerInfo, playerData, playerId, activeCommunityId]);

  // fetch the journey details if they're not already on state
  useEffect(() => {
    if (!playerJourney && !!journeyId) fetchJourneyDetails(journeyId);
  }, [journeyId, playerJourney, fetchJourneyDetails]);

  // fetch the teamData if not already on state
  useEffect(() => {
    if (!teamData && !!teamId) getTeamInfo(teamId, activeCommunityId);
  }, [teamId, activeCommunityId, teamData, getTeamInfo]);

  // find the goal associated
  const goal = useMemo(() => {
    if (!!playerData?.get("goals")) {
      return playerData?.get("goals").find((g) => g.get("id") === goalId && g);
    }
  }, [playerData, goalId]);

  // ensure page is not loaded until goals and journey are on the state
  if (!playerJourney || !playerData?.get("goals")) return <Loading />;
  return (
    <AuthorizedHOC>
      <GridContainer mt={4}>
        <GridBox>
          {!!teamData && (
            <Header h4 color="darkFGSecondary" id="journey-page">
              {`Team ${teamData.get("name")}`}
              {journeyCount && ` - Journey #${journeyCount}`}
            </Header>
          )}
          <Header
            h1
            mb={3}
            label={`${moment(playerJourney.get("start"), "MM/DD/YYYY").format("MMMM DD")}-${moment(
              playerJourney.get("end"),
              "MM/DD/YYYY"
            ).format("MMMM DD")}`}
          />
        </GridBox>
        <GridBox>
          <JourneyStatus
            playerData={playerData}
            journeyDetails={playerJourney}
            isAnotherPlayersProfile={isAnotherPlayersJourney}
            goal={goal?.get("goal")}
            currentPlayerId={playerId}
          />
        </GridBox>
      </GridContainer>
    </AuthorizedHOC>
  );
};

export default connect((state, props) => ({}), {
  fetchJourneyDetails,
  getTeamInfo,
  getExternalPlayerInfo,
})(JourneyPage);
