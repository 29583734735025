import React, { useState } from "react";

import useInput from "./utils/useInput";
import * as fieldValidators from "./utils/fieldValidators";
import { recoverAccount } from "../../containers/Community/helpers";
import { Input, Button, Paragraph } from "../../Storybook";
import Space from "../Utility/Space";
import { validEmailAddressError } from "./utils/formMessageStrings";

export const RecoverAccount = () => {
  const {
    value: email,
    bind: bindEmail,
    valid: validEmail,
    active: activeEmail,
  } = useInput("", fieldValidators.emailField, true);
  const [submited, setSubmited] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setSubmited(true);
    if (validEmail) {
      setError("");
      try {
        await recoverAccount(email);
        setSuccess(true);
      } catch (e) {
        setError("Your email was not found in our system.");
      }
    } else {
      setError(validEmailAddressError);
    }
  };

  if (success)
    return (
      <Paragraph textAlign="center">
        A link has been sent to your email address. Follow that link to reset
        your password.
      </Paragraph>
    );
  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <Input
        type="email"
        placeholder="Email Address"
        submited={submited}
        textLabel="E-mail Address"
        activeInput={activeEmail}
        error={validEmailAddressError}
        {...bindEmail}
      />
      {error.length > 0 ? (
        <Paragraph textAlign="center" color="darkNegative500" mb={3}>
          {error}
        </Paragraph>
      ) : (
        <Space mt={3} />
      )}
      <Button primary disabled={!validEmail} type="submit">
        Submit
      </Button>
    </form>
  );
};

export default RecoverAccount;
