import React from "react";
import styled from "styled-components";
import { space, layout, grid, flexbox, color, background } from "styled-system";

export const Container = styled.div(
  space,
  layout,
  grid,
  flexbox,
  color,
  background
);

const GridContainer = ({
  style,
  alignItems,
  children,
  ignoreHeight = false,
  fixedWidth = true,
  ...props
}) => {
  const height = ignoreHeight ? {} : { minHeight: "75vh" };

  return (
    <Container
      display="grid"
      width="100%"
      justifyContent="center"
      gridTemplateColumns={[
        "repeat(4, 1fr)",
        "repeat(8, 1fr)",
        fixedWidth ? "repeat(12, 60px)" : "repeat(12, 1fr)", //
      ]}
      gridColumnGap={["20px", "20px", "20px", "32px"]}
      gridRowGap={["20px", "20px", "20px", "32px"]}
      padding={
        !fixedWidth ? 0 : ["20px 10px", "20px 10px", "20px 10px", "32px 20px"]
      }
      style={{ ...height, ...style, alignItems: alignItems || "center" }}
      {...props}
    >
      {children}
    </Container>
  );
};

export default GridContainer;
