import { fromJS } from "immutable";

import initialState from "./initialState";
import {
  WEB3_INIT,
  CONTRACT_INIT,
  MOCHI_TOKEN_BALANCE,
  DISCONNECT_WEB3,
  SET_CURRENT_NETWORK,
  LOG_OUT,
} from "../../constants/actionTypes";
import { WEB3_NETWORK } from "../../constants/endpoints";

/* eslint-disable complexity */
const reducer = (state = fromJS(initialState), action) => {
  switch (action.type) {
    case WEB3_INIT:
      return state
        .set("ethereumAddress", action.address)
        .set("currentNetwork", action.currentNetwork)
        .set("provider", action.provider)
        .set("web3Modal", action.web3Modal)
        .set("wrongNetwork", action.currentNetwork !== WEB3_NETWORK);
    case CONTRACT_INIT:
      return state
        .setIn(
          ["contractInstances", action.activeCommunityId, "gameContract"],
          action.gameContract
        )
        .setIn(
          ["contractInstances", action.activeCommunityId, "tokenContract"],
          action.tokenContract
        );
    case MOCHI_TOKEN_BALANCE:
      return state.set("mochiTokenBalance", action.mochiTokenBalance);
    case DISCONNECT_WEB3:
      return state
        .set("ethereumAddress", "")
        .set("currentNetwork", null)
        .set("provider", {});
    case LOG_OUT:
      localStorage.setItem("WEB3_CONNECT_CACHED_PROVIDER", null);
      return state;
    case SET_CURRENT_NETWORK:
      return state.set("currentNetwork", action.currentNetwork);
    default:
      return state;
  }
};

export default reducer;
