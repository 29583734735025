import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";

import { JourneyStatus } from "./index";
import JourneyNotification from "../Journey/JourneyNotifications/JourneyNotification";
import { Flex } from "../Utility";
import {
  getOverallCurrentJourneyFromState,
  getPlayerAndTeamDataFromState,
} from "../../containers/Community/stateGetterHelpers";
import { fetchJourneyDetails } from "../../containers/Community/actions";

export const PlayerJourney = ({
  isAnotherPlayersProfile,
  currentPlayerId,
  playerData,
  teamData,
  journeyData,
  fetchJourneyDetails,
  journeyDetails,
  amLeader,
  journeyId,
  currentJourney,
  activeCommunityId,
}) => {
  // if playerdata indicated that there is journey data but the journey details are not on state, fetch them
  useEffect(() => {
    if (!journeyDetails && !!journeyData?.size)
      fetchJourneyDetails(journeyId, activeCommunityId);
  }, [
    journeyId,
    activeCommunityId,
    journeyData,
    journeyDetails,
    fetchJourneyDetails,
  ]);

  if (!playerData) return <></>;
  return (
    <Fragment>
      {!isAnotherPlayersProfile && (
        <Flex flexDirection="row" justifyContent="center">
          <JourneyNotification
            onTeam={playerData?.get("team_id")}
            currentJourney={currentJourney}
            newestJourney={playerData.getIn(["journeyData", 0]) || teamData?.getIn(["journeys", 0])}
            playerId={playerData?.get("user_id")}
            playerClaimedAccount={!!playerData?.getIn(["details", "id"])}
            isTeamPageNotification={false}
            amLeader={amLeader}
          />
        </Flex>
      )}
      {/* // TODO: Rename components to make sense of new setup //
      JourneyStatus: Reports, status and goal for journey */}
      <JourneyStatus
        playerData={playerData}
        journeyDetails={journeyDetails}
        isAnotherPlayersProfile={isAnotherPlayersProfile}
        goal={playerData?.get("current_goal")}
        currentPlayerId={currentPlayerId}
      />
    </Fragment>
  );
};

export default connect(
  (state, props) => {
    const currentPlayerId = Number(props.match.params.profileUserId);
    const { playerData, teamData } = getPlayerAndTeamDataFromState(state, currentPlayerId);
    const isAnotherPlayersProfile = currentPlayerId !== state.community.get("authedUserId");
    const activeCommunityId = state.community.get("activeCommunityId");
    const journeyData = playerData?.get("journeyData");
    const journeyId =
      playerData.get("current_journey") || journeyData?.getIn([props.journeyInd || 0, "id"]);

    return {
      isAnotherPlayersProfile,
      playerData,
      teamData,
      activeCommunityId,
      currentPlayerId,
      journeyId,
      journeyData,
      currentJourney: getOverallCurrentJourneyFromState(state, currentPlayerId),
      journeyDetails: state.community.getIn([
        "journeys",
        activeCommunityId,
        journeyId,
        currentPlayerId.toString(),
      ]),
      amLeader: teamData?.get("leader") === playerData?.get("user_id"),
    };
  },
  { fetchJourneyDetails }
)(PlayerJourney);
