import React, { useState } from "react";
import { connect } from "react-redux";

import { Flex, Square, Fill, Box, ApiError, Counter } from "../Utility";
import { Button, Paragraph, Modal } from "../../Storybook";
import { generalItemActivatedMsg } from "./shopStrings";
import {
  applyGameItem,
  getPlayerInventory,
} from "../../containers/Shop/actions";
import ShopItemDetails from "./ShopItemDetails";
import { List } from "immutable";
import ChangeGoal from "../Journey/ChangeGoal";

const InventoryTile = ({
  itemInfo,
  item,
  inventoryItem,
  totalItemInventory,
  getPlayerInventory,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [used, setUsed] = useState(!inventoryItem);
  const [viewGoalSetting, setViewGoalSetting] = useState(false);

  const showModal = showDetails || !!error?.length || success;

  const closeModal = () => {
    setShowDetails(false);
    setSuccess(false);
    setError("");
    getPlayerInventory();
  };

  const activateItem = async () => {
    if (item.get("item_type") !== "WH") {
      try {
        setError("");
        await applyGameItem(inventoryItem?.get("id"));
        setUsed(true);
        setSuccess(generalItemActivatedMsg);
      } catch (er) {
        setError(er.request?.statusText);
      }
    } else setViewGoalSetting(true);
  };

  return (
    <>
      {showModal && (
        <Modal onClose={() => closeModal()}>
          {showDetails && (
            <Box>
              <ShopItemDetails itemInfo={itemInfo} price={item.get("retail")} />
              {!!error?.length && <ApiError msg={error} mb={4} />}
              {success && (
                <Paragraph
                  color="darkPositive700"
                  label={generalItemActivatedMsg}
                  mb={4}
                />
              )}
              <Flex mt={4}>
                {!used && itemInfo.needsActivation && (
                  <Button
                    secondary
                    label="Use"
                    width="100%"
                    onClick={(e) => activateItem(e)}
                    mr={3}
                    disabled={!!error || !!success}
                  />
                )}
                <Button
                  primary
                  label="Dismiss"
                  width="100%"
                  onClick={() => closeModal()}
                />
              </Flex>
            </Box>
          )}
        </Modal>
      )}
      {viewGoalSetting && (
        <Modal onClose={() => setViewGoalSetting(false)}>
          <ChangeGoal
            wishItem={inventoryItem}
            postAction={() => {
              setSuccess(true);
              setViewGoalSetting(false);
            }}
            exit={() => setViewGoalSetting(false)}
          />
        </Modal>
      )}
      <Square onClick={() => !used && setShowDetails(true)}>
        <Fill
          bgColor="darkBGTertiary"
          borderRadius="2px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {!!itemInfo?.image && !used ? (
            <>
              <Counter
                number={totalItemInventory.toString()}
                bottom="6.56%"
                right="6.56%"
              />
              <img
                width="80%"
                height="80%"
                src={itemInfo.image}
                alt={itemInfo.name}
              />
            </>
          ) : null}
        </Fill>
      </Square>
    </>
  );
};

export default connect(
  (state, props) => {
    const playerId = state.community.get("authedUserId");
    const activeCommunityId = state.community.get("activeCommunityId");

    const inventory =
      state.shop.getIn([
        "inventory",
        activeCommunityId,
        playerId,
        props.item?.get("item_type"),
      ]) || new List();

    const unusedInventory = inventory.filter(
      (it) => it.get("is_used") === false
    );

    return {
      playerId,
      totalItemInventory: unusedInventory.size,
      inventoryItem: unusedInventory.last(),
    };
  },
  { getPlayerInventory }
)(InventoryTile);
