import React from "react";
import moment from "moment";
import { formatEther } from "viem";

import { Box, EthLogo, Flex } from "../Utility";
import oneToken from "../../img/tokens/one-token.png";
import { Header, Paragraph } from "../../Storybook";
import { formatDisplayDate, genNewId } from "../../utils/mochiHelpers";

const DailyReportsStats = ({
  isAnotherPlayersProfile,
  playerId,
  playerName,
  teamInfo,
  ethLost,
  displayFailure,
  isBeforeCheckInDeadline,
  displayTokens,
  checkInSuccess,
  tokenAward,
  showAndTell,
  teamMisses = [],
  isPaused,
  journeyQuitDate,
  quitEthLost,
  reportDate,
  outstandingReport,
  // checkInDue, // TODO: why is this here?
}) => {
  const displayDate = formatDisplayDate(moment(reportDate, "MM/DD/YYYY"));
  const reportIsToday = displayDate === "Today";
  const failedShowNTell = !!showAndTell && showAndTell?.get("player_slash_amount") >= 0;
  const failedToSubmitButEnoughShowNTells =
    !!showAndTell && showAndTell.get("completed") === null && !showAndTell.get("report");
  const quitDateIsSameAsReportDate = moment(reportDate, "MM/DD/YYYY").isSame(
    moment(journeyQuitDate, "MM/DD/YYYY")
  );
  const displayEthLost =
    quitDateIsSameAsReportDate && quitEthLost ? quitEthLost + ethLost : ethLost;
  return (
    <Flex
      flexDirection="column"
      alignItems="flex-start"
      alignContent="flex-start"
      justifyContent="flex-start"
      mt={3}
      pr={4}
      width={[
        1,
        1,
        !checkInSuccess && !showAndTell?.get("report") && !(outstandingReport && reportIsToday)
          ? 1
          : 1 / 2,
      ]}
    >
      <Header
        h3
        fontSize={["22px", "30px"]}
        lineHeight={["24.75px", "33.75px"]}
        mt={0}
        mb={3}
        label={displayDate}
      />
      {/* TODO: Get streak data for current journey */}
      {/* <Box mb={2}> */}
      {/* <Text leftIcon>🍡</Text> */}
      {/* <Text green >many days steak!</Text> */}
      {/* </Box> */}
      <Box marginLeft="4px">
        {quitDateIsSameAsReportDate && (
          <Paragraph
            color="darkFGSecondary"
            mb={2}
            weight={400}
            label={`
          ☠️ ${isAnotherPlayersProfile ? `${playerName} quit their` : "You quit your"} journey...
          `}
          />
        )}
        {!!displayTokens && (
          <Flex justifyContent="flex-start" alignItems="baseline" mb={2}>
            <Paragraph
              color="darkWarning500"
              weight={900}
              mr={1}
              size="fz2"
              label={`+${tokenAward} `}
            />
            <img width="14px" height="14px" src={oneToken} alt="One MochiToken Coin" />
            <Paragraph
              color="darkWarning500"
              weight={900}
              size="fz2"
              ml={1}
              label={" Mochi Tokens"}
            />
          </Flex>
        )}
        {!!displayEthLost && (!reportIsToday || !!teamMisses.size || quitDateIsSameAsReportDate) && (
          <Box mb={2}>
            <Paragraph color="darkNegative700" weight={900} size="fz2">
              -<EthLogo />
              {Number(formatEther(displayEthLost.toString())).toFixed(5)} 💔 Bond
            </Paragraph>
          </Box>
        )}
        {quitDateIsSameAsReportDate && quitEthLost > 0 && (
          <Paragraph color="darkNegative700" label="You lost your bond for quitting..." />
        )}
        {checkInSuccess !== undefined && !checkInSuccess && !quitDateIsSameAsReportDate && (
          <Box mb={2}>
            {!(outstandingReport && reportIsToday) && (
              <>
                {!!isBeforeCheckInDeadline || reportIsToday || isPaused ? (
                  <Paragraph
                    color={!isPaused ? "darkWarning500" : "darkCyan500"}
                    size="fz2"
                    mb={2}
                    label={
                      isPaused
                        ? `Kick back, relax - you're on vacation 🏝`
                        : !isAnotherPlayersProfile
                        ? "You haven't yet submitted your pearl of wisdom."
                        : `${playerName} hasn't yet submitted their pearl of wisdom.`
                    }
                  />
                ) : (
                  displayFailure && (
                    <Paragraph
                      color="darkNegative700"
                      size="fz2"
                      label={
                        !isAnotherPlayersProfile
                          ? "You failed to submit your pearl of wisdom."
                          : `${playerName} failed to submit their pearl of wisdom.`
                      }
                    />
                  )
                )}
              </>
            )}
          </Box>
        )}

        {!!teamMisses.size &&
          teamMisses.map((teammateId) => (
            <Box mb={2} key={genNewId(`teammate-${teammateId}`)}>
              {!!(teammateId !== playerId) && (
                <Paragraph color={"darkNegative700"} size="fz2">
                  {`${teamInfo.getIn([
                    teammateId.toString(),
                    "user_info",
                    "first_name",
                  ])} ${teamInfo.getIn([
                    teammateId.toString(),
                    "user_info",
                    "last_name",
                  ])} failed to submit a pearl of wisdom.`}
                </Paragraph>
              )}
            </Box>
          ))}
        {failedShowNTell ? (
          <Paragraph
            mb={3}
            size="fz2"
            color={"darkNegative700"}
            textAlign="left"
            label={`
                ${
                  !!showAndTell.get("report")
                    ? "Not enough reflections were submitted."
                    : failedShowNTell && "No Show & Tell was submitted."
                }
                `}
          />
        ) : (
          failedToSubmitButEnoughShowNTells && (
            <Paragraph mb={3} size="fz2" color="darkFGSecondary" textAlign="left">
              &#128065; {isAnotherPlayersProfile ? playerName : "You"} didn't submit a reflection...
            </Paragraph>
          )
        )}
      </Box>
    </Flex>
  );
};

export default DailyReportsStats;
