import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import { formatEther } from "viem";

import { Modal, Space } from "../../Utility";
import { Button, Paragraph } from "../../../Storybook";
import { removeTeamMembership } from "../../../containers/Community/actions";
import {
  getPlayerAndTeamDataFromState,
  isCurrentlyInAJourney,
} from "../../../containers/Community/stateGetterHelpers";
import {
  infoJourneyNotStartedLeader,
  infoJourneyNotStartedMember,
  infoNoQuitAsLeader,
  infoLeaveTeamFailure,
} from "./QuitModalStrings";

export const QuitJourney = ({
  playerData,
  teamData,
  removeTeamMembership,
  minimumStakeToLose,
  currentlyInJourney,
}) => {
  const [infoModal, setInfoModal] = useState("");
  const [quitModal, setQuitModal] = useState(false);
  const amLeader = teamData?.get("leader") === playerData?.get("user_id") || false;

  const quitBtnClick = () => {
    if (!currentlyInJourney) {
      if (amLeader) setInfoModal(infoJourneyNotStartedLeader);
      else setInfoModal(infoJourneyNotStartedMember);
    } else setQuitModal(true);
  };

  const quitJourney = async () => {
    setQuitModal(false);
    if (amLeader && currentlyInJourney) {
      setInfoModal(infoNoQuitAsLeader);
    } else {
      try {
        await removeTeamMembership();
      } catch (e) {
        setInfoModal(infoLeaveTeamFailure);
      }
    }
  };

  return (
    <Fragment>
      <Button
        secondary
        bgColor="darkNegative500"
        width={[1, 1, 1]}
        mb={2}
        onClick={(e) => quitBtnClick()}
      >
        Leave Team
      </Button>
      {!!infoModal.length && (
        <Modal onClose={() => setInfoModal("")}>
          <Space mb={20} />
          <Paragraph>{infoModal}</Paragraph>
        </Modal>
      )}
      {quitModal && (
        <Modal onClose={() => setQuitModal(false)}>
          <Space mb={20} />
          <Paragraph data-testid="quit-modal-info">
            Are you sure you want to quit in the middle of this journey? If so, you must be willing
            to sacrifice the minimum stake required to play at your difficulty. You will also be
            removed from your current team.
          </Paragraph>
          <Space mb={20} />
          <Paragraph>
            It will cost you{" "}
            <span data-testid="quit-modal-eth-lost">
              {formatEther(minimumStakeToLose.toString())}
            </span>{" "}
            ETH. Click the button below if you wish to continue.
          </Paragraph>
          <Button
            secondary
            bgColor="darkNegative500"
            data-testid="quit-modal-btn"
            mt={2}
            onClick={() => quitJourney()}
          >
            Quit Journey
          </Button>
        </Modal>
      )}
    </Fragment>
  );
};

export default connect(
  (state) => {
    const { playerData, teamData } = getPlayerAndTeamDataFromState(state);
    const gameModes = state.gameModes.get("all");
    const currentGameMode = gameModes.find((gm) => gm.get("id") === playerData.get("game_mode"));
    return {
      playerData,
      currentlyInJourney: isCurrentlyInAJourney(state),
      minimumStakeToLose: currentGameMode ? currentGameMode.get("minimum_stake") : 0,
      teamData,
    };
  },
  { removeTeamMembership }
)(QuitJourney);
