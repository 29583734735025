import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";

import PlayerSpecifics from "./PlayerSpecifics";
import MochiHealth from "./MochiHealth";
import { convertWeiToEth, setFirmnessAndMinimumStake } from "../../utils/mochiHelpers";
import { getExternalPlayerInfo, fetchDiscorduserAvatar } from "../../containers/Community/actions";
import { Box, Flex, EthLogo } from "../Utility";
import { Header, Paragraph } from "../../Storybook";
import CenterEllipsisText from "../Utility/CenterEllipsisText";
import { getLatestJourney } from "../../containers/Community/stateGetterHelpers";

export const PlayerMochiSB = ({
  playerId,
  email,
  playerData,
  gameModes,
  isProfile,
  isAnotherPlayersProfile,
  activeCommunityId,
  communityInfo,
  fetchDiscorduserAvatar,
  mostRecentJourney,
}) => {
  const [currentFirmness, setCurrenFirmness] = useState();
  const [minimumStake, setMinimumStake] = useState();
  const [ethBalance, setEthBalance] = useState(null);
  const [avatarRequested, setRequestedAvatar] = useState();

  const mochiColor = playerData?.get("color") || "none";

  const slashes =
    (!!mostRecentJourney &&
      mostRecentJourney?.getIn(["player_totals", playerId.toString(), "total_slash"])) ||
    0;

  const weiBalance = playerData?.get("wei_balance") >= 0 ? playerData.get("wei_balance") : null;

  const originalGameModeId =
    (!!mostRecentJourney && mostRecentJourney?.get("gameModeId")) || playerData?.get("game_mode");

  useEffect(() => {
    if (!playerData?.get("user_id") && !!playerId && isAnotherPlayersProfile) {
      getExternalPlayerInfo(playerId, activeCommunityId);
    }
  });

  useEffect(() => {
    if (
      playerData?.getIn(["details", "image_512"]) === null &&
      communityInfo?.get("community_type") === "D" &&
      !avatarRequested
    ) {
      fetchDiscorduserAvatar(activeCommunityId, playerId);
      setRequestedAvatar(true);
    }
  }, [
    playerData,
    communityInfo,
    avatarRequested,
    fetchDiscorduserAvatar,
    activeCommunityId,
    playerId,
  ]);

  useEffect(() => {
    if (weiBalance !== null) {
      const formattedEth = convertWeiToEth(weiBalance);
      setEthBalance(formattedEth);

      const { currFirmness, minStake } = setFirmnessAndMinimumStake(
        playerData?.get("game_mode"),
        playerData?.get("wei_balance"),
        gameModes
      );
      setCurrenFirmness(currFirmness);
      setMinimumStake(minStake);
    }
  }, [playerData, gameModes, weiBalance]);

  return (
    <Box mb={3}>
      <Box style={{ cursor: "pointer" }}>
        <MochiHealth
          playerId={playerId}
          isProfile={isProfile}
          activeCommunityId={activeCommunityId}
          isAnotherPlayersProfile={isAnotherPlayersProfile}
          minimumStake={minimumStake}
          weiBalance={weiBalance}
          slashes={slashes}
          gameModeId={playerData?.get("game_mode")}
          mochiColor={mochiColor}
          journeyCount={playerData?.get("journeyData")?.size}
          originalGameMode={gameModes?.find((gm) => gm.get("id") === originalGameModeId)}
          currentGameMode={gameModes?.find((gm) => gm.get("id") === playerData?.get("game_mode"))}
          isNewUser={!playerData?.get("team_id") && !playerData?.get("journeyData")?.size}
          isActivated={!!playerData?.get("email")}
        />
      </Box>
      <Box mb={1}>
        <Flex justifyContent="center" alignItems="baseline">
          {!!ethBalance && (
            <>
              <EthLogo />
              <Paragraph
                m={0}
                fontSize="fz2"
                color="darkFGSecondary"
                label={ethBalance.toString()}
              />
            </>
          )}
          <Header h4 ml={2} color="darkFGSecondary">
            {currentFirmness ? currentFirmness : "?¿?"}
          </Header>
        </Flex>
      </Box>
      {playerData?.get("eth_address") ? (
        <PlayerSpecifics
          playerData={playerData}
          isAnotherPlayersProfile={isAnotherPlayersProfile}
          activeCommunityId={activeCommunityId}
          communityInfo={communityInfo}
          isProfile={isProfile}
        />
      ) : !isAnotherPlayersProfile ? (
        <Header h2 label={playerData?.get("eth_address")} textAlign="center" mt={9}>
          <CenterEllipsisText originalText={playerData?.get("eth_address")} length={16} />
        </Header>
      ) : (
        <Header h2 label={!!email ? "Pending" : "Vacant"} textAlign="center" />
      )}
    </Box>
  );
};

PlayerMochiSB.propTypes = {
  playerId: PropTypes.number,
  playerData: PropTypes.object,
  gameModes: PropTypes.object,
  isProfile: PropTypes.bool,
  isAnotherPlayersProfile: PropTypes.bool,
  activeCommunityId: PropTypes.number,
  getExternalPlayerInfo: PropTypes.func,
  fetchDiscorduserAvatar: PropTypes.func,
  communityInfo: PropTypes.object,
  email: PropTypes.string,
};

PlayerMochiSB.defaultProps = {
  playerId: null,
  playerData: null,
  gameModes: null,
  isProfile: false,
  isAnotherPlayersProfile: true,
  activeCommunityId: null,
  getExternalPlayerInfo: null,
  fetchDiscorduserAvatar: null,
  communityInfo: null,
  email: null,
};

const PlayerMochi = PlayerMochiSB;

export default connect(
  (state, props) => {
    const activeCommunityId = state.community.get("activeCommunityId");
    const authedUserId = state.community.get("authedUserId");
    let playerData;
    if (props.playerId)
      playerData = state.community.getIn(["players", activeCommunityId, Number(props.playerId)]);

    return {
      playerData,
      activeCommunityId,
      gameModes: state.gameModes.get("all"),
      isAnotherPlayersProfile: Number(authedUserId) !== Number(props.playerId),
      communityInfo: state.community.getIn(["communityInfo", activeCommunityId]),
      mostRecentJourney: !!playerData && getLatestJourney(state, playerData.get("user_id")),
    };
  },
  { fetchDiscorduserAvatar }
)(PlayerMochi);
