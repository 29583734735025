import moment from "moment";

export const emailField = (str) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(str);

export const basicTextField = (str) => !!str && str.length > 0;

export const validPassword = (str) => !!str && str.length >= 8;

export const validTopUp = (str) => !!str && !isNaN(str);
export const validWithdrawal = (str) =>
  Number(str) >= 0 && !!str && !isNaN(str);

export const validClaim = (str) => Number(str) >= 0 && !!str && !isNaN(str);

export const validEthAddress = (str) =>
  str.slice(0, 2) === "0x" && str.length === 42;

export const notWeekend = (date) => {
  const day = moment(date).day();
  return day > 0 && day < 6;
};

export const isMonday = (date) => {
  const selectedDate = moment(date);
  const today = moment();
  return selectedDate.day() === 1 && selectedDate.isAfter(today, "day");
};
