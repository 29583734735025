import React from "react";

import { GridContainer, Header, Body, Space } from "../components/Utility";
import { GridBox } from "../Storybook";

export const PrivacyPolicyPage = (props) => {
  return (
    <GridContainer>
      <GridBox span={[4, 6, 6, 6]} start={[1, 2, 4, 4]} mb={4}>
        <Header h1 mt={4} mb={4}>
          Privacy Policy
        </Header>

        <Body mt="0">
          Protecting your private information is our priority. This Statement of
          Privacy applies to MOCHI, and LIQHTWORKS, INC. and governs data
          collection and usage. For the purposes of this Privacy Policy, unless
          otherwise noted, all references to LIQHTWORKS, INC. include mochi.game
          and LIQHTWORKS, INC.. The LIQHTWORKS, INC. application is a social
          productivity application. By using the LIQHTWORKS, INC. application,
          you consent to the data practices described in this statement.
        </Body>

        <Header h3 mt={4} mb={4}>
          Collection of your Information
        </Header>

        <Body mt="0">
          In order to better provide you with products and services offered,
          LIQHTWORKS, INC. may collect personally identifiable information, such
          as your:
        </Body>
        <Space mb={20} />
        <ul>
          <li>First and Last name</li>
          <li>Mailing Address</li>
          <li>E-mail Address</li>
          <li>Phone Number</li>
          <li>Wallet Address</li>
          <li>Forum or discussion board activities</li>
        </ul>
        <Space mb={20} />
        <Body mt="0">
          If you purchase LIQHTWORKS, INC.'s products and services, we collect
          billing and credit card information. This information is used to
          complete the purchase transaction.
        </Body>
        <Space mb={20} />
        <Body mt="0">
          LIQHTWORKS, INC. may also collect anonymous demographic information,
          which is not unique to you, such as your:
        </Body>
        <Space mb={20} />
        <ul>
          <li>Age</li>
          <li>Gender</li>
          <li>Race</li>
          <li>Religion</li>
          <li>Political Affiliation</li>
          <li>Household Income</li>
        </ul>
        <Space mb={20} />
        <Body mt="0">
          We do not collect any personal information about you unless you
          voluntarily provide it to us. However, you may be required to provide
          certain personal information to us when you elect to use certain
          products or services. These may include: (a) registering for an
          account; (b) entering a sweepstakes or contest sponsored by us or one
          of our partners; (c) signing up for special offers from selected third
          parties; (d) sending us an email message; (e) submitting your credit
          card or other payment information when ordering and purchasing
          products and services. To wit, we will use your information for, but
          not limited to, communicating with you in relation to services and/or
          products you have requested from us. We also may gather additional
          personal or non-personal information in the future.
        </Body>
        <Space mb={20} />
        <Header h3 mt={4} mb={4}>
          Use of your Information
        </Header>
        <Body mt="0">
          LIQHTWORKS, INC. collects and uses your personal information to
          operate and deliver the services you have requested.
        </Body>
        <Space mb={20} />
        <Body mt="0">
          LIQHTWORKS, INC. may also use your personally identifiable information
          to inform you of other products or services available from LIQHTWORKS,
          INC. and its affiliates.
        </Body>
        <Header h3 mt={4} mb={4}>
          Sharing Information with Third Parties
        </Header>
        <Body mt="0">
          LIQHTWORKS, INC. does not sell, rent or lease its customer lists to
          third parties.
        </Body>
        <Body>
          LIQHTWORKS, INC. may, from time to time, contact you on behalf of
          external business partners about a particular offering that may be of
          interest to you. In those cases, your unique personally identifiable
          information (e-mail, name, address, telephone number) is transferred
          to the third party. LIQHTWORKS, INC. may share data with trusted
          partners to help perform statistical analysis, send you email or
          postal mail, provide customer support, or arrange for deliveries. All
          such third parties are prohibited from using your personal information
          except to provide these services to LIQHTWORKS, INC., and they are
          required to maintain the confidentiality of your information.
        </Body>
        <Body mt="0">
          LIQHTWORKS, INC. may disclose your personal information, without
          notice, if required to do so by law or in the good faith belief that
          such action is necessary to: (a) conform to the edicts of the law or
          comply with legal process served on LIQHTWORKS, INC. or the site; (b)
          protect and defend the rights or property of LIQHTWORKS, INC.; and/or
          (c) act under exigent circumstances to protect the personal safety of
          users of LIQHTWORKS, INC., or the public.
        </Body>
        <Header h3 mt={4} mb={4}>
          Opt-Out Disclosure of Personal Information to Third Parties
        </Header>
        <Body mt="0">
          In connection with any personal information we may disclose to a third
          party for a business purpose, you have the right to know:
          <ul>
            <li>
              The categories of personal information that we disclosed about you
              for a business purpose.
            </li>
          </ul>
          <Body mt="0">
            You have the right under the California Consumer Privacy Act of 2018
            (CCPA) and certain other privacy and data protection laws, as
            applicable, to opt-out of the disclosure of your personal
            information. If you exercise your right to opt-out of the disclosure
            of your personal information, we will refrain from disclosing your
            personal information, unless you subsequently provide express
            authorization for the disclosure of your personal information. To
            opt-out of the disclosure of your personal information, please
            contact contact@mochi.game.
          </Body>
        </Body>
        <Header h3 mt={4} mb={4}>
          Right to Deletion
        </Header>
        <Body mt="0">
          Subject to certain exceptions set out below, on receipt of a
          verifiable request from you, we will:
        </Body>
        <ul>
          <li>Delete your personal information from our records; and</li>
          <li>
            Direct any service providers to delete your personal information
            from their records.
          </li>
        </ul>
        <Body mt="0">
          Please note that we may not be able to comply with requests to delete
          your personal information if it is necessary to:
        </Body>
        <ul>
          <li>
            Complete the transaction for which the personal information was
            collected, fulfill the terms of a written warranty or product recall
            conducted in accordance with federal law, provide a good or service
            requested by you, or reasonably anticipated within the context of
            our ongoing business relationship with you, or otherwise perform a
            contract between you and us;
          </li>
          <li>
            Detect security incidents, protect against malicious, deceptive,
            fraudulent, or illegal activity; or prosecute those responsible for
            that activity;
          </li>
          <li>
            Debug to identify and repair errors that impair existing intended
            functionality;
          </li>
          <li>
            Exercise free speech, ensure the right of another consumer to
            exercise his or her right of free speech, or exercise another right
            provided for by law;
          </li>
          <li>
            Comply with the California Electronic Communications Privacy Act;
          </li>
          <li>
            Engage in public or peer-reviewed scientific, historical, or
            statistical research in the public interest that adheres to all
            other applicable ethics and privacy laws, when our deletion of the
            information is likely to render impossible or seriously impair the
            achievement of such research, provided we have obtained your
            informed consent
          </li>
          <li>
            Enable solely internal uses that are reasonably aligned with your
            expectations based on your relationship with us;
          </li>
          <li>Comply with an existing legal obligation; or</li>
          <li>
            Otherwise use your personal information, internally, in a lawful
            manner that is compatible with the context in which you provided the
            information.
          </li>
        </ul>
        <Header h3 mt={4} mb={4}>
          Children Under Thirteen
        </Header>
        <Body mt="0">
          LIQHTWORKS, INC. does not knowingly collect personally identifiable
          information from children under the age of thirteen. If you are under
          the age of thirteen, you must ask your parent or guardian for
          permission to use this application.
        </Body>
        <Header h4 mt={4} mb={2}>
          How to Access & Change Your Information
        </Header>
        <Body>
          To update your account information, visit mochi.game/profile. In order
          to review, correct, or delete any personal information collected on
          the Site, contact contact@mochi.game.
        </Body>
        <Header h3 mt={4} mb={4}>
          Opt-Out & Unsubscribe from Third Party Communications
        </Header>
        <Body mt="0">
          We respect your privacy and give you an opportunity to opt-out of
          receiving announcements of certain information. Users may opt-out of
          receiving any or all communications from third-party partners of
          LIQHTWORKS, INC. by contacting us here:
        </Body>
        <Body mt="0">- Email: contact@mochi.game - Phone: 818-732-0954</Body>
        <Header h3 mt={4} mb={4}>
          E-mail Communications
        </Header>
        <Body mt="0">
          From time to time, LIQHTWORKS, INC. may contact you via email for the
          purpose of providing announcements, promotional offers, alerts,
          confirmations, surveys, and/or other general communication.
        </Body>
        <Body mt="0">
          If you would like to stop receiving marketing or promotional
          communications via email from LIQHTWORKS, INC., you may opt out of
          such communications by clicking on the UNSUBSCRIBE button.
        </Body>
        <Header h3 mt={4} mb={4}>
          External Data Storage Sites
        </Header>
        <Body>
          We may store your data on servers provided by third party hosting
          vendors with whom we have contracted.
        </Body>
        <Header h3 mt={4} mb={4}>
          Changes to this Statement
        </Header>
        <Body mt="">
          LIQHTWORKS, INC. reserves the right to change this Privacy Policy from
          time to time. We will notify you about significant changes in the way
          we treat personal information by sending a notice to the primary email
          address specified in your account, by placing a prominent notice on
          our application, and/or by updating any privacy information. Your
          continued use of the application and/or Services available after such
          modifications will constitute your: (a) acknowledgment of the modified
          Privacy Policy; and (b) agreement to abide and be bound by that
          Policy.
        </Body>
        <Header h3 mt={4} mb={4}>
          Contact Information
        </Header>
        <Body mt="0" mb={2}>
          LIQHTWORKS, INC. welcomes your questions or comments regarding this
          Statement of Privacy. If you believe that LIQHTWORKS, INC. has not
          adhered to this Statement, please contact LIQHTWORKS, INC. at:
        </Body>
        <Body>
          LIQHTWORKS, INC.
          <br />
          1933 N Bronson Ave.
          <br />
          Hollywood, California 90068
          <br />
          <br />
          Email Address: contact@mochi.game
          <br />
          Telephone number: 818-732-0954
          <br />
          Effective as of September 22, 2021
        </Body>
      </GridBox>
    </GridContainer>
  );
};

export default PrivacyPolicyPage;
