import React, { useState, Fragment } from "react";

import { Space, Input } from "../Utility";

export const Terms = ({ gameModeStatus, updateUserAgreementStatus }) => {
  const [rules, setRules] = useState(gameModeStatus);
  const [terms, setTerms] = useState(gameModeStatus);

  const handleCheck = (rulesStatus, termsStatus) => {
    setRules(rulesStatus);
    setTerms(termsStatus);
    updateUserAgreementStatus(rulesStatus && termsStatus);
  };

  return (
    <Fragment>
      <Input
        type="checkbox"
        defaultChecked={rules}
        onClick={() => handleCheck(!rules, terms)}
        id="rules"
      />
      <label style={{ display: "block" }} htmlFor="rules">
        <span>I have read the rules of Mochi</span>
      </label>
      <Space mb={"5px"} />
      <Input
        type="checkbox"
        defaultChecked={terms}
        onClick={() => handleCheck(rules, !terms)}
        id="terms"
      />
      <label style={{ display: "block" }} htmlFor="terms">
        <span>I agree to the terms</span>
      </label>
      <Space mb={40} />
    </Fragment>
  );
};
