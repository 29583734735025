import axios, { resetAuthToken } from "../../config/axios";
import { API_ENDPOINT } from "../../constants/endpoints";

import { AUTH_SUCCESS, AUTH_FAILURE, LOG_OUT } from "../../constants/actionTypes";
import { fetchProfileInfo } from "../Community/actions";

export const getSelf = async () => {
  const r = await axios.get(`${API_ENDPOINT}/users/`);
  return r.data;
};

export const logOutUser = () => {
  resetAuthToken();
  localStorage.setItem("token", "");
  return { type: LOG_OUT };
};

export const reviveSession = (activeCommunityIdOrNull) => async (dispatch, getState) => {
  if (getState().auth.get("auth")) return;
  try {
    const user = await getSelf();
    dispatch({
      type: AUTH_SUCCESS,
      data: {
        user: user,
        activeCommunityIdOrNull,
      },
    });
    fetchProfileInfo(user, dispatch, getState);
  } catch (e) {
    resetAuthToken();
    dispatch({
      type: AUTH_FAILURE,
    });
  }
};
