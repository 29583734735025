export const invalidPasswordError =
  "Please use at least 8 characters, 1 letter, & 1 number";

export const matchPasswordError = "Make sure your passwords match";

export const incorrectPasswordError =
  "Incorrect password. Use the recover my account link if this persists";

export const incorrectPasswordShortError =
  "The password you entered is incorrect";

export const recoverAccountError =
  "If the error persists, use the link below to recover your account";

export const recoverAccountLinkError =
  "Please try again. Or use the link on the Change Password form to recover your account";

export const contactSupportError =
  "Something went wrong. Please try again or contact support if you experience further issues";

export const incorrectLoginError =
  "Your email and password do not match our record.";

export const noPermissionError =
  "You do not have permission to perform this action.";

export const incompleteFormError =
  "Every field must be filled out and passwords must be 8 or more characters";

export const incompleteConfirmAccountFormError =
  "Every field must be filled out.";

export const updatedPasswordSuccessMsg = "Your password has been updated";

export const validEmailAddressError = "Please enter a valid e-mail address";

export const systemErrorInvalidPassword =
  "There was an error creating a new password in our system. Make sure the password you have chosen has a mix of letters and numbers and is unique.";

export const weekdayError = "Date must be a weekday";

export const mustBeMondayError = "Date must be a Monday in the future";

export const updatedProfileMsg = "Your profile has been updated";

export const firstNameError = "Enter your first name";

export const lastNameError = "Enter your last name";

export const verifyEmail = "Verify you entered the correct e-mail address";

export const verifyPassword = "Verify you entered the correct password";

export const createAccountErrorMsg =
  "There was an error creating the profile. Have you already signed up before?";

export const verifyEmailMsg =
  "We just sent an email to the email address you signed up with. It contains a link you will neet to use to activate your account. Once you click that link you will be able to authenicate.";

export const welcomeCreateAccountMsg = (name) =>
  `Welcome, ${name}! Before you meet your Mochi, please finish creating your account.`;
