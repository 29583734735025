import styled from "styled-components";
import {
  space,
  color,
  layout,
  flexbox,
  grid,
  typography,
  border,
} from "styled-system";

const Square = styled.div(
  {
    minWidth: 0,
    width: "100%",
    paddingBottom: "100%",
    position: "relative",

    "& > *": {
      position: "absolute",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      zIndex: "0",
    },
  },
  space,
  color,
  layout,
  flexbox,
  grid,
  typography,
  border
);

export default Square;
