import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";

import StyledNavItem from "./StyledNavItem";
import { Box } from "../Utility";

const NavItem = ({ itemPath, label }) => {
  const location = useLocation();
  const active = location.pathname === itemPath ? 1 : 0;

  return (
    <Box mb={[2, 0]} pl={[3, 0]}>
      <Link to={itemPath} active={active}>
        <StyledNavItem active={active}>{label}</StyledNavItem>
      </Link>
    </Box>
  );
};

export default NavItem;
