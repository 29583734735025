import { combineReducers } from "redux";

import { authReducer } from "../containers/Auth";
import { communityReducer } from "../containers/Community";
import { freeAgentReducer } from "../containers/FreeAgent";
import { web3Reducer } from "../containers/Web3";
import { gameModeReducer } from "../containers/GameMode";
import { shopReducer } from "../containers/Shop";
import { checkInReducer } from "../containers/CheckIn";

const reducer = combineReducers({
  web3: web3Reducer,
  community: communityReducer,
  freeAgent: freeAgentReducer,
  gameModes: gameModeReducer,
  auth: authReducer,
  shop: shopReducer,
  checkIn: checkInReducer,
});

export default reducer;
