import React from "react";

import { GridContainer, DiscordButton } from "../components/Utility";
import { GridBox, Header, Paragraph } from "../Storybook";
import mochiWorld from "../img/home-page/MochiWorld-stars.png";
import mochiCampfire from "../img/home-page/campfire-mochis.png";
import MochiBotIntroAni from "../components/Home/MochiBotIntroAni";
import CheckInAni from "../components/Home/CheckInAni";
import TeamAni from "../components/Home/TeamAni";
import DaoCarousel from "../components/Home/DaoCarousel";

export const HomePage = () => (
  <GridContainer mt={[4, 5]} justifyContent="center">
    {/* Header Image */}
    <GridBox alignItems="center" justifyContent="flex-end" span={[4, 4, 6]} mb={[0, 0, 6]}>
      <img
        width="100%"
        style={{
          border: "1px solid rgba(255,255,255,0.1)",
          borderRadius: "32px",
          boxShadow: "0px 0px 64px rgb(107 27 185 / 50%)",
        }}
        src={mochiWorld}
        alt="Mochi World render"
      />
    </GridBox>
    {/* Header Title */}
    <GridBox span={[4]} mb={[60, 20, 60]}>
      <Header
        h1
        fontSize={["42px", "42px", "54px"]}
        label="Stick together"
        style={{ whiteSpace: "noWrap" }}
      />
      <Header
        h1
        fontSize={["42px", "42px", "54px"]}
        label="better."
        color="darkPositive700"
        mb={2}
      />
      <Paragraph
        label="Install Mochi in your Discord. Bond with friends, align on goals, & grow your DAO."
        color="darkFGSecondary"
        mb={3}
        size="fz2"
      />
      <DiscordButton label="Join Discord" />
    </GridBox>

    {/* ani one */}
    <GridBox span={[4]} start={[1, 1, 3]} mb={60} mt={60} pl={[0, 4, 4]}>
      <Header h2 label="Stand out" mb={3} />
      <Paragraph
        size="fz2"
        label="Discord is a busy place. Keep DAO members focused and engaged by having them work towards goals together."
        color="darkFGSecondary"
      />
    </GridBox>
    <GridBox
      span={[4]}
      start={[1, 5, 8]}
      mb={60}
      mt={60}
      ml={[80, 60, 0]}
      display="flex"
      justifyContent={["center", "flex-end"]}
    >
      <MochiBotIntroAni />
    </GridBox>

    {/* ani two */}
    <GridBox
      span={[4]}
      start={[1, 1, 3]}
      mb={[0, 60]}
      mt={60}
      display="flex"
      justifyContent={["center", "flex-end"]}
    >
      <TeamAni />
    </GridBox>

    <GridBox span={[4]} start={[1, 5, 7]} mt={60} mb={60} pr={[0, 4]} pl={[0, 4]}>
      <Header h2 label="Form stickier pods" mb={3} />
      <Paragraph
        size="fz2"
        label="Bond with a team of up to three friends at a time using Ethereum. Check in, reflect, & plan with each other in Discord or Slack. Share your progress publicly in your community."
        color="darkFGSecondary"
      />
    </GridBox>

    {/* ani three */}
    <GridBox span={[4]} start={[1, 1, 3]} mb={[0, 60]} mt={60} pl={[0, 4]}>
      <Header
        h2
        label="Share wisdom,
earn tokens"
        mb={3}
      />
      <Paragraph
        size="fz2"
        label="Reward teams by gifting them tokens for completing their goals & supporting the DAO. Earn rank by showing up every day."
        color="darkFGSecondary"
      />
    </GridBox>
    <GridBox
      span={[4]}
      start={[1, 5, 7]}
      mb={100}
      mt={60}
      pl={[0, 4]}
      display="flex"
      justifyContent={["center", "flex-start"]}
    >
      <CheckInAni />
    </GridBox>

    {/* DAO Carousel */}
    <GridBox span={[4]} start={[1, 1, 3]} pl={[0, 4, 0]} mb={380} display="flex">
      <DaoCarousel />
    </GridBox>
    <GridBox span={[4]} start={[1, 5, 7]} mb={60} mt={60} pr={[0, 4]} pl={[0, 4]}>
      <Header h2 label="The DAO of Mochi" mb={3} />
      <Paragraph
        size="fz2"
        label="Check in with N_ll and QwwQ to complete reports with your team. Get together and share your work in Show & Tells every two weeks."
        color="darkFGSecondary"
      />
    </GridBox>

    {/* row five */}
    <GridBox span={[4, 4, 4]} start={[1, 1, 3]} mb={[0, 60]} mt={60} pl={[0, 4]}>
      <Header h2 label="Journey into Mochi" mb={3} />
      <Paragraph
        size="fz2"
        label="No matter whether your squad meets in Discord or Slack, install Mochi and build a cozy campfire for yourselves in the metaverse."
        color="darkFGSecondary"
        mb={3}
      />
      <DiscordButton label="Join Discord" />
    </GridBox>
    <GridBox span={[4, 4, 4]} start={[1, 5, 7]} mb={60} mt={60}>
      <img width="100%" src={mochiCampfire} alt="Mochis campfire render" />
    </GridBox>
  </GridContainer>
);

export default HomePage;
