import React, { Fragment, useState, useEffect } from "react";
import { PropTypes } from "prop-types";

import CommunityTag from "./CommunityTag";
import { Button, Header } from "../../Storybook";
import StyledDropdownSelect from "../Utility/DropdownSelect/StyledDropdownSelect";
import darkTheme from "../../styles/theme";
import { Box } from "../Utility";

const CommunityToggle = ({
  currentCommunityId,
  menuElements,
  theme,
  toggleFunction,
  logOutAction,
  sendToProfile,
  viewMenu,
  setViewMenu,
  displayName,
}) => {
  const [current, setCurrent] = useState(menuElements?.get(currentCommunityId));

  let menuItems = [];
  menuElements?.forEach((element) => menuItems.push(element));

  useEffect(() => {
    if (!!menuElements) setCurrent(menuElements.get(currentCommunityId));
  }, [menuElements, currentCommunityId]);

  const image =
    "https://res.cloudinary.com/delu9m4xu/image/upload/v1643398243/mochi/Mochi-Icon_qkewbf.png";

  const handleToggle = (elementId) => {
    toggleFunction(elementId);
    setCurrent(menuElements.get(elementId));
    setViewMenu(!viewMenu);
  };

  const takeToProfile = () => {
    sendToProfile();
    setViewMenu(false);
  };

  return (
    <Fragment>
      {viewMenu && (
        <Box
          position={["fixed", "absolute"]}
          style={{
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          onClick={() => setViewMenu(false)}
        />
      )}
      <Box
        position={["initial", "absolute"]}
        backgroundColor={[
          "transparent",
          viewMenu && darkTheme.color.darkBGTertiary,
        ]}
        padding={["", "8px 12px 8px 8px"]}
        borderRadius={["0", "8px"]}
        mt={[0, -2]}
        style={{
          overflow: "hidden",
          display: "inline-block",
          zIndex: "1",
        }}
      >
        <StyledDropdownSelect theme={theme}>
          <CommunityTag
            image={current?.get("icon") || image}
            label={displayName || "Mochi Player"}
            onClick={() => setViewMenu(!viewMenu)}
            current
            hideName={!viewMenu}
            ml={[-2, 0]}
          />

          {!!menuItems && viewMenu && (
            <Box>
              <Button
                textButton
                arrow
                label="View Profile"
                onClick={() => takeToProfile()}
                ml={2}
              />
              <Header h5 m={2} label="Communities" />
              {menuItems?.map((element) => (
                <CommunityTag
                  key={element.get("id")}
                  image={element.get("icon") || image}
                  label={element.get("name")}
                  onClick={() => handleToggle(element.get("id"))}
                  fullWidth
                />
              ))}
              <Button textButton onClick={() => logOutAction()}>
                <Header h4 ml={2} mt={3} mb={2} label="Log Out" />
              </Button>
            </Box>
          )}
        </StyledDropdownSelect>
      </Box>
    </Fragment>
  );
};

CommunityToggle.propTypes = {
  /**
   * The currently active community ID
   */
  currentCommunityId: PropTypes.number,
  /**
   * Pass an object with all the menu items indexed by ID
   */
  menuElements: PropTypes.object,
  /**
   * Community user name
   */
  displayName: PropTypes.string,
  /**
   * Optional pass another theme once we have Light
   */
  theme: PropTypes.object,
};

CommunityToggle.defaultProps = {
  theme: darkTheme,
  currentCommunityId: 0,
  menuElements: null,
};

export default CommunityToggle;
