import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { GridBox, Header, Paragraph, Button } from "../../Storybook";
import { Notification } from "../Utility";
import { connect } from "react-redux";
import { fetchJourneyDetails, getTeamInfo } from "../../containers/Community/actions";

const JourneyOverviewForGoal = ({
  activeCommunityId,
  journeyId,
  goalId,
  playerId,
  team,
  color,
  journeyNum,
  journey,
  fetchJourneyDetails,
  getTeamInfo,
}) => {
  const [journeyFetched, setJourneyFetched] = useState(false);
  const [teamFetched, setTeamFetched] = useState(false);

  const journeyLink = `/${activeCommunityId}/journey/${journeyId}/${playerId}/${goalId}/${team?.get(
    "team_id"
  )}?journeyNumber=${journeyNum}#journey-page`;

  useEffect(() => {
    if (!journey && !journeyFetched) {
      setJourneyFetched(true);
      fetchJourneyDetails(journeyId, activeCommunityId);
    }
  }, [
    activeCommunityId,
    journey,
    journeyFetched,
    journeyId,
    fetchJourneyDetails,
  ]);

  useEffect(() => {
    if (!!journey && !team && !teamFetched) {
      setTeamFetched(true);
      getTeamInfo(journey.get("teamId"), activeCommunityId);
    }
  }, [team, journey, activeCommunityId, getTeamInfo, teamFetched]);

  if (!journey || !team) return <></>;
  return (
    <GridBox span={[3]}>
      <Notification
        bgColor={color}
        flexDirection="column"
        alignContent="space-between"
        pb={1}
        overflow="hidden"
      >
        <Link to={journeyLink}>
          <Header h3 label={`Journey #${journeyNum}`} colored />
        </Link>
        <Paragraph
          colored
          label={moment(journey.get("start"), "MM/DD/YYYY").format("MMMM YYYY")}
          mb={2}
        />
        <Link to={`/${activeCommunityId}/team/${team.get("team_id")}`}>
          <Paragraph color="colorFGSecondary" label={`Team ${team.get("name")}`} mb={1} />
        </Link>
        <Link to={journeyLink}>
          <Button colored={color} textButton arrow label="See the Pearls" />
        </Link>
      </Notification>
    </GridBox>
  );
};

export default connect(
  (state, props) => {
    const activeCommunityId = state.community.get("activeCommunityId");
    const journey = state.community.getIn([
      "journeys",
      activeCommunityId,
      props.journeyId,
      props.playerId.toString(),
    ]);

    return {
      activeCommunityId,
      journey,
      team: state.community.getIn(["teams", activeCommunityId, journey?.get("teamId")]),
    };
  },
  { fetchJourneyDetails, getTeamInfo }
)(JourneyOverviewForGoal);
