import React from "react";
import styled from "styled-components";
import { space, layout, grid } from "styled-system";

import Box from "./Box";

export const Container = styled.div(space, layout, grid);

const BoxContainer = ({ style, children, ignoreHeight = false }) => {
  const css = {
    minHeight: "75vh",
    alignItems: "center",
  };

  return (
    <Container
      display="grid"
      gridTemplateColumns={["15px 1fr 15px", "45px 1fr 45px", "10% 1fr 10%"]}
      style={ignoreHeight ? {} : css}
    >
      <div />
      <Box style={style}>{children}</Box>
      <div />
    </Container>
  );
};

export default BoxContainer;
