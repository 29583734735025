import React from "react";

import { GridContainer, Body, InlineAnchor, Link } from "../Utility";
import { GridBox } from "../../Storybook";
import mediumIcon from "../../img/svg/medium-icon.svg";
import twitterIcon from "../../img/svg/twitter-icon.svg";

export const Footer = (props) => {
  return (
    <GridContainer
      ignoreHeight
      style={{
        fontSize: "24px",
        color: "white",
      }}
    >
      <GridBox>
        <Body>
          <a href="https://medium.com/@mochi.game" target="_blank" rel="noopener noreferrer">
            <img src={mediumIcon} style={{ marginRight: "8px" }} alt="Medium" />
          </a>
          <a href="https://twitter.com/bondwithmochi" target="_blank" rel="noopener noreferrer">
            <img src={twitterIcon} style={{ marginRight: "8px" }} alt="Twitter" />
          </a>
          <span>All rights Mochi Game&trade; 2023.</span>
          <br />
        </Body>
        <Body>
          <Link to="/terms-and-conditions" style={{ marginRight: "16px" }}>
            Terms and Conditions
          </Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </Body>
        <Body>
          Reach out to <InlineAnchor href="mailto:contact@mochi.game">contact@mochi.game</InlineAnchor> with any
          questions.
        </Body>
      </GridBox>
    </GridContainer>
  );
};

export default Footer;
