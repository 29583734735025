import React from "react";

import oneToken from "../../img/tokens/one-token.png";
import twoTokens from "../../img/tokens/two-tokens.png";
import threeTokens from "../../img/tokens/three-tokens.png";
import Flex from "./Flex";

const MochiToken = ({ two, three, opacity, large }) => (
  <Flex justifyContent="center" height="100%" alignContent="center">
    <img
      width={large ? "22px" : "19px"}
      height={large ? "22px" : "19px"}
      opacity={opacity}
      src={three ? threeTokens : two ? twoTokens : oneToken}
      alt={`${three ? "Three" : two ? "Two" : "One"} Mochi Tokens`}
    />
  </Flex>
);

export default MochiToken;
