import { fromJS } from "immutable";
import {
  BUY_GAME_ITEM,
  FETCH_GAME_ITEMS,
  FETCH_PLAYER_INVENTORY,
  APPLY_SHOP_ITEM,
} from "../../constants/actionTypes";

import initialState from "./initialState";

/* eslint-disable complexity */
const reducer = (state = fromJS(initialState), action) => {
  switch (action.type) {
    case FETCH_GAME_ITEMS:
      return state.setIn(["gameItems", action.activeCommunityId], fromJS(action.data));

    /////////////////////////////
    // Shop-User Actions
    /////////////////////////////

    case FETCH_PLAYER_INVENTORY:
      let newInventory = {};

      action.data.forEach((item) => {
        if (!item.is_used) {
          const itemType = item.item_type;
          if (!newInventory[itemType]) newInventory[itemType] = [item];
          else newInventory[itemType].push(item);
        }
      });

      return state.setIn(
        ["inventory", action.activeCommunityId, action.playerId],
        fromJS(newInventory)
      );
    case BUY_GAME_ITEM:
      const item = action.data.item;
      const item_type = item.item_type;
      return state.updateIn(["inventory", action.activeCommunityId, action.playerId], (v) =>
        v.has(item_type)
          ? v.update(item_type, (a) => a.push(fromJS(item)))
          : v.set(item_type, fromJS([item]))
      );

    case APPLY_SHOP_ITEM:
      return state;

    default:
      return state;
  }
};

export default reducer;
