import React, { useEffect, useState, useCallback } from "react";
import { connect, useSelector } from "react-redux";
import { fromJS } from "immutable";
import { useHistory, useParams } from "react-router-dom";

import { Flex, Loading, GridContainer, RoutingMessage } from "../components/Utility";
import { getTeamInfo, fetchJourneyDetails } from "../containers/Community/actions";
import TeammatesView from "../components/Team/TeammatesView";
import JourneyNotification from "../components/Journey/JourneyNotifications/JourneyNotification";
import {
  getPlayerAndTeamDataFromState,
  hasUpcomingJourney,
  isCurrentlyInAJourney,
  getOverallCurrentJourneyFromState,
} from "../containers/Community/stateGetterHelpers";
import { GridBox } from "../Storybook";
import { TeamName } from "../components/Team/TeamName";
import LatestTeamPearls from "../components/Team/LatestTeamPearls";

export const TeamPage = ({
  playerData,
  activeCommunityId,
  getTeamInfo,
  teamHasUpcomingJourney,
  currentJourney,
  currentlyInJourney,
  fetchJourneyDetails,
}) => {
  let history = useHistory();
  let { teamId, communityId } = useParams();
  teamId = Number(teamId);
  const teamData = useSelector((state) =>
    state.community.getIn(["teams", activeCommunityId, teamId])
  );
  const onTeam = playerData?.get("team_id") === teamId;
  const newestJourney = playerData?.getIn(["journeyData", 0]);
  const isMyTeam = playerData?.get("team_id") === teamId;
  const amLeader = teamData ? teamData.get("leader") === playerData?.get("user_id") : false;
  const authUserPlatformId = !!playerData && playerData?.getIn(["details", "id"]);
  const [error, setError] = useState();

  const fetchTeamData = useCallback(async () => {
    try {
      await getTeamInfo(teamId, Number(communityId));
    } catch (e) {
      if (e.status === 404) history.push("/404");
      else setError(e.statusText);
    }
  }, [teamId, communityId, getTeamInfo, history]);

  useEffect(() => {
    if (onTeam && currentlyInJourney && !currentJourney) {
      fetchJourneyDetails(playerData.get("current_journey"));
    }
  }, [currentlyInJourney, currentJourney, playerData, fetchJourneyDetails, onTeam]);

  useEffect(() => {
    if (!teamData?.size) fetchTeamData();
  }, [teamData, communityId, fetchTeamData]);

  if (error)
    return (
      <RoutingMessage
        title={error.status?.toString() || "Something Went Wrong"}
        message={error.statusText || "If you continue experiencing this problem, contact support."}
      />
    );
  else if (!activeCommunityId || !playerData?.get("user_id") || !teamData?.get("team_id"))
    return <Loading />;
  return (
    <GridContainer mb={2} alignContent="flex-start">
      <TeamName teamData={teamData} onTeam={onTeam} amLeader={amLeader} />
      <GridBox>
        <TeammatesView
          teamData={teamData}
          amLeader={amLeader}
          playerData={playerData}
          isAnotherPlayersProfile={!onTeam}
          canInvite={!teamHasUpcomingJourney && !currentlyInJourney && onTeam}
        />
      </GridBox>
      {isMyTeam && (
        <GridBox>
          <Flex flexDirection="row" justifyContent="center">
            <JourneyNotification
              authUserPlatformId={authUserPlatformId}
              currentJourney={currentJourney}
              newestJourney={newestJourney}
              amLeader={amLeader}
              onTeam={onTeam}
              playerId={playerData?.get("user_id")}
              playerClaimedAccount={!!playerData?.getIn(["details", "id"])}
              newestStartDate={newestJourney?.get("start")}
              isTeamPageNotification={true}
            />
          </Flex>
        </GridBox>
      )}
      <LatestTeamPearls />
    </GridContainer>
  );
};

export default connect(
  (state, props) => {
    const authUserId = state.auth.get("authedUserId");
    const activeCommunityId = state.community.get("activeCommunityId");
    const { playerData } = getPlayerAndTeamDataFromState(state);

    return {
      playerData,
      authUserId,
      activeCommunityId,
      currentJourney: getOverallCurrentJourneyFromState(state),
      currentlyInJourney: isCurrentlyInAJourney(state),
      invitationsToTeam: playerData?.get("invitations") || fromJS([]),
      teamHasUpcomingJourney: hasUpcomingJourney(state, playerData?.get("user_id")),
    };
  },
  {
    getTeamInfo,
    fetchJourneyDetails,
  }
)(TeamPage);
