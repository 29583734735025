import React from "react";

import theme from "../../../styles/theme";

const WarningSmall = ({ color }) => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0L0 14H16L8 0ZM7.5 10L7 4H9L8.5 10H7.5ZM9 12C9 12.5523 8.55228 13 8 13C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11C8.55228 11 9 11.4477 9 12Z"
      fill={theme.color[color]}
    />
  </svg>
);

export default WarningSmall;
