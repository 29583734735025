import { fromJS } from "immutable";
import { checkInInitialState } from "../containers/CheckIn";

import { communityInitialState } from "../containers/Community";
import { shopInitialState } from "../containers/Shop";
import { web3InitialState } from "../containers/Web3";

const initialState = {
  community: fromJS(communityInitialState),
  web3: fromJS(web3InitialState),
  shop: fromJS(shopInitialState),
  checkIn: fromJS(checkInInitialState),
};

export default initialState;
