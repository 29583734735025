import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { useAccount, useContractRead, useContractWrite, usePrepareContractWrite } from "wagmi";
import { formatEther, parseEther } from "viem";

import { Header, Paragraph, Modal, Button } from "../../Storybook";
import { Box, Flex, ItemMat, MochiToken } from "../Utility";
import {
  failedPurchaseMessage,
  failedToAcceptMsg,
  insufficientTokensMessage,
  loadingMessage,
  maxInventoryMessage,
  successPurchaseMsg,
} from "./shopStrings";
import ShopItemDetails from "./ShopItemDetails";
import { buyGameItem } from "../../containers/Shop/actions";
import LoadingAni from "../Utility/Loading/LoadingAni";
import { MOCHI_TOKEN_ADDRESS, DEFAULT_TOKEN_WALLET, WEB3_NETWORK } from "../../constants/endpoints";
import { getCurrentJourneyPlayerTotal } from "../../containers/Community/stateGetterHelpers";
import MochiTokenContract from "../../contracts/MochiToken.json";

const ShopItem = ({ item, activeCommunityId, authedPlayerId, itemDetails, buyGameItem }) => {
  const [viewDetails, setViewDetails] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const { address } = useAccount();
  const journeyPlayerTotal = useSelector((state) =>
    getCurrentJourneyPlayerTotal(state, activeCommunityId, authedPlayerId)
  );
  const journeyTokenBalaceBN = parseEther(
    (
      journeyPlayerTotal?.get("total_tokens_earned") -
        journeyPlayerTotal?.get("total_tokens_spent") || "0"
    ).toString()
  );
  const itemPriceBN = parseEther(item.get("retail").toString());

  const { data: walletTokenBalance } = useContractRead({
    address: MOCHI_TOKEN_ADDRESS,
    abi: MochiTokenContract.abi,
    functionName: "balanceOf",
    args: [address],
  });

  let insufficientTokens = true;
  if (walletTokenBalance)
    insufficientTokens = itemPriceBN > journeyTokenBalaceBN && itemPriceBN > walletTokenBalance;

  const { config } = usePrepareContractWrite({
    address: MOCHI_TOKEN_ADDRESS,
    chainId: WEB3_NETWORK,
    abi: MochiTokenContract.abi,
    functionName: "transfer",
    args: [DEFAULT_TOKEN_WALLET, itemPriceBN],
  });
  const { writeAsync } = useContractWrite(config);

  const awaitConfirmationToUpdate = async (txId) => {
    try {
      await buyGameItem(item.get("id"), 0, txId);
      setLoading(false);
      setSuccess(true);
    } catch (err) {
      if (typeof err === "string" && err.includes("Too few confirmations"))
        setTimeout(() => awaitConfirmationToUpdate(txId), 6000);
      else {
        setSuccess(false);
        setLoading(false);
        setError(err);
      }
    }
  };

  const purchaseItem = async () => {
    setError("");
    setViewDetails(false);
    try {
      setLoading(true);
      if (journeyTokenBalaceBN >= itemPriceBN) {
        await buyGameItem(item.get("id"), item.get("retail"));
        setLoading(false);
        setSuccess(true);
      } else if (walletTokenBalance >= itemPriceBN) {
        const { hash } = await writeAsync();
        setTimeout(() => {
          awaitConfirmationToUpdate(hash);
        }, 5000);
      }
    } catch (er) {
      setSuccess(false);
      setLoading(false);
      if (typeof er === "string" && er?.includes("maximum")) setError(maxInventoryMessage);
      else if (er?.message?.includes("User Denied")) setError(failedToAcceptMsg);
      else if (!!er?.message) setError(er?.message);
      else setError(failedPurchaseMessage);
    }
  };

  return (
    <>
      {loading && (
        <Modal noClose>
          <Box>
            <Flex height="300px" alignItems="center" mb={3}>
              <LoadingAni />
            </Flex>
            <Header width="100%" h4 label={`You are purchasing a ${itemDetails?.name}`} />
            <Flex
              mb={3}
              mt={3}
              justifyContent="center"
              alignItems="center"
              flexDirection="row"
              alignContent="flex-start"
            >
              <MochiToken />
              <Header h4 ml={2} label={formatEther(itemPriceBN.toString())} />
            </Flex>
            <Paragraph mb={3} label={loadingMessage} />
          </Box>
        </Modal>
      )}
      {!!error.length && (
        <Modal onClose={() => setError("")}>
          <Box>
            <Header h3 label="Hmm strange" mb={3} />
            <Paragraph mb={4} label={error} />
            <Flex>
              <Button
                primary
                small
                noWrap
                label="Try Again"
                mr={3}
                onClick={() => purchaseItem()}
              />
              <Button secondary arrow label="Dismiss" noWrap onClick={() => setError("")} />
            </Flex>
          </Box>
        </Modal>
      )}
      {success && (
        <Modal onClose={() => setSuccess(false)}>
          <Box>
            <Header h2 label={`${itemDetails?.name} Purchased!`} mb={3} />
            <Paragraph label={successPurchaseMsg(itemDetails?.name)} mb={4} />
            <Flex>
              <Button primary small label="Continue" mr={3} onClick={() => setSuccess(false)} />
              <RouterLink to={`/${activeCommunityId}/profile/${authedPlayerId}/#inventory`}>
                <Button
                  secondary
                  arrow
                  label="View Profile"
                  paddingLeft={2}
                  paddingRight={2}
                  noWrap
                />
              </RouterLink>
            </Flex>
          </Box>
        </Modal>
      )}
      {viewDetails && (
        <Modal onClose={() => setViewDetails(false)}>
          <Box>
            <Header h3 label={`${itemDetails?.name} Details`} mb={3} />
            <Paragraph label={itemDetails?.description} mb={3} />
            {insufficientTokens && (
              <Paragraph color="darkNegative500" label={insufficientTokensMessage} mb={4} />
            )}
            <Button
              primary
              label="Buy"
              disabled={insufficientTokens}
              type="button"
              width="100%"
              mb={3}
              onClick={() => purchaseItem()}
            />
          </Box>
        </Modal>
      )}

      <ItemMat
        bgColor="darkBGSecondary"
        borderRadius="4px"
        selectBoxShadow="8px"
        alignContent="flex-start"
        alignItems="flex-start"
        justifyContent="flex-start"
        id={itemDetails?.name}
        p={2}
        mb={4}
        mr={4}
        onClick={() => setViewDetails(true)}
      >
        <ShopItemDetails itemInfo={itemDetails} price={formatEther(itemPriceBN.toString())} />
      </ItemMat>
    </>
  );
};

export default connect(
  (state) => {
    const activeCommunityId = state.community.get("activeCommunityId");
    const authedPlayerId = state.community.get("authedUserId");
    return {
      activeCommunityId,
      authedPlayerId,
    };
  },
  { buyGameItem }
)(ShopItem);
