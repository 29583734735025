import React from "react";

const BackgroundImage = ({ src, alt }) => {
  return (
    <img
      src={src}
      alt={alt}
      style={{
        objectFit: "cover",
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: -2,
      }}
    />
  );
};

export default BackgroundImage;
