import React from "react";

const arrowNormalLeft = ({ color }) => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13 6H1M1 6L6 1M1 6L6 11" stroke={color} />
  </svg>
);

export default arrowNormalLeft;
