const web3InitialState = {
  ethereumAddress: "",
  currentNetwork: null,
  contractInstances: {},
  web3Modal: {},
  provider: {},
  tokenBalance: "0.0",
  wrongNetwork: null,
  mochiTokenBalance: null,
};

export default web3InitialState;
