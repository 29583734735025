import {
  ArrowBoldLeft,
  ArrowBoldRight,
  ArrowNormalLeft,
  ArrowNormalRight,
  calendar,
  checkLarge,
  checkSmall,
  crossLarge,
  crossSmall,
  ethDarkPrimary,
  ethDarkSecondary,
  ethLightPrimary,
  eyeOpen,
  eyeClosed,
  eyeOpenColored,
  eyeClosedColored,
  journeyComplete,
  journeyInProgress,
  journeyIncomplete,
  WarningLarge,
  WarningSmall,
  foldDown,
  foldDownBlack,
  foldUp,
} from "../img/svg/icons";

const theme = {
  color: {
    light: {},
    //* Dark Theme
    dark: {
      fg: {
        primary: "#FFFFFF",
        secondary: "#B3B3B3",
        tertiary: "#808080",
      },
      bg: {
        primary: "#000000",
        secondary: "#1F1B1B",
        tertiary: "#221F1F",
      },
    },
    // Foreground
    darkFGPrimary: "#FFFFFF",
    darkFGSecondary: "#B3B3B3",
    darkFGTertiary: "#808080",
    darkFGPrimary50: "rgba(255, 255, 255, 0.5);",
    darkFGPrimary15: "rgba(255, 255, 255, 0.15)",
    // Background
    darkBGPrimary: "#000000",
    darkBGSecondary: "#1F1B1B",
    darkBGTertiary: "#221F1F",

    //* Foreground in color containers
    colorFGPrimary: "#000000",
    colorFGSecondary: "rgba(0, 0, 0, 0.55)",
    colorFGTertiary: "rgba(0, 0, 0, 0.35)",
    colored: {
      fg: {
        //* Foreground in color containers
        primary: "#000000",
        secondary: "rgba(0, 0, 0, 0.55)",
        tertiary: "rgba(0, 0, 0, 0.35)",
      },
    },
    positive: {
      green300: "#BBDEB2",
      green500: "#80d183",
      green700: "#52ac55",
    },
    warning: {
      orange300: "#FFF8B5",
      orange500: "#FFB800",
      orange700: "#D99C00",
    },
    negative: {
      red300: "#FF7575",
      red500: "#FB4444",
      red700: "#F11212",
    },

    discordBlurple: "#5865F2",

    // Context Foreground Colors
    // reds
    darkNegative300: "#FF7575",
    darkNegative500: "#FB4444",
    darkNegative700: "#F11212",
    // yellow orange
    darkWarning300: "#FFF8B5",
    darkWarning500: "#FFB800",
    darkWarning700: "#D99C00",
    // green
    darkPositive300: "#BBDEB2",
    darkPositive500: "#80d183",
    darkPositive700: "#52ac55",
    // orange / token
    darkToken300: "#FFF8B5",
    darkToken500: "#FFB800",
    darkToken600: "#EEAB00",
    darkToken700: "#D99C00",
    // All Colors
    //orange
    darkOrange300: "#E7B07E",
    darkOrange500: "#EE8D33",
    darkOrange700: "#CD721F",
    mutedOrange88: "rgba(153, 78, 8, 0.88)",
    // cyan
    darkCyan300: "#9CD7EA",
    darkCyan500: "#4DC3E8",
    darkCyan700: "#3898B7",
    // magenta
    darkMagenta300: "#F08CDA",
    darkMagenta500: "#D252B6",
    darkMagenta700: "#AF529B",

    darkYellow500: "#FFB800",

    //! To deprecate
    darkWarning: "#FFB800",
    darkWarningDim: "#D99C00",
    green: "#32A126",
    white: "#FFFFFF",
    black: "#000000",
    grey: "#240628",
    offBlack: "#333333",
    lightOffBlack: "#3D1843",
    red: "#CD4115",
    blue: "#2EB6E1",
    teal: "#24A3BF",
    orange: "#FC6D26",
  },

  pixels: {
    // *NOTE: Border radius should always be one scale smaller than parent component border radius
    // pixel size scale
    sz0: "2px",
    sz1: "4px",
    sz2: "8px",
    sz3: "16px",
    sz4: "32px",
    sz5: "64px",
    sz6: "128px",
  },

  //font size scale
  fontSize: {
    fz0: "12px",
    fz1: "14px",
    fz2: "16px",
    fz3: "22px",
    fz4: "30px",
    fz5: "42px",
    fz6: "54px",
  },

  breakpoints: ["640px", "960px", "1112px"], // '768px',
  fonts: {
    headers: "Poppins",
    body: "Inter",
    mono: "Space Mono",
  },

  icons: {
    foldDown,
    foldDownBlack,
    foldUp,
    pwDarkShow: eyeOpen,
    pwDarkHide: eyeClosed,
    pwColoredShow: eyeOpenColored,
    pawColoredHide: eyeClosedColored,
    warningSmall: WarningSmall,
    warningLarge: WarningLarge,
    crossSmall,
    crossLarge,
    checkSmall,
    checkLarge,
    ArrowNormalLeft,
    ArrowNormalRight,
    ArrowBoldLeft,
    ArrowBoldRight,
    journeyComplete,
    journeyInProgress,
    journeyIncomplete,
    ethDarkPrimary,
    ethDarkSecondary,
    ethLightPrimary,
    calendar,

    password: {
      dark: {
        show: eyeOpen,
        hide: eyeClosed,
      },
      colored: {
        show: eyeOpenColored,
        hide: eyeClosedColored,
      },
    },
    eyeOpen,
    eyeClosed,
    eyeClosedColored,
    eyeOpenColored,
    warning: {
      small: WarningSmall,
      large: WarningLarge,
    },
    WarningSmall,
    WarningLarge,
    cross: {
      small: crossSmall,
      large: crossLarge,
    },
    check: {
      small: checkSmall,
      large: checkLarge,
    },
    arrow: {
      normal: {
        right: ArrowNormalRight,
        left: ArrowNormalLeft,
      },
      bold: {
        right: ArrowBoldRight,
        left: ArrowBoldLeft,
      },
    },
    journeyMarker: {
      incomplete: journeyIncomplete,
      inProgress: journeyInProgress,
      complete: journeyComplete,
    },
    eth: {
      dark: {
        primary: ethDarkPrimary,
        secondary: ethDarkSecondary,
      },
      light: {
        primary: ethLightPrimary,
      },
    },
  },

  //! to deprecate
  fontSizes: [12, 14, 16, 20],
};

export default theme;
