import React from "react";
import PropTypes from "prop-types";

import StyledAvatar from "../../components/Utility/Avatar.js";
import theme from "../../styles/theme.js";

const Avatar = ({ alt, image, theme, menuBurger, props, large, square }) => {
  return (
    <StyledAvatar
      alt={alt}
      src={image}
      theme={theme}
      large={large}
      menuBurger={menuBurger}
      square={square}
      {...props}
    />
  );
};

Avatar.propTypes = {
  /**
   * Pass an image to display on the avatar
   */
  image: PropTypes.string.isRequired,
  /**
   * Pass large to use the large version
   */
  large: PropTypes.bool,
  /**
   * Pass square to use the square avatar, rather than the default circle version
   */
  square: PropTypes.bool,
  /**
   *  Optional to remove margin on the right
   */
  menuBurger: PropTypes.bool,
  /**
   * Optional pass a theme other than the default
   */
  theme: PropTypes.object,
};

Avatar.defaultProps = {
  image: null,
  large: false,
  square: false,
  theme: theme,
};

export default Avatar;
