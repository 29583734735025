import styled from "styled-components";

const ChatBox = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: flex-start;
  padding: 4px 12px;

  width: ${(props) => props.width};
  left: ${(props) => props.left};
  top: ${(props) => props.top};

  background: rgba(17, 17, 17, 0.85);
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-sizing: border-box;
  box-shadow: 0px 0px 32px ${(props) => props.color},
    0px 4px 16px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);

  /* Note: backdrop-filter has minimal browser support */
  border-radius: 8px;
`;

export default ChatBox;
