import moment from "moment";
import axios from "../../config/axios";
import * as Sentry from "@sentry/browser";

import { API_ENDPOINT } from "../../constants/endpoints";
import {
  getSelf,
  getTeam,
  getJourney,
  getTeamsJourney,
  getExternalPlayerData,
  getExternalPlayerJourneyInfo,
  requestExternalPlayerStats,
  findCurrentJourneyAndFetchDetails,
} from "./helpers";
import {
  EDIT_PERSON,
  SET_PAUSE_DATE,
  DELETE_PAUSE_DATE,
  JOURNEY_INFO,
  TEAM_INFO,
  TEAM_INVITATION,
  TEAM_INVITATION_DELETE,
  TEAM_INVITATION_ACCEPT,
  TEAM_INVITATION_DECLINE,
  TEAM_START_EPOCH,
  TEAM_REMOVE_JOURNEY,
  REMOVE_MEMBERSHIP,
  REMOVE_TEAM_MEMBER,
  GET_PERSONAL_STATS,
  TEAM_FETCH,
  EXTERNAL_PLAYER_DATA_FETCH,
  EXTERNAL_PLAYER_DATA_FETCH_FAILURE,
  EXTERNAL_PLAYER_STATS,
  GET_CUSTOMER_DATA,
  ACCEPT_COMMUNITY_INVITATION,
  COMMUNITY_INVITATION_FAIL,
  GET_COMMUNITY_INVITATION,
  EDIT_COMMUNITY_MEMBERSHIP,
  TOGGLE_COMMUNITY,
  GET_DISCORD_USER_AVATAR,
  GET_DISCORD_COMMUNITY_ICON,
  CONFIRM_ACCOUNT,
  AUTH_SUCCESS,
  GET_JOURNEY_DETAILS,
  GET_COMMUNITY_INFO,
  JOIN_WAVE,
  LEAVE_WAVE,
  INSTALL_MOCHIBOT,
  START_WAVE,
  TOP_UP,
  TOP_UP_FOR_PLAYER,
  WITHDRAW,
} from "../../constants/actionTypes";

export const getNewProfileData = () => async (dispatch) => {
  try {
    const user = await getSelf();
    dispatch({
      type: AUTH_SUCCESS,
      data: { user: user },
    });
    return user;
  } catch (error) {
    if (error.data?.statusText) throw error.data.statusText;
    else if (error.message) throw error.message;
    else throw error;
  }
};

// TODO: wish I didn't have to do this but getNewProfileData is used too many other places...
export const getallDataForProfilePage = () => async (dispatch, getState) => {
  try {
    const user = await getSelf();
    dispatch({
      type: AUTH_SUCCESS,
      data: { user: user },
    });
    fetchProfileInfo(user, dispatch, getState);
  } catch (error) {
    throw error.data.statusText;
  }
};

// TODO: this function should be separated out
export const fetchProfileInfo = async (user, dispatch, getState) => {
  try {
    if (user.active_teams.length) {
      user.active_teams.map(async (team) => {
        const teamData = await getTeam(team.team_id);
        dispatch({
          type: TEAM_INFO,
          data: teamData,
        });
      });
    }
  } catch (error) {
    console.log(error);
  }

  try {
    user.active_communities.map(async (community) => {
      const journey = await getJourney(user.user_id, community.id);

      const journeyGoal = getState().community.getIn([
        "players",
        community.id,
        user.user_id,
        "current_goal",
      ]);
      const currentJourney = getState().community.getIn([
        "players",
        community.id,
        user.user_id,
        "current_journey",
      ]);
      dispatch({
        type: JOURNEY_INFO,
        data: journey,
        userId: user.user_id,
        communityId: community.id,
        currentJourney,
        journeyGoal,
      });
    });
  } catch (error) {
    console.log(error);
  }
};

export const getJourneysForUser = (userId, communityId) => async (dispatch) => {
  try {
    const journey = await getJourney(userId, communityId);
    dispatch({
      type: JOURNEY_INFO,
      data: journey,
      userId,
      communityId,
    });
  } catch (e) {
    console.error(e.message);
  }
};

export const editProfile = (data) => async (dispatch) => {
  try {
    const res = await axios.put(`${API_ENDPOINT}/users/`, data);
    dispatch({
      type: EDIT_PERSON,
      data: res.data,
    });
  } catch (e) {
    throw e;
  }
};

export const confirmAccount = (data, communityMembershipId, tz) => async (dispatch) => {
  try {
    const res = await axios.put(`${API_ENDPOINT}/users/`, data);

    dispatch({
      type: CONFIRM_ACCOUNT,
      data: { user: res.data },
    });

    const ress = await axios.put(`${API_ENDPOINT}/community-membership/${communityMembershipId}/`, {
      tz,
    });
    dispatch({
      type: EDIT_COMMUNITY_MEMBERSHIP,
      data: ress.data,
      userId: res.data.user_id,
    });
  } catch (e) {
    throw e.response.data.message;
  }
};

export const setNewPassword = (data) => async (dispatch) => {
  try {
    await axios.put(`${API_ENDPOINT}/users/password/`, data);
  } catch (e) {
    throw e.response.data;
  }
};

export const setNewEmailAddress = (data) => async (dispatch) => {
  try {
    await axios.put(`${API_ENDPOINT}/users/email/`, data);
  } catch (e) {
    throw e.response.data;
  }
};

export const topUp = (weiAmount, tx_id, eth_address, game_mode) => async (dispatch, getState) => {
  try {
    const activeCommunityId = getState().community.get("activeCommunityId");
    await axios.post(`${API_ENDPOINT}/community/${activeCommunityId}/topup/`, {
      amount: weiAmount,
      tx_id,
      eth_address,
      game_mode,
    });
    dispatch({
      type: TOP_UP,
      data: {
        weiAmount,
        game_mode,
      },
    });
  } catch (e) {
    // Send transaction information to Sentry for reference
    Sentry.captureMessage(`Failed TopUp: txId: ${tx_id}, txAmount: ${weiAmount}, error: ${e}`);

    throw e?.response?.message || e?.message;
  }
};

export const topUpForPlayer =
  (weiAmount, tx_id, playerToTopUpData) => async (dispatch, getState) => {
    try {
      const activeCommunityId = getState().community.get("activeCommunityId");
      await axios.post(
        `${API_ENDPOINT}/community/${activeCommunityId}/${playerToTopUpData.get(
          "community_membership_id"
        )}/topup/`,
        {
          amount: weiAmount,
          tx_id,
        }
      );
      dispatch({
        type: TOP_UP_FOR_PLAYER,
        data: {
          weiAmount,
          userId: playerToTopUpData.get("user_id"),
        },
      });
    } catch (e) {
      // Send transaction information to Sentry for reference
      Sentry.captureMessage(
        `Failed TopUpForPLayer: txId: ${tx_id}, txAmount: ${weiAmount}, error: ${e}`
      );

      throw e?.response?.message || e?.message;
    }
  };

export const withdraw = (communityId, weiAmount, tx_id, game_mode) => async (dispatch) => {
  try {
    dispatch({
      type: WITHDRAW,
      data: {
        game_mode,
        weiAmountWithdrawn: weiAmount,
      },
    });
    await axios.post(`${API_ENDPOINT}/community/${communityId}/withdraw/`, {
      amount: weiAmount,
      tx_id,
    });
  } catch (e) {
    throw e;
  }
};

export const setPauseDate = (pauseDate, membershipId) => async (dispatch) => {
  try {
    let res = await axios.post(`${API_ENDPOINT}/community-membership/${membershipId}/pause/`, {
      pause: pauseDate,
    });
    dispatch({
      type: SET_PAUSE_DATE,
      data: res.data,
    });
  } catch (e) {
    throw e;
  }
};

export const deletePause = (membershipId) => async (dispatch) => {
  try {
    let res = await axios.delete(`${API_ENDPOINT}/community-membership/${membershipId}/pause/`);
    dispatch({
      type: DELETE_PAUSE_DATE,
      data: res.data,
    });
  } catch (e) {
    throw e;
  }
};

// !TODO: depreciate stats
export const getStats = (communityId) => async (dispatch) => {
  try {
    const res = await axios.get(`${API_ENDPOINT}/community/${communityId}/user-stats/`);
    dispatch({
      type: GET_PERSONAL_STATS,
      data: res.data.data,
      communityId,
    });
  } catch (e) {
    console.log(e);
  }
};

/////////////////////////////
// Community-based Actions
/////////////////////////////
export const getCommunityInfo = (communityId) => async (dispatch) => {
  try {
    const res = await axios.get(`${API_ENDPOINT}/community/${communityId}/`);
    dispatch({
      type: GET_COMMUNITY_INFO,
      data: res.data,
    });
  } catch (error) {
    throw error.response.statusText;
  }
};

export const getCommunityInvitationInformation = (invitationUUID) => async (dispatch) => {
  try {
    const res = await axios.get(`${API_ENDPOINT}/community/invitation/${invitationUUID}/`);
    let errorMessage;
    let errorReason;

    if (res.data.uses < res.data.max_uses && moment(res.data.expiration) > moment()) {
      dispatch({
        type: GET_COMMUNITY_INVITATION,
        data: res.data,
      });
    } else {
      if (moment(res.data.expiration) <= moment()) {
        errorMessage = "link expired or no longer works.";
        errorReason = "Expired Invite";
      } else if (res.data.uses >= res.data.max_uses) {
        errorMessage = "has exceeded the number of uses alotted.";
        errorReason = "Invite Exceeded Max Uses";
      }
      dispatch({
        type: COMMUNITY_INVITATION_FAIL,
        error: {
          message: errorMessage,
          reason: errorReason,
        },
      });
    }
  } catch (error) {
    console.error(error.message || "Invalid Invitation");
    console.log("error", error);
    dispatch({
      type: COMMUNITY_INVITATION_FAIL,
      error: {
        message: "is invalid.",
        reason: "Invalid Invite",
      },
    });
  }
};

export const acceptCommunityInvitation =
  (uuId, userId, communityId) => async (dispatch, getState) => {
    try {
      const res = await axios.patch(`${API_ENDPOINT}/users/`, {
        community_invitation: uuId,
      });

      dispatch({
        type: ACCEPT_COMMUNITY_INVITATION,
        data: res.data,
        userId,
        communityId,
      });
    } catch (error) {
      throw error;
    }
  };

export const fetchJourneyDetails = (journeyId, communityId) => async (dispatch, getState) => {
  try {
    const journey = await axios.get(`${API_ENDPOINT}/journeys/${journeyId}/`);

    const authedUserId = getState().auth.get("authedUserId");
    const journeyGoal = getState().community.getIn([
      "players",
      communityId,
      authedUserId,
      "current_goal",
    ]);

    dispatch({
      type: GET_JOURNEY_DETAILS,
      data: journey.data,
      communityId,
      journeyGoal,
    });
  } catch (error) {
    throw error?.response?.detail;
  }
};

/////////////////////////////
// Discord Actions
/////////////////////////////

export const fetchDiscordCommunityIcon = (communityId) => async (dispatch, getState) => {
  const existingIcon = getState().community.getIn(["communityInfo", communityId, "icon"]);

  if (!existingIcon && existingIcon !== null) {
    try {
      const res = await axios.get(`${API_ENDPOINT}/community/${communityId}/icon/`);
      dispatch({
        type: GET_DISCORD_COMMUNITY_ICON,
        data: res.data,
        communityId,
      });
    } catch (error) {
      console.log(`Fetching Discord community icon failed: ${error?.response?.statusText || ""}.`);
    }
  }
};

export const installMochibot = (installParams) => async (dispatch) => {
  try {
    const res = await axios.get(
      `${API_ENDPOINT}/discord/oauth/community/?code=${installParams.code}&permissions=${installParams.permissions}&guild_id=${installParams.guild_id}`
    );

    dispatch({
      type: INSTALL_MOCHIBOT,
      data: res.data,
    });

    dispatch({
      type: GET_DISCORD_COMMUNITY_ICON,
      data: { icon_url: res.data.community.icon_url },
      communityId: res.data.community.id,
    });

    return res;
  } catch (error) {
    console.error(error);
    throw error.response;
  }
};

/////////////////////////////
// Community-Membership Actions
/////////////////////////////
export const editCommunityMembership =
  (communityMembershipId, color, userId) => async (dispatch) => {
    try {
      const res = await axios.put(
        `${API_ENDPOINT}/community-membership/${communityMembershipId}/`,
        {
          color,
        }
      );
      dispatch({
        type: EDIT_COMMUNITY_MEMBERSHIP,
        data: res.data,
        userId,
      });
    } catch (error) {
      throw error.response.statusText;
    }
  };

export const joinJourneyWave = (communityId, wave) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_ENDPOINT}/community/${communityId}/wave/memberships/`, {
      wave,
    });
    dispatch({
      type: JOIN_WAVE,
      data: res.data,
      communityId,
    });
  } catch (error) {
    throw error.response.statusText;
  }
};

export const leaveJourneyWave = (communityId, waveId) => async (dispatch) => {
  try {
    const res = await axios.patch(`${API_ENDPOINT}/community/${communityId}/wave/${waveId}/`, {
      is_active: false,
    });

    dispatch({
      type: LEAVE_WAVE,
      data: res.data,
      communityId,
    });
  } catch (error) {
    throw error.response.statusText;
  }
};

export const startJourneyWave = (communityId, date) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_ENDPOINT}/community/${communityId}/wave/`, { stop: date });

    dispatch({
      type: START_WAVE,
      data: res.data,
      communityId,
    });

    return res.data;
  } catch (error) {
    throw error.response.statusText;
  }
};

/////////////////////////////
// Discord-based Actions
/////////////////////////////
export const fetchDiscorduserAvatar = (communityId, userId) => async (dispatch) => {
  try {
    const res = await axios.get(`${API_ENDPOINT}/community/${communityId}/user/${userId}/avatar/`);

    dispatch({
      type: GET_DISCORD_USER_AVATAR,
      data: res.data,
      communityId,
      userId,
    });
  } catch (error) {
    console.error(error);
  }
};

/////////////////////////////
// Team-based Actions
/////////////////////////////
export const inititalizeTeam = (name, emailArray, communityId) => async (dispatch) => {
  try {
    let teamData;
    const res = await axios.post(`${API_ENDPOINT}/teams/`, {
      name,
      community: communityId,
    });
    teamData = res.data;

    // make sure only uniques to prevent errors
    emailArray = emailArray.filter((v, i, arr) => arr.indexOf(v) === i && !!v);

    if (emailArray.length) {
      const asyncInvites = emailArray.map(
        async (email) =>
          await axios.post(`${API_ENDPOINT}/invitations/`, {
            team_id: res.data.team_id,
            email,
          })
      );

      const results = await Promise.all(asyncInvites);
      console.log("results", results);

      teamData = results[results.length - 1].data; // the most recent team
    }

    dispatch({
      type: TEAM_INFO,
      data: teamData,
      communityId,
    });
  } catch (e) {
    throw e.response.data.message;
  }
};

// export const sendInvites = (team_id, emailArray) => async (dispatch) => {
//   try {
//     // make sure only uniques to prevent errors
//     emailArray = emailArray.filter((v, i, arr) => arr.indexOf(v) === i && !!v);

//     const asyncInvites = emailArray.map(
//       async (email) =>
//         await axios.post(`${API_ENDPOINT}/invitations/`, { team_id, email })
//     );

//     const results = await Promise.all(asyncInvites);
//     console.log("INVITES:", results[results.length - 1].data);

//     dispatch({
//       type: TEAM_INFO,
//       data: results[results.length - 1].data, // the most recent team
//     });
//   } catch (e) {
//     throw e;
//   }
// };

export const updateTeamName = (teamId, name) => async (dispatch) => {
  try {
    const res = await axios.put(`${API_ENDPOINT}/teams/${teamId}/`, { name });

    dispatch({
      type: TEAM_INFO,
      data: res.data,
    });
  } catch (e) {
    throw e.response.data.message;
  }
};

export const updateTeamLead = (teamData, leader) => async (dispatch) => {
  try {
    const res = await axios.put(`${API_ENDPOINT}/teams/${teamData.get("team_id")}/`, {
      leader,
      name: teamData.get("name"),
    });

    dispatch({
      type: TEAM_INFO,
      data: res.data,
    });
  } catch (e) {
    throw e;
  }
};

export const removeTeamMembership = () => async (dispatch, getState) => {
  try {
    const activeCommunityId = getState().community.get("activeCommunityId");
    await axios.delete(`${API_ENDPOINT}/community/${activeCommunityId}/memberships/`);
    const user = await getSelf();

    dispatch({
      type: REMOVE_MEMBERSHIP,
      data: user,
    });
  } catch (e) {
    throw e;
  }
};

export const removeTeamMember = (user_id) => async (dispatch, getState) => {
  try {
    const activeCommunityId = getState().community.get("activeCommunityId");

    const memResponse = await axios.delete(
      `${API_ENDPOINT}/community/${activeCommunityId}/memberships/`,
      {
        data: { user_id },
      }
    );

    dispatch({
      type: REMOVE_TEAM_MEMBER,
      userId: user_id,
      data: memResponse.data,
    });
  } catch (e) {
    throw e.response.data.message;
  }
};

export const inviteEmail = (team_id, email) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_ENDPOINT}/invitations/`, {
      team_id,
      email,
    });
    dispatch({
      type: TEAM_INVITATION,
      data: Object.assign(res.data, { email }),
    });
  } catch (e) {
    throw e.response.data.message;
  }
};

export const makeNewLeader = (teamId, leader) => async (dispatch) => {
  try {
    const res = await axios.put(`${API_ENDPOINT}/teams/${teamId}/`, { leader });
    dispatch({
      type: TEAM_INFO,
      data: res.data,
    });
  } catch (e) {
    throw e;
  }
};
export const acceptInvitation = (invitation_id, team_id) => async (dispatch) => {
  try {
    const res = await axios.patch(`${API_ENDPOINT}/invitations/${invitation_id}/`, { state: "A" });
    dispatch({
      type: TEAM_INVITATION_ACCEPT,
      data: res.data,
      invitation_id,
    });
    const team = await getTeam(team_id);
    dispatch({
      type: TEAM_INFO,
      data: team,
    });
  } catch (e) {
    throw e.response.statusText;
  }
};

export const declineInvitation = (invitation_id) => async (dispatch) => {
  try {
    const res = await axios.patch(`${API_ENDPOINT}/invitations/${invitation_id}/`, { state: "R" });
    dispatch({
      type: TEAM_INVITATION_DECLINE,
      data: res.data,
      invitation_id,
    });
  } catch (e) {
    throw e.response.statusText;
  }
};

export const deleteInvite = (invitation_id) => async (dispatch) => {
  try {
    await axios.delete(`${API_ENDPOINT}/invitations/${invitation_id}/`);
    dispatch({
      type: TEAM_INVITATION_DELETE,
      invitation_id,
    });
  } catch (e) {
    throw e;
  }
};

export const createJourney =
  (communityId, start, teamId, updateAPICall, upcomingJourneyId) => async (dispatch, getState) => {
    try {
      let res;
      if (updateAPICall && !!upcomingJourneyId)
        res = await axios.patch(`${API_ENDPOINT}/journeys/${upcomingJourneyId}/`, { start });
      else
        res = await axios.post(
          `${API_ENDPOINT}/community/${communityId}/journeys/team/${teamId}/`,
          {
            start,
          }
        );

      dispatch({
        type: TEAM_START_EPOCH,
        data: res.data,
      });
    } catch (e) {
      throw e.response.data.message;
    }
  };

export const deleteUpcomingJourney = (journeyId) => async (dispatch) => {
  try {
    await axios.delete(`${API_ENDPOINT}/journeys/${journeyId}/`);
    dispatch({
      type: TEAM_REMOVE_JOURNEY,
      journeyId,
    });
  } catch (e) {
    throw e;
  }
};

export const fetchTeamsWithActiveJourneys = (communityId) => async (dispatch, getState) => {
  try {
    // set current page to 1
    let next = 1;
    while (next > 0) {
      // fetch teams with active journeys from the current page
      const activeJourneyTeams = await axios.get(
        `${API_ENDPOINT}/community/${communityId}/teams/activejourney/?page=${next}`
      );
      // for each of the teams, dispatch team action and fetch the journey details for the current journey
      activeJourneyTeams.data.results.forEach(async (t) => {
        if (!getState().community.getIn(["teams", communityId, t.team_id])) {
          dispatch({
            type: TEAM_FETCH,
            data: {
              teamData: t,
              journeyData: { results: t.journeys || [] },
            },
            teamId: t.team_id,
            communityId,
          });
          const currentJourney = await findCurrentJourneyAndFetchDetails(
            t.journeys,
            getState().community.getIn(["journeys", communityId])
          );
          if (!!currentJourney)
            dispatch({
              type: GET_JOURNEY_DETAILS,
              data: currentJourney,
            });
        }
      });
      if (!!activeJourneyTeams.data.next) next++;
      else next = 0;
      return activeJourneyTeams.data.results.length;
    }
  } catch (e) {
    if (e.response?.data?.message) {
      throw e.response?.data?.message;
    }
    throw new Error("There was an error fetching teams with active journeys.");
  }
};
/////////////////////////////
// External Player Actions
/////////////////////////////

export const getExternalPlayerStats = (playerId) => async (dispatch, getState) => {
  try {
    const communityId = getState().community.get("activeCommunityId");
    const res = await requestExternalPlayerStats(playerId, communityId);
    dispatch({
      type: EXTERNAL_PLAYER_STATS,
      data: res.data,
      communityId,
      playerId,
    });
  } catch (error) {
    console.log(error);
  }
};

export const fetchExternalPlayerDataOnly = (playerId, communityId) => async (dispatch) => {
  try {
    const playerData = await getExternalPlayerData(playerId, communityId);

    dispatch({
      type: EXTERNAL_PLAYER_DATA_FETCH,
      data: {
        playerData,
        playerJourneys: {},
        playerId,
        communityId,
      },
    });
  } catch (e) {
    console.log(`There was a problem fetching data for player ${playerId}`);
  }
};

export const getExternalPlayerInfo = (playerId, communityId) => async (dispatch, getState) => {
  if (Number(getState().community.get("authedUserId")) === playerId) return;
  try {
    const playerData = await getExternalPlayerData(playerId, communityId);

    const playerJourneys = await getExternalPlayerJourneyInfo(playerId, communityId);
    const teamId = playerData.active_teams.length ? playerData.active_teams[0]?.team_id : null;
    if (teamId && !getState().community.getIn(["teams", communityId, teamId])) {
      const teamRes = await getTeam(teamId);
      const teamJourneyRes = await getTeamsJourney(teamId, communityId);
      dispatch({
        type: TEAM_FETCH,
        data: {
          teamData: teamRes,
          journeyData: teamJourneyRes,
        },
        teamId,
        communityId,
        playerId,
      });
    }

    dispatch({
      type: EXTERNAL_PLAYER_DATA_FETCH,
      data: {
        playerData,
        playerJourneys,
        playerId,
        communityId,
      },
    });

    if (getState().community.getIn(["players", communityId, playerId, "journeyData"])?.size) {
      const res = await requestExternalPlayerStats(playerId, communityId);

      dispatch({
        type: EXTERNAL_PLAYER_STATS,
        data: res.data,
        communityId,
        playerId,
      });
    }
  } catch (e) {
    if (e.message.includes("404") || e.message.includes("403")) {
      dispatch({
        type: EXTERNAL_PLAYER_DATA_FETCH_FAILURE,
        playerId,
        communityId,
      });
    } else {
      console.log(e);
    }
  }
};

export const setTeamInfo = (teamId, communityId, teamData, journeyData) => ({
  type: TEAM_FETCH,
  data: {
    teamData,
    journeyData,
  },
  teamId,
  communityId,
});

export const getTeamInfo = (teamId, activeCommunityId) => async (dispatch) => {
  try {
    const teamRes = await getTeam(teamId);
    const teamJourneyRes = await getTeamsJourney(teamId, activeCommunityId);
    dispatch({
      type: TEAM_FETCH,
      data: {
        teamData: teamRes,
        journeyData: teamJourneyRes,
      },
      teamId,
      communityId: activeCommunityId,
    });
  } catch (e) {
    throw e.response;
  }
};

export const fetchCustomerData = (userId) => async (dispatch) => {
  try {
    const res = await axios.get(`${API_ENDPOINT}/payments/customer/${userId}/`);
    dispatch({
      type: GET_CUSTOMER_DATA,
      data: res.data,
      playerId: userId,
    });
  } catch (e) {
    console.log("[non]member detected");
  }
};

export const toggleCommunity = (communityId) => ({
  type: TOGGLE_COMMUNITY,
  communityId,
});

export const joinMochiAndTeam = (inviteParams) => async () => {
  try {
    const res = await axios.get(`${API_ENDPOINT}/discord/oauth/user/?code=${inviteParams.code}`);
    return res;
  } catch (error) {
    throw error.response;
  }
};
