import React from "react";

const ButtonArrow = (color, fontSize) => (
  <span
    style={{ fontSize: fontSize || "14px", marginLeft: "8px", color: color }}
  >
    &#8594;
  </span>
);

export default ButtonArrow;
