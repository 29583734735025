import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { ProfileCard } from "./index";
import { Box } from "../Utility";
import { Button, Paragraph } from "../../Storybook";

const TeamCard = ({
  leader,
  invited,
  onTeam,
  teamName,
  isAnotherPlayersProfile,
  teamId,
  createTeamModal,
  activeCommunityId,
}) => {
  const teamLink = `/${activeCommunityId}/team/${teamId}`;
  const authUserNotOnTeam = !onTeam && !isAnotherPlayersProfile;
  let subText = "";
  if (onTeam && isAnotherPlayersProfile) subText = "See their team";
  else if (!onTeam && isAnotherPlayersProfile) subText = null;
  else if (!onTeam) subText = invited ? "You have been invited" : "Create a team";
  else {
    subText = leader ? "Meet the team" : "See your team";
  }
  return (
    <ProfileCard heading="Team" bgColor={authUserNotOnTeam && "darkYellow500"} testId="team-card">
      <Paragraph
        color={authUserNotOnTeam ? "darkBGPrimary" : "darkFGPrimary"}
        label={teamName || "No Team"}
        weight={700}
        fontSize="fz3"
        m={0}
      />
      <Box>
        {authUserNotOnTeam && (
          <Button
            textButton
            arrow
            height="auto"
            label={subText}
            textAlign="left"
            colored={"darkYellow500"}
            onClick={() => !onTeam && createTeamModal()}
          />
        )}
        {!!subText && onTeam && (
          <RouterLink to={teamLink}>
            <Button
              textButton
              arrow
              textAlign="left"
              mt={-3}
              colored={authUserNotOnTeam ? "darkYellow500" : null}
            >
              {subText}
            </Button>
          </RouterLink>
        )}
      </Box>
    </ProfileCard>
  );
};

export default TeamCard;
