import React from "react";

const WarningPointer = () => (
  <svg
    width="25"
    height="15"
    viewBox="0 0 25 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.755615 0H24.7556L14.3556 13.8667C13.5556 14.9333 11.9556 14.9333 11.1556 13.8667L0.755615 0Z"
      fill="white"
    />
  </svg>
);

export default WarningPointer;
