import React from "react";
import { connect } from "react-redux";

import { GridBox, Header } from "../../Storybook";
import { Body, Box, Space } from "../Utility";
import WithdrawStep from "./WithdrawStep";
import PlayerMochi from "../Mochi/PlayerMochi";
import ProfileCard from "../Profile/ProfileCard";
import { getPlayerAndTeamDataFromState } from "../../containers/Community/stateGetterHelpers";

export const Withdraw = ({ userData }) => {
  return (
    <>
      <GridBox span={[4, 4, 6]} start={[1, 1, 1]}>
        <Header h1 textAlign="left">
          Withdrawal
        </Header>
        <Body>
          You can withdraw your bond from Mochi at any time you aren't in a
          Journey. In order to play Mochi again, you'll need to re-bond to your
          desired firmness level.
        </Body>
        <Space mb={40} />
        {!!userData.get("user_id") && <WithdrawStep userData={userData} />}
      </GridBox>
      <GridBox span={[4, 4, 6]} start={[1, 5, 7]}>
        <ProfileCard>
          <Box p={5}>
            <PlayerMochi playerId={userData.get("user_id")} />
          </Box>
        </ProfileCard>
      </GridBox>
    </>
  );
};

export default connect((state) => {
  const { playerData } = getPlayerAndTeamDataFromState(state);
  return {
    userData: playerData,
  };
})(Withdraw);
