export const gameItemDetails = {
  HT: {
    name: "Hardhat",
    needsActivation: false,
    description: "Protect yourself when a teammate misses a report.",
    image:
      "https://res.cloudinary.com/delu9m4xu/image/upload/v1656625223/mochi/hardhat-1_gnb937.png",
  },
  HL: {
    name: "Heal",
    needsActivation: false,
    description: "Protect your team when a teammate forgets to report.",
    image:
      "https://res.cloudinary.com/delu9m4xu/image/upload/v1656625223/mochi/heal-1_bnmm7z.png",
  },
  WH: {
    name: "Wish",
    needsActivation: true,
    description: "Reset your goal while a journey is in progress.",
    image:
      "https://res.cloudinary.com/delu9m4xu/image/upload/v1656625224/mochi/wish-1_orwlkk.png",
    success: "Your new goal has been set!",
  },
  SD: {
    name: "Shield",
    needsActivation: false,
    description: "Protect your team when you forget to report.",
    image:
      "https://res.cloudinary.com/delu9m4xu/image/upload/v1656625224/mochi/shield-1_nj7g09.png",
  },
};
