import { API_ENDPOINT } from "../../constants/endpoints";
import axios from "../../config/axios";
import moment from "moment";

// THIS DOES NOT AUTH THE USER - ONLY CREATES INSTANCE IN DB
// NEED TO ACCEPT VIA EMAIL TO ADDRESS
export const createUser = async (first_name, last_name, email, password, community_invitation) => {
  try {
    const res = await axios.post(`${API_ENDPOINT}/users/`, {
      first_name,
      last_name,
      email,
      password,
      community_invitation,
    });
    return res.data;
  } catch (e) {
    throw Error(e);
  }
};

export const attemptActivation = async (key) => {
  try {
    const res = await axios.get(`${API_ENDPOINT}/activation/${key}`);
    return res.data;
  } catch (e) {
    throw Error(e);
  }
};

export const addLead = async (first_name, last_name, email) => {
  try {
    const res = await axios.post(`${API_ENDPOINT}/lead/`, {
      first_name,
      last_name,
      email,
    });
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const getSelf = async () => {
  const r = await axios.get(`${API_ENDPOINT}/users/`);
  return r.data;
};

export const getTeam = async (id) => {
  const r = await axios.get(`${API_ENDPOINT}/teams/${id}/`);
  return r.data;
};

export const getJourney = async (userId, communityId) => {
  const r = await axios.get(`${API_ENDPOINT}/community/${communityId}/journeys/user/${userId}/`);
  return r.data;
};

export const getTeamsJourney = async (teamId, activeCommunityId) => {
  const r = await axios.get(
    `${API_ENDPOINT}/community/${activeCommunityId}/journeys/team/${teamId}/`
  );
  return r.data;
};

export const recoverAccount = async (email) => {
  try {
    await axios.post(`${API_ENDPOINT}/password_reset/`, { email });
    return;
  } catch (e) {
    throw e;
  }
};

export const getExternalPlayerData = async (playerId, activeCommunityId) => {
  const r = await axios.get(`${API_ENDPOINT}/community/${activeCommunityId}/users/${playerId}/`);
  return r.data;
};

export const requestExternalPlayerStats = async (playerId, communityId) => {
  const r = await axios.get(
    `${API_ENDPOINT}/community/${communityId}/user-stats/user/${playerId}/`
  );
  return r.data;
};

export const getExternalPlayerJourneyInfo = async (userId, activeCommunityId) => {
  const r = await axios.get(
    `${API_ENDPOINT}/community/${activeCommunityId}/journeys/user/${userId}/`
  );
  return r.data;
};

// add data from the user object to the community membership
export const flattenUserCommunityData = (userData) => {
  userData.community_memberships = userData.community_memberships.map((cm) => {
    cm.user_id = userData.user_id;
    cm.first_name = userData.first_name;
    cm.last_name = userData.last_name;
    cm.email = userData.email;
    cm.is_onboarded = userData.is_onboarded;
    cm.eth_address = userData.eth_address;
    cm.goals.forEach((goal) => {
      if (goal.is_current) cm.current_goal = goal.goal;
    });
    if (userData.active_teams) {
      userData.active_teams.forEach((team) => {
        if (team.community === cm.community_id) cm.team_id = team.team_id;
      });
    }
    return cm;
  });
  return userData;
};

// TODO: this is so ugly. Only happening because we are merging CM and User data
// in the INSTALL_MOCHIBOT response, we do not have User data, only CM data
export const createFlatCMExistingUser = (
  communityMembership,
  authedUserId,
  communityMembershipDataInState
) => {
  let additionalCM;
  communityMembershipDataInState.valueSeq().forEach((userIdMap) => {
    if (!additionalCM && userIdMap.has(authedUserId))
      additionalCM = userIdMap.get(authedUserId).toJS();
  });

  communityMembership.current_goal = communityMembership.goals[0].goal;
  communityMembership.user_id = additionalCM.user_id;
  communityMembership.first_name = additionalCM.first_name;
  communityMembership.last_name = additionalCM.last_name;
  communityMembership.email = additionalCM.email;
  communityMembership.is_onboarded = additionalCM.is_onboarded;
  communityMembership.eth_address = additionalCM.eth_address;
  communityMembership.is_admin = true;
  communityMembership.journeyData = [];
  communityMembership.currentJourney = null;
  return communityMembership;
};

// takes an array of journey outlines, fetches the details and deturns the response
export const findCurrentJourneyAndFetchDetails = async (journeys, journeyState) => {
  try {
    const now = moment();
    const currentJourney = journeys.find(
      async (j) =>
        moment(j.start, "MM/DD/YYYY").isBefore(now) && moment(j.end, "MM/DD/YYYY").isAfter(now)
    );
    if (!journeyState?.get(currentJourney.id)) {
      if (!!currentJourney) {
        const journeyDetails = await axios.get(`${API_ENDPOINT}/journeys/${currentJourney.id}/`);
        return journeyDetails.data;
      }
    }
  } catch (error) {
    throw error;
  }
};
