import React, { forwardRef } from "react";
import PropTypes from "prop-types";

import StyledButton from "../../components/Utility/Button.js";
import { Box } from "../../components/Utility";
import darkTheme from "../../styles/theme";
import {
  ArrowBoldRight,
  ArrowBoldLeft,
  ArrowNormalLeft,
  ArrowNormalRight,
} from "../../img/svg/icons";

const Button = forwardRef(
  (
    {
      primary,
      secondary,
      colored,
      textButton,
      large,
      arrow,
      leftArrow,
      color,
      bgColor,
      label,
      theme,
      onClick,
      disabled,
      children,
      type,
      noWrap,
      ...props
    },
    ref
  ) => {
    const overwriteColor =
      !!color && darkTheme.color[color]
        ? darkTheme.color[color]
        : !color
        ? darkTheme.color.darkBGPrimary
        : color;

    let arrowColor;
    const colors = darkTheme.color;
    if (colored) {
      if (primary) arrowColor = colors[colored];
      if (secondary) {
        if (disabled) arrowColor = colors.colored.fg.tertiary;
        else arrowColor = colors.colored.fg.primary;
      }
      if (textButton) arrowColor = colors.dark.bg.primary;
    } else if (bgColor && secondary) arrowColor = colors[bgColor];
    else if (primary) {
      if (disabled) arrowColor = colors.dark.bg.secondary;
      else arrowColor = colors.dark.bg.primary;
    } else if ((secondary && !bgColor) || textButton) {
      if (disabled) arrowColor = colors.dark.fg.secondary;
      else arrowColor = colors.dark.fg.primary;
    }

    return (
      <StyledButton
        ref={ref}
        type={type}
        primary={primary}
        secondary={secondary}
        colored={colored}
        textButton={textButton}
        large={large}
        color={overwriteColor}
        bgColor={bgColor}
        arrow={arrow}
        leftArrow={leftArrow}
        theme={theme}
        onClick={onClick}
        disabled={disabled}
        noWrap={noWrap}
        {...props}
      >
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="nowrap"
          justifyContent="center"
          alignItems="center"
        >
          {leftArrow && (
            <Box mr={2}>
              {large ? (
                <ArrowBoldLeft color={arrowColor} />
              ) : (
                <ArrowNormalLeft color={arrowColor} />
              )}
            </Box>
          )}
          {label || children}
          {arrow && (
            <Box ml={2}>
              {large ? (
                <ArrowBoldRight color={arrowColor} />
              ) : (
                <ArrowNormalRight color={arrowColor} />
              )}
            </Box>
          )}
        </Box>
      </StyledButton>
    );
  }
);

Button.propTypes = {
  /**
   * There are three main types of button, always choose one of the three. *Primary*, *Secondary* and *TextButton*
   */
  /**
   * Always pass a button type to ensure they work as intended
   */
  type: PropTypes.oneOf(["button", "submit", "reset"]).isRequired,
  /**
   * Primary style is default
   */
  primary: PropTypes.bool,
  /**
   * Secondary style, has a transparent background and a border.
   */
  secondary: PropTypes.bool,
  /**
   * This type of button has no background color or border
   */
  textButton: PropTypes.bool,
  /**
   * This type of button is not clickable
   */
  disabled: PropTypes.bool,
  /**
   * if the button is in a colored container, such as a notification, pass a theme color
   */
  colored: PropTypes.string,

  // ! Other options
  /**
   * Button is small by default, use large: true for large buttons
   */
  large: PropTypes.bool,
  /**
   * Optional pass an arrow
   */
  arrow: PropTypes.bool,
  /**
   * Optional pass an arrow, left arrow only takes effect on textbutton
   */
  leftArrow: PropTypes.bool,
  /**
   * What background color to use
   */
  bgColor: PropTypes.string,
  /**
   * Text color for display
   */
  color: PropTypes.string,
  /**
   * Button contents
   */
  label: PropTypes.string,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  /**
   * Optional pass another theme once we have Light, dark is passed by default.
   */
  theme: PropTypes.object,
};

Button.defaultProps = {
  theme: darkTheme,
  primary: false,
  secondary: false,
  large: false,
  disabled: false,
  textButton: false,
  label: null,
  children: null,
  color: null,
  bgColor: null,
  type: "button",
};

export default Button;
