import { React } from "react";

import mochiLogo from "../../img/MochiLogoFillStroked.svg";
import { Space, Flex, Box } from "../Utility";
import { Button, Header, Paragraph } from "../../Storybook";
import { contactHelpMsg } from "./communityInvitationStrings";
import Web3Auth from "../Web3/Web3Auth";

const InitialInviteView = ({ isAuthed, acceptInvitation, communityInvitation }) => {
  // remove alternate image when we have more than one community
  const communityImage = communityInvitation?.get("image") || mochiLogo;
  const invitationError = communityInvitation?.get("error");

  if (invitationError) {
    return (
      <>
        <Header h3>{invitationError.get("reason")}</Header>
        <Flex flexDirection="column" padding="20px">
          <Paragraph>
            {`Unfortunately, this invite ${communityInvitation.getIn(["error", "message"])}`}
          </Paragraph>
          <Space mb={20} />
          <Paragraph label={contactHelpMsg} />
        </Flex>
      </>
    );
  }
  const invitorName = communityInvitation.get("creator");
  const communityName = communityInvitation.getIn(["community_details", "name"]);
  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Paragraph>{`${invitorName} has invited you to join ${communityName}`}</Paragraph>
      <Box m={3}>
        {!!communityImage && (
          <img
            src={communityImage}
            alt={`${communityInvitation.getIn(["community_details", "name"])} logo`}
            width="90%"
          />
        )}
      </Box>
      <Flex flexDirection="column" justifyContent="center" mt={2}>
        {isAuthed ? (
          <Button
            primary
            alignText="center"
            label="Accept Invitation"
            onClick={() => acceptInvitation()}
          />
        ) : (
          <Web3Auth
            primary
            prompt="Connect Wallet to Accept"
            communityInvitationId={communityInvitation.get("id")}
          />
        )}
      </Flex>
    </Box>
  );
};

export default InitialInviteView;
