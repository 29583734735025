export const failedPurchaseMessage =
  "Something went wrong and you didn’t buy an item. Make sure to approve the transaction in your wallet.";

export const maxInventoryMessage =
  "You already have the maximum quantity allowed for this item.";

export const failedRetrivalMsg =
  "Failed to retrieve inventory. Try again later.";

export const generalItemActivatedMsg =
  "Your item has been successfully activated!";

export const loadingMessage =
  "Be sure to accept the transaction in MetaMask. After that it may take some time for your transaction to confirm...";

export const insufficientTokensMessage =
  "Insufficient tokens. Keep checking in to earn more.";

export const failedToAcceptMsg = "Did you accept the transaction on MetaMask?";

export const successPurchaseMsg = (itemName) =>
  `You purchased ${itemName}! View it on your profile. Click on it for instructions on how to use it.`;
