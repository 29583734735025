import styled, { css } from "styled-components";
import { space, flexbox, color, layout } from "styled-system";

const StyledCommunityTag = styled.button`
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${(props) =>
    !props.color ? "transparent" : props.theme.color[props.color]};
  text-align: left;
  border: none;
  border-radius: ${(props) => props.theme.pixels.sz1};
  padding: ${(props) =>
    !props.color ? "4px 8px 4px 8px" : "8px 16px 8px 16px"};
  width: ${(props) => (!props.narrow ? "100%" : "auto")};

  &:hover {
    ${(props) =>
      !props.current &&
      css`
        background: ${(props) => props.theme.color.darkBGPrimary};
      `};
  }
  ${space} ${flexbox} ${color} ${layout};
`;

export default StyledCommunityTag;
