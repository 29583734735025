import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import { Button, GridBox, Paragraph, Avatar } from "../../Storybook";
import { Box } from "../Utility";
import MutedCheckmark from "../Utility/MutedCheckmark";
import null288 from "../../img/null-288.png";

const CheckInStatusMessage = ({
  history,
  allComplete,
  numberOfGoals,
  activeCommunityId,
  authedPlayerId,
  checkInGoal,
}) => {
  const goalPlural = numberOfGoals > 1 ? "goals" : "goal";
  return (
    <GridBox
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      {allComplete ? (
        <MutedCheckmark active={true} />
      ) : (
        <Avatar image={null288} square large />
      )}
      <Box>
        {!numberOfGoals ? (
          <Box>
            <Paragraph
              fontSize="fz3"
              label="You're not working toward any goals right now."
              mb={2}
            />
            <Paragraph fontSize="fz3" label="Start a new journey." mb={3} />
            <Link to={`/${activeCommunityId}/profile/${authedPlayerId}`}>
              <Button primary arrow label="See your profile" />
            </Link>
          </Box>
        ) : (
          <>
            {!allComplete ? (
              <Paragraph
                fontSize="fz3"
                color={true ? "darkFGSecondary" : "darkFGPrimary"}
                label={`Hey Mochi, you're working towards the following ${goalPlural} currently:`}
              />
            ) : (
              <Box>
                <Paragraph
                  fontSize="fz3"
                  label="You’ve checked in on all your goals - good luck on your endeavors today & see you next report."
                  mb={3}
                />
                <Box>
                  <Paragraph
                    fontSize="fz3"
                    label="Don’t forget to check in on your teammates!"
                    mb={4}
                  />
                  <Link to={`/${activeCommunityId}/profile/${authedPlayerId}`}>
                    <Button primary arrow label="Return to Profile" />
                  </Link>
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
    </GridBox>
  );
};

export default withRouter(
  connect((state, props) => {
    const authedPlayerId = state.community.get("authedUserId");
    const activeCommunityId = state.community.get("activeCommunityId");
    return {
      authedPlayerId,
      activeCommunityId,
    };
  })(CheckInStatusMessage)
);
