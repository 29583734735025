import React, { useState, useMemo, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import { BackgroundImage, Container, Box, Flex } from "../components/Utility";
import LoadingAni from "../components/Utility/Loading/LoadingAni";
import Web3Auth from "../components/Web3/Web3Auth";
import backgroundImage from "../img/invite-background.jpg";
import { Modal, Paragraph, Header } from "../Storybook";
import { getPlayerAndTeamDataFromState } from "../containers/Community/stateGetterHelpers";
import mochiLogo from "../img/MochiLogoFillStroked.svg";
import { getTeamInfo, getNewProfileData, joinMochiAndTeam } from "../containers/Community/actions";

const CombinedInvitationPage = ({
  authed,
  authedUserId,
  allCommunitiesInfo,
  joinMochiAndTeam,
  getNewProfileData,
  getTeamInfo,
  playerData,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [step, setStep] = useState("initial");
  const [error, setError] = useState("");
  const [joined, setJoined] = useState(null);
  const [requested, setRequested] = useState(false);

  const installParams = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return {
      code: params.get("code"),
    };
  }, [location.search]);

  const validParams = !!installParams && !!installParams.code;
  const fullCommunityInfo = allCommunitiesInfo.get(joined?.community_id);

  useEffect(() => {
    if (!!fullCommunityInfo && !playerData?.get("teamInvitations")) {
      setStep("success");
      const actions = new URLSearchParams(joined.actions.map((a) => ["actions", a]));

      history.push(`/${joined?.community_id}/profile/${authedUserId}?${actions})`);
    } else if (authed && !!joined) {
      setTimeout(() => getNewProfileData(), 4000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullCommunityInfo, authedUserId]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const joinMochi = async () => {
    if (!requested && authed) {
      try {
        setStep("connecting");
        setRequested(true);
        const res = await joinMochiAndTeam(installParams);

        // already click no team id
        if (res.data.community_id) {
          await getNewProfileData();
          if (res.data.team_id) await getTeamInfo(res.data.team_id, res.data.community_id);
        } else {
          setStep("error");
          setError("This invitation is no longer valid, or you're not authorized to use it.");
        }
        if (res.data.community_id) setJoined(res.data);
      } catch (er) {
        setStep("error");
        if (er.detail) setError(er.detail);
        else setError(`${er.status}: ${er.statusText}`);
      }
    }
  };

  useEffect(() => {
    if (authed && validParams && !joined && !requested) joinMochi();
  }, [validParams, authed, joined, requested, joinMochi]);

  const showLoadingAnimation = !error.length && validParams && step === "connecting";

  return (
    <Container>
      <BackgroundImage src={backgroundImage} alt="background: circle gradients" />
      <Modal height="500px">
        {error?.length > 0 && (
          <Box>
            <Header h3 mb={3} label="Something Went Wrong" />
            <Paragraph label={error} mb={3} />
          </Box>
        )}

        {!validParams && step === "initial" && (
          <Box>
            <Header h3 mb={3} label="Invalid URL" />
            <Paragraph label="Make sure you got this URL by accepting an invitation to install Mochibot on Discord." />
          </Box>
        )}

        {showLoadingAnimation && (
          <Flex height="500px" alignItems="center" alignContent="center">
            <LoadingAni alt="loading" />
          </Flex>
        )}

        {!authed && step !== "connecting" && (
          <Box>
            <Paragraph fontSize="fz1" mb={3} label="Create a Mochi account to continue" />
            <Box mb={3}>
              <img src={mochiLogo} alt="Mochi Logo" width="90%" />
            </Box>
            <Web3Auth primary wide />
          </Box>
        )}
      </Modal>
    </Container>
  );
};

export default connect(
  (state) => {
    const { playerData } = getPlayerAndTeamDataFromState(state);
    const authedUserId = state.community.get("authedUserId");
    return {
      playerData,
      authedUserId,
      authed: state.auth.get("auth") && !!playerData?.get("user_id"),
      activeCommunityId: state.community.get("activeCommunityId"),
      allCommunitiesInfo: state.community.get("communityInfo"),
    };
  },
  {
    joinMochiAndTeam,
    getNewProfileData,
    getTeamInfo,
  }
)(CombinedInvitationPage);
