import React, { useState } from "react";
import { connect } from "react-redux";

import * as fieldValidators from "./utils/fieldValidators";
import useInput from "./utils/useInput";
import { inititalizeTeam } from "../../containers/Community/actions";
import { Input, Button, Paragraph, Header } from "../../Storybook";

export const CreateATeam = ({ inititalizeTeam, communityId }) => {
  const [submited, setSubmited] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const {
    value: teamName,
    bind: bindTeamName,
    valid: validTeamName,
    active: activeTeamName,
  } = useInput("", fieldValidators.basicTextField, true);

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setSubmited(true);
    setError("");
    if (validTeamName) {
      try {
        setSuccess("Your team has been created");
        await inititalizeTeam(teamName, ["", "", ""], communityId);
      } catch (e) {
        setSuccess("");
        setError(
          `There was an error creating your team: ${e}. Please try again.`
        );
      }
    }
  };
  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      {success.length > 0 && (
        <Paragraph color="darkPositive500" mb={3} label={success} />
      )}
      <Header mb={3} label="Create A Team" />
      <Input
        type="text"
        role="textbox"
        placeholder={teamName || ""}
        submited={submited}
        activeInput={activeTeamName}
        error="Please enter a new team name"
        textLabel="Team Name"
        {...bindTeamName}
      />
      {error.length > 0 && (
        <Paragraph mb={3} color="darkNegative700" label={error} />
      )}
      <Button
        primary
        disabled={!validTeamName || !!success}
        type="submit"
        label="Create Team"
      />
    </form>
  );
};

export default connect(
  (state) => ({
    communityId: state.community.get("activeCommunityId"),
  }),
  {
    inititalizeTeam,
  }
)(CreateATeam);
