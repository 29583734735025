import React from "react";

import { GridContainer } from "../Utility";
import FreePlayers from "./FreePlayers";
import OpenTeams from "./OpenTeams";

const FreeToPlay = () => {
  return (
    <GridContainer>
      <FreePlayers />
      <OpenTeams />
    </GridContainer>
  );
};

export default FreeToPlay;
