import styled from "styled-components";

const Tab = styled.li`
  padding: 6px 14px;
  background: ${(props) =>
    props.selected
      ? props.theme.color.white
      : props.theme.color.darkBGSecondary};
  border-radius: 50px;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

export default Tab;
