import React, { Fragment } from "react";

import { gameModeInfo } from "./bondingMessages";
import { Header } from "../../Storybook";
import { InlineAnchor, Paragraph, Space, Tab, TabList } from "../Utility";
import { genNewId } from "../../utils/mochiHelpers";

export const SelectFirmness = ({
  gameModeName,
  gameModeAward,
  selectFirmness,
  activeGameMode,
  orderedGameModes,
  isForAnotherPlayer = false,
}) => {
  return (
    <Fragment>
      <Header h3>Select a Firmness</Header>
      {isForAnotherPlayer && (
        <Paragraph weight="800">
          When bonding for another player, you will not be able to set their game mode for them.
          They must select the difficulty themselves. Mochibot will notify the player if a top up is
          made on their behalf, but we recommend to reach out to the player directly to let them
          know you have topped up for them.
        </Paragraph>
      )}
      <TabList>
        {orderedGameModes.map((m, i) => (
          <Tab
            key={genNewId("game-modes")}
            data-testid={m.get("name") + " Tab"}
            onClick={() => selectFirmness(m.get("id"))}
            selected={m.get("id") === activeGameMode}
          >
            <Header h5 selected={m.get("id") === activeGameMode}>
              {m.get("name")}
            </Header>
          </Tab>
        ))}
      </TabList>
      <Space mb={10} />
      {gameModeInfo(gameModeName, gameModeAward)}
      <Space mb={10} />
      <InlineAnchor onClick={() => window.open("/the-game?scrollto=bond", "_blank")}>
        Learn more about Firmness
      </InlineAnchor>
    </Fragment>
  );
};
