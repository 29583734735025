import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { colors, genNewId } from "../utils/mochiHelpers";
import { GridContainer, ItemMat, Flex, Box, Loading } from "../components/Utility";
import { Button, GridBox, Header, Modal, Paragraph } from "../Storybook";
import { editCommunityMembership } from "../containers/Community/actions";

const ColorSelectionPage = ({ editCommunityMembership }) => {
  // TODO: should set state to current color
  const [selected, setSelected] = useState();
  const [error, setError] = useState();
  const { communityId } = useParams();
  let history = useHistory();
  const playerData = useSelector((state) =>
    state.community.getIn(["players", Number(communityId), state.community.get("authedUserId")])
  );

  const colorsArray = Object.keys(colors.toJS());

  const handleColorSelection = async (e) => {
    e.preventDefault();
    try {
      await editCommunityMembership(
        playerData.get("community_membership_id"),
        selected,
        playerData.get("user_id")
      );
      history.push(`/${communityId}/profile/${playerData.get("user_id")}/`);
    } catch (error) {
      setError(error);
    }
  };
  if (!playerData) return <Loading />;
  return (
    <>
      {!!error && (
        <Modal onClose={() => setError("")}>
          <Paragraph label={error} />
        </Modal>
      )}
      <GridContainer>
        <GridBox>
          <Box mb={3}>
            <Header
              h4
              color="darkFGSecondary"
              label={`${playerData.get("first_name")} ${playerData.get("last_name")}'s Mochi`}
            />
            <Header h1 label="Select a Flavor" />
          </Box>
        </GridBox>

        <GridBox>
          <Flex
            flexWrap="wrap"
            flexDirection="row"
            justifyContent="center"
            alignItems="flex-start"
            alignContent="flex-start"
          >
            {colorsArray.map((c) => (
              <ItemMat
                bgColor="darkBGTertiary"
                key={genNewId("color")}
                margin="10px"
                data-testid={colors.getIn([c, "name"])}
                onClick={() => setSelected(colors.getIn([c, "name"]))}
                selected={selected === colors.getIn([c, "name"])}
                width="220px"
                height="220px"
              >
                <img src={colors.getIn([c, "img"])} alt={colors.getIn([c, "name"])} width="120px" />
                <Header
                  h4
                  color={
                    selected === colors.getIn([c, "name"]) ? "darkFGPrimary" : "darkFGTertiary"
                  }
                  label={colors.getIn([c, "name"])}
                  mt={3}
                />
              </ItemMat>
            ))}
          </Flex>
        </GridBox>
        <GridBox display="flex" justifyContent="flex-end">
          <Button
            primary
            label="Save Flavor"
            onClick={(e) => handleColorSelection(e)}
            disabled={!selected}
            data-testid="Save Flavor"
          />
        </GridBox>
      </GridContainer>
    </>
  );
};

export default connect((state, props) => ({}), { editCommunityMembership })(ColorSelectionPage);
