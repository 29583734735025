/* eslint-disable no-undef */
import { formatEther } from "viem";
import { fromJS, List, Map } from "immutable";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";

import theme from "../styles/theme";
import {
  kiwi,
  durian,
  guava,
  fig,
  acai,
  papaya,
  lychee,
  mango,
  blueberry,
  banana,
  grapefruit,
} from "../img/colors";

export const setFirmnessAndMinimumStake = (currentGameModeId, weiBalance, gameModes) => {
  const gameModeWeiBal = gameModes?.filter((gm) => gm.get("minimum_stake") <= weiBalance).last();

  const selectedGameMode =
    gameModes?.find((gm) => gm.get("id") === currentGameModeId) || gameModeWeiBal;

  if (gameModeWeiBal) {
    return {
      currFirmness: selectedGameMode.get("name"),
      minStake: selectedGameMode.get("minimum_stake"),
    };
  } else return [];
};

// shorten the input address to have 0x + 4 characters at start and end
export const shortenAddress = (address, chars = 4) => {
  return `${address.substring(0, chars + 2)}...${address.substring(42 - chars)}`;
};

// returns a string in ETH
export const calculateSlashProtectionAmount = (minBond, multiplier) => {
  return BigInt(minBond.toString()) / BigInt(multiplier).toString();
};

export const calculateMochiHealth = (
  minimumStake,
  weiBalance,
  slashes,
  journeyCount,
  gameModeId,
  playerHasBeenDowngraded,
  isNewUser
) => {
  let showBondWarning, meterColor;

  const greenUpperLimit = minimumStake * 2;
  const greenLimit = minimumStake * 1.09;
  const yellowLimit = minimumStake;

  const green = theme.color.positive.green500;
  const orange = theme.color.warning.orange500;
  const red = theme.color.negative.red500;
  const gray = theme.color.darkFGSecondary;

  // if on soft mode or not yet bonded
  // or if this is the user's first journey
  if (isNewUser) {
    showBondWarning = false;
    meterColor = green;
  } else if (playerHasBeenDowngraded || (journeyCount > 1 && gameModeId === 1)) {
    showBondWarning = true;
    meterColor = red;
  } else if (
    weiBalance === null ||
    !gameModeId ||
    (gameModeId === 1 && (!journeyCount || journeyCount <= 1))
  ) {
    showBondWarning = true;
    meterColor = green;
  } else if (
    (weiBalance > 0 && weiBalance >= greenLimit) ||
    (weiBalance > yellowLimit && slashes === 0)
  ) {
    meterColor = green;
    showBondWarning = false;
  } else {
    showBondWarning = true;
    if (weiBalance > 0 && weiBalance >= yellowLimit) meterColor = orange;
    else meterColor = red;
  }

  // based on the highest stake level, determine the percentage of bond, using wei balance
  const bondPercent = (weiBalance / greenUpperLimit) * 100;

  return {
    showBondWarning,
    bondPercent,
    meterColor,
    // For most meter colors, we fill the minimum bond in the same color. The exception is red, because we don't want to fill the entire meter red. We fill the bond in red and the minimum bond gray
    minimumBondColor: meterColor === red ? gray : meterColor,
  };
};

export const colors = fromJS({
  Kiwi: {
    img: kiwi,
    name: "Kiwi",
  },
  Durian: {
    img: durian,
    name: "Durian",
  },
  Guava: {
    img: guava,
    name: "Guava",
  },
  Fig: {
    img: fig,
    name: "Fig",
  },
  Acai: {
    img: acai,
    name: "Acai",
  },
  Papaya: {
    img: papaya,
    name: "Papaya",
  },
  Lychee: {
    img: lychee,
    name: "Lychee",
  },
  Mango: {
    img: mango,
    name: "Mango",
  },
  Grapefruit: {
    img: grapefruit,
    name: "Grapefruit",
  },
  Banana: {
    img: banana,
    name: "Banana",
  },
  Blueberry: {
    img: blueberry,
    name: "Blueberry",
  },
});

export const convertWeiToEth = (weiBalance) => {
  const weiConvertedToEth = formatEther(weiBalance.toString());
  const formattedEth = weiConvertedToEth.includes(".")
    ? `${weiConvertedToEth.split(".")[0]}.${weiConvertedToEth.split(".")[1].slice(0, 3)}`
    : weiConvertedToEth;

  return formattedEth;
};

export const processShowAndTell = (journeyData, playerId) => {
  let showAndTellDataObj = new Map();
  let showAndTellDataAsAList = new List();

  journeyData?.get("showAndTellData").forEach((val, key) => {
    const playerIdstring = playerId.toString();
    const date = val.getIn([playerIdstring, "report_date"]) || key;
    const showAndTellDay = fromJS({
      date: key,
      report_date: date,
      report: val.getIn([playerIdstring, "report"]) || null,
      paused: val.getIn([playerIdstring, "paused"]),
      player_slash_amount: val.getIn(["slashes", playerIdstring]),
      completed: val.get("completed"),
    });

    showAndTellDataAsAList = showAndTellDataAsAList.push(showAndTellDay);
    showAndTellDataObj = showAndTellDataObj.set(date, showAndTellDay);
  });
  return { showAndTellDataAsAList, showAndTellDataObj };
};

export const formatDisplayDate = (dayDateStr) => {
  // formatting display date
  const oneWeekBefore = moment().subtract(7, "days");
  const today = moment().subtract(moment().hours(), "hours");
  const differenceFromToday = dayDateStr.diff(today, "days");

  if (dayDateStr.isBefore(oneWeekBefore)) return dayDateStr.format("MMMM Do, YYYY");
  else if (differenceFromToday === 0) return "Today";
  else if (differenceFromToday === -1) return "Yesterday";
  else return dayDateStr.format("dddd");
};

export const shouldReportBeRendered = (d, quitDate, showNTellReportDate) => {
  const journeyQuitDate = quitDate;
  const dayAfterQuitDate = !!quitDate && moment(quitDate).add(1, "days");
  const today = moment().subtract(moment().hours(), "hours");

  const reportIsBeforeQuitDate = !!journeyQuitDate
    ? moment(d.get("date"), "MM/DD/YYYY").isBefore(dayAfterQuitDate)
    : true;

  const showAndTellReportIsBeforeToday =
    !!showNTellReportDate && moment(showNTellReportDate, "MM/DD/YYYY").isBefore(today);

  const playerSubmittedShowNTell =
    showAndTellReportIsBeforeToday && showNTellReportDate === d.get("date");

  const playerSubmittedPOW = reportIsBeforeQuitDate && !!d.get("conversation");

  return playerSubmittedPOW || playerSubmittedShowNTell || !!d.get("player_slash_amount");
};

export const getCommunitIdFromRedirectUrl = (url) => {
  const urlFromFirstSlash = url.substr(url.indexOf("/") + 1, url.length - 1);

  const redirectCommunityId = Number(urlFromFirstSlash.slice(0, urlFromFirstSlash.indexOf("/")));

  if (typeof redirectCommunityId === "number") return redirectCommunityId;
};

export const genNewId = (name) => `${name}-${uuidv4()}`;

export const isViewingJourneyInprogress = (journey) => {
  const start = moment(journey.get("start"), "MM/DD/YYYY");
  const end = moment(journey.get("end"), "MM/DD/YYYY");
  const now = moment();

  return start.isBefore(now) && end.isAfter(now);
};

export const findLatestPearls = (latestJourney, teamPlayers) => {
  if (!latestJourney?.get("start")) return {};
  let latestPearls = {};
  let latestPearlDate;

  teamPlayers.forEach((p) => {
    const playerId = p.get("user_id");
    const latestPlayerJourney = latestJourney.getIn([playerId.toString(), "simpleJourney"]);
    if (!!latestPlayerJourney?.size) {
      latestPearls[playerId] = latestPlayerJourney.last().toJS();
      if (!latestPearlDate) latestPearlDate = moment(latestPearls[playerId].date, "MM/DD/YYYY");
    }
  });

  return { latestPearls, latestPearlDate };
};

export const generateCommunityLink = (communityInfo, userDetails) => {
  const communityType = communityInfo.get("community_type");

  // once platform details is available, determine community name based on community type
  const platformName = communityType === "S" ? "Slack" : communityType === "D" && "Discord";

  const slackCommunityURL =
    communityType === "S" && `slack://user?team=${communityInfo.get("community_id")}&id=`;

  // determine community link based on type of community
  const platformUserLink =
    platformName === "Slack"
      ? `${slackCommunityURL}${userDetails.get("id")}`
      : platformName === "Discord" && `discord://discord.com/users/${userDetails.get("id")}`;

  return { platformUserLink, platformName };
};

const convertTZ = (date, tzString) => {
  const timeZone = tzString || "America/Los_Angeles";
  return new Date(typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
    timeZone: timeZone,
  });
};

export const assignReportStatus = (pearl, playerData, pearlDate) => {
  const timeZone = playerData.getIn(["details", "tz"]);

  const now = moment();

  const deadline = moment(
    `${pearlDate.format("MM/DD/YYYY")}, ${
      playerData.get("night_mode") ? "10:00:00 pm" : "6:00:00 pm"
    }`,
    "MM/DD/YYYY, hh:mm:ss a"
  );

  const nowFormattedInTZ = moment(convertTZ(now, timeZone), "MM/DD/YYYY, hh:mm:ss a");

  const timeFromDeadline = deadline.diff(nowFormattedInTZ, "hours");
  const playerHasntCheckIn =
    !pearl ||
    timeFromDeadline >= 8 ||
    pearl.is_on_time === null ||
    pearl.check_in_success !== false;

  let color, status;

  if (!!pearl && pearl.check_in_success && pearl.is_on_time) {
    color = "darkPositive500";
    status = "reported";
  } else if (!!pearl?.paused) {
    color = "darkCyan500";
    status = "On vacation 🏝️ ";
  } else if (timeFromDeadline >= 0 && playerHasntCheckIn) {
    color = "darkWarning500";
    status = `${timeFromDeadline
      .toString()
      .concat(timeFromDeadline > 1 ? " Hours" : " Hour")} Left...`;
  } else {
    color = "darkNegative500";
    status = "Missed!";
  }

  return { color, status };
};

export const getDisplayName = (playerData) => {
  if (!!playerData) {
    let displayName = playerData.getIn(["details", "display_name"]);
    const firstNLast = `${playerData.get("first_name")} ${playerData.get("last_name")}`;
    if (displayName !== null && !!displayName?.length) return displayName;
    else if (firstNLast !== "null null" && firstNLast !== "undefined undefined") return firstNLast;
  }
  return "Mochi";
};

export const getRedirectCommunity = () =>
  !!window.location.search.length ? window.location.search.split("=/")[1] : null;

// from a list of journeys, detail or otherwise, returns a journey that is current but not upcoming
export const getActiveJourneyFromJourneyList = (journeys = new List()) => {
  const today = moment().add(24 - moment().hours(), "hours");

  return journeys.find(
    (j) =>
      moment(j.get("start"), "MM/DD/YYYY").isBefore(today) &&
      moment(j.get("end"), "MM/DD/YYYY").isAfter(today)
  );
};

// from a list of journeys, detail or otherwise, returns a journey that is upcoming and not current
export const getUpcomingJourneyFromJourneyList = (journeys = new List()) => {
  const today = moment().add(24 - moment().hours(), "hours");

  return journeys.find((j) => moment(j.get("start"), "MM/DD/YYYY").isAfter(today));
};

// Given a goal and an object of journeys ordered indexed by journeyId, it returns a string describing what month and year the goal was last used
export const determineWhenGoalWasLastUsed = (goal, journeyObj) => {
  if (!!goal.get("is_current")) return "Current Goal";
  else if (!!goal.get("completed_date"))
    return `Last active ${moment(goal.get("completed_date")).format("MMM YYYY")}`;
  else if (!!goal.get("journeys").size && !!journeyObj) {
    let lastActiveDate;
    // look up the end dates of all journeys for the goal, and determine which has the latest end date
    goal.get("journeys").forEach((j) => {
      const journeyEnd = moment(journeyObj.getIn([j.toString(), "end"]), "MM/DD/YYYY");
      if (!lastActiveDate || journeyEnd.isAfter(lastActiveDate)) lastActiveDate = journeyEnd;
    });
    return `Last active ${lastActiveDate?.format("MMM YYYY")}`;
  } else return "Unused";
};
