import React, { useEffect, useState } from "react";

import "./modal.css";
import theme from "../../styles/theme";
import { Box } from "./index";

const Modal = ({ children, onClose, noClose }) => {
  const [wiggle, setwiggle] = useState(false);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);

  const onBGClick = () => {
    if (noClose) {
      setwiggle(true);
      setTimeout(() => setwiggle(false), 500);
    } else {
      onClose && onClose();
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        bottom: "0",
        right: "0",
        left: "0",
        background:
          "radial-gradient(50% 50% at 50% 50%, rgba(68, 68, 68, 0) 0%, rgba(68, 68, 68, 0.25) 100%), rgba(68, 68, 68, 0.125)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: "10",
      }}
      data-testid="modal"
      onClick={() => onBGClick()}
    >
      <Box
        width="300px"
        padding={theme.pixels.sz3}
        textAlign="center"
        onClick={(e) => e.stopPropagation()}
        className={wiggle ? "wiggle" : ""}
        style={{
          background: theme.color.darkBGPrimary,
          boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25), 0px 0px 12px rgba(0, 0, 0, 0.125)",
          borderRadius: theme.pixels.sz2,
          position: "relative",
        }}
      >
        {children}
      </Box>
    </div>
  );
};

export default Modal;
