import React from "react";

import { Flex } from "../Utility/index";
import WarningPointer from "../../img/svg/warning-pointer";
import { Button } from "../../Storybook";
import { PropTypes } from "prop-types";

const WarningIndicator = ({ warningMessage }) => (
  <Flex flexDirection="column" justifyContent="center" alignItems="center">
    <Button primary arrow height="auto" label={warningMessage} p={3} pr={3} />
    <WarningPointer position="relative" />
    <div
      style={{
        width: "8px",
        height: "8px",
        borderRadius: "50%",
        background: "#000000",
        position: "absolute",
        bottom: 0,
        left: "50%",
        marginBottom: "-15px",
        marginLeft: "-4px",
        zIndex: 5,
      }}
    />
  </Flex>
);

WarningIndicator.propTypes = {
  warningMessage: PropTypes.string,
};

WarningIndicator.defaultProps = {
  warningMessage: null,
};

export default WarningIndicator;
