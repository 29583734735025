export const infoJourneyNotStartedLeader =
  "You cannot leave your team and quit your journey since it has not started yet. Use the Cancel button below to cancel the upcoming journey.";
export const infoJourneyNotStartedMember =
  "You cannot quit the journey since it has not started yet. Reach out to your team lead and have them cancel the upcoming journey so you can leave the current team.";
export const infoNoQuitAsLeader =
  "You cannot quit the current journey and leave your current team as the team leader. Please assign a new team leader first by using the 'Make Leader' button below a teammate.";
export const infoLeaveTeamFailure =
  "There was an issue leaving the current journey. Please try again or contact support if you experience further issues.";
export const infoNoLeaveTeamAsLeader =
  "You cannot leave your current team as the team leader. Please assign a new team leader first by using the 'Make Leader' button below a teammate.";
