import theme from "../../../styles/theme";
const WarningLarge = ({ color }) => (
  <svg
    width="24"
    height="14"
    viewBox="0 0 28 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 0.5L0 25.5H28L14 0.5ZM13.125 18.3571L12.25 7.64286H15.75L14.875 18.3571H13.125ZM15.75 21.9286C15.75 22.9148 14.9665 23.7143 14 23.7143C13.0335 23.7143 12.25 22.9148 12.25 21.9286C12.25 20.9423 13.0335 20.1429 14 20.1429C14.9665 20.1429 15.75 20.9423 15.75 21.9286Z"
      fill={theme.color[color]}
    />
  </svg>
);

export default WarningLarge;
