import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAccount } from "wagmi";

import { Loading } from "./";
import Web3Auth from "../Web3/Web3Auth";

export const AuthorizedHOC = ({ children }) => {
  let history = useHistory();
  const { isConnected } = useAccount();
  const authenticated = useSelector((state) => state.auth.get("auth"));
  const [authAttempted, changeAttempt] = useState(false);

  useEffect(() => {
    if (!authenticated) {
      const token = window.localStorage.getItem("token");
      if (!token && authAttempted) history.push(`/sign-in?redirect=${history.location.pathname}`);
      else if (!authAttempted) changeAttempt(true);
    }
  }, [authenticated, authAttempted, history]);

  if (!isConnected) return <Web3Auth />;
  if (authenticated && isConnected) return children;
  else return <Loading />;
};
export default AuthorizedHOC;
