import React from "react";

import Neophyte from "../../img/rank/neophyte.svg";
import Chamoe from "../../img/rank/chamoe.svg";
import Ube from "../../img/rank/ube.svg";
import Daifuku from "../../img/rank/daifuku.svg";
import Matcha from "../../img/rank/matcha.svg";
import Pomelo from "../../img/rank/pomelo.svg";
import { Link as RouterLink } from "react-router-dom";
import { Flex, Box } from "../Utility";
import { Button, Header } from "../../Storybook";

const images = {
  neophyte: Neophyte,
  chamoe: Chamoe,
  ube: Ube,
  daifuku: Daifuku,
  matcha: Matcha,
  pomelo: Pomelo,
};

export const RankIndicator = ({ rankName }) => {
  const nameCapitalized = rankName.charAt(0).toUpperCase() + rankName.slice(1);

  const altText = "The user's rank is " + nameCapitalized;

  return (
    <Box mb={3}>
      <Flex alignItems="center" justifyContent="flex-start">
        <img src={images[rankName]} width="22px" alt={altText} />
        <Header h4 fontWeight={600} ml={2}>
          {`${nameCapitalized} Rank`}
        </Header>
      </Flex>
      <RouterLink to="/the-game?scrollto=rank">
        <Button textButton arrow textAlign="left" height="auto">
          Learn about ranks
        </Button>
      </RouterLink>
    </Box>
  );
};

export default RankIndicator;
