/* eslint-disable no-undef */
import React, { useState, useEffect, Fragment, useCallback, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useContractWrite, usePrepareContractWrite } from "wagmi";
import { formatEther, getAddress, parseEther } from "viem";

import { WEB3_NETWORK } from "../../constants/endpoints";
import { fetchJourneyDetails, topUpForPlayer } from "../../containers/Community/actions";
import theme from "../../styles/theme";
import {
  getCurrentJourneyFromState,
  isCurrentlyInAJourney,
} from "../../containers/Community/stateGetterHelpers";
import loading from "../../img/load.gif";
import { SlashProtection } from "./SlashProtection";
import { SelectFirmness } from "./SelectFirmness";
import {
  cantChangeFirmnessInJourney,
  bondingRequirementForGameModeInfo,
  transactionFailed,
  bondTxFailedLowBalance,
  pendingTransaction,
  depositSuccess,
  bondingHeader,
  totalBondAfterTransaction,
  slashProtectionAdditionalInfo,
  topUpUpdateFailed,
  cantChangeToHigherFirmnessInJourney,
} from "./bondingMessages";
import { Header, Button, Input, Paragraph, Modal } from "../../Storybook";
import { Box, ButtonArrow, Space, Flex, Body } from "../Utility";
import ActiveCommunityTag from "../Community/ActiveCommunityTag";
import MochiProdContract from "../../contracts/Mochi.js";
import MochiStagingContract from "../../contracts/Mochi-NEW.js";

let MochiContract;
if (process.env.REACT_APP_VERSION === "production") MochiContract = MochiProdContract;
else MochiContract = MochiStagingContract;

export const TopUpStep = ({
  playerToTopUpData,
  gameModes,
  inJourney,
  topUpForPlayer,
  activeCommunityId,
  currentJourney,
  communityName,
  fetchJourneyDetails,
}) => {
  const slashProtectionObj = useMemo(
    () => ({
      0: { label: "None 💀", divisor: 0n, amount: "0%" },
      1: { label: "+10% Bond", divisor: 10n, amount: "10%" },
      2: { label: "+25% Bond", divisor: 4n, amount: "25%" },
      3: { label: "+50% Bond", divisor: 2n, amount: "50%" },
    }),
    []
  );
  const gameModeId = playerToTopUpData.get("game_mode");

  const currentWeiBalanceBN = useMemo(
    () => BigInt(playerToTopUpData.get("wei_balance").toString()),
    [playerToTopUpData]
  );

  const [activeGameMode, setActiveGameMode] = useState(!!gameModeId ? gameModeId : 2);
  const [slashProtectionId, setSlashProtectionId] = useState(2);
  const [slashProtectionAmount, setSlashProtectionAmount] = useState(0n);
  const [loaded, setLoaded] = useState(false);
  const [bonded, setBonded] = useState(false);
  const [error, setError] = useState("");
  const [processing, setProcessing] = useState(false);
  const journeyGameMode = currentJourney?.get("gameModeId");

  const selectFirmness = (id) => {
    const originalGameMode = gameModes.filter((m) => m.get("id") === journeyGameMode);
    const originalMinStake = originalGameMode.getIn([0, "minimum_stake"]);
    const originalModeName = originalGameMode.getIn([0, "name"]);
    const selectedMinStake = gameModes
      .filter((m) => m.get("id") === id)
      .getIn([0, "minimum_stake"]);

    const playerWasDowngraded = journeyGameMode !== gameModeId;
    const selectedMinStakeIsLargerThanOriginal = originalMinStake < selectedMinStake;

    if (inJourney && gameModeId !== id && !playerWasDowngraded)
      setError(cantChangeFirmnessInJourney);
    else if (inJourney && playerWasDowngraded && selectedMinStakeIsLargerThanOriginal)
      setError(cantChangeToHigherFirmnessInJourney(originalModeName));
    else {
      setError("");
      setActiveGameMode(id);
      setSlashProtectionAmount(
        slashProtectionObj[slashProtectionId].divisor !== 0
          ? BigInt(selectedMinStake.toString()) /
              BigInt(slashProtectionObj[slashProtectionId].divisor)
          : 0
      );
    }
  };

  const getMinimumBondRequired = useCallback(
    (id) => {
      let minimumStake = gameModes.filter((m) => m.get("id") === id).getIn([0, "minimum_stake"]);
      const amountRequired = BigInt(minimumStake?.toString() || "0");
      if (amountRequired >= currentWeiBalanceBN)
        return formatEther(amountRequired - currentWeiBalanceBN).toString();
      return "0.0";
    },
    [gameModes, currentWeiBalanceBN]
  );

  const selectSlashProtection = (id, divisor) => {
    setSlashProtectionId(id);
    setSlashProtectionAmount(divisor !== 0n ? minStakeBN / divisor : 0n);
  };

  const orderedGameModes = gameModes.sortBy((m) => m.get("id"));
  let selectedGameMode = gameModes.filter((m) => m.get("id") === activeGameMode);
  let gameModeMinStake = selectedGameMode.getIn([0, "minimum_stake"]);
  let gameModeName = selectedGameMode.getIn([0, "name"]);
  let gameModeAward = selectedGameMode.getIn([0, "award"]);
  let minStakeBN = gameModeMinStake ? BigInt(gameModeMinStake.toString()) : BigInt(0);

  const topUpAmt = getMinimumBondRequired(activeGameMode);
  const totalTopUpWeiBN = BigInt(parseEther(topUpAmt)) + BigInt(slashProtectionAmount);

  // const { data: signer } = useSigner();
  const contractAddress = useSelector((state) => {
    const activeCommId = state.community.get("activeCommunityId");
    return state.community?.getIn(["communityInfo", activeCommId, "game_contract_address"]);
  });

  const addressToTopUp = getAddress(playerToTopUpData.get("eth_address"));
  const { config } = usePrepareContractWrite({
    address: contractAddress,
    abi: MochiContract.abi,
    chainId: WEB3_NETWORK,
    // signerOrProvider: signer,
    functionName: "topUpForPlayer",
    args: [addressToTopUp],
    value: totalTopUpWeiBN,
  });
  const { writeAsync } = useContractWrite(config);

  const bondEth = async () => {
    setError("");
    setProcessing(true);
    try {
      const { hash } = await writeAsync();
      await topUpForPlayer(totalTopUpWeiBN.toString(), hash, playerToTopUpData);
      setBonded(true);
      setProcessing(false);
    } catch (e) {
      setProcessing(false);
      setError(e);
      // set the error on state, then fine tune to avoid `no conditional error handling` error on console
      if (!e || typeof e === "string") setError(topUpUpdateFailed);
      else if (!!e?.data) {
        if (e.data?.message.includes("insufficient funds")) setError(bondTxFailedLowBalance);
        else setError(e.data.message);
      } else if (!!e?.message) {
        if (e.message.includes("User denied")) setError(transactionFailed);
        else setError(e.message);
      }
    }
  };

  useEffect(() => {
    if (playerToTopUpData.get("current_journey") && !!!currentJourney?.size)
      fetchJourneyDetails(playerToTopUpData.get("current_journey"), activeCommunityId);
  }, [playerToTopUpData, currentJourney, activeCommunityId, fetchJourneyDetails]);

  // ensures that if bond page is loaded first,
  // amount and default firmness default to journey difficulty
  // slash protection amount defaults to 10% of firmness minBond
  useEffect(() => {
    if (!loaded && !!gameModeId && gameModes.size) {
      setActiveGameMode(gameModeId);
      setSlashProtectionId(2);
      setSlashProtectionAmount(
        slashProtectionObj[slashProtectionId].divisor !== 0
          ? BigInt(
              gameModes
                .filter((m) => m.get("id") === gameModeId)
                .getIn([0, "minimum_stake"])
                .toString()
            ) / BigInt(slashProtectionObj[slashProtectionId].divisor)
          : 0
      );
      setLoaded(true);
    }
  }, [gameModeId, loaded, gameModes, slashProtectionId, slashProtectionObj]);

  const topUpInEthString = formatEther(totalTopUpWeiBN);
  const totalBalanceAfterTopUp = formatEther(totalTopUpWeiBN + currentWeiBalanceBN).toString();

  if (!gameModes.size) return <div />;
  return (
    <Box>
      {error?.length > 0 && (
        <Modal onClose={() => setError("")}>
          <Header h3 mb={3} label="Something Went Wrong" />
          <Paragraph color="darkNegative500" label={error} overflow="hidden" />
        </Modal>
      )}
      {!bonded ? (
        <Box>
          {processing ? (
            <Modal>
              <img src={loading} alt="loading" width="100px" />
              <Header h3>{bondingHeader(true, topUpInEthString)}</Header>
              <Body>{totalBondAfterTransaction(topUpInEthString)}</Body>
              <Body>{pendingTransaction.beSureToAccept}</Body>
              <Body>{pendingTransaction.wait}</Body>
            </Modal>
          ) : (
            <Box>
              <Header h3></Header>
              <Box width={1} textAlign="left">
                <SelectFirmness
                  activeGameMode={activeGameMode}
                  orderedGameModes={orderedGameModes}
                  gameModeName={gameModeName}
                  gameModeAward={gameModeAward}
                  selectFirmness={(id) => selectFirmness(id)}
                  isForAnotherPlayer
                />
                <Space mb={20} />
                {activeGameMode !== 1 && (
                  <SlashProtection
                    slashProtectionId={slashProtectionId}
                    slashProtectionObj={slashProtectionObj}
                    selectSlashProtection={(id, divisor) => selectSlashProtection(id, divisor)}
                  />
                )}
                <Space mb={20} />
                <Header h3>Bond Amount</Header>
                <Body>
                  {bondingRequirementForGameModeInfo(
                    gameModeName,
                    formatEther(gameModeMinStake.toString()),
                    getMinimumBondRequired(activeGameMode)
                  )}
                </Body>
                {slashProtectionId !== 0 && activeGameMode !== 1 && (
                  <Body>
                    {slashProtectionAdditionalInfo(
                      slashProtectionObj[slashProtectionId].amount,
                      formatEther(slashProtectionAmount)
                    )}
                  </Body>
                )}
                <Space mb={10} />
                {activeGameMode !== 1 && (
                  <Fragment>
                    <Flex alignItems="baseline" flexDirection="row">
                      <Input
                        data-testid="bondAmountInput"
                        type="number"
                        placeholder={topUpInEthString}
                        value={topUpInEthString}
                        submited={processing}
                        icon="ethDarkPrimary"
                        textLabel="Amount to Bond"
                        disabled={true}
                        hideSpin
                      />
                    </Flex>
                    <Header h3>Community</Header>
                    <Body>
                      You are adding a bond to the{" "}
                      <span style={{ fontWeight: "bold" }}>{communityName}</span> community. If
                      you'd like to bond to another community, select the community from the toggle
                      in the top right.
                    </Body>
                    <ActiveCommunityTag />
                    <Space mb={30} />
                    <Header h4>Players New Bond Will Be</Header>
                    <Space mb={20} />
                    <Flex justifyContent={"flex-start"}>
                      <img src={theme.icons.eth.dark.secondary} mr={5} alt="Ethereum logo" />
                      <Paragraph fontSize="fz3" ml={1} data-testid={"newBondAmount"}>
                        {totalBalanceAfterTopUp}
                      </Paragraph>
                    </Flex>
                  </Fragment>
                )}
              </Box>
              <Space mb={20} />
              <Button primary role="bondButton" onClick={() => bondEth(activeGameMode)}>
                Bond For Player
                <ButtonArrow />
              </Button>
            </Box>
          )}
        </Box>
      ) : (
        <Box textAlign="center">
          <Body>{depositSuccess}</Body>
          <Space mb={30} />
          <Link to={`/${activeCommunityId}/profile/${playerToTopUpData.get("user_id")}`}>
            <Button primary>Return to Profile</Button>
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default connect(
  (state, props) => {
    const inJourney = isCurrentlyInAJourney(state);
    const currentJourney = getCurrentJourneyFromState(
      state,
      props.playerToTopUpData.get("user_id")
    );
    const activeCommunityId = state.community.get("activeCommunityId");

    return {
      inJourney,
      activeCommunityId,
      currentJourney,
      gameModes: state.gameModes.get("all"),
      firmImages: state.gameModes.get("imgs"),
      communityName: state.community.getIn(["communityInfo", activeCommunityId, "name"]),
    };
  },
  {
    topUpForPlayer,
    fetchJourneyDetails,
  }
)(TopUpStep);
