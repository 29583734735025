import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";

import { Box, Flex } from "../Utility";
import ProfileCard from "./ProfileCard";
import { Link as RouterLink } from "react-router-dom";
import { genNewId } from "../../utils/mochiHelpers";
import { Button } from "../../Storybook";
import { gameItemDetails } from "../Shop/itemDetails";
import {
  getPlayerInventory,
  getAllGameItems,
} from "../../containers/Shop/actions";
import InventoryTile from "../Shop/InventoryTile";

const InventoryCard = ({
  activeCommunityId,
  playerInventory,
  gameItems,
  getPlayerInventory,
  getAllGameItems,
}) => {
  useEffect(() => {
    if (!playerInventory) getPlayerInventory(activeCommunityId);
  }, [activeCommunityId, getPlayerInventory, playerInventory]);

  useEffect(() => {
    if (!gameItems?.size) getAllGameItems();
  }, [gameItems, getAllGameItems]);

  const shopItems = useMemo(() => {
    if (!gameItems) return null;
    let keyedItems = {};
    gameItems.forEach((item) => (keyedItems[item.get("item_type")] = item));
    return keyedItems;
  }, [gameItems]);

  // We want to display 4 items per row at all times
  // If there are no items in the inventory, 4 tiles will be blank
  // If there are only two inventory items, 2 tiles will be blank
  const gridItems = useMemo(() => {
    let tiles = !!playerInventory ? Object.keys(playerInventory?.toJS()) : [""];
    let rowWidth = tiles?.length || 0;
    let totalTiles = 4;
    // we want to make sure the total number of tiles we display is a multiple of 4, to display neatly as a full row
    while (rowWidth > totalTiles) totalTiles += 4;
    // when there are less tiles than a multiple of four, we fill with blank tiles
    while (totalTiles > rowWidth) {
      tiles.push("");
      rowWidth++;
    }
    return tiles;
  }, [playerInventory]);

  if (!gameItems) return null;

  return (
    <ProfileCard heading="Inventory" id="inventory">
      <Box display="grid" gridGap="1rem" gridTemplateColumns="repeat(4, 1fr)">
        {gridItems.map((item) => (
          <Box key={genNewId("inventory")} mt={3}>
            <InventoryTile
              itemInfo={gameItemDetails[item]}
              item={shopItems[item]}
            />
          </Box>
        ))}
      </Box>
      <Flex justifyContent="center">
        <RouterLink to={`/${activeCommunityId}/shop/#shop`}>
          <Button mt={2} textButton arrow label={"Check out the shop"} />
        </RouterLink>
      </Flex>
    </ProfileCard>
  );
};

export default connect(
  (state) => {
    const activeCommunityId = state.community.get("activeCommunityId");
    const authedPlayerId = state.community.get("authedUserId");
    return {
      activeCommunityId,
      playerInventory: state.shop.getIn([
        "inventory",
        activeCommunityId,
        authedPlayerId,
      ]),
      gameItems: state.shop.getIn(["gameItems", activeCommunityId]),
    };
  },
  { getPlayerInventory, getAllGameItems }
)(InventoryCard);
