import { List } from "immutable";

// using a report rate, it returns a color and matching text
export const setReportRateMotivation = (reportRate) => {
  let motivation = "";
  let motivationColor = "";
  if (reportRate >= 80) {
    motivation = "Keep it Up";
    motivationColor = "darkPositive500";
  } else if (reportRate >= 70 && reportRate < 80) {
    motivation = "Hang in there";
    motivationColor = "darkWarning500";
  } else if (reportRate < 70) {
    motivation = "Get it Together";
    motivationColor = "darkNegative500";
  }
  return { motivation, motivationColor };
};

// returns a player's journey specific report rate using a journey with player_totals and a player Id
export const getReportRate = (journey, playerId) => {
  // determine how many times the player checked in
  const checkIns = parseInt(
    journey?.getIn(["player_totals", playerId.toString(), "total_check_in"])
  );

  // determine how many times they were slashed
  const totalSlash = parseInt(
    journey?.getIn(["player_totals", playerId.toString(), "total_slash"])
  );

  // Add the checkins and slashes to determine how long the journey was, and calculate check in percentage
  return checkIns >= 0
    ? parseInt((checkIns / (checkIns + totalSlash)) * 100)
    : null;
};

// returns the report rate for a goal, taking into account all the journeys that used the goal
export const getGoalReportPercentage = (
  journeyIdsForGoal = new List(),
  journeyDataMap,
  playerId
) => {
  // filter only journeys associated with the goal that have player_totals
  // make playerId's player_totals the only values in the object
  const goalJourneysPlayerTotals = journeyIdsForGoal.map((j) =>
    journeyDataMap.getIn([j.toString(), "player_totals", playerId.toString()])
  );

  //calculate the report rate for every journey in the goal and add it to a total percentage
  const totalPercentage = goalJourneysPlayerTotals.reduce(
    (cumulativePercentage, playerTotals) => {
      return (
        cumulativePercentage +
        (playerTotals?.get("total_check_in") /
          (playerTotals?.get("total_check_in") +
            playerTotals?.get("total_slash"))) *
          100
      );
    },
    0
  );
  return Math.floor(totalPercentage / goalJourneysPlayerTotals.size);
};

// Using a journey with a player_totals object ( not a detailed journey ), it returns the reporting average for a specific team in a specific journey
export const getTeamReportAverageForJourney = (journey = new Map()) => {
  // extract the list of player totals
  const players = journey.get("player_totals")?.keySeq() || new List();

  // get the cumulative report rate for all the players in the journey
  const cumulativeReportRate =
    players?.reduce((reportRateSum, m) => {
      // map through each player and calculating the report rate for the journey
      return (reportRateSum += getReportRate(journey, Number(m)));
    }, 0) || 0;

  // devide the cummulative report rate by the number of players
  if (!players.size) return 0;
  return cumulativeReportRate / players.size;
};

// an average report rate of all the teammates in a list of journeys
export const getReportRateAverageForJourneyList = (journeys) => {
  let journeySize = journeys.size;
  if (!journeySize) return 0;
  const reportRateForATeamsJourneys = journeys.reduce(
    (journeysReportSum, journey) => {
      if (!!journey && !!journey.get("player_totals")?.toSeq()?.size) {
        const teamReportRateForJourney = getTeamReportAverageForJourney(
          journey
        );
        return (journeysReportSum += teamReportRateForJourney);
      } else {
        journeySize--;
        return journeysReportSum;
      }
    },
    0
  );
  return reportRateForATeamsJourneys > 0
    ? reportRateForATeamsJourneys / journeySize
    : 0;
};

export const getLastReportPercentageForTeam = (
  currentJourneyDetails,
  teamMembers
) => {
  if (!currentJourneyDetails || !teamMembers.size) return 0;
  return (
    teamMembers.reduce((cummulativeReportRate, m) => {
      const playerLastReportPercent = !!currentJourneyDetails
        .getIn([m, "simpleJourney"])
        ?.last()
        ?.get("is_on_time")
        ? 100
        : 0;
      return (cummulativeReportRate += playerLastReportPercent);
    }, 0) / teamMembers.size
  );
};
