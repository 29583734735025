import React, { useState, Fragment } from "react";
import { connect } from "react-redux";

import { Modal, Space } from "../Utility";
import { Button, Paragraph } from "../../Storybook";
import { removeTeamMembership } from "../../containers/Community/actions";
import {
  getPlayerAndTeamDataFromState,
  hasUpcomingJourney,
} from "../../containers/Community/stateGetterHelpers";
import {
  infoJourneyNotStartedMember,
  infoNoLeaveTeamAsLeader,
} from "../Team/Journey/QuitModalStrings";

export const LeaveTeamButton = ({
  amLeader,
  removeTeamMembership,
  hasAJourneyUpcoming,
}) => {
  const [infoModal, setInfoModal] = useState("");

  const removeTeammate = async (e) => {
    e.preventDefault();
    if (amLeader) setInfoModal(infoNoLeaveTeamAsLeader);
    else if (hasAJourneyUpcoming) setInfoModal(infoJourneyNotStartedMember);
    else {
      try {
        setInfoModal("Your team has been updated successfully.");
        await removeTeamMembership();
      } catch (e) {
        setInfoModal(e);
      }
    }
  };

  return (
    <Fragment>
      <Button
        secondary
        width={[1, 1, 1]}
        mb={2}
        bgColor="darkNegative500"
        onClick={(e) => removeTeammate(e)}
      >
        Leave Team
      </Button>
      {infoModal.length > 0 && (
        <Modal onClose={() => setInfoModal("")}>
          <Space mb={20} />
          <Paragraph>{infoModal}</Paragraph>
        </Modal>
      )}
    </Fragment>
  );
};

export default connect(
  (state, props) => {
    const { playerData, teamData } = getPlayerAndTeamDataFromState(state);
    return {
      amLeader: teamData
        ? teamData.get("leader") === playerData.get("user_id")
        : false,
      hasAJourneyUpcoming: hasUpcomingJourney(state, playerData.get("user_id")),
    };
  },
  { removeTeamMembership }
)(LeaveTeamButton);
