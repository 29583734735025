import React, { useMemo } from "react";
import { connect } from "react-redux";
import { fromJS } from "immutable";

import { GridContainer } from "../Utility";
import { GridBox, Header } from "../../Storybook";
import { getPlayerAndTeamDataFromState } from "../../containers/Community/stateGetterHelpers";
import GoalOverview from "./GoalOverview";
import { genNewId } from "../../utils/mochiHelpers";

const AllGoals = ({ playerData, activeCommunityId }) => {
  const colors = ["darkMagenta500", "darkCyan500", "darkOrange500"];

  // Created a map of the player's journeys indexed by ID. This map reduces the look up time for two helpers used in GoalOverview component: one to determine when a goal was last used, another to calculate the report rate for all journeys in the goal
  const journeyDataMap = useMemo(() => {
    let journeyDataObj = {};
    if (!!playerData?.get("journeyData")) {
      playerData
        .get("journeyData")
        .map((j) => (journeyDataObj[j.get("id")] = j));
    }
    return fromJS(journeyDataObj);
  }, [playerData]);

  return (
    <GridContainer
      fixedWidth={false}
      style={{ gridGap: "20px" }}
      alignItems="top center"
      ignoreHeight
    >
      <GridBox>
        <Header mb={3} fontSize="42px">
          All Goals
        </Header>
      </GridBox>
      {playerData
        .get("goals")
        .reverse()
        .map((goal, i) => {
          return (
            <GridBox
              span={[4, 4, 6]}
              key={genNewId(`goalbox-${goal.get("id")}`)}
            >
              <GoalOverview
                key={genNewId(`goal-${goal.get("id")}`)}
                goal={goal}
                color={colors[i % 3]}
                profileUserId={playerData.get("user_id")}
                journeyDataMap={journeyDataMap}
                goalJourneyLink={`/${activeCommunityId}/goal/${goal.get(
                  "id"
                )}/${playerData.get("user_id")}?color=${colors[i % 3]}`}
              />
            </GridBox>
          );
        })}
      {/* TODO: get this working */}
      {/* <GridBox span={[4, 4, 6]} key="goal-button">
          <Button width="100%">Create a new goal →</Button>
        </GridBox> */}
    </GridContainer>
  );
};

export default connect((state, props) => {
  const profileUserId = Number(props.match.params.id);
  const activeCommunityId = state.community.get("activeCommunityId");
  const { playerData } = getPlayerAndTeamDataFromState(
    state,
    profileUserId || null
  );
  return {
    activeCommunityId,
    playerData,
  };
})(AllGoals);
