import React from "react";

import TopUp from "../components/Bonding/TopUp";
import AuthorizedHOC from "../components/Utility/AuthorizedHOC";
import { GridContainer, Space } from "../components/Utility";

export const TopUpForPlayerPage = () => {
  return (
    <AuthorizedHOC>
      <GridContainer alignItems="flex-start" alignContent="flex-start">
        <TopUp forAnotherPlayer />
      </GridContainer>
      <Space mb={40} />
    </AuthorizedHOC>
  );
};

export default TopUpForPlayerPage;
