import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { useAccount, useDisconnect } from "wagmi";

import { GridContainer, Box, Flex } from "../Utility";
import { logOutUser } from "../../containers/Auth/actions";
import { toggleCommunity, fetchDiscordCommunityIcon } from "../../containers/Community/actions";
import CommunityToggle from "../Community/CommunityToggle";
import { GridBox } from "../../Storybook";
import logo from "../../img/logo/mochi/logoFill.svg";
import NavItem from "./NavItem";
import Web3Auth from "../Web3/Web3Auth";
import theme from "../../styles/theme";
import burger from "../../img/svg/icons/burger.svg";
import { getPlayerAndTeamDataFromState } from "../../containers/Community/stateGetterHelpers";
import NewProfileModals from "../Profile/NewProfileModals";
import { getDisplayName } from "../../utils/mochiHelpers";
import Web3AuthHandler from "../Web3/Web3AuthHandler";

export const NavBar = ({
  authenticated,
  logOutUser,
  authedUserId,
  communityData,
  toggleCommunity,
  fetchDiscordCommunityIcon,
  activeCommunityId,
  displayName,
}) => {
  let history = useHistory();
  const { isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const [showMenu, setShowMenu] = useState(false);
  const [communityIconsFetched, setCommunityIconsFetched] = useState({});
  const profileLink = `/${activeCommunityId}/profile/${authedUserId}`;

  const selectCommunity = (communityId) => {
    toggleCommunity(communityId);
    history.push(`/${communityId}/profile/${authedUserId}`);
  };

  useEffect(() => {
    if (authenticated) {
      communityData?.forEach((community) => {
        if (
          community.get("community_type") === "D" &&
          !community.get("icon") &&
          !communityIconsFetched[community.get("id")]
        ) {
          const icons = Object.assign(communityIconsFetched);
          icons[community.get("id")] = true;
          setCommunityIconsFetched(icons);
          fetchDiscordCommunityIcon(community.get("id"));
        }
      });
    }
  }, [authenticated, communityIconsFetched, communityData, fetchDiscordCommunityIcon]);

  const logUserOut = () => {
    disconnect();
    logOutUser();
    history.push("/");
  };

  const backgroundGradient =
    "linear-gradient(180deg, rgba(0,0,0,1) 0%,rgba(0,0,0,0.1)80%,rgba(0,0,0,0) 100%)";

  const mobileMenuWrapperDisplay = showMenu ? "flex" : "none";
  const mobileWrapperBackgroundColor = showMenu ? theme.color.darkBGTertiary : backgroundGradient;
  const mobileWrapperPadding = showMenu ? "0 0 8px 8px" : "0";
  const mobileWrapperGridBoxSpan = showMenu ? 4 : 2;
  const mobileWrapperJustify = showMenu ? "space-between" : "flex-start";
  const mobileWrapperMarginBottom = showMenu ? 3 : 0;

  const menuContentMobileGridBoxStart = showMenu ? 1 : 3;
  const menuContentMobileJustify = showMenu ? "flex-start" : "flex-end";

  const unauthMobileGridBoxSpan = showMenu ? 3 : 2;
  const unAuthMobileDisplay = showMenu ? "block" : "none";
  const unAuthMobileBurgerDisplay = !showMenu ? "block" : "none";

  const authMobileGridBoxSpan = showMenu ? 4 : 2;

  return (
    <>
      <GridContainer
        style={{
          position: "fixed",
          top: 0,
          zIndex: 3,
        }}
        background={[mobileWrapperBackgroundColor, backgroundGradient]}
        borderRadius={[mobileWrapperPadding, "0"]}
        ignoreHeight
        pt={[2]}
        mb={0}
        alignItems={["flex-start"]}
        alignContent={["flex-start"]}
        height={[showMenu ? "100%" : "auto", "auto"]}
      >
        <NewProfileModals />
        <GridBox span={[mobileWrapperGridBoxSpan, 5, 6]}>
          <Flex
            flexDirection={["column", "row"]}
            alignItems={["flex-start", "center"]}
            alignContent={"flex-start"}
            justifyContent={"flex-start"}
          >
            <Flex
              justifyContent={[mobileWrapperJustify, "space-between"]}
              width={["100%", "120px"]}
              alignItems="flex-start"
            >
              <Flex
                mb={[mobileWrapperMarginBottom, 0]}
                mr={[0, 2]}
                alignItems={["space-between", "center"]}
              >
                <img
                  src={logo}
                  alt="Mochi filled logo - Expand menu"
                  width={["140px", "120px"]}
                  onClick={() => setShowMenu(!showMenu)}
                />
              </Flex>
              {showMenu && (
                <Box pt={1} pr={2} mb={[3, 0]} display={["auto", "none"]}>
                  <img
                    src={theme.icons.cross.large}
                    alt="Collapse Menu"
                    onClick={() => setShowMenu(!showMenu)}
                  />
                </Box>
              )}
            </Flex>
            <Flex
              ml={[-3, 30, 50]}
              flexDirection={["column", "row"]}
              width="100%"
              display={[mobileMenuWrapperDisplay, "flex"]}
            >
              <NavItem itemPath={"/"} label={"Home"} />
              <NavItem itemPath={"/the-game"} label={"The Game"} />
              <NavItem itemPath={"/the-story"} label={"The Story"} />
            </Flex>
          </Flex>
        </GridBox>
        {authenticated && isConnected ? (
          <GridBox
            span={[authMobileGridBoxSpan, 3]}
            start={[menuContentMobileGridBoxStart, 6, 10]}
            justifyContent={[menuContentMobileJustify, "flex-end"]}
          >
            <Flex justifyContent={[menuContentMobileJustify, "flex-end"]}>
              <CommunityToggle
                menuElements={communityData}
                currentCommunityId={activeCommunityId}
                toggleFunction={selectCommunity}
                logOutAction={logUserOut}
                sendToProfile={() => history.push(profileLink)}
                viewMenu={showMenu}
                setViewMenu={() => setShowMenu(!showMenu)}
                displayName={displayName}
              />
            </Flex>
          </GridBox>
        ) : (
          <GridBox
            span={[unauthMobileGridBoxSpan, 3]}
            start={[menuContentMobileGridBoxStart, 6, 10]}
            alignContent="center"
          >
            <Flex
              justifyContent={[menuContentMobileJustify, "flex-end"]}
              flexWrap={["wrap", "no-wrap"]}
            >
              <Box mt={[3, 0]} display={[unAuthMobileDisplay, "block"]}>
                <Web3Auth prompt="Connect Wallet" />
              </Box>
              <Box
                pt={2}
                pr={2}
                onClick={() => setShowMenu(!showMenu)}
                display={[unAuthMobileBurgerDisplay, "none"]}
              >
                <img src={burger} width="27px" alt="menu" />
              </Box>
            </Flex>
          </GridBox>
        )}
      </GridContainer>
      <GridContainer ignoreHeight height="100px" />
      <Web3AuthHandler />
    </>
  );
};
export default connect(
  (state) => {
    const communityData = state.community.get("communityInfo");
    const authedUserId = state.auth.get("authedUserId");
    const { playerData } = getPlayerAndTeamDataFromState(state);
    const displayName = getDisplayName(playerData);
    return {
      activeCommunityId: state.community.get("activeCommunityId"),
      authenticated: state.auth.get("auth"),
      displayName,
      communityData,
      authedUserId,
    };
  },
  {
    toggleCommunity,
    logOutUser,
    fetchDiscordCommunityIcon,
  }
)(NavBar);
