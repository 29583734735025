/* eslint-disable no-undef */
import React, { useState, useEffect, Fragment, useCallback, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useAccount, useSendTransaction, usePrepareSendTransaction } from "wagmi";
import { formatEther, parseEther } from "viem";

import { fetchJourneyDetails, topUp } from "../../containers/Community/actions";
import theme from "../../styles/theme";
import {
  getCurrentJourneyFromState,
  isCurrentlyInAJourney,
  withinGracePeriod,
} from "../../containers/Community/stateGetterHelpers";
import loading from "../../img/load.gif";
import { Terms } from "./Terms";
import { SlashProtection } from "./SlashProtection";
import { SelectFirmness } from "./SelectFirmness";
import {
  cantChangeFirmnessInJourney,
  bondingRequirementForGameModeInfo,
  transactionFailed,
  bondTxFailedLowBalance,
  pendingTransaction,
  depositSuccess,
  bondingHeader,
  totalBondAfterTransaction,
  firmnessInfo,
  bondAmountInfo,
  slashProtectionAdditionalInfo,
  topUpUpdateFailed,
  cantChangeToHigherFirmnessInJourney,
} from "./bondingMessages";
import { Header, Button, Input, Paragraph, Modal } from "../../Storybook";
import { Box, ButtonArrow, Space, Flex, Body, InlineAnchor } from "../Utility";
import ActiveCommunityTag from "../Community/ActiveCommunityTag";
import { POMELO_MULTISIG_ADDRESS } from "../../constants/endpoints";

export const TopUpStep = ({
  playerData,
  gameModes,
  inJourney,
  inGracePeriod,
  topUp,
  activeCommunityId,
  currentJourney,
  communityName,
  fetchJourneyDetails,
}) => {
  const slashProtectionObj = useMemo(
    () => ({
      0: { label: "None 💀", divisor: 0n, amount: "0%" },
      1: { label: "+10% Bond", divisor: 10n, amount: "10%" },
      2: { label: "+25% Bond", divisor: 4n, amount: "25%" },
      3: { label: "+50% Bond", divisor: 2n, amount: "50%" },
    }),
    []
  );
  const { address } = useAccount();
  const gameModeId = playerData.get("game_mode");
  const currentWeiBalanceBN = useMemo(
    () => BigInt(playerData.get("wei_balance").toString()),
    [playerData]
  );

  const [agreedToTerms, setAgreedToTerms] = useState(!!gameModeId);
  const [activeGameMode, setActiveGameMode] = useState(!!gameModeId ? gameModeId : 2);
  const [slashProtectionId, setSlashProtectionId] = useState(2);
  const [slashProtectionAmount, setSlashProtectionAmount] = useState(0n);
  const [loaded, setLoaded] = useState(false);
  const [bonded, setBonded] = useState(false);
  const [errorModal, setErrorModal] = useState("");
  const [processing, setProcessing] = useState(false);
  const journeyGameMode = currentJourney?.get("gameModeId");

  const selectFirmness = (id) => {
    const originalGameMode = gameModes.filter((m) => m.get("id") === journeyGameMode);
    const originalMinStake = originalGameMode.getIn([0, "minimum_stake"]);
    const originalModeName = originalGameMode.getIn([0, "name"]);
    const selectedMinStake = gameModes
      .filter((m) => m.get("id") === id)
      .getIn([0, "minimum_stake"]);

    const playerWasDowngraded = journeyGameMode !== gameModeId;
    const selectedMinStakeIsLargerThanOriginal = originalMinStake < selectedMinStake;

    if (inJourney && !inGracePeriod) {
      if (gameModeId !== id && !playerWasDowngraded)
        return setErrorModal(cantChangeFirmnessInJourney);
      else if (playerWasDowngraded && selectedMinStakeIsLargerThanOriginal)
        return setErrorModal(cantChangeToHigherFirmnessInJourney(originalModeName));
    }
    setErrorModal("");
    setActiveGameMode(id);
    setSlashProtectionAmount(
      slashProtectionObj[slashProtectionId].divisor !== 0
        ? BigInt(selectedMinStake.toString()) /
            BigInt(slashProtectionObj[slashProtectionId].divisor)
        : 0
    );
  };

  const getMinimumBondRequired = useCallback(
    (id) => {
      let minimumStake = gameModes.filter((m) => m.get("id") === id).getIn([0, "minimum_stake"]);
      const amountRequired = BigInt(minimumStake?.toString() || "0");
      if (amountRequired >= currentWeiBalanceBN)
        return formatEther(amountRequired - currentWeiBalanceBN).toString();
      return "0.0";
    },
    [gameModes, currentWeiBalanceBN]
  );

  const selectSlashProtection = (id, divisor) => {
    setSlashProtectionId(id);
    setSlashProtectionAmount(divisor !== 0n ? minStakeBN / divisor : 0n);
  };

  const orderedGameModes = gameModes.sortBy((m) => m.get("id"));
  let selectedGameMode = gameModes.filter((m) => m.get("id") === activeGameMode);
  let gameModeMinStake = selectedGameMode.getIn([0, "minimum_stake"]);
  let gameModeName = selectedGameMode.getIn([0, "name"]);
  let gameModeAward = selectedGameMode.getIn([0, "award"]);
  let minStakeBN = gameModeMinStake ? BigInt(gameModeMinStake.toString()) : BigInt(0);

  const topUpAmt = getMinimumBondRequired(activeGameMode);
  const totalTopUpWeiBN = BigInt(parseEther(topUpAmt) + slashProtectionAmount);
  let contractAddress = useSelector((state) => {
    const activeCommId = state.community.get("activeCommunityId");
    return state.community?.getIn(["communityInfo", activeCommId, "game_contract_address"]);
  });

  contractAddress = contractAddress ? contractAddress : POMELO_MULTISIG_ADDRESS;
  const { config, error } = usePrepareSendTransaction({
    to: contractAddress,
    value: totalTopUpWeiBN,
  });
  const { sendTransactionAsync } = useSendTransaction(config);

  // cleanup animation if bonding was successful
  useEffect(() => () => (document.body.className = ""), []);

  const bondEth = async () => {
    setErrorModal("");
    setProcessing(true);
    try {
      if (totalTopUpWeiBN === 0n) {
        await topUp("0", "0x", address, activeGameMode);
        setBonded(true);
        document.body.className += "bg-confetti-animated";
      } else if (sendTransactionAsync) {
        const { hash } = await sendTransactionAsync();
        await topUp(totalTopUpWeiBN.toString(), hash, address, activeGameMode);
        setBonded(true);
        document.body.className += "bg-confetti-animated";
      } else if (error) {
        if (!error || typeof error === "string") setErrorModal(topUpUpdateFailed);
        else if (!!error?.data) {
          if (error.data.message?.includes("insufficient funds"))
            setErrorModal(bondTxFailedLowBalance);
          else setErrorModal(error.data.message);
        }
      } else {
        setErrorModal(error.data.message);
      }
      setProcessing(false);
    } catch (e) {
      setProcessing(false);
      setErrorModal(e);
      // set the errorModal on state, then fine tune to avoid `no conditional errorModal handling` errorModal on console
      if (!e || typeof e === "string") setErrorModal(topUpUpdateFailed);
      else if (!!e?.data) {
        if (e.data.message?.includes("insufficient funds")) setErrorModal(bondTxFailedLowBalance);
        else setErrorModal(e.data.message);
      } else if (!!e?.message) {
        if (e.message.includes("User denied")) setErrorModal(transactionFailed);
        else setErrorModal(e.message);
      }
    }
  };

  useEffect(() => {
    if (playerData.get("current_journey") && !!!currentJourney?.size)
      fetchJourneyDetails(playerData.get("current_journey"), activeCommunityId);
  }, [playerData, currentJourney, activeCommunityId, fetchJourneyDetails]);

  // ensures that if bond page is loaded first,
  // amount and default firmness default to journey difficulty
  // slash protection amount defaults to 10% of firmness minBond
  useEffect(() => {
    if (!loaded && !!gameModeId && gameModes.size) {
      setActiveGameMode(gameModeId);
      setSlashProtectionId(2);
      setSlashProtectionAmount(
        slashProtectionObj[slashProtectionId].divisor !== 0
          ? BigInt(
              gameModes
                .filter((m) => m.get("id") === gameModeId)
                .getIn([0, "minimum_stake"])
                .toString()
            ) / BigInt(slashProtectionObj[slashProtectionId].divisor)
          : 0
      );
      setLoaded(true);
    }
  }, [gameModeId, loaded, gameModes, slashProtectionId, slashProtectionObj]);

  const topUpInEthString = formatEther(totalTopUpWeiBN);
  const totalBalanceAfterTopUp = formatEther(totalTopUpWeiBN + currentWeiBalanceBN).toString();

  if (!gameModes.size) return <div />;
  return (
    <Box>
      {!gameModeId && !agreedToTerms && (
        <Box textAlign="left">
          <Body>
            There are two aspects of bonding: Firmness and Bond amount. You can read more about them
            on{" "}
            <InlineAnchor onClick={() => window.open("/the-game", "_blank")}>The Game</InlineAnchor>{" "}
            page.
          </Body>
          <Body>{firmnessInfo}</Body>
          <Body>{bondAmountInfo}</Body>
          <Terms updateUserAgreementStatus={(agreed) => setAgreedToTerms(agreed)} />
        </Box>
      )}
      <>
        {errorModal?.length > 0 && (
          <Modal onClose={() => setErrorModal("")}>
            <Header h3 mb={3} label="Something Went Wrong" />
            <Paragraph color="darkNegative500" label={errorModal} overflow="hidden" />
          </Modal>
        )}
        {!bonded ? (
          <Box>
            {processing ? (
              <Modal noClose>
                <img src={loading} alt="loading" width="100px" />
                <Header h3>{bondingHeader(true, topUpInEthString)}</Header>
                <Body>{totalBondAfterTransaction(totalBalanceAfterTopUp)}</Body>
                <Body>{pendingTransaction.beSureToAccept}</Body>
                <Body>{pendingTransaction.wait}</Body>
              </Modal>
            ) : (
              <Box>
                <Box>
                  {agreedToTerms && (
                    <Box width={1} textAlign="left">
                      <SelectFirmness
                        activeGameMode={activeGameMode}
                        orderedGameModes={orderedGameModes}
                        gameModeName={gameModeName}
                        gameModeAward={gameModeAward}
                        selectFirmness={(id) => selectFirmness(id)}
                      />
                      <Space mb={20} />
                      {activeGameMode !== 1 && (
                        <SlashProtection
                          slashProtectionId={slashProtectionId}
                          slashProtectionObj={slashProtectionObj}
                          selectSlashProtection={(id, divisor) =>
                            selectSlashProtection(id, divisor)
                          }
                        />
                      )}
                      <Space mb={20} />
                      <Header h3>Bond Amount</Header>
                      <Body>
                        {bondingRequirementForGameModeInfo(
                          gameModeName,
                          formatEther(gameModeMinStake.toString()),
                          getMinimumBondRequired(activeGameMode)
                        )}
                      </Body>
                      {slashProtectionId !== 0 && activeGameMode !== 1 && (
                        <Body>
                          {slashProtectionAdditionalInfo(
                            slashProtectionObj[slashProtectionId].amount,
                            formatEther(slashProtectionAmount)
                          )}
                        </Body>
                      )}
                      <Space mb={10} />
                      {activeGameMode !== 1 && (
                        <Fragment>
                          <Flex alignItems="baseline" flexDirection="row">
                            <Input
                              data-testid="bondAmountInput"
                              type="number"
                              placeholder={topUpInEthString}
                              value={topUpInEthString}
                              submited={processing}
                              icon="ethDarkPrimary"
                              textLabel="Amount to Bond"
                              disabled={true}
                              hideSpin
                            />
                          </Flex>
                          <Header h3>Community</Header>
                          <Body>
                            You are adding a bond to the{" "}
                            <span style={{ fontWeight: "bold" }}>{communityName}</span> community.
                            If you'd like to bond to another community, select the community from
                            the toggle in the top right.
                          </Body>
                          <ActiveCommunityTag />
                          <Space mb={30} />
                          <Header h4>Your New Bond Will Be</Header>
                          <Space mb={20} />
                          <Flex justifyContent={"flex-start"}>
                            <img src={theme.icons.eth.dark.secondary} mr={5} alt="Ethereum logo" />
                            <Paragraph fontSize="fz3" ml={1} data-testid={"newBondAmount"}>
                              {totalBalanceAfterTopUp}
                            </Paragraph>
                          </Flex>
                        </Fragment>
                      )}
                    </Box>
                  )}
                </Box>
                <Space mb={20} />
                <Button
                  primary
                  role="bondButton"
                  disabled={!(activeGameMode && agreedToTerms)}
                  onClick={() => bondEth(activeGameMode)}
                >
                  {agreedToTerms &&
                  getMinimumBondRequired(activeGameMode) <= 0 &&
                  totalTopUpWeiBN === 0n
                    ? `Set Firmness to ${gameModeName}`
                    : "Bond Mochi"}
                  <ButtonArrow />
                </Button>
              </Box>
            )}
          </Box>
        ) : (
          <Box textAlign="center">
            <Body>{depositSuccess}</Body>
            <Space mb={30} />
            <Link to={`/${activeCommunityId}/profile/${playerData.get("user_id")}`}>
              <Button primary>Return to Profile</Button>
            </Link>
          </Box>
        )}
      </>
    </Box>
  );
};

export default connect(
  (state) => {
    const inJourney = isCurrentlyInAJourney(state);
    const inGracePeriod = withinGracePeriod(state);
    const currentJourney = getCurrentJourneyFromState(state);
    const activeCommunityId = state.community.get("activeCommunityId");
    return {
      inJourney,
      inGracePeriod,
      activeCommunityId,
      currentJourney,
      gameModes: state.gameModes.get("all"),
      firmImages: state.gameModes.get("imgs"),
      communityName: state.community.getIn(["communityInfo", activeCommunityId, "name"]),
    };
  },
  {
    topUp,
    fetchJourneyDetails,
  }
)(TopUpStep);
