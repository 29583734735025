import styled, { css } from "styled-components";
import { space } from "styled-system";

const Input = styled.input.attrs((props) => ({
  "aria-labelledby": props.ariaLabelledby,
}))`
  box-sizing: border-box;
  webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 36px;
  border-radius: 4px;
  outline: 0;
  padding: 4px 8px;
  margin-bottom: 4px;
  background: transparent;
  placeholder: ${(props) => props.placeholder}
  font-family: ${(props) => props.theme.fonts.body}
  font-size: ${(props) => props.theme.fontSize.fz3}
  font-style: normal;
  font-weight: 400;
  line-heght: 27.5px;
  border: 2px solid ${(props) =>
    !props.error
      ? props.theme.color.dark.fg.primary
      : props.theme.color.darkNegative500};
  color: ${(props) => props.theme.color.dark.fg.secondary};
  value: ${(props) => props.value || props.placeholder}
Î
  &:autofill,
  &:autofill:hover,
  &:autofill:focus,
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-internal-autofill,
  &:-internal-autofill:hover,
  &:-internal-autofill:focus {
    background-color: ${(props) => props.theme.color.darkBGPrimary} !important;
  }

  &:focus {
    outline: 0;
    border-color: ${(props) => props.theme.color.dark.fg.tertiary};
    color: ${(props) => props.theme.color.dark.fg.primary};
    caret-color: ${(props) => props.theme.color.darkCyan500};
  }

  // colored parent component styling
  ${(props) =>
    props.colored &&
    css`
      border: 2px solid ${(props) => props.theme.color.colored.fg.primary};
      color: ${(props) => props.theme.color.colored.fg.primary};

      &::placeholder {
        color: ${(props) => props.theme.color.colored.fg.secondary};
      }

      &:focus {
        border-color: ${(props) => props.theme.color.colored.fg.tertiary};
        color: ${(props) => props.theme.color.colored.fg.primary};
      }
    `}

  // error styling
  ${(props) =>
    props.error &&
    !props.colored &&
    css`
      border: 2px solid ${(props) => props.theme.color.darkNegative500};
    `};

  // error styling
  ${(props) =>
    props.overrideError &&
    !props.colored &&
    css`
      border: 2px solid ${(props) => props.theme.color.darkNegative500};
    `};

  // success styling
  ${(props) =>
    props.success &&
    !props.overrideError &&
    css`
      border: 2px solid ${(props) => props.theme.color.darkPositive500};
      background: transparent;
      background-color: transparent;
    `};


  ${(props) =>
    props.icon &&
    css`
      background-image: url(${(props) => props.icon});
      background-position: 6px;
      background-repeat: no-repeat;
      background-size: 24px 24px;
      padding-left: 36px;
    `};

  // password input styling
  ${(props) =>
    props.password &&
    css`
      background-position: 10px 8px;
      background-repeat: no-repeat;
      padding-left: 36px;
    `};

  ${(props) =>
    props.password &&
    props.type === "password" &&
    css`
      background-image: url(${(props) =>
        props.colored
          ? props.theme.icons.password.colored.show
          : props.theme.icons.password.dark.show});
    `};
  ${(props) =>
    props.password &&
    props.type === "text" &&
    css`
      background-image: url(${(props) =>
        props.colored
          ? props.theme.icons.password.colored.hide
          : props.theme.icons.password.dark.hide});
    `};

  ${(props) =>
    props.type === "date" &&
    css`
      ::-webkit-calendar-picker-indicator {
        position: absolute;
        opacity: 0;
        left: -10px;
      }
    `};

    ${(props) =>
      props.type === "number" &&
      props.hideSpin &&
      css`
        -moz-appearance: textfield;

        ::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
          // display: none;
          -webkit-appearance: none;
          margin: 0;
        }
      `};

  ${(props) =>
    (props.type === "checkbox" || props.type === "radio") &&
    css`
    opacity: 0;
    width: 0;

    & + label {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction : ${(props) => (props.bottomLabel ? "column" : "row")};
    }

    & + label:before {
      content: "";
      border-radius: 4px;
      background-color: transparent;
      border: 3px solid ${(props) => props.theme.color.dark.fg.primary};
      width: ${(props) => (props.small ? "20px" : "42px")}
      height: ${(props) => (props.small ? "20px" : "42px")}
      margin: ${(props) => (props.bottomLabel ? "0 0 4px 0" : "0 8px 0 0")};

      ${(props) =>
        props.colored &&
        css`
          border: 3px solid ${(props) => props.theme.color.dark.bg.primary};
        `};
    }

    &:disabled + label,
    &:disabled + label:before,
    &[disabled] + label,
    &[disabled] + label:before {
      opacity: 0.4;
      cursor: not-allowed;
    }

    & + label:before {
      background-position: center center;
      background-repeat: no-repeat;
    }

    &:checked {
      & + label:before {
        background-image: url(${(props) =>
          props.theme.icons.check[props.small ? "small" : "large"]});
        background-color: ${(props) => props.theme.color.darkPositive500};
        border-color: ${(props) => props.theme.color.darkPositive500};
      }
    }
  `};
  ${space};
`;

export default Input;
