import React from "react";
import { connect } from "react-redux";
import { loadStripe } from "@stripe/stripe-js/pure";
import axios from "axios";

import { Flex, Box, Space } from "../Utility";
import { Paragraph, Button } from "../../Storybook";
import { API_ENDPOINT, STRIPE_PUBLIC_KEY, QUARTERLY_PRICE_ID } from "../../constants/endpoints";
import moment from "moment";

export const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const QuarterlyCheckout = ({ activeCommunityId }) => {
  const handleClick = async (event) => {
    // Get Stripe.js instance
    const stripe = await stripePromise;
    const stripeSessionData = await axios.post(`${API_ENDPOINT}/payments/session/`, {
      price_id: QUARTERLY_PRICE_ID,
      community_id: activeCommunityId,
    });
    const sessionId = stripeSessionData.data["session_id"];
    await stripe.redirectToCheckout({
      sessionId,
    });
  };

  return (
    <Flex flexDirection="column" alignItems="center">
      <Flex flexDirection="column" margin="30px">
        <Paragraph fontSize="fz2" textAlign="center">
          Mochi Quarterly Membership
        </Paragraph>
        <Space mb={10} />
        <Paragraph textAlign="center">$30 paid today</Paragraph>
        <Space mb={10} />
        <Button primary arrow label="Join Mochi" onClick={handleClick} />
      </Flex>
      <Box textAlign="center" width="50%">
        <Paragraph mb={30} fontStyle="italic" small color="gray">
          You will be charged $30 today & again on {moment().add(3, "months").calendar()}. Your
          membership will renew automatically, & you will receive a confirmation email a week before
          you are charged again.
        </Paragraph>
      </Box>
    </Flex>
  );
};

export default connect((state) => ({
  activeCommunityId: state.community.get("activeCommunityId"),
}))(QuarterlyCheckout);
