import React, { useEffect, useState } from "react";
import { Tab, TabList, Header, Flex } from "./index";
import { Link as RouterLink, Route, useLocation } from "react-router-dom";
import { GridBox } from "../../Storybook";

const TabController = ({ tabs, tabControllerJustifyContent, gridSpan, gridStart, routeInline }) => {
  const location = useLocation();
  // This component takes history and an array of tabs:
  // Each tab is an object with properties: {name: string, url: string, selected: boolean, and component: component object}

  const [selectedTab, setSelectedTab] = useState(0);
  const [previousTab, setPreviousTab] = useState(1);

  useEffect(() => {
    if (location.pathname !== tabs[selectedTab].url) {
      setPreviousTab(selectedTab);
      tabs.forEach((tab, ind) => {
        if (tab.url === location.pathname) setSelectedTab(ind);
      });
    }
  }, [location.pathname, tabs, selectedTab]);

  useEffect(() => {
    if (tabs.length > 1) {
      tabs[previousTab].selected = false;
      tabs[selectedTab].selected = true;
    }
  });

  const currentTab = tabs[selectedTab];

  return (
    <>
      <GridBox span={gridSpan} start={gridStart}>
        <Flex justifyContent={tabControllerJustifyContent}>
          <TabList views>
            {tabs.map((tab, index) => (
              <Tab key={tab.name} selected={selectedTab === index}>
                <RouterLink
                  to={tab.url}
                  onClick={() => {
                    setPreviousTab(selectedTab);
                    setSelectedTab(index);
                  }}
                >
                  <Header h5 selected={selectedTab === index} style={{ textDecoration: "none" }}>
                    {tab.name}
                  </Header>
                </RouterLink>
              </Tab>
            ))}
          </TabList>
        </Flex>
        {routeInline && (
          <Route
            exact
            path={currentTab.path}
            url={currentTab.url}
            component={currentTab.component}
          />
        )}
      </GridBox>
      {!routeInline && (
        <Route exact path={currentTab.path} url={currentTab.url} component={currentTab.component} />
      )}
    </>
  );
};

export default TabController;
