import React from "react";

import { GridContainer, Header, Body } from "../components/Utility";
import { GridBox } from "../Storybook";

export const StoryPage = (props) => {
  return (
    <GridContainer>
      <GridBox span={[4, 6, 6, 6]} start={[1, 2, 4, 4]} mb={4}>
        <Header h1 textAlign="center" mt={4} mb={4}>
          The Story
        </Header>

        <Body mt="0">
          Do you ever feel small? I know I do. Here and there. Now and again.
          Once in a blue and gibbous moon.
        </Body>
        <Body mt="0">
          I don't know why I feel this way. I'm a Mochi after all. Everything I
          am fits inside of a chewy and irresistibly delicious little treat. Of
          all the snacks in the saccharine universe, I wouldn't want to be
          anything else. But as sweet as it is to be a Mochi, sometimes I can't
          help but feel so small.
        </Body>
        <Body mt="0">
          There are nights where I lay in bed with all these big and heavy
          thoughts. Why am I here? What's my purpose? What in the world is a
          mochi made for? ‘Round and ‘round my mind turns like a prima
          ballerina. Winding, twisting, spiraling in a mental pirouette. Nothing
          moves but in my mind the ballet twirls on a single point: will I ever
          amount to more than a tiny, glutinous rice cake?
        </Body>
        <Body mt="0">
          This is the last thing I remember before I awoke to the sound of
          shattering.
        </Body>
        <Body mt="0">
          I looked outside my window and saw falling chandeliers. I watched as
          tender shards of glass dropped like pearls from the sky. They drifted
          gently to the ground, where they gathered into piles, ‘til shattered
          rainbows littered the streets and parks where little mochigomes play.
          I felt a creamy sense of wonder watching everything sparkle in morning
          light. And then that wonder turned to horror, because this could mean
          only one thing.
        </Body>
        <Body mt="0">
          The Magnificent Egghead, Sultan Supreme, is in big, BIG trouble.
        </Body>
        <Body mt="0">
          As Sultan Supreme of the Ethereal realm, the Magnificent Egghead had
          one duty: to keep the Pearly Egg safe until it was ready to hatch.
          Only one in existence, this was the most treasured object in the
          universe, for inside its dazzling shell was the naked embryo of an
          unborn cosmos. With enough time, care, and attention beneath the glow
          of the rising sun, baby stars, moons, and planets would soon emerge
          from its milky shell.
        </Body>
        <Body mt="0">
          Rumors spread throughout Mochi World that the Magnificent Egghead was
          unwell.
        </Body>
        <Body mt="0">
          After a lifetime of serving as the egg's protector, his own body had
          begun to wither from old age. With age comes pain, and pain comes
          suffering. His nimble mind had begun to fog. And even as sultan, The
          Magnificent Egghead longed for an end to his worldly suffering.
        </Body>
        <Body mt="0">
          So he sought guidance from Aia the Yoni Goddess, mother of the waning
          universe. She promised to do everything in her power to ease the
          Magnificent Egghead's pain. All the sultan had to do was remove a
          piece of the Pearly Egg. From a single drop of its hot pink yolk, she
          could make him a pot of cosmic soup which was strong enough to soothe
          all the suffering in the universe.
        </Body>
        <Body mt="0">
          "Drink this," Aia said to him, "and the gravities of life will all but
          vanish. Then I'll return the Pearly Egg to you in its original,
          unbroken form."
        </Body>
        <Body mt="0">
          Unable to bear the burden for much longer, the Magnificent Egghead
          weighed her offer.
        </Body>
        <Body mt="0">
          "A single shell for universal peace?" he pondered. "No mere mortal
          could even dream of a trade this big. I'd be the savior of the cosmos,
          and the Egghead name would reign supreme!"
        </Body>
        <Body mt="0">
          So the sultan handed her the egg and happily drank her cosmic soup. In
          an instant, his pain left him, and child-like clarity returned. The
          Sultan's eyes shimmered like diamonds. Its life force giggled through
          his veins. And the Sultan slept like he hadn't slept since he was a
          baby in the womb.
        </Body>
        <Body mt="0">
          But the Magnificent Egghead woke up the next morning feeling anything
          but reborn. In a flash, the pain came back to him, like a debt
          collector to an unpaid bill. The sultan moaned in his beachside
          bungalow and started to panic as he scanned the room. A certain glow
          had somehow gone missing in the thrashings of the night. He checked
          every pouch, pocket, and compartment, but could not locate the Pearly
          Egg. So he lept into sandals, and tightened his turban, and almost
          went running out the front door. And then he stopped, because there it
          was, lying helpless on his doorstep. He picked it up and held it high
          to inspect its delicate shell. It dazzled bright like crystal fire as
          he turned it near the sun, and then he froze in hot paralysis as he
          reached the other side. The Yoni Goddess returned what she'd promised,
          but it was missing a single piece. Aia tricked him, it turns out, for
          something she could not bear to admit: she loved our universe so very
          much that she feared new one being born. And now the Pearly Egg was in
          danger because its guardian had failed.
        </Body>
        <Body mt="0">
          Devastated by what he had done, the Magnificent Egghead stared at the
          empty space, and for a moment that stretched to eternity, lost himself
          in the shell. And the Magnificent Egghead, whose body ached, whose
          mind was cloudy and hands were weak, finally lost his grip on the
          Pearly Egg, and down on us it fell...
        </Body>
      </GridBox>
    </GridContainer>
  );
};

export default StoryPage;
