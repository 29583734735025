import React from "react";
import { connect } from "react-redux";

import { Link as RouterLink } from "react-router-dom";

import moment from "moment";
import { Box } from "../Utility";
import { Paragraph, Button } from "../../Storybook";

// const displayDate = formatDisplayDate(moment(reportDate, "MM/DD/YYYY"));
// const reportIsToday = displayDate === "Today";
// (outstandingReport && reportIsToday)
const JourneyReportButton = ({ outstandingReport }) => {
  const PearlDue = outstandingReport
    ? `Your pearl is due in ${moment(outstandingReport.get("checkin_date"))
        .add(8, "hours")
        .fromNow()}`
    : "";
  return (
    <Box mt={3} pr={4}>
      <Paragraph color={"darkYellow500"} size="fz2" mb={2} label={PearlDue} />
      <RouterLink to={`/report/`}>
        <Button
          primary
          arrow
          label="Report"
          bgColor="darkYellow500"
          color="black"
          width="100%"
          mb={2}
        />
      </RouterLink>
    </Box>
  );
};

export default connect((state, props) => {
  return {
    outstandingReport: state.checkIn.getIn([
      "outstanding",
      state.community.get("activeCommunityId"),
    ]),
  };
})(JourneyReportButton);
