import React from "react";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
import { BrowserRouter } from "react-router-dom";

import AppWrapper from "./components/AppWrapper";
import { WalletConnect } from "./components/Web3/Web3AuthNew";
import AppRoutes from "./pages";
import store from "./config/store";
import "./styles/main.css";
import theme from "./styles/theme";

const App = () => (
  <Provider store={store()}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AppWrapper>
          <WalletConnect>
            <AppRoutes />
          </WalletConnect>
        </AppWrapper>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);

export default App;
