import React, { useRef, useLayoutEffect } from "react";
import { connect } from "react-redux";
import { formatEther } from "viem";

import { GridContainer, Box, Header, Body, InlineAnchor, Text } from "../components/Utility";
import chamoe from "../img/rank/chamoe.svg";
import ube from "../img/rank/ube.svg";
import daifuku from "../img/rank/daifuku.svg";
import matcha from "../img/rank/matcha.svg";
import pomelo from "../img/rank/pomelo.svg";
import pearlsImg from "../img/game-page/pearlsofwisdom.png";
import pausesImg from "../img/game-page/pause.png";
import softImg from "../img/firmness/soft.svg";
import firmImg from "../img/firmness/firm.svg";
import superFirmImg from "../img/firmness/superfirm.svg";
import extraFirmImg from "../img/firmness/extrafirm.svg";
import EthLogo from "../components/Utility/EthLogo";
import { GridBox, Paragraph } from "../Storybook";
import Flex from "../components/Utility/Flex";

export const GamePage = ({ authUserId, activeCommunityId, slashData }) => {
  const refsForScroll = {
    bond: useRef(null),
    slashes: useRef(null),
    pearlsofwisdom: useRef(null),
    journeys: useRef(null),
    pauses: useRef(null),
    teams: useRef(null),
    goals: useRef(null),
    showntell: useRef(null),
    mochibot: useRef(null),
    rank: useRef(null),
  };
  const scrollToRef = (ref) => window.scrollTo({ top: ref.current.offsetTop });

  slashData = slashData.sortBy((gameMode) => gameMode.get("minimum_stake"));
  const imgList = [softImg, firmImg, extraFirmImg, superFirmImg];

  useLayoutEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const scrollTo = urlParams.get("scrollto");
    if (scrollTo) {
      // image loading can take a bit and will affect height of page for scrolling
      setTimeout(() => {
        scrollToRef(refsForScroll[scrollTo]);
      }, 500);
    }
  });

  return (
    <GridContainer>
      <GridBox span={[4, 6, 6, 6]} start={[1, 2, 4, 4]} mb={4}>
        <Header h1 textAlign="center" mt={4} mb={4}>
          The Game
        </Header>
        <Body style={{ lineHeight: "22px", marginTop: "0px" }}>
          Welcome to Mochi! We are so excited for you to embark on your first{" "}
          <InlineAnchor onClick={() => scrollToRef(refsForScroll["journeys"])}>
            Journey
          </InlineAnchor>
          , which if you've been invited to play, will be beginning very soon! If you have any
          questions while you play, please return to this page.
        </Body>
        <Header h3 mb={1} mt={2}>
          The Game
        </Header>
        <Body style={{ lineHeight: "22px", marginTop: "0px" }}>
          Mochi is a{" "}
          <InlineAnchor onClick={() => scrollToRef(refsForScroll["teams"])}>
            team-based
          </InlineAnchor>{" "}
          <InlineAnchor onClick={() => scrollToRef(refsForScroll["goals"])}>
            goal-setting
          </InlineAnchor>{" "}
          game built on ethereum where YOU set your own personal goals and{" "}
          <InlineAnchor onClick={() => scrollToRef(refsForScroll["pearlsofwisdom"])}>
            Report
          </InlineAnchor>{" "}
          on your progress towards them. You'll try out new habits, ditch old ones, and track your
          progress using Discord or Slack for 8 weeks at a time. Throughout those 8 weeks Mochi
          members are invited to create events or attend any of the events put on by other Mochi
          members.
        </Body>

        <Header h3 mb={1} mt={2} ref={refsForScroll.journeys}>
          Journeys
        </Header>
        <Body style={{ lineHeight: "22px", marginTop: "0px" }}>
          A Journey takes place over the course of 8 weeks. For each weekday (Monday through Friday)
          during that journey, you'll{" "}
          <InlineAnchor onClick={() => scrollToRef(refsForScroll["pearlsofwisdom"])}>
            Report
          </InlineAnchor>{" "}
          on your progress towards your personal{" "}
          <InlineAnchor onClick={() => scrollToRef(refsForScroll["goals"])}>Goal</InlineAnchor> by
          submitting a Pearl of Wisdom. At the end of the Journey you'll complete a short reflection
          (3 paragraphs minimum) on the Journey, recounting what you learned and experiences you
          shared with your{" "}
          <InlineAnchor onClick={() => scrollToRef(refsForScroll["teams"])}>Teammates</InlineAnchor>
          .
        </Body>

        <Header h3 mb={1} mt={2} ref={refsForScroll.teams}>
          Teams
        </Header>
        <Body style={{ lineHeight: "22px", marginTop: "0px" }}>
          If it's your first time playing Mochi, you'll be placed on a team of 2-4 new Mochi
          members, selected at random. Everyone who plays Mochi plays on a team of 2-4 members—think
          of them as your acountability-buddies. You'll meet online or in-person every 2 weeks to
          discuss your progress towards your goals: this is called a{" "}
          <InlineAnchor onClick={() => scrollToRef(refsForScroll["showntell"])}>
            Show &amp; Tell
          </InlineAnchor>
          .
        </Body>

        <Header h3 mb={1} mt={2} ref={refsForScroll.goals}>
          Goals
        </Header>
        <Body style={{ lineHeight: "22px", marginTop: "0px" }}>
          Here at Mochi we understand that setting goals &amp; achieving them is no easy task.
          Sometimes it can even feel impossible. We believe that by being mindful of your goals,
          reflecting on how you spend your time, and comparing notes with teammates, anyone can
          accomplish their goals.
        </Body>
        <Body style={{ lineHeight: "22px", marginTop: "0px" }}>
          Before beginning your{" "}
          <InlineAnchor onClick={() => scrollToRef(refsForScroll["journeys"])}>
            Journey
          </InlineAnchor>{" "}
          you will be asked to choose a goal. Your goal can look like anything from "I want to
          meditate every morning" to "Drink 11 cups of water each day to promote my overall health."
          Everyone's goals are different: some are accomplished in one Journey, others in 10.
        </Body>
        <Body style={{ lineHeight: "22px", marginTop: "0px" }}>
          Goals are lofty. Mochi helps you build strong habits that will make your goals easier to
          reach.
        </Body>

        <Body style={{ lineHeight: "22px", marginTop: "0px" }}>
          Be mindful when setting your goal. The words you pick for your goal matter because you'll
          reflect on them each day of your{" "}
          <InlineAnchor onClick={() => scrollToRef(refsForScroll["journeys"])}>
            Journey
          </InlineAnchor>
          . Goals are visible to other members playing Mochi when you submit a{" "}
          <InlineAnchor onClick={() => scrollToRef(refsForScroll["pearlsofwisdom"])}>
            Pearl of Wisdom
          </InlineAnchor>
          .
        </Body>

        <Header h3 mb={1} mt={2}>
          Mochi's Tips for Goal-setting:
        </Header>
        <ol>
          <li style={{ lineHeight: "22px" }}>
            Take 10-20 minutes to reflect on the past week and ask yourself: "What can I be doing
            differently to improve my overall well-being?"
          </li>
          <li style={{ lineHeight: "22px", marginTop: "9px" }}>
            Find a peaceful time &amp; place to think about what your personal goals are.
          </li>
          <li style={{ lineHeight: "22px", marginTop: "9px" }}>
            Pick the goal that feels most important &amp; imagine what steps you might take or what
            habits you might adopt in order to reach that goal. What is something small, measurable,
            &amp; repeatable that you can do to work towards that goal?
          </li>
          <li style={{ lineHeight: "22px", marginTop: "9px" }}>
            Finally, complete a goal madlib: "In order to ___, I will ___."
          </li>
        </ol>

        <Body style={{ lineHeight: "22px", marginTop: "0px" }}>
          An example of a bad goal might be "I want to write more."
        </Body>
        <Body style={{ lineHeight: "22px", marginTop: "0px" }}>
          A better goal might be "In order to flex my mental muscle &amp; better process my
          thoughts, I will write for 10 minutes each day."
        </Body>
        <Body style={{ lineHeight: "22px", marginTop: "0px" }}>
          Both goals involve writing but the better one reinforces your reasoning and is small,
          measurable, &amp; repeatable.
        </Body>

        <Header h3 mb={1} mt={2} ref={refsForScroll.bond}>
          Bond
        </Header>
        <Body style={{ lineHeight: "22px", marginTop: "0px" }}>
          Before beginning your{" "}
          <InlineAnchor onClick={() => scrollToRef(refsForScroll["journeys"])}>
            Journey
          </InlineAnchor>
          , you will be given the choice to bond with your Mochi on a level ranging from "Soft" to
          "Superfirm." Each level represents a different amount of ethereum that you'll be playing
          with, which impacts the{" "}
          <InlineAnchor onClick={() => scrollToRef(refsForScroll["slashes"])}>
            Penalties
          </InlineAnchor>{" "}
          &amp; rewards you can earn. Your bond amount is set on your{" "}
          <InlineAnchor
            href={authUserId && `https://mochi.game/${activeCommunityId}/profile/${authUserId}`}
          >
            Mochi Profile
          </InlineAnchor>
          .
        </Body>

        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            position: "relative",
            left: "-12px",
          }}
        >
          {slashData.map((gameMode, key) => (
            <Box style={{ display: "flex", flexDirection: "column", width: "25%" }} key={key}>
              <img
                src={imgList[key]}
                alt={`${gameMode.get("name")} character`}
                style={{ position: "relative", left: "24px" }}
              />
              <Text style={{ textAlign: "center", padding: "10px" }}>{gameMode.get("name")}</Text>
              <Text style={{ textAlign: "center" }} fontSize="12px">
                <EthLogo />
                {formatEther(gameMode.get("minimum_stake").toString())}
              </Text>
              <Text style={{ textAlign: "center" }} fontSize="12px" color="green">
                {gameMode.get("award")} Token
                {gameMode.get("award") === 1 ? "" : "s"} earned
              </Text>
            </Box>
          ))}
        </Box>

        <Header h3 mb={1} mt={3} ref={refsForScroll.mochibot}>
          Mochibot
        </Header>
        <Body style={{ lineHeight: "22px", marginTop: "0px" }}>
          Mochibot is the club's friendly little helper - a chat bot that will message you with
          important information & remind you to submit your{" "}
          <InlineAnchor onClick={() => scrollToRef(refsForScroll["pearlsofwisdom"])}>
            Pearls of Wisdom
          </InlineAnchor>
          .
        </Body>

        <Header h3 mb={1} mt={2} ref={refsForScroll.pearlsofwisdom}>
          Pearls of Wisdom
        </Header>
        <Body style={{ lineHeight: "22px", marginTop: "0px" }}>
          Every day you'll receive a message on Discord or Slack from Mochibot asking you four
          questions; the answers that you submit to these questions are called Pearls of Wisdom.
          Each day you’ll have 8 hours to reflect &amp; submit a Pearl of Wisdom. One hour before
          the deadline, Mochibot will send you &amp; your{" "}
          <InlineAnchor onClick={() => scrollToRef(refsForScroll["teams"])}>Teammates</InlineAnchor>{" "}
          a reminder to check in if you haven't already.
        </Body>

        <img
          alt="A sweating mochi character holds up a pearl of wisdom it earned, while the associated check in from Discord or Slack floats overhead"
          src={pearlsImg}
          style={{ width: "100%" }}
        />

        <Body style={{ lineHeight: "22px", marginTop: "9px" }}>
          Mochibot can be instructed to message you at 10AM (day mode) or 2PM (night mode) at your
          local time. To change modes, type '/mode day' or '/mode night'. The submission deadlines
          are 6PM for day mode or 10PM for night mode. If you’re getting work started on your{" "}
          <InlineAnchor onClick={() => scrollToRef(refsForScroll["goals"])}>Goal</InlineAnchor>{" "}
          early, you can prompt{" "}
          <InlineAnchor onClick={() => scrollToRef(refsForScroll["mochibot"])}>
            Mochibot
          </InlineAnchor>{" "}
          to message you by typing '/report'.
        </Body>

        <Body style={{ lineHeight: "22px", marginTop: "0px" }}>
          Upon submitting a Pearl of Wisdom, you'll earn Mochi tokens that correspond to the{" "}
          <InlineAnchor onClick={() => scrollToRef(refsForScroll["bond"])}>Bond</InlineAnchor> you
          share with your Mochi, which you can redeem for Mochi clothing or in-game items.
        </Body>

        <Header h3 mb={1} mt={2} ref={refsForScroll.slashes}>
          Slashes
        </Header>
        <Body style={{ lineHeight: "22px", marginTop: "0px" }}>
          If you forget to submit your{" "}
          <InlineAnchor onClick={() => scrollToRef(refsForScroll["pearlsofwisdom"])}>
            Pearl of Wisdom
          </InlineAnchor>{" "}
          before the submission deadline, Mochibot will slash you, stealing a little of you (and all
          your{" "}
          <InlineAnchor onClick={() => scrollToRef(refsForScroll["teams"])}>Teammates</InlineAnchor>
          !) ethereum. You are slashed at a rate that corresponds with the{" "}
          <InlineAnchor onClick={() => scrollToRef(refsForScroll["bond"])}>Bond</InlineAnchor> you
          share with your Mochi.
        </Body>
        {slashData.map((gameMode, key) => (
          <Flex key={key} mt={4} mb={4} flexDirection="column" justifyContent="center">
            <Header h3>{gameMode.get("name")}</Header>
            <Paragraph size={"fz2"}>
              {gameMode.get("award")} token
              {gameMode.get("award") === 1 ? "" : "s"} earned
            </Paragraph>
            <table
              style={{
                textAlign: "center",
              }}
            >
              <tbody>
                <tr>
                  <th># of Slashes</th>
                  <th>Your Slash</th>
                  <th>Teammate's Slash</th>
                </tr>
                {gameMode
                  .get("player_slash_amounts")
                  .toOrderedMap()
                  .sort()
                  .toList()
                  .map((slashAmt, key) => {
                    return (
                      <tr key={key}>
                        {key <= 11 && (
                          <td>
                            <Text>{key + 1}</Text>
                          </td>
                        )}
                        {key <= 11 && (
                          <>
                            <td>
                              <Text red>{Math.floor(slashAmt * 100)}%</Text>
                            </td>
                            <td>
                              <Text red>
                                {Math.floor(
                                  gameMode
                                    .get("team_slash_amounts")
                                    .toOrderedMap()
                                    .sort()
                                    .toList()
                                    .get(key) * 100
                                )}
                                %
                              </Text>
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </Flex>
        ))}
        <Body style={{ lineHeight: "22px", marginTop: "9px" }}>
          If you are slashed enough times, your{" "}
          <InlineAnchor onClick={() => scrollToRef(refsForScroll["bond"])}>Bond</InlineAnchor> with
          your Mochi may become less firm, causing your level of Bond to drop &amp; the amount of
          Mochi tokens you earn per{" "}
          <InlineAnchor onClick={() => scrollToRef(refsForScroll["pearlsofwisdom"])}>
            Pearl of Wisdom
          </InlineAnchor>{" "}
          to decrease.
        </Body>

        <Header h3 mb={1} mt={2} ref={refsForScroll.pauses}>
          Pauses
        </Header>
        <Body style={{ lineHeight: "22px", marginTop: "0px" }}>
          Every{" "}
          <InlineAnchor onClick={() => scrollToRef(refsForScroll["journeys"])}>
            Journey
          </InlineAnchor>
          , a player gets one Pause. The Pause enables a Player to skip up to five consecutive{" "}
          <InlineAnchor onClick={() => scrollToRef(refsForScroll["pearlsofwisdom"])}>
            Pearls of Wisdom
          </InlineAnchor>
          . It also makes them immune to{" "}
          <InlineAnchor onClick={() => scrollToRef(refsForScroll["slashes"])}>Slashes</InlineAnchor>{" "}
          that may occur because a teammate missed a report. Since there is only one per journey,
          use it wisely!
        </Body>

        <Body style={{ lineHeight: "22px", marginTop: "0px" }}>
          A pause must be scheduled at least two days in advance. To set a pause, visit your{" "}
          <InlineAnchor href="https://mochi.game/profile">Mochi Profile</InlineAnchor>.
        </Body>

        <img
          alt="The modal window for changing your pause date."
          src={pausesImg}
          style={{
            width: "60%",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />

        <Header h3 mb={1} mt={2} ref={refsForScroll.showntell}>
          Show &amp; Tell
        </Header>
        <Body style={{ lineHeight: "22px", marginTop: "0px" }}>
          Every two weeks{" "}
          <InlineAnchor onClick={() => scrollToRef(refsForScroll["mochibot"])}>
            Mochibot
          </InlineAnchor>{" "}
          will send a message asking you to reflect on your progress. Mochibot will then share your
          reflection with your{" "}
          <InlineAnchor onClick={() => scrollToRef(refsForScroll["teams"])}>Team</InlineAnchor>, and
          after submitting your reflections your team is required to meet virtually (or in person)
          to discuss.
        </Body>

        <Body style={{}}>
          Take a photo or screenshot of the meeting and post it in the #pow channel in Discord or
          Slack. If you fail to post the photo or you fail to gather at least 50% of your team,
          Mochibot will{" "}
          <InlineAnchor onClick={() => scrollToRef(refsForScroll["slashes"])}>Slash</InlineAnchor>{" "}
          each player on the team. To confirm that you met, one teammate must press the "We Met"
          button that Mochibot sends to your team's channel.
        </Body>

        <Header h3 mb={1} mt={2} ref={refsForScroll.rank}>
          Rank
        </Header>
        <Body style={{ lineHeight: "22px", marginTop: "0px" }}>
          As you continue to play Mochi your rank will increase, unlocking different perks within
          the club &amp; game. For example, Daifuku members can request access to movies via the
          #mochi-plex channel in the{" "}
          <InlineAnchor href="https://discord.gg/bEj6NzXy6m">Mochi Discord</InlineAnchor>. You can
          view your rank by typing /leaderboard and you'll also see it when Mochibot posts your{" "}
          <InlineAnchor onClick={() => scrollToRef(refsForScroll["pearlsofwisdom"])}>
            Pearl of Wisdom
          </InlineAnchor>{" "}
          in your team channel or the #pow channel.
        </Body>

        <Body style={{ lineHeight: "22px", marginTop: "0px" }}>
          You rank is determined by three factors:
        </Body>
        <ol>
          <li style={{ lineHeight: "22px" }}>The number of Journeys you have completed</li>
          <li style={{ lineHeight: "22px", marginTop: "9px" }}>
            The total amount of Pearls of Wisdom you have submitted
          </li>
          <li style={{ lineHeight: "22px", marginTop: "9px" }}>
            Your current Bond with your Mochi, which must be Firm or higher
          </li>
        </ol>

        <table style={{ borderSpacing: "12px", textAlign: "center" }}>
          <tbody>
            <tr>
              <th></th>
              <th>Rank</th>
              <th>Journeys</th>
              <th>PoW's</th>
              <th>Show + Tell's</th>
              <th>Difficulty</th>
            </tr>
            <tr>
              <td>
                <img src={chamoe} style={{ width: "30px" }} alt="chamoe" />
              </td>
              <td>Chamoe</td>
              <td>1</td>
              <td>35</td>
              <td>2</td>
              <td>Firm</td>
            </tr>
            <tr>
              <td>
                <img src={ube} style={{ width: "30px" }} alt="ube" />
              </td>
              <td>Ube</td>
              <td>2</td>
              <td>75</td>
              <td>9</td>
              <td>Firm</td>
            </tr>
            <tr>
              <td>
                <img src={daifuku} style={{ width: "30px" }} alt="daifuku" />
              </td>
              <td>Daifuku</td>
              <td>3</td>
              <td>115</td>
              <td>13</td>
              <td>Extra Firm</td>
            </tr>
            <tr>
              <td>
                <img src={matcha} style={{ width: "30px" }} alt="matcha" />
              </td>
              <td>Matcha</td>
              <td>5</td>
              <td>195</td>
              <td>21</td>
              <td>Super Firm</td>
            </tr>
            <tr>
              <td>
                <img src={pomelo} style={{ width: "30px" }} alt="pomelo" />
              </td>
              <td>Pomelo</td>
              <td>8</td>
              <td>315</td>
              <td>33</td>
              <td>Super Firm</td>
            </tr>
          </tbody>
        </table>
      </GridBox>
    </GridContainer>
  );
};

export default connect((state) => ({
  activeCommunityId: state.community.get("activeCommunityId"),
  slashData: state.gameModes.get("all"),
  authUserId: state.auth.get("authedUserId"),
}))(GamePage);
