import styled, { css } from "styled-components";
import {
  space,
  color,
  layout,
  flexbox,
  grid,
  typography,
  border,
} from "styled-system";

const Notification = styled.div`
  border-radius: 4px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  margin-bottom: 8px;
  ${space}
  ${color}
  ${layout}
  ${flexbox}
  ${grid}
  ${typography}
  ${border}

${(props) =>
    props.bgColor &&
    css`
      background: ${(props) => props.theme.color[props.bgColor]};
    `}

${(props) =>
    props.css &&
    css`
      ${(props) => props.css};
    `}
`;

export default Notification;
