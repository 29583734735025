/* eslint-disable no-undef */
import React from "react";
import { formatEther } from "viem";

import { Flex, MochiToken } from "../Utility";
import { Header } from "../../Storybook";

const bNOne = BigInt(10 ** 18);

export const TokenIndicator = ({ inWallet = false, amountOfTokensBN }) => {
  const tokensGT3 = amountOfTokensBN >= bNOne * 3n;

  return (
    <Flex alignItems="center" justifyContent="flex-start">
      {bNOne >= amountOfTokensBN ? (
        <MochiToken large opacity={amountOfTokensBN > 0 ? "1" : "0.5"} />
      ) : (
        <MochiToken large three={tokensGT3} two={!tokensGT3} />
      )}
      <Header h4 fontWeight={600} ml={2}>
        {formatEther(amountOfTokensBN)} token
        {(amountOfTokensBN > bNOne || amountOfTokensBN < bNOne) && <span>s</span>}
        {inWallet ? " in wallet" : " earned in journey"}
      </Header>
    </Flex>
  );
};

export default TokenIndicator;
