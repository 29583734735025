import React from "react";
import { useRouteMatch } from "react-router-dom";

import { TabController } from "../Utility";
import { AnnualCheckout, QuarterlyCheckout } from "./index";

const PriceRouting = () => {
  const match = useRouteMatch();
  const tabs = [
    {
      name: "Annual",
      url: match.path,
      selected: true,
      component: AnnualCheckout,
    },
    {
      name: "Quarterly",
      url: `${match.path}/quarterly`,
      selected: false,
      component: QuarterlyCheckout,
    },
  ];

  return <TabController tabControllerJustifyContent="center" header="Join the Qlub" tabs={tabs} />;
};

export default PriceRouting;
