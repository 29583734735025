import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router";
import { fromJS } from "immutable";

import InitialInviteView from "../components/CommunityInvitation/InitialInviteView";
import { Container, Flex, Box, Space, Text, Loading, BackgroundImage } from "../components/Utility";
import { reviveSession } from "../containers/Auth/actions";
import {
  acceptCommunityInvitation,
  getCommunityInvitationInformation,
} from "../containers/Community/actions";
import { Button, Header, Paragraph } from "../Storybook";
import inviteImage from "../img/invite-background.jpg";
import { DEFAULT_MOCHI_INVITE } from "../constants/endpoints";

const CommunityInvitationPage = ({
  authUserId,
  getCommunityInvitationInformation,
  acceptCommunityInvitation,
  communityInvitation,
  alreadyMember,
  activeCommunityId,
}) => {
  const { commInvId } = useParams();
  let history = useHistory();
  const communityInvitationUUID = commInvId || DEFAULT_MOCHI_INVITE;
  const isInvitationToAnotherCommunity = communityInvitationUUID !== DEFAULT_MOCHI_INVITE;
  const [invitationState, setInvitationState] = useState("acceptInvitePrompt");
  const [invitation, setInvitation] = useState(
    fromJS({
      id: DEFAULT_MOCHI_INVITE,
      community_id: 1,
      creator: "Null",
      community_details: {
        name: "Mochi",
      },
    })
  );

  useEffect(() => {
    if (!!communityInvitation) setInvitation(communityInvitation);
  }, [communityInvitation]);

  useEffect(() => {
    reviveSession();
    getCommunityInvitationInformation(communityInvitationUUID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Redirect to profile if the user is already a member of that community
  useEffect(() => {
    if (authUserId && !!communityInvitation && alreadyMember) setInvitationState("alreadyMember");
  }, [authUserId, alreadyMember, communityInvitation]);

  const profileLink = `/${activeCommunityId}/profile/${authUserId}`;

  const handleCommunityAcceptance = async () => {
    // When the user accepts the community invitation
    if (!isInvitationToAnotherCommunity) history.push(profileLink);
    else {
      try {
        const communityId = communityInvitation.getIn(["community_details", "id"]);
        // forward them to the profile if they're logged in
        await acceptCommunityInvitation(communityInvitation.get("id"), authUserId, communityId);
        history.push(profileLink);
      } catch (error) {
        setInvitationState("failure");
      }
    }
  };

  if (isInvitationToAnotherCommunity && !!!communityInvitation) return <Loading />;
  return (
    <Container>
      <BackgroundImage alt="background: circle gradients" src={inviteImage} />
      <Box>
        <Box
          width="300px"
          padding="16px"
          textAlign="center"
          margin="0 auto"
          style={{
            background: "#000000",
            boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25), 0px 0px 12px rgba(0, 0, 0, 0.125)",
            borderRadius: "4px",
            position: "relative",
          }}
        >
          {invitationState === "acceptInvitePrompt" && (
            <InitialInviteView
              communityInvitation={invitation}
              isAuthed={!!authUserId}
              acceptInvitation={() => handleCommunityAcceptance()}
            />
          )}
          {invitationState === "failure" && (
            <>
              <Header h2>Unable To accept Invite</Header>
              <Flex flexDirection="column" padding="20px">
                <Text>
                  Please try again. If you continue to experience this problem, please contact us.
                </Text>
              </Flex>
            </>
          )}
          {invitationState === "alreadyMember" && (
            <>
              <Paragraph
                fontSize="fz2"
                mb={4}
                label={`You're already a member of ${invitation.getIn([
                  "community_details",
                  "name",
                ])}`}
              />
              <Button
                primary
                arrow
                label="See your profile"
                onClick={() =>
                  history.push(
                    `/${invitation.getIn(["community_details", "id"])}/profile/${authUserId}`
                  )
                }
              />
            </>
          )}
        </Box>
      </Box>
      <Space mb={4} />
    </Container>
  );
};

export default connect(
  (state, props) => {
    const authUserId = state.auth.get("authedUserId") || null;
    const activeCommunityId = state.community.get("activeCommunityId") || null;
    const communityInvitation = state.community.get("communityInvitation");
    return {
      authUserId,
      activeCommunityId,
      userData: !!authUserId && state.community.getIn(["players", activeCommunityId, authUserId]),
      communityInvitation,
      alreadyMember: state.community
        .get("communityInfo")
        .has(Number(communityInvitation?.getIn(["community_details", "id"]))),
    };
  },
  {
    getCommunityInvitationInformation,
    acceptCommunityInvitation,
  }
)(CommunityInvitationPage);
