import axios from "axios";

// TODO: Let's set the base endpoint URL HERE
// axios.defaults.baseURL = 'http://localhost:8000'

const localStorage = window.localStorage;
const token = localStorage.getItem("token");

if (token) {
  axios.defaults.headers.common = { Authorization: `Token ${token}` };
}

export const resetAuthToken = () => {
  localStorage.setItem("token", "");
  axios.defaults.headers.common = null;
};

export default axios;
