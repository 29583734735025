import { fromJS } from "immutable";

import initialState from "./initialState";
import { FREE_USER_LOAD, FREE_TEAM_LOAD } from "../../constants/actionTypes";

/* eslint-disable complexity */
const reducer = (state = fromJS(initialState), action) => {
  switch (action.type) {
    case FREE_USER_LOAD:
      return state
        .setIn(
          ["userData", action.communityId, action.page],
          fromJS(action.data.results)
        )
        .setIn(["userCount", action.communityId], action.data.count);
    case FREE_TEAM_LOAD:
      return state
        .setIn(
          ["teamData", action.communityId, action.page],
          fromJS(action.data.results)
        )
        .set("teamCount", action.communityId, action.data.count);
    default:
      return state;
  }
};

export default reducer;
