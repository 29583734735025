import React from "react";
import { connect } from "react-redux";

import { leaveJourneyWave } from "../../containers/Community/actions";
import PlayerListItem from "../Community/PlayerListItem";

const WaveParticipant = ({
  activeCommunityId,
  isAuthedPlayer,
  participant,
  leaveJourneyWave,
  waveId,
  leftWave,
  setLeftWave,
  joinedWave,
  setError,
}) => {
  const displayLeaveButton = isAuthedPlayer && (joinedWave || !leftWave);
  const leaveWave = async () => {
    try {
      await leaveJourneyWave(activeCommunityId, waveId);
      setLeftWave(true);
    } catch (e) {
      setError(e);
    }
  };

  return (
    <PlayerListItem
      playerOutline={participant}
      buttonText={displayLeaveButton && "Leave Wave"}
      buttonAction={leaveWave}
    />
  );
};

export default connect(
  (state) => {
    const activeCommunityId = state.community.get("activeCommunityId");
    return {
      activeCommunityId,
      waveId: state.community.getIn([
        "communityInfo",
        activeCommunityId,
        "active_wave",
        "id",
      ]),
    };
  },
  {
    leaveJourneyWave,
  }
)(WaveParticipant);
