import React, { useEffect, useState, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { BackgroundImage, Container, Box, Flex } from "../components/Utility";
import LoadingAni from "../components/Utility/Loading/LoadingAni";
import Web3Auth from "../components/Web3/Web3Auth";
import {
  installMochibot,
  toggleCommunity,
  acceptCommunityInvitation,
} from "../containers/Community/actions";
import backgroundImage from "../img/invite-background.jpg";
import { Modal, Paragraph, Header, Button } from "../Storybook";
import CommunityTag from "../components/Community/CommunityTag";

const InstallMochibotPage = ({
  authed,
  authedUserId,
  allPlayersInfo,
  installMochibot,
  acceptCommunityInvitation,
  allCommunitiesInfo,
  toggleCommunity,
}) => {
  const location = useLocation();
  const [error, setError] = useState("");
  const [installedCommunityId, setInstalledCommunityId] = useState(null);
  const [communityInvitationId, setCommunityInvitationId] = useState(null);

  // using the redux state, define the player data and community info once the installed community ID is received from the install response
  const fullPlayerData = allPlayersInfo.getIn([installedCommunityId, authedUserId]);
  const communityInfo = allCommunitiesInfo.get(installedCommunityId);

  // extract the guild, permissions and code params from URL
  const installParams = useMemo(() => {
    const params = new URLSearchParams(location.search);
    if (!!params.get("guild_id") && !!params.get("permissions") && !!params.get("code"))
      // only return the params if all are there
      return {
        guild_id: params.get("guild_id"),
        permissions: params.get("permissions"),
        code: params.get("code"),
      };
    // if not all params are available in the URL, set an error on state and return null
    else {
      setError(
        "Make sure you got this URL by accepting an invitation to install Mochibot on Discord."
      );
      return null;
    }
  }, [location.search]);

  // make install mochibot request, set the community ID from the response on the state. If player is not authed when the request is made, the response should contain a community invitation, which we set on state to make a request to accept that invitation
  const attemptInstall = useCallback(async () => {
    try {
      setError("");
      const res = await installMochibot(installParams);
      if (res.data.invitation) setCommunityInvitationId(res.data.invitation);
      setInstalledCommunityId(res.data.community.id);
      toggleCommunity(res.data.community.id);
    } catch (error) {
      if (!!error?.status) setError(`${error.status}: ${error.statusText}. Please try again.`);
      else setError(error);
    }
  }, [installParams, installMochibot, toggleCommunity]);

  // install on load, whether authed or not
  useEffect(() => {
    if (!error?.length && !communityInfo && !!installParams) {
      setTimeout(() => attemptInstall(), 1000);
    }
  }, [error, attemptInstall, communityInfo, installParams]);

  // Once the community invitation is on state, accept it
  useEffect(() => {
    if (authed && !!installedCommunityId && !!communityInvitationId) {
      acceptCommunityInvitation(communityInvitationId, authedUserId, installedCommunityId);
    }
  }, [
    communityInvitationId,
    authedUserId,
    authed,
    installedCommunityId,
    acceptCommunityInvitation,
  ]);

  return (
    <Container>
      <BackgroundImage src={backgroundImage} alt="background: circle gradients" />
      <Modal height="500px">
        {error?.length > 0 && (
          <Box>
            <Header h3 mb={3} label={!!installParams ? "Something Went Wrong" : "Invalid URL"} />
            <Paragraph label={error} mb={3} />
          </Box>
        )}

        {!error?.length && !installedCommunityId && (
          <Flex height="500px" alignItems="center" alignContent="center">
            <LoadingAni alt="loading" />
          </Flex>
        )}

        {!!communityInfo && (
          <Flex flexDirection="column" alignItems="center" alignContent="center" mb={3}>
            <Header h3 mb={3} label="Success!" />
            <Paragraph fontSize={"fz1"} mb={4}>
              Mochibot was installed in {communityInfo.get("name")}
            </Paragraph>

            {authed ? (
              <CommunityTag
                current
                image={fullPlayerData?.get("icon_url")}
                label={communityInfo.get("name")}
                color="darkBGSecondary"
                narrow
                mb={3}
              />
            ) : (
              <Box>
                <Header h3 mb={3} label={`Join ${communityInfo.get("name")}`} />
                <Paragraph
                  mb={4}
                  label={`To join ${communityInfo.get("name")}, connect your wallet.`}
                />
                <Web3Auth primary wide />
              </Box>
            )}
            {!!fullPlayerData && !!communityInfo && (
              <Link to={`/${communityInfo.get("id")}/profile/${authedUserId}`}>
                <Button primary mt={4} width="100%" label="See My Profile" />
              </Link>
            )}
          </Flex>
        )}
      </Modal>
    </Container>
  );
};

export default connect(
  (state) => {
    const authedUserId = state.community.get("authedUserId");
    return {
      authed: state.auth.get("auth"),
      allPlayersInfo: state.community.get("players"),
      allCommunitiesInfo: state.community.get("communityInfo"),
      authedUserId,
    };
  },
  { installMochibot, acceptCommunityInvitation, toggleCommunity }
)(InstallMochibotPage);
