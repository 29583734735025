import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { Map } from "immutable";

import initStates from "./initialState";
import combineReducers from "./combineReducers";
import createSentryMiddleware from "./sentry-middleware";

let makeStore;

if (process.env.REACT_APP_VERSION === "production") {
  const sentryMiddleware = createSentryMiddleware();
  makeStore = (initialState = initStates, reducer = combineReducers) => {
    return createStore(reducer, initialState, applyMiddleware(thunkMiddleware, sentryMiddleware));
  };
} else if (process.env.REACT_APP_VERSION === "staging") {
  const sentryMiddleware = createSentryMiddleware();
  makeStore = (initialState = initStates, reducer = combineReducers) => {
    const middleware = applyMiddleware(
      thunkMiddleware,
      sentryMiddleware,
      createLogger({ stateTransformer: (state) => Map(state).toJS() })
    );
    return createStore(reducer, initialState, composeWithDevTools(middleware));
  };
} else {
  // testing and local
  makeStore = (initialState = initStates, reducer = combineReducers) => {
    let middleware;
    if (process.env.NODE_ENV === "test") {
      middleware = applyMiddleware(thunkMiddleware);
    } else {
      // local development
      middleware = applyMiddleware(
        thunkMiddleware,
        createLogger({ stateTransformer: (state) => Map(state).toJS() })
      );
    }
    return createStore(reducer, initialState, composeWithDevTools(middleware));
  };
}

export default makeStore;
