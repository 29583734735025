import React, { useState } from "react";

import { Box, Button, ButtonArrow, Modal, Body } from "../Utility";
import PlayerMochi from "../Mochi/PlayerMochi";

export const InviteTeammateView = ({ setInviteModal, canInvite }) => {
  const [warningModal, setWarningModal] = useState(false);
  return (
    <Box width={[1, 1 / 2, 1 / 4]} display="inline-block" mb={5} p={3}>
      {warningModal && (
        <Modal open={warningModal} onClose={() => setWarningModal(false)}>
          <Body>
            Teammates cannot be invited while a journey is underway or upcoming.
          </Body>
        </Modal>
      )}
      <Box opacity=".7">
        <PlayerMochi />
      </Box>
      <Box>
        <Button
          primary
          width="100%"
          onClick={() =>
            canInvite ? setInviteModal(true) : setWarningModal(true)
          }
        >
          Invite Player
          <ButtonArrow />
        </Button>
      </Box>
    </Box>
  );
};

export default InviteTeammateView;
