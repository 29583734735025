import React, { Fragment } from "react";

import { Box } from "../Utility";
import { Paragraph, Header } from "../../Storybook";
import { genNewId } from "../../utils/mochiHelpers";

const MochiBotConversation = ({ day, showAndTell }) => {
  const showNTellReportIsToday =
    !!showAndTell && day.get("date") === showAndTell.get("report_date");
  const checkInSuccess = !!day.get("check_in_success");

  return (
    <Box mt={2}>
      {checkInSuccess &&
        day
          .get("conversation")
          .map((m, j) => (
            <Box key={genNewId("conversation")}>
              {j % 2 ? (
                <Paragraph size="fz2" label={m} mb={3} />
              ) : (
                <Header h5 mb={1} label={m} color="darkFGSecondary" />
              )}
            </Box>
          ))}
      {!!showAndTell && showNTellReportIsToday && showAndTell.get("report") !== null && (
        <Fragment>
          <Header h4 label="Show &amp; Tell Reflection" mb={3} />
          {showAndTell.get("report").map((m, j) => (
            <Box key={genNewId("report")}>
              {!!(j % 2) ? (
                <Paragraph size="fz2" label={m} m={0} mb={3} />
              ) : (
                <Header h5 mb={1} label={m} color="darkFGSecondary" />
              )}
            </Box>
          ))}
        </Fragment>
      )}
    </Box>
  );
};

export default MochiBotConversation;
