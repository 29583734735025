import React from "react";

import { Flex, MochiToken } from "../Utility";
import { Header, Paragraph } from "../../Storybook";

const ShopItemDetails = ({ itemInfo, price = 0 }) => (
  <>
    <Flex width="100%" alignContent="center" mt={2} mb={2}>
      <img width="120px" src={itemInfo?.image} alt={itemInfo?.name} />
    </Flex>

    <Header h3 label={itemInfo?.name} m={2} textAlign="center" />

    <Flex justifyContent="center" alignItems="center">
      <MochiToken />
      <Paragraph label={price.toString()} m={0} ml={2} />
    </Flex>

    <Paragraph label={itemInfo?.description} m={2} textAlign="center" mb={4} />
  </>
);

export default ShopItemDetails;
