import axios from "../../config/axios";
import moment from "moment";

import { API_ENDPOINT } from "../../constants/endpoints";
import {
  GET_CHECKINS,
  INITIATE_REPORT,
  SUBMIT_CHECKIN,
  UPDATE_CHECKIN,
} from "../../constants/actionTypes";

export const fetchRemainingCheckIns = () => async (dispatch) => {
  try {
    const reports = await axios.get(`${API_ENDPOINT}/reports/`);

    dispatch({
      type: GET_CHECKINS,
      data: reports.data,
    });
  } catch (error) {
    throw error?.response?.detail;
  }
};

export const initiateReport = (communityId) => async (dispatch) => {
  try {
    const reports = await axios.post(
      `${API_ENDPOINT}/community/${communityId}/report/`
    );

    dispatch({
      type: INITIATE_REPORT,
      data: reports.data,
    });
  } catch (error) {
    if (error?.response?.data) {
      throw error?.response?.data;
    } else if (error?.message) {
      throw error?.message;
    } else {
      throw error;
    }
  }
};

export const submitCheckInReport = (communityId, reportId, answers) => async (
  dispatch,
  getState
) => {
  try {
    const authedUserId = getState().auth.get("authedUserId");
    const report = await axios.patch(
      `${API_ENDPOINT}/community/${communityId}/report/${reportId}/`,
      { answers }
    );
    if (report.data?.is_complete) {
      // convert report prompts & answers into simplejourney conversation
      report.data["conversation"] = [];
      for (let i = 0; i < report.data.details.prompts.length; i++) {
        report.data["conversation"].push(
          report.data.details.prompts[i],
          report.data.details.answers[i]
        );
      }
      report.data["date"] = moment(report.data.checkin_date).format(
        "MM/DD/YYYY"
      );
      report.data["check_in_success"] =
        report.data.is_complete && report.data.is_on_time;

      dispatch({
        type: SUBMIT_CHECKIN,
        data: report.data,
        authedUserId,
        communityId,
        reportId,
      });
    } else {
      dispatch({
        type: UPDATE_CHECKIN,
        data: report.data,
        authedUserId,
        communityId,
        reportId,
      });
    }
    return report.data;
  } catch (error) {
    if (error?.response?.data) {
      throw error?.response?.data;
    } else if (error?.message) {
      throw error?.message;
    } else {
      throw error;
    }
  }
};
