import styled, { css } from "styled-components";
import { typography, space } from "styled-system";

const Body = styled.p`
  font-family: ${(props) => props.theme.fonts.body};
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => props.theme.fontSize.fz2};
  line-height: 19.2px;
  color: ${(props) => props.theme.color.darkFGPrimary};
  ${typography};
  ${space};

  ${(props) =>
    props.error &&
    css`
      color: ${(props) => props.theme.color.red};
    `}

  ${(props) =>
    props.color &&
    css`
      color: ${(props) => props.theme.color[props.color]};
    `}

${(props) =>
    props.bold &&
    css`
    font-weight: 600 };
  `}

  ${(props) =>
    props.larger &&
    css`
      font-size: 22px;
      font-weight: 700;
    `}
`;

export default Body;
