import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  genNewId,
  getActiveJourneyFromJourneyList,
  getDisplayName,
  getUpcomingJourneyFromJourneyList,
} from "../../utils/mochiHelpers";
import {
  getReportRateAverageForJourneyList,
  getTeamReportAverageForJourney,
  getLastReportPercentageForTeam,
} from "../../utils/reportRateHelpers";
import { Paragraph, Avatar } from "../../Storybook";
import theme from "../../styles/theme";
import { Flex, Box, BorderedWrapper } from "../Utility";
import TeamJourneyProgressIndicator from "./TeamJourneyProgressIndicator";
import defaultImage from "../../img/logo/mochi/Mochi-Icon.png";

const CommunityJourneyStats = ({
  teamData,
  isCurrentJourney,
  authPlayerId,
  activeCommunityId,
  currentJourneyDetails,
  journeyOutline,
}) => {
  const authPlayerQuitJourney = !!currentJourneyDetails?.getIn([
    authPlayerId.toString(),
    "player_totals",
    authPlayerId.toString(),
    "quit_date",
  ]);

  const isOwnJourney =
    !!journeyOutline?.getIn(["player_totals", authPlayerId.toString()]) &&
    !authPlayerQuitJourney;

  if (!journeyOutline) return <></>;

  const reportAverage = isCurrentJourney
    ? getTeamReportAverageForJourney(journeyOutline)
    : getReportRateAverageForJourneyList(teamData?.get("journeys"));

  const lastReportPercentage = getLastReportPercentageForTeam(
    currentJourneyDetails,
    journeyOutline?.get("player_totals")?.keySeq()
  );

  if (!teamData?.get("members")) return <></>;
  return (
    <BorderedWrapper
      bgColor={
        isCurrentJourney && isOwnJourney
          ? theme.color.mutedOrange88
          : theme.color.darkBGPrimary
      }
    >
      {isOwnJourney && (
        <Paragraph
          weight={600}
          fontSize={"fz1"}
          label="Your Team"
          color={theme.color.darkFGPrimary50}
          mb={2}
        />
      )}
      <Flex
        justifyContent="space-between"
        alignItems={["flex-end", "flex-start"]}
      >
        <Flex
          flexDirection={["column", "row"]}
          alignItems="center"
          alignContent="flex-start"
          justifyContent="flex-start"
          flexWrap="no-wrap"
        >
          <Link to={`/${activeCommunityId}/team/${teamData.get("team_id")}`}>
            <Paragraph
              fontSize="fz1"
              weight={600}
              label={teamData.get("name")}
              mr={2}
              mb={1}
            />
          </Link>
          <Flex
            justifyContent="flex-start"
            alignContent="flex-start"
            width={["100%", "auto"]}
            ml={0}
            mb={[1, 0]}
          >
            {teamData.get("members").map(
              (m, i) =>
                !!m?.get("details") && (
                  <Box mr={-3} key={genNewId(`${teamData.get("name")}-member`)}>
                    <Avatar
                      key={genNewId(`${teamData.get("name")}-member`)}
                      alt={`${getDisplayName(m)}'s avatar`}
                      image={m.getIn(["details", "image_512"]) || defaultImage}
                    />
                  </Box>
                )
            )}
          </Flex>
        </Flex>
        <Flex mb={1} alignItems="flex-end">
          <Paragraph
            mono
            color={theme.color.darkFGPrimary50}
            fontSize="fz1"
            weight={400}
          >
            {reportAverage.toFixed(0)}% Reporting
          </Paragraph>
          {isCurrentJourney && (
            <Paragraph mono ml={1} weight={400}>
              {lastReportPercentage <= 50 ? "📉" : "📈"}
            </Paragraph>
          )}
        </Flex>
      </Flex>
      {isCurrentJourney && (
        <Box>
          <TeamJourneyProgressIndicator journey={journeyOutline} />
          <Flex justifyContent="space-between" mt={1}>
            <Paragraph
              mono
              color={theme.color.darkFGPrimary50}
              fontSize="fz1"
              weight={400}
              label={journeyOutline.get("start")}
            />
            <Paragraph
              mono
              color={theme.color.darkFGPrimary50}
              fontSize="fz1"
              weight={400}
              label={journeyOutline.get("end")}
            />
          </Flex>
        </Box>
      )}
    </BorderedWrapper>
  );
};

export default connect((state, props) => {
  const activeCommunityId = state.community.get("activeCommunityId");
  const teamData = state.community.getIn([
    "teams",
    activeCommunityId,
    props.teamId,
  ]);
  const journeyOutline = props.isCurrentJourney
    ? getActiveJourneyFromJourneyList(teamData?.get("journeys"))
    : getUpcomingJourneyFromJourneyList(teamData?.get("journeys"));

  const journeyId = journeyOutline?.get("id");

  return {
    activeCommunityId,
    journeyOutline,
    journeyId,
    currentJourneyDetails:
      props.isCurrentJourney &&
      state.community.getIn(["journeys", activeCommunityId, journeyId]),
    authPlayerId: state.community.get("authedUserId"),
    teamData:
      state.community.getIn(["teams", activeCommunityId, props.teamId]) ||
      new Map(),
  };
}, {})(CommunityJourneyStats);
