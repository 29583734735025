import React from "react";

import { Box } from "../Utility";
import JourneyReportsView from "../Journey/JourneyReportsView";
import JourneyInfo from "../Journey/JourneyInfo";
import { getDisplayName } from "../../utils/mochiHelpers";

//Renders the most recent goal associatd to a journey, related stats and reports. All props come from parent components Player Journey and Journey Page.
export const JourneyStatus = ({
  playerData,
  journeyDetails,
  isAnotherPlayersProfile,
  goal,
  currentPlayerId,
}) => (
  <Box>
    {!!goal && (
      <JourneyInfo
        goalText={goal}
        selectedJourney={journeyDetails}
        currentPlayerId={currentPlayerId}
        isAnotherPlayersProfile={isAnotherPlayersProfile}
      />
    )}
    <JourneyReportsView
      journeyDetails={journeyDetails}
      playerName={getDisplayName(playerData)}
      playerId={playerData.get("user_id")}
      isAnotherPlayersProfile={isAnotherPlayersProfile}
    />
  </Box>
);

export default JourneyStatus;
