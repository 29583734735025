import React, { useState, useEffect, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Button, GridBox, Paragraph } from "../../Storybook";
import theme from "../../styles/theme";
import { Box, Flex } from "../Utility";
import CheckInReport from "./CheckInReport";
import CheckInSuccess from "./CheckInSuccess";
import { initiateReport, submitCheckInReport } from "../../containers/CheckIn/actions";

const CheckInReports = ({
  currentGoals,
  remainingCheckIns,
  checkInGoal,
  setCheckInGoal,
  initiateReport,
  submitCheckInReport,
  answers,
  setModalError,
  allComplete,
}) => {
  const [reportingGoal, setReportingGoal] = useState(null);
  const [valError, setValError] = useState({});
  const [complete, setComplete] = useState(false);
  const [nextGoal, setNextGoal] = useState(null);

  useEffect(() => {
    if (checkInGoal !== reportingGoal) {
      setReportingGoal(null);
    }
  }, [checkInGoal, reportingGoal]);

  useEffect(() => {
    if (reportingGoal === null) {
      setValError(null);
    }
  }, [reportingGoal]);

  const onInitiate = (goal) => {
    (async function () {
      try {
        await initiateReport(goal.get("community_id"));
        setReportingGoal(goal);
      } catch (e) {
        setModalError(e?.message);
      }
    })(goal);
  };

  const onSubmit = async (ev, answer) => {
    ev.preventDefault();
    try {
      const communityId = checkInGoal.get("community_id");
      const reportId = remainingCheckIns.getIn([communityId, "id"]);
      const answers = remainingCheckIns.getIn([communityId, "details", "answers"]);

      const data = await submitCheckInReport(communityId, reportId, [...answers, answer]);
      if (data?.is_complete) {
        setComplete(true);
      }
      setModalError(null);
      setValError(null);
    } catch (e) {
      if (e?.index) {
        setValError(e);
      } else {
        setModalError(e?.message);
      }
    }
  };

  const onNext = () => {
    setCheckInGoal(nextGoal);
    setComplete(false);
    if (!remainingCheckIns.get(nextGoal?.get("community_id"))) {
      onInitiate(nextGoal);
    } else {
      setReportingGoal(nextGoal);
    }
  };

  const checkInGoalIsOustanding = useMemo(
    () => remainingCheckIns.get(checkInGoal?.get("community_id")),
    [remainingCheckIns, checkInGoal]
  );

  return (
    <>
      {!allComplete && (
        <GridBox display="flex" flexDirection="row" justifyContent="flex-start">
          <Flex>
            <Box width={theme.pixels.sz4} mr={theme.pixels.sz2} />
            <Box>
              <Paragraph
                fontSize="fz3"
                label={`Select a Goal, and when you're ready, press begin below`}
                mb={3}
                color={!reportingGoal ? "darkFGPrimary" : "darkFGSecondary"}
              />
              {!reportingGoal && (
                <>
                  {checkInGoalIsOustanding ? (
                    <Button
                      primary
                      label={answers?.size ? `Continue` : `Begin`}
                      disabled={!checkInGoal}
                      onClick={() => setReportingGoal(checkInGoal)}
                    />
                  ) : (
                    <Button
                      primary
                      label={`Initiate Report`}
                      disabled={!checkInGoal}
                      onClick={() => onInitiate(checkInGoal)}
                    />
                  )}
                </>
              )}
            </Box>
          </Flex>
        </GridBox>
      )}
      <GridBox style={{ opacity: 1 }}>
        <Box>
          <CheckInReport
            reportingGoal={reportingGoal}
            onSubmit={onSubmit}
            error={valError}
            setError={setValError}
          />
          {complete && (
            <>
              <CheckInSuccess
                currentGoals={currentGoals}
                onSubmit={onNext}
                allComplete={allComplete}
                setCheckInGoal={setNextGoal}
                checkInGoal={nextGoal}
              />
              {!allComplete && (
                <Button
                  primary
                  label={"Start Next Goal"}
                  disabled={!nextGoal}
                  onClick={onNext}
                  ml={40}
                />
              )}
            </>
          )}
        </Box>
      </GridBox>
    </>
  );
};

export default withRouter(
  connect(
    (state, props) => {
      return {
        answers: state.checkIn.getIn([
          "outstanding",
          props.checkInGoal?.get("community_id"),
          "details",
          "answers",
        ]),
      };
    },
    { initiateReport, submitCheckInReport }
  )(CheckInReports)
);
