import styled, { css } from "styled-components";
import { space, typography, color, layout } from "styled-system";

const Paragraph = styled.p`
${color}
${layout}
font-family: ${(props) => props.theme.fonts.body}
font-weight: 400;
line-height: 120%;
color: ${(props) => props.color}
overflow-wrap: break-word;

${(props) =>
  props.mono &&
  css`
    font-family: ${(props) => props.theme.fonts.mono};
  `}


${(props) =>
  props.fontSize === "12px" &&
  css`
    letter-spacing: 0.02em;
  `}

${(props) =>
  props.weight &&
  css`
    font-weight: ${(props) => props.weight};
  `}

${(props) =>
  props.link &&
  css`
    color: ${(props) => props.theme.color.darkCyan500};
    text-decoration-line: underline;
  `}

${(props) =>
  props.height &&
  css`
    line-height: ${(props) => props.height};
  `}

${(props) =>
  props.color &&
  css`
    color: ${(props) => props.theme.color[props.color]};
  `}

${(props) =>
  props.colored &&
  css`
    color: ${(props) => props.theme.color.colored.fg.primary};
  `}

//! to deprecate
${(props) =>
  props.selected &&
  css`
    color: ${(props) => props.theme.color.darkBGSecondary};
  `}

${(props) =>
  props.bgColor &&
  css`
    background: ${(props) => props.theme.color[props.bgColor]};
  `};

${space}
${typography}
`;

export default Paragraph;
