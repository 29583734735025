import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import AuthorizedHOC from "../components/Utility/AuthorizedHOC";
import { GridContainer, Space, TabController } from "../components/Utility";
import { TopUp, Withdraw } from "../components/Bonding";

export const BondPage = () => {
  const match = useRouteMatch();
  const history = useHistory();

  const tabs = [
    {
      name: "Bond",
      url: `${match.url}/top-up`,
      selected: true,
      component: TopUp,
    },
    {
      name: "Withdraw",
      url: `${match.url}/withdraw`,
      selected: false,
      component: Withdraw,
    },
  ];

  return (
    <AuthorizedHOC>
      <GridContainer alignItems="flex-start" alignContent="flex-start">
        <TabController tabControllerJustifyContent="flex-start" history={history} tabs={tabs} />
      </GridContainer>
      <Space mb={40} />
    </AuthorizedHOC>
  );
};

export default BondPage;
