import React from "react";
import { connect } from "react-redux";

import { Box, Fill, Flex } from "../Utility";
import {
  getDisplayName,
  assignReportStatus,
  generateCommunityLink,
} from "../../utils/mochiHelpers";
import { getPlayerAndTeamDataFromState } from "../../containers/Community/stateGetterHelpers";
import { Header, Paragraph, Button } from "../../Storybook";
import MochiBotConversation from "../Journey/MochiBotConversation";
import theme from "../../styles/theme";
import { fromJS } from "immutable";
import CenterEllipsisText from "../Utility/CenterEllipsisText";
import { Link } from "react-router-dom";

const TeamPearl = ({
  pearl,
  pearlDate,
  playerData,
  height,
  communityInfo,
  isAnotherPlayersPearl,
  activeCommunityId,
}) => {
  const { color, status } = assignReportStatus(pearl, playerData, pearlDate);

  const displayName = !!playerData && getDisplayName(playerData);

  const { platformUserLink } = generateCommunityLink(
    communityInfo,
    playerData.get("details")
  );

  if (!playerData?.get("details")) {
    return null;
  }
  return (
    <Box>
      {!!playerData && (
        <Fill
          bgColor="darkBGSecondary"
          borderRadius="4px"
          padding="10px"
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          height={height || "100%"}
        >
          <Link
            to={`/${activeCommunityId}/profile/${playerData.get("user_id")}`}
          >
            <Header h3 mb={3} alignText="center">
              {displayName.length > 13 ? (
                <CenterEllipsisText originalText={displayName} length={13} />
              ) : (
                displayName
              )}
            </Header>
          </Link>
          <Fill
            width="120px"
            bgColor={color}
            mb={3}
            style={{
              padding: "4px 8px",
              borderRadius: "4px",
              textAlign: "center",
            }}
          >
            <Header h5 label={status} color={theme.color["darkBGPrimary"]} />
          </Fill>
          {status === "Missed!" && (
            <Paragraph weight={600} color={theme.color[color]}>
              {isAnotherPlayersPearl
                ? `Oh no! @${displayName} missed their check in.`
                : `Oh no! You missed your check in.`}
            </Paragraph>
          )}
          {status?.includes("Hour") && (
            <Flex flexDirection="column" alignItems="center" width="100%">
              <Paragraph color={color} mb={3}>
                {`${
                  isAnotherPlayersPearl
                    ? `@${displayName} hasn’t`
                    : "You haven't"
                } reported yet...`}
              </Paragraph>
              {isAnotherPlayersPearl && (
                <Button
                  primary
                  arrow
                  bgColor={color}
                  width="100%"
                  label="Remind them!"
                  onClick={() => window.open(platformUserLink, "_blank")}
                />
              )}
            </Flex>
          )}
          {!!pearl?.conversation && (
            <Box width="100%">
              <MochiBotConversation day={fromJS(pearl)} key={pearl.id} />
            </Box>
          )}
        </Fill>
      )}
    </Box>
  );
};

export default connect((state, props) => {
  const authedUserId = state.community.get("authedUserId");
  const activeCommunityId = state.community.get("activeCommunityId");
  const isAnotherPlayersPearl = props.playerId !== authedUserId;
  const playerId = isAnotherPlayersPearl ? props.playerId : null;
  const { playerData } = getPlayerAndTeamDataFromState(state, playerId);
  return {
    activeCommunityId,
    playerData,
    isAnotherPlayersPearl,
    communityInfo: state.community.getIn(["communityInfo", activeCommunityId]),
  };
})(TeamPearl);
