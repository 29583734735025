import React from "react";
import moment from "moment";

import { Flex } from "../Utility";
import theme from "../../styles/theme";
import { genNewId } from "../../utils/mochiHelpers";

const TeamJourneyProgressIndicator = ({ journey }) => {
  const today = moment();
  const start = moment(journey.get("start"), "MM/DD/YYYY");

  const journeyLength = Math.abs(start.diff(moment(journey.get("end"), "MM/DD/YYYY"), "days"));
  const segments = [25, 50, 75, 100];

  const timeElapsed = Math.abs(start.diff(today, "days"));
  const percentElapsed = (timeElapsed / journeyLength) * 100;

  const indicatorFill = (segment) => {
    if (percentElapsed > segment) return "100%";
    else if (percentElapsed < segment - 24) return "0%";
    // if (percentElapsed <= segment && percentElapsed > segment - 25)
    // find the difference between the first segment larger than the percent elapsed. Subtract the difference from 25 and multiple by 4 to get the percentage to fill the current segment
    else return `${(25 - (segments.filter((s) => s > percentElapsed)[0] - percentElapsed)) * 4}%`;
  };

  return (
    <Flex justifyContent="space-between">
      {segments.map((segment) => (
        <div
          key={genNewId(`journey-progress-${journey.get("id")}`)}
          style={{
            background: theme.color.darkFGPrimary15,
            opacity: 1,
            width: "24.5%",
            height: "4px",
            borderRadius: "4px",
            position: "relative",
            zIndex: 0,
          }}
        >
          <div
            key={genNewId(`journey-progress-${journey.get("id")}`)}
            style={{
              backgroundColor: theme.color.darkPositive500,
              width: indicatorFill(segment),
              height: "4px",
              borderRadius: "4px",
              position: "absolute",
              zIndex: 5,
              opacity: 1,
            }}
          />
        </div>
      ))}
    </Flex>
  );
};

export default TeamJourneyProgressIndicator;
