import React from "react";
import moment from "moment";

import NotOnTeamNotification from "./NotOnTeamNotification";
import NotInJourneyNotification from "./NotInJourneyNotification";
import PendingJourneyNotification from "./PendingJourneyNotification";
import CurrentlyInJourneyNotification from "./CurrentlyInJourneyNotification";
import NotYetClaimedNotification from "./NotYetClaimedNotification";

const JourneyNotification = ({
  onTeam,
  currentJourney,
  newestJourney,
  playerId,
  playerClaimedAccount,
  isTeamPageNotification,
  amLeader,
}) => {
  const now = moment.utc();
  const newestStartDate = newestJourney?.get("start");
  const pendingJourney = newestStartDate
    ? now.isBefore(moment.utc(newestStartDate, "MM/DD/YYYY"))
    : false;

  if (!playerClaimedAccount) {
    return <NotYetClaimedNotification />;
  } else if (!onTeam) return <NotOnTeamNotification />;
  else if (!pendingJourney && !currentJourney) {
    return (
      <NotInJourneyNotification
        isTeamPageNotification={isTeamPageNotification}
        amLeader={amLeader}
      />
    );
  } else if (pendingJourney) {
    return (
      <PendingJourneyNotification
        newestStartDate={newestStartDate}
        amLeader={amLeader}
        playerId={playerId}
        upcomingJourneyId={newestJourney?.get("id")}
        teamId={onTeam}
      />
    );
  } else if (currentJourney?.size > 0)
    return <CurrentlyInJourneyNotification currentJourney={currentJourney} />;
  else return null;
};

export default JourneyNotification;
