import React from "react";
import { useConnect, useNetwork } from "wagmi";
import { useWeb3Modal } from "@web3modal/react";
import { Web3Button } from "@web3modal/react";

import { Button, Header, Modal } from "../../Storybook";
import { WEB3_NETWORK } from "../../constants/endpoints";

export const SelectConnector = ({ setModalClosed }) => {
  const { chains } = useNetwork();
  const { connect, connectors, isLoading } = useConnect();
  const { open, setDefaultChain } = useWeb3Modal();
  setDefaultChain(chains[0]);

  const makeConnection = (connector) => {
    if (connector.name === "WalletConnect") open();
    else {
      connect({
        connector,
        chainId: WEB3_NETWORK,
      });
    }
  };

  return (
    <Modal onClose={() => setModalClosed()}>
      {connectors.map((connector) =>
        connector.name === "Injected" ? null : (
          <Button
            primary
            mb={2}
            key={connector.id}
            onClick={() => makeConnection(connector)}
            label={connector.name}
            width="100%"
          />
        )
      )}
      <div style={{ display: "none" }}>
        <Web3Button />
      </div>
      {isLoading && <Header mt={3}>Connecting...</Header>}
    </Modal>
  );
};
