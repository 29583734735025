import styled, { css } from "styled-components";

const StyledOption = styled.span`
  text-align: left;
  height: 100%;
  width: 100%;
  border: 2px solid transparent;
  font-family: ${(props) => props.theme.fonts.body};
  font-size: ${(props) => props.theme.fontSize.fz2};
  font-weight: 400;
  padding: ${(props) => props.theme.pixels.sz2};
  background-color: ${(props) => props.theme.color.dark.fg.primary};
  color: ${(props) => props.theme.color.dark.bg.primary};

  &:hover {
    border: 2px solid ${(props) => props.theme.color.dark.fg.primary};
    cursor: pointer;

    ${(props) =>
      props.colored &&
      props.selected &&
      css`
        border: 2px solid ${(props) => props.theme.color.colorFGPrimary};
      `};
  }

  ${(props) =>
    props.selected &&
    css`
      background: transparent;
      color: ${(props) => props.theme.color.dark.fg.primary};
      border-radius: ${(props) => props.theme.pixels.sz1};
      font-size: ${(props) => props.theme.fontSize.fz3};

      ${(props) =>
        !props.open &&
        css`
          border: 2px solid ${(props) => props.theme.color.dark.fg.secondary};
        `};

      ${(props) =>
        props.open &&
        css`
          border: 2px solid ${(props) => props.theme.color.dark.fg.primary};
          border-radius: ${(props) => props.theme.pixels.sz1}
            ${(props) => props.theme.pixels.sz1} 0 0;
        `};
    `};

  ${(props) =>
    props.open &&
    !props.selected &&
    css`
      border-radius: 0 0 0 0;

      &:hover {
        background-color: ${(props) => props.theme.color.dark.bg.primary};
        border: 2px solid ${(props) => props.theme.color.dark.fg.primary};
        color: ${(props) => props.theme.color.dark.fg.primary};
      }
    `};

  // colored styling

  ${(props) =>
    props.colored &&
    css`
      ${(props) =>
        props.selected &&
        css`
          border: 2px solid ${(props) => props.theme.color.colorFGSecondary};
          color: ${(props) => props.theme.color.dark.bg.primary};

          ${(props) =>
            props.open &&
            css`
              &:hover {
                background-color: ${(props) =>
                  props.theme.color.colorFGSecondary};
              }
            `};
        `};

      ${(props) =>
        props.open &&
        !props.selected &&
        css`
          background-color: ${(props) => props.theme.color.dark.bg.primary};
          color: ${(props) => props.theme.color.dark.fg.primary};
          &:hover {
            background-color: ${(props) => props.theme.color.colorFGSecondary};
            border: 2px solid transparent;
          }
        `};
    `}

  ${(props) =>
    props.last &&
    css`
      border-radius: 0 0 8px 8px;
    `};
`;

export default StyledOption;
