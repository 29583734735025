import styled from "styled-components";
import { space, color } from "styled-system";

const Report = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "space-around"};
  background-color: ${(props) => props.theme.color.darkBGSecondary};
  padding: 10px 20px;
  margin: 4px 0;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "8px"};
  ${space};
  ${color};
`;

export default Report;
