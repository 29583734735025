import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import HomePage from "./HomePage";
import SignInPage from "./SignInPage";
import CommunityInvitationPage from "./CommunityInvitationPage";
import GamePage from "./GamePage";
import StoryPage from "./StoryPage";
import RecoverAccountPage from "./RecoverAccountPage";
import SlackOAuthPage from "./SlackOAuthPage";
import MembershipPage from "./MembershipPage";
import PrivacyPolicyPage from "./PrivacyPolicyPage";
import TermsAndConditionsPage from "./TermsAndConditionsPage";
import InstallMochibotPage from "./InstallMochibotPage";
import TopUpForPlayerPage from "./TopUpForPlayerPage";
import NavbarComponent from "../components/NavBar";
import Footer from "../components/Footer";
import CommunityRouter from "./CommunityRouter";
import Error404 from "./Error404";
import CombinedInvitationPage from "./CombinedInvitationPage";
import CheckInPage from "./CheckInPage";
// for development purposes
// import ComponentPage from "./ComponentPage";
// import GridPage from "./GridTestPage";

export default class AppRoutes extends React.Component {
  render() {
    return (
      <div>
        <Route>
          <NavbarComponent />
        </Route>
        <Switch>
          {/*
          <Route path="/grid" component={GridPage} />
          <Route path="/components">
            <ComponentPage />
          </Route>
          */}
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route path="/the-game">
            <GamePage />
          </Route>
          <Route path="/the-story">
            <StoryPage />
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicyPage />
          </Route>
          <Route path="/terms-and-conditions">
            <TermsAndConditionsPage />
          </Route>
          <Route path="/recover">
            <RecoverAccountPage />
          </Route>
          <Route path="/sign-in">
            <SignInPage />
          </Route>
          <Route path="/slack-oauth">
            <SlackOAuthPage />
          </Route>
          <Route path="/membership-info">
            <MembershipPage />
          </Route>
          <Route path="/community-invitation/:commInvId">
            <CommunityInvitationPage />
          </Route>
          <Route path="/install-mochibot">
            <InstallMochibotPage />
          </Route>
          <Route path="/mochi-invite">
            <CombinedInvitationPage />
          </Route>
          <Route path="/report">
            <CheckInPage />
          </Route>
          <Route path="/:communityId/top-up-for-player/:playerId">
            <TopUpForPlayerPage />
          </Route>
          <Route path="/:communityId">
            <CommunityRouter />
          </Route>

          <Route path="/404" component={Error404} render={() => <Redirect to="/404" />} />
        </Switch>
        <Route component={Footer} />
      </div>
    );
  }
}
