import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";

import useInput from "../Forms/utils/useInput";
import { notWeekend } from "../Forms/utils/fieldValidators";
import { Box, Body } from "../Utility";
import { deletePause, setPauseDate } from "../../containers/Community/actions";
import { Input, Header, Modal, Button, Paragraph } from "../../Storybook";
import { weekdayError } from "../Forms/utils/formMessageStrings";
import {
  getPlayerAndTeamDataFromState,
  getCurrentJourneyFromState,
} from "../../containers/Community/stateGetterHelpers";

export const SchedulePause = ({
  pauseDate,
  endDate,
  setPauseDate,
  isPaused,
  deletePause,
  membershipId,
  hasAlreadyPaused,
}) => {
  const tomorrow = moment().add(1, "days").format("YYYY-MM-DD");
  const {
    value: proposedDate,
    bind: bindProposedDate,
    valid: validDate,
    active: activeDate,
  } = useInput(tomorrow, notWeekend, false);
  const [pauseModal, setPauseModal] = useState(false);
  const [pauseError, setPauseError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    try {
      setSuccess(true);
      await setPauseDate(
        moment(proposedDate).format("MM/DD/YYYY"),
        membershipId
      );
    } catch (err) {
      setSuccess(false);
      setPauseError(
        "There was an error setting the pause date. Reach out to the team with any further issues."
      );
    }
  };

  const onClose = () => {
    setPauseModal(false);
    setSuccess(false);
  };

  const pauseIsBeforeToday = pauseDate && moment().isAfter(pauseDate);
  return (
    <Fragment>
      {pauseModal && (
        <Modal width="100%" onClose={() => onClose(false)}>
          <Header mt={0}>Set Pause Date</Header>
          <Body>
            Your account will be <u>paused for 1 week</u> starting on the day
            you select below. Pauses do not work between journeys.
          </Body>
          <Box textAlign="left">
            <form onSubmit={handleSubmit}>
              {success ? (
                <Body textAlign="center">
                  The date of your pause has been set. You will be able to
                  change it if you need.
                </Body>
              ) : (
                <Fragment>
                  <Input
                    type="date"
                    icon="calendar"
                    min={tomorrow}
                    max={endDate.format("YYYY-MM-DD")}
                    activeInput={activeDate}
                    error={weekdayError}
                    role="dialog"
                    {...bindProposedDate}
                  />
                  {pauseError.length > 0 && (
                    <Body error textAlign="center">
                      {pauseError}
                    </Body>
                  )}
                  <Button
                    primary
                    disabled={!validDate}
                    width="100%"
                    type="submit"
                    label="Submit"
                  />
                </Fragment>
              )}
            </form>
          </Box>
        </Modal>
      )}
      {!!pauseDate ? (
        <Box>
          <Paragraph mb={2}>
            Pause scheduled to start at:{" "}
            {moment(pauseDate).format("MM/DD/YYYY")}
          </Paragraph>
          <Button
            secondary
            bgColor="white"
            onClick={() => deletePause(membershipId)}
            width={[1, 1, 1]}
            mb={2}
          >{`${pauseIsBeforeToday ? "Stop" : "Cancel"} Pause`}</Button>
        </Box>
      ) : (
        <Fragment>
          <Button
            secondary
            bgColor="white"
            disabled={isPaused || hasAlreadyPaused}
            width={[1, 1, 1]}
            onClick={() => setPauseModal(true)}
            mb={2}
          >
            Pause
          </Button>
        </Fragment>
      )}
    </Fragment>
  );
};

export default connect(
  (state) => {
    const { playerData } = getPlayerAndTeamDataFromState(state);
    const currentJourney = getCurrentJourneyFromState(state);
    const pauseValue = playerData?.get("paused");
    return {
      pauseDate: pauseValue
        ? moment.utc(pauseValue, "YYYY-MM-DDTHH:mm:ssZ")
        : null,
      membershipId: playerData.get("community_membership_id"),
      isPaused: currentJourney?.get("paused"),
      hasAlreadyPaused: !!currentJourney
        .get("simpleJourney")
        .filter((d) => d.get("paused") === true).size,
      endDate:
        currentJourney &&
        moment(currentJourney.get("end"), "MM/DD/YYYY").subtract(1, "days"),
    };
  },
  {
    setPauseDate,
    deletePause,
  }
)(SchedulePause);
