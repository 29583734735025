import React from "react";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";

import { Flex, Box } from "../Utility";
import nonMemberIcon from "../../img/svg/icons/non-member.svg";
import memberIcon from "../../img/svg/icons/member.svg";
import MembershipConfirmationModal from "./MembershipConfirmationModal";
import { Button, Header } from "../../Storybook";

const Membership = ({ customerData }) => {
  return (
    <Box mb={3}>
      {customerData ? (
        <Flex alignItems="center" justifyContent="flex-start">
          <Box mt={1}>
            <img
              width="22px"
              src={memberIcon}
              alt="Mochi Game logo- a white outlined drawing of a mochi with a transparent background"
            />
          </Box>
          <Header h4 fontWeight={600} ml={2}>
            Member since {moment(customerData.get("created")).fromNow()}
          </Header>
          {moment().diff(customerData.get("created")) < 600000 && (
            <MembershipConfirmationModal />
          )}
        </Flex>
      ) : (
        <Box>
          <Flex alignItems="center" justifyContent="flex-start">
            <img
              width="24px"
              src={nonMemberIcon}
              alt="A brown and green grasshopper icon for non-members"
            />
            <Header h4 fontWeight={600} ml={2}>
              Not a member
            </Header>
          </Flex>
          <Flex justifyContent="flex-start">
            <RouterLink to="/membership-info">
              <Button textButton arrow textAlign="left" mt={-3}>
                Learn how to become a member
              </Button>
            </RouterLink>
          </Flex>
        </Box>
      )}
    </Box>
  );
};
export default Membership;
