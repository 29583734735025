import React from "react";

import { RoutingMessage } from "../components/Utility";

export const Error404 = () => (
  <RoutingMessage
    title="404"
    message="Hmm, this link doesn’t seem to lead anywhere. Check your link & try again."
  />
);

export default Error404;
