import styled from "styled-components";
import {
  space,
  color,
  layout,
  flexbox,
  grid,
  typography,
  border,
  system,
  position,
} from "styled-system";

const GridContainer = styled.div(
  {
    minWidth: 0,
  },
  space,
  color,
  layout,
  flexbox,
  grid,
  typography,
  border,
  position,
  system({
    gridColumnStart: true,
  })
);

const GridItem = ({ spanArray, startArray, children, ...props }) => {
  const gridColumnArray = spanArray.map((span, i) => {
    const startColumn = startArray[i] ? `${startArray[i]}/` : "";
    return `${startColumn} span ${span}`;
  });

  return (
    <GridContainer
      justifyContent="center"
      gridColumn={gridColumnArray}
      {...props}
    >
      {children}
    </GridContainer>
  );
};

export default GridItem;
