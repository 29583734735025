import React, { useState } from "react";
import { connect } from "react-redux";

import useInput from "./utils/useInput";
import { setNewEmailAddress } from "../../containers/Community/actions";
import * as fieldValidators from "./utils/fieldValidators";
import { Space } from "../Utility";
import { Input, Button, Header, Paragraph } from "../../Storybook";
import {
  contactSupportError,
  noPermissionError,
  validEmailAddressError,
} from "./utils/formMessageStrings";

export const NewPassword = ({ setNewEmailAddress }) => {
  const {
    value: email,
    bind: bindEmail,
    valid: validEmail,
    active: activeEmail,
  } = useInput("", fieldValidators.emailField, true);

  const [submited, setSubmited] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setSubmited(true);

    try {
      setError("");
      const submitData = {
        email,
      };
      await setNewEmailAddress(submitData);
      setSuccess("Your email address has been updated");
    } catch (e) {
      if (e.detail && e.detail === noPermissionError) {
        setError(contactSupportError);
      }
    }
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <Header h3>Change Email Address</Header>
      <Space mb={20} />
      {success.length > 0 && (
        <Paragraph color="darkPositive500" mb={3}>
          {success}
        </Paragraph>
      )}
      <Input
        type="email"
        placeholder="Your Email Address"
        submited={submited}
        textLabel="New E-mail Address"
        activeInput={activeEmail}
        error={validEmailAddressError}
        {...bindEmail}
      />
      {error.length > 0 ? (
        <Paragraph textAlign="center" color="darkNegative500" mb={3}>
          {error}
        </Paragraph>
      ) : (
        <Space mt={3} />
      )}
      <Button primary disabled={!validEmail} type="submit">
        Submit
      </Button>
    </form>
  );
};

export default connect((state) => ({}), {
  setNewEmailAddress,
})(NewPassword);
