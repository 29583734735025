import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import TopUpStep from "./TopUpStep";
import TopUpForPlayer from "./TopUpForPlayer";
import ProfileCard from "../Profile/ProfileCard";
import PlayerMochi from "../Mochi/PlayerMochi";
import { GridBox, Header } from "../../Storybook";
import { Body, Box } from "../Utility";
import { getPlayerAndTeamDataFromState } from "../../containers/Community/stateGetterHelpers";
import { getExternalPlayerInfo } from "../../containers/Community/actions";

export const TopUp = ({ forAnotherPlayer = false, getExternalPlayerInfo }) => {
  //TODO: mock playermochi values during top up selections
  const headerText = forAnotherPlayer ? "Bond a Mochi" : "Bond with Mochi";
  const bodyText =
    "Bonding a Mochi makes it stronger, and more resiliant to slashes from QwwQ. Having a higher bond also earns more Mochi Tokens for each Pearl of Wisdom submitted.";
  const { playerId, communityId } = useParams();
  const { playerData } = useSelector((state) => getPlayerAndTeamDataFromState(state, playerId));
  useEffect(() => {
    if (forAnotherPlayer && !playerData?.get("user_id"))
      getExternalPlayerInfo(Number(playerId), Number(communityId));
  });

  if (!playerData?.get("user_id")) return <></>;
  return (
    <>
      <GridBox span={[4, 4, 6]} start={[1, 1, 1]}>
        <Header h1 textAlign="left">
          {headerText}
        </Header>
        <Body>{bodyText}</Body>
        {forAnotherPlayer ? (
          <TopUpForPlayer playerToTopUpData={playerData} />
        ) : (
          <TopUpStep playerData={playerData} />
        )}
      </GridBox>
      <GridBox span={[4, 4, 6]} start={[1, 5, 7]}>
        <ProfileCard>
          <Box p={5}>
            <PlayerMochi playerId={playerData.get("user_id")} />
          </Box>
        </ProfileCard>
      </GridBox>
    </>
  );
};

export default connect((state, props) => ({}), { getExternalPlayerInfo })(TopUp);
