import React, { useState } from "react";
import { connect } from "react-redux";

import useInput from "./utils/useInput";
import * as fieldValidators from "./utils/fieldValidators";
import { inviteEmail } from "../../containers/Community/actions";
import { Input, Button, Paragraph } from "../../Storybook";
import Space from "../Utility/Space";
import { validEmailAddressError } from "./utils/formMessageStrings";

export const TeamInviteForm = ({ teamId, inviteEmail, afterInvite }) => {
  const {
    value: email,
    bind: bindEmail,
    valid: validEmail,
    active: activeEmail,
  } = useInput("", fieldValidators.emailField, true);
  const [success, setSuccess] = useState("");
  const [submited, setSubmited] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setSubmited(true);
    if (validEmail) {
      try {
        setSuccess("Your invite has been sent");
        setError("");
        await inviteEmail(teamId, email.toLowerCase());
        setTimeout(afterInvite, 2000);
      } catch (e) {
        setSuccess("");
        setError(e);
      }
    }
  };

  return (
    <form data-testid="inviteForm" onSubmit={(e) => handleSubmit(e)}>
      {success.length > 0 && (
        <Paragraph color="darkPositive500" mb={3}>
          {success}
        </Paragraph>
      )}
      <Input
        type="email"
        placeholder="Player Email Address"
        textLabel="Player E-mail Address"
        submited={submited}
        activeInput={activeEmail}
        error={validEmailAddressError}
        {...bindEmail}
      />
      {error.length > 0 ? (
        <Paragraph textAlign="center" color="darkNegative500" mb={3}>
          {error}
        </Paragraph>
      ) : (
        <Space mt={3} />
      )}
      <Button primary disabled={!validEmail} width="100%" type="submit">
        Invite Friend
      </Button>
    </form>
  );
};

export default connect((state) => ({}), {
  inviteEmail,
})(TeamInviteForm);
