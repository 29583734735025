import { FREE_USER_LOAD, FREE_TEAM_LOAD } from "../../constants/actionTypes";

export const setFreeUsers = (data, page, communityId) => ({
  type: FREE_USER_LOAD,
  data,
  page,
  communityId,
});

export const setFreeTeams = (data, page, communityId) => ({
  type: FREE_TEAM_LOAD,
  data,
  page,
  communityId,
});
