import React from "react";
import "./mochiBotIntroAni.css";

const MochiBotIntroAni = () => (
  <div className="mochibot-intro">
    <div className="mochibot-shape">
      <div className="server-list">
        <div id="s1" className="server">
          <img
            src="https://res.cloudinary.com/dh1l1uwde/image/upload/v1642692985/Screen_Shot_2022-01-20_at_10.36.22_AM_pwhnfn.png"
            alt=""
          />
          <div className="notifs">
            <div className="pill">
              <div className="end left"></div>
              <div className="center"></div>
              <div className="end right"></div>
            </div>
            <div className="p-list">
              <p id="msg01" className="msg-set-1">
                1
              </p>
              <p id="msg02" className="msg-set-1">
                7
              </p>
              <p id="msg03" className="msg-set-1">
                8
              </p>
              <p id="msg04" className="msg-set-1">
                9
              </p>
              <p id="msg05" className="notif-2digit">
                21
              </p>
              <p id="msg06" className="notif-2digit">
                48
              </p>
              <p id="msg07" className="notif-2digit">
                96
              </p>
            </div>
          </div>
        </div>
        <div id="s2" className="server">
          <img
            src="https://res.cloudinary.com/dh1l1uwde/image/upload/v1642692998/Screen_Shot_2022-01-20_at_10.36.35_AM_tkgr2b.png"
            alt=""
          />
          <div className="notifs">
            <div className="pill">
              <div className="end left"></div>
              <div className="center"></div>
              <div className="end right"></div>
            </div>
            <div className="p-list">
              <p id="msg08" className="msg-set-2">
                1
              </p>
              <p id="msg09" className="msg-set-2">
                4
              </p>
              <p id="msg10" className="msg-set-2">
                8
              </p>
              <p id="msg11" className="msg-set-2">
                9
              </p>
              <p id="msg12" className="notif-2digit">
                13
              </p>
              <p id="msg13" className="notif-2digit">
                19
              </p>
              <p id="msg14" className="notif-2digit">
                42
              </p>
            </div>
          </div>
        </div>
        <div id="s3" className="server">
          <img
            src="https://res.cloudinary.com/dh1l1uwde/image/upload/v1642693020/Screen_Shot_2022-01-20_at_10.36.57_AM_bej611.png"
            alt=""
          />
          <div className="notifs">
            <div className="pill">
              <div className="end left"></div>
              <div className="center"></div>
              <div className="end right"></div>
            </div>
            <div className="p-list">
              <p id="msg15" className="msg-set-3">
                1
              </p>
              <p id="msg16" className="msg-set-3">
                3
              </p>
              <p id="msg17" className="msg-set-3">
                7
              </p>
              <p id="msg18" className="notif-2digit">
                24
              </p>
              <p id="msg19" className="notif-2digit">
                30
              </p>
              <p id="msg20" className="notif-2digit">
                61
              </p>
              <p id="msg21" className="notif-2digit">
                73
              </p>
              <p id="msg22" className="notif-2digit">
                88
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bot-intro-null">
        <img
          src="https://res.cloudinary.com/dh1l1uwde/image/upload/v1642694579/null_o-removebg-preview_copy_b6t14h.png"
          alt=""
        />
        <div className="bot-intro-message">
          <div className="bot-intro-title">
            <p className="bot-intro-sender">N_ll</p>
            <div className="timestamp">just now</div>
          </div>
          <p className="nullMessage">Hi 4nubis, how’s today going?</p>
        </div>
      </div>
    </div>
  </div>
);

export default MochiBotIntroAni;
