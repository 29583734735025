import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { PropTypes } from "prop-types";

import { Box } from "../Utility";
import MochiCharacter from "./MochiCharacter";
import WarningIndicator from "./WarningIndicator";
import HealthMeter from "./HealthMeter";
import { calculateMochiHealth } from "../../utils/mochiHelpers";

export const MochiHealth = ({
  isProfile,
  activeCommunityId,
  isAnotherPlayersProfile,
  minimumStake,
  weiBalance,
  slashes,
  gameModeId,
  mochiColor,
  journeyCount,
  originalGameMode,
  currentGameMode,
  isNewUser,
  isActivated,
}) => {
  const [showWarning, setShowWarning] = useState(false);
  const [meterColor, setMeterColor] = useState();
  const [bondAmount, setBondAmount] = useState();
  const [minimumBondColor, setMinimumBondColor] = useState();

  const playerHasBeenDowngraded =
    !!originalGameMode &&
    originalGameMode.get("minimum_stake") > currentGameMode?.get("minimum_stake");

  const warningMessage = playerHasBeenDowngraded
    ? `You've dropped from ${originalGameMode.get("name")} to ${currentGameMode.get(
        "name"
      )}. Bond more to firm up!`
    : journeyCount <= 1 && !weiBalance
    ? "Bond with Mochi to earn tokens."
    : "Increase your bond to keep earning tokens";

  useEffect(() => {
    const { showBondWarning, meterColor, bondPercent, minimumBondColor } = calculateMochiHealth(
      minimumStake,
      weiBalance,
      slashes,
      journeyCount,
      gameModeId,
      playerHasBeenDowngraded,
      isNewUser
    );
    setShowWarning(showBondWarning);
    setMeterColor(meterColor);
    setBondAmount(Number(bondPercent));
    setMinimumBondColor(minimumBondColor);
  }, [
    minimumStake,
    weiBalance,
    slashes,
    journeyCount,
    gameModeId,
    playerHasBeenDowngraded,
    isNewUser,
  ]);

  return (
    <Box alignContent="center" id="mochi-health">
      <Box alignContent="space-between" data-testid="health-indicator">
        {isProfile && !isAnotherPlayersProfile && showWarning && (
          <Box style={{ position: "relative", zIndex: 2 }}>
            <RouterLink to={`/${activeCommunityId}/bond/top-up`}>
              <WarningIndicator warningMessage={warningMessage} />
            </RouterLink>
          </Box>
        )}
        {!!meterColor && (
          <Box opacity={!isActivated ? 0 : 1} data-testid="health-meter">
            <HealthMeter
              bondPercent={bondAmount}
              meterColor={meterColor}
              gameModeId={gameModeId || 0}
              minimumBondColor={minimumBondColor}
            />
          </Box>
        )}
      </Box>
      <Box mb={3} marginTop={!!meterColor ? "-40%" : "15.5%"}>
        <MochiCharacter mochiColor={mochiColor} isAnotherPlayersProfile={isAnotherPlayersProfile} />
      </Box>
    </Box>
  );
};

MochiHealth.propTypes = {
  isProfile: PropTypes.bool,
  activeCommunityId: PropTypes.number,
  isAnotherPlayersProfile: PropTypes.bool,
  minimumStake: PropTypes.number,
  weiBalance: PropTypes.number,
  slashes: PropTypes.number,
  gameModeId: PropTypes.number,
  mochiColor: PropTypes.string,
  journeyCount: PropTypes.number,
  playerHasBeenDowngraded: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  isNewUser: PropTypes.bool,
  isActivated: PropTypes.bool,
};

MochiHealth.defaultProps = {
  isProfile: false,
  activeCommunityId: null,
  isAnotherPlayersProfile: false,
  minimumStake: null,
  weiBalance: null,
  slashes: null,
  gameModeId: 0,
  mochiColor: null,
  journeyCount: null,
  playerHasBeenDowngraded: null,
  isNewUser: true,
  isActivated: false,
};

export default MochiHealth;
