import React from "react";

import { GridContainer, Header, Body } from "../components/Utility";
import { GridBox } from "../Storybook";

export const PrivacyPolicyPage = (props) => {
  return (
    <GridContainer>
      <GridBox span={[4, 6, 6, 6]} start={[1, 2, 4, 4]} mb={4}>
        <Header h1 mt={4} mb={4}>
          Terms & Conditions
        </Header>
        <Body mt="0">
          BY USING THIS SITE, YOU AGREE TO THESE TERMS OF USE; IF YOU DO NOT
          AGREE, DO NOT USE THIS SITE
        </Body>
        <Header h3 mt={4} mb={4}>
          Use of your Personal Information
        </Header>
        <Body mt="0">
          LIQHTWORKS, INC. collects and uses your personal information to
          operate and deliver the services you have requested.
        </Body>
        <Body mt="0">
          LIQHTWORKS, INC. may also use your personally identifiable information
          to inform you of other products or services available from LIQHTWORKS,
          INC. and its affiliates.
        </Body>
        <Header h3 mt={4} mb={4}>
          Sharing Information with Third Parties
        </Header>
        <Body mt="0">
          LIQHTWORKS, INC. does not sell, rent or lease its customer lists to
          third parties.
        </Body>
        <Body mt="0">
          LIQHTWORKS, INC. may, from time to time, contact you on behalf of
          external business partners about a particular offering that may be of
          interest to you. In those cases, your unique personally identifiable
          information (e-mail, name, address, telephone number) is transferred
          to the third party. LIQHTWORKS, INC. may share data with trusted
          partners to help perform statistical analysis, send you email or
          postal mail, provide customer support, or arrange for deliveries. All
          such third parties are prohibited from using your personal information
          except to provide these services to LIQHTWORKS, INC., and they are
          required to maintain the confidentiality of your information.
        </Body>
        <Body mt="0">
          LIQHTWORKS, INC. may disclose your personal information, without
          notice, if required to do so by law or in the good faith belief that
          such action is necessary to: (a) conform to the edicts of the law or
          comply with legal process served on LIQHTWORKS, INC. or the site; (b)
          protect and defend the rights or property of LIQHTWORKS, INC.; and/or
          (c) act under exigent circumstances to protect the personal safety of
          users of LIQHTWORKS, INC., or the public.
        </Body>
        <Header h3 mt={4} mb={4}>
          Opt-Out Disclosure of Personal Information to Third Parties
        </Header>
        <Body mt="0">
          In connection with any personal information we may disclose to a third
          party for a business purpose, you have the right to know:
          <ul>
            <li>
              The categories of personal information that we disclosed about you
              for a business purpose.
            </li>
          </ul>
          You have the right under the California Consumer Privacy Act of 2018
          (CCPA) and certain other privacy and data protection laws, as
          applicable, to opt-out of the disclosure of your personal information.
          If you exercise your right to opt-out of the disclosure of your
          personal information, we will refrain from disclosing your personal
          information, unless you subsequently provide express authorization for
          the disclosure of your personal information. To opt-out of the
          disclosure of your personal information, please contact
          contact@mochi.game.
        </Body>
        <Header h3 mt={4} mb={4}>
          Children Under Thirteen
        </Header>
        <Body mt="0">
          LIQHTWORKS, INC. does not knowingly collect personally identifiable
          information from children under the age of thirteen. If you are under
          the age of thirteen, you must ask your parent or guardian for
          permission to use this application.
        </Body>
        <Header h3 mt={4} mb={4}>
          Cancellation/Refund Policy
        </Header>
        <Body mt="0">
          You may cancel your subscription at any time. Cancellations may
          qualify for a refund. Please contact us at contact@mochi.game with any
          questions.
        </Body>
        <Header h3 mt={4} mb={4}>
          Links to Third Party Sites/Third Party Services
        </Header>
        <Body mt="0">
          mochi.game may contain links to other websites ("Linked Sites"). The
          Linked Sites are not under the control of LIQHTWORKS, INC. and
          LIQHTWORKS, INC. is not responsible for the contents of any Linked
          Site, including without limitation any link contained in a Linked
          Site, or any changes or updates to a Linked Site. LIQHTWORKS, INC. is
          providing these links to you only as a convenience, and the inclusion
          of any link does not imply endorsement by LIQHTWORKS, INC. of the site
          or any association with its operators.
        </Body>
        <Body mt="0">
          Certain services made available via mochi.game are delivered by third
          party sites and organizations. By using any product, service or
          functionality originating from the mochi.game domain, you hereby
          acknowledge and consent that LIQHTWORKS, INC. may share such
          information and data with any third party with whom LIQHTWORKS, INC.
          has a contractual relationship to provide the requested product,
          service or functionality on behalf of mochi.game users and customers.
        </Body>
        <Header h3 mt={4} mb={4}>
          No Unlawful or Prohibited Use/Intellectual Property
        </Header>
        <Body mt="0">
          You are granted a non-exclusive, non-transferable, revocable license
          to access and use mochi.game strictly in accordance with these terms
          of use. As a condition of your use of the Site, you warrant to
          LIQHTWORKS, INC. that you will not use the Site for any purpose that
          is unlawful or prohibited by these Terms. You may not use the Site in
          any manner which could damage, disable, overburden, or impair the Site
          or interfere with any other party's use and enjoyment of the Site. You
          may not obtain or attempt to obtain any materials or information
          through any means not intentionally made available or provided for
          through the Site.
        </Body>
        <Body mt="0">
          All content included as part of the Service, such as text, graphics,
          logos, images, as well as the compilation thereof, and any software
          used on the Site, is the property of LIQHTWORKS, INC. or its suppliers
          and protected by copyright and other laws that protect intellectual
          property and proprietary rights. You agree to observe and abide by all
          copyright and other proprietary notices, legends or other restrictions
          contained in any such content and will not make any changes thereto.
        </Body>
        <Body mt="0">
          You will not modify, publish, transmit, reverse engineer, participate
          in the transfer or sale, create derivative works, or in any way
          exploit any of the content, in whole or in part, found on the Site.
          LIQHTWORKS, INC. content is not for resale. Your use of the Site does
          not entitle you to make any unauthorized use of any protected content,
          and in particular you will not delete or alter any proprietary rights
          or attribution notices in any content. You will use protected content
          solely for your personal use, and will make no other use of the
          content without the express written permission of LIQHTWORKS, INC. and
          the copyright owner. You agree that you do not acquire any ownership
          rights in any protected content. We do not grant you any licenses,
          express or implied, to the intellectual property of LIQHTWORKS, INC.
          or our licensors except as expressly authorized by these Terms.
        </Body>
        <Header h3 mt={4} mb={4}>
          Use of Communication Services
        </Header>
        <Body mt="0">
          The Site may contain bulletin board services, chat areas, news groups,
          forums, communities, personal web pages, calendars, and/or other
          message or communication facilities designed to enable you to
          communicate with the public at large or with a group (collectively,
          "Communication Services"). You agree to use the Communication Services
          only to post, send and receive messages and material that are proper
          and related to the particular Communication Service.
        </Body>
        <Body mt="0">
          By way of example, and not as a limitation, you agree that when using
          a Communication Service, you will not: defame, abuse, harass, stalk,
          threaten or otherwise violate the legal rights (such as rights of
          privacy and publicity) of others; publish, post, upload, distribute or
          disseminate any inappropriate, profane, defamatory, infringing,
          obscene, indecent or unlawful topic, name, material or information;
          upload files that contain software or other material protected by
          intellectual property laws (or by rights of privacy of publicity)
          unless you own or control the rights thereto or have received all
          necessary consents; upload files that contain viruses, corrupted
          files, or any other similar software or programs that may damage the
          operation of another's computer; advertise or offer to sell or buy any
          goods or services for any business purpose, unless such Communication
          Service specifically allows such messages; conduct or forward surveys,
          contests, pyramid schemes or chain letters; download any file posted
          by another user of a Communication Service that you know, or
          reasonably should know, cannot be legally distributed in such manner;
          falsify or delete any author attributions, legal or other proper
          notices or proprietary designations or labels of the origin or source
          of software or other material contained in a file that is uploaded;
          restrict or inhibit any other user from using and enjoying the
          Communication Services; violate any code of conduct or other
          guidelines which may be applicable for any particular Communication
          Service; harvest or otherwise collect information about others,
          including e-mail addresses, without their consent; violate any
          applicable laws or regulations.
        </Body>
        <Body mt="0">
          LIQHTWORKS, INC. has no obligation to monitor the Communication
          Services. However, LIQHTWORKS, INC. reserves the right to review
          materials posted to a Communication Service and to remove any
          materials in its sole discretion. LIQHTWORKS, INC. reserves the right
          to terminate your access to any or all of the Communication Services
          at any time without notice for any reason whatsoever.
        </Body>
        <Body mt="0">
          LIQHTWORKS, INC. reserves the right at all times to disclose any
          information as necessary to satisfy any applicable law, regulation,
          legal process or governmental request, or to edit, refuse to post or
          to remove any information or materials, in whole or in part, in
          LIQHTWORKS, INC.'s sole discretion.
        </Body>
        <Body mt="0">
          Always use caution when giving out any personally identifying
          information about yourself or your children in any Communication
          Service. LIQHTWORKS, INC. does not control or endorse the content,
          messages or information found in any Communication Service and,
          therefore, LIQHTWORKS, INC. specifically disclaims any liability with
          regard to the Communication Services and any actions resulting from
          your participation in any Communication Service. Managers and hosts
          are not authorized LIQHTWORKS, INC. spokespersons, and their views do
          not necessarily reflect those of LIQHTWORKS, INC.
        </Body>
        <Body mt="0">
          Materials uploaded to a Communication Service may be subject to posted
          limitations on usage, reproduction and/or dissemination. You are
          responsible for adhering to such limitations if you upload the
          materials.
        </Body>
        <Header h3 mt={4} mb={4}>
          Materials Provided to mochi.game or Posted on Any LIQHTWORKS, INC. Web
          Page
        </Header>
        <Body mt="0">
          LIQHTWORKS, INC. does not claim ownership of the materials you provide
          to mochi.game
        </Body>
        <Body mt="0">
          (including feedback and suggestions) or post, upload, input or submit
          to any LIQHTWORKS, INC. Site or our associated services (collectively
          "Submissions"). However, by posting, uploading, inputting, providing
          or submitting your Submission you are granting LIQHTWORKS, INC., our
          affiliated companies and necessary sublicensees permission to use your
          Submission in connection with the operation of their Internet
          businesses including, without limitation, the rights to: copy,
          distribute, transmit, publicly display, publicly perform, reproduce,
          edit, translate and reformat your Submission; and to publish your name
          in connection with your Submission.
        </Body>
        <Body mt="0">
          No compensation will be paid with respect to the use of your
          Submission, as provided herein. LIQHTWORKS, INC. is under no
          obligation to post or use any Submission you may provide and may
          remove any Submission at any time in LIQHTWORKS, INC.'s sole
          discretion.
        </Body>
        <Body mt="0">
          By posting, uploading, inputting, providing or submitting your
          Submission you warrant and represent that you own or otherwise control
          all of the rights to your Submission as described in this section
          including, without limitation, all the rights necessary for you to
          provide, post, upload, input or submit the Submissions.
        </Body>
        <Header h3 mt={4} mb={4}>
          International Users
        </Header>
        <Body mt="0">
          The Service is controlled, operated and administered by LIQHTWORKS,
          INC. from our offices within the USA. If you access the Service from a
          location outside the USA, you are responsible for compliance with all
          local laws. You agree that you will not use the LIQHTWORKS, INC.
          Content accessed through mochi.game in any country or in any manner
          prohibited by any applicable laws, restrictions or regulations.
        </Body>
        <Header h3 mt={4} mb={4}>
          Indemnification
        </Header>
        <Body mt="0">
          You agree to indemnify, defend and hold harmless LIQHTWORKS, INC., its
          officers, directors, employees, agents and third parties, for any
          losses, costs, liabilities and expenses (including reasonable
          attorney's fees) relating to or arising out of your use of or
          inability to use the Site or services, any user postings made by you,
          your violation of any terms of this Agreement or your violation of any
          rights of a third party, or your violation of any applicable laws,
          rules or regulations. LIQHTWORKS, INC. reserves the right, at its own
          cost, to assume the exclusive defense and control of any matter
          otherwise subject to indemnification by you, in which event you will
          fully cooperate with LIQHTWORKS, INC. in asserting any available
          defenses.
        </Body>
        <Header h3 mt={4} mb={4}>
          Arbitration
        </Header>
        <Body mt="0">
          In the event the parties are not able to resolve any dispute between
          them arising out of or concerning these Terms and Conditions, or any
          provisions hereof, whether in contract, tort, or otherwise at law or
          in equity for damages or any other relief, then such dispute shall be
          resolved only by final and binding arbitration pursuant to the Federal
          Arbitration Act, conducted by a single neutral arbitrator and
          administered by the American Arbitration Association, or a similar
          arbitration service selected by the parties, in a location mutually
          agreed upon by the parties. The arbitrator's award shall be final, and
          judgment may be entered upon it in any court having jurisdiction. In
          the event that any legal or equitable action, proceeding or
          arbitration arises out of or concerns these Terms and Conditions, the
          prevailing party shall be entitled to recover its costs and reasonable
          attorney's fees. The parties agree to arbitrate all disputes and
          claims in regards to these Terms and
        </Body>
        <Body mt="0">
          Conditions or any disputes arising as a result of these Terms and
          Conditions, whether directly or indirectly, including Tort claims that
          are a result of these Terms and Conditions. The parties agree that the
          Federal Arbitration Act governs the interpretation and enforcement of
          this provision. The entire dispute, including the scope and
          enforceability of this arbitration provision shall be determined by
          the Arbitrator. This arbitration provision shall survive the
          termination of these Terms and Conditions.
        </Body>
        <Header h3 mt={4} mb={4}>
          Class Action Waiver
        </Header>
        <Body mt="0">
          Any arbitration under these Terms and Conditions will take place on an
          individual basis; class arbitrations and
          class/representative/collective actions are not permitted. THE PARTIES
          AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN EACH'S
          INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
          PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE PROCEEDING, SUCH AS
          IN THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION AGAINST THE OTHER.
          Further, unless both you and LIQHTWORKS, INC. agree otherwise, the
          arbitrator may not consolidate more than one person's claims, and may
          not otherwise preside over any form of a representative or class
          proceeding.
        </Body>
        <Header h3 mt={4} mb={4}>
          Liability Disclaimer
        </Header>
        <Body mt="0">
          THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
          AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL
          ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN.
          LIQHTWORKS, INC. AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR
          CHANGES IN THE SITE AT ANY TIME.
        </Body>
        <Body mt="0">
          LIQHTWORKS, INC. AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT
          THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY
          OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS
          CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED
          BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES
          AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR
          CONDITION OF ANY KIND. LIQHTWORKS, INC. AND/OR ITS SUPPLIERS HEREBY
          DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS
          INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS,
          INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY,
          FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
        </Body>
        <Body mt="0">
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
          LIQHTWORKS, INC. AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT,
          INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY
          DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF
          USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE
          USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE
          SITE OR RELATED SERVICES, THE PROVISION OF OR
        </Body>
        <Body mt="0">
          FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE,
          PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR
          OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON
          CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF
          LIQHTWORKS, INC. OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE
          POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW
          THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
          INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU
          ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE
          TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING
          THE SITE.
        </Body>
        <Header h3 mt={4} mb={4}>
          Changes to Terms
        </Header>
        <Body mt="0">
          LIQHTWORKS, INC. reserves the right, in its sole discretion, to change
          the Terms under which mochi.game is offered. The most current version
          of the Terms will supersede all previous versions. LIQHTWORKS, INC.
          encourages you to periodically review the Terms to stay informed of
          our updates.
        </Body>
        <Header h3 mt={4} mb={4}>
          Contact Us
        </Header>
        <Body mt="0" mb={2}>
          LIQHTWORKS, INC. welcomes your questions or comments regarding this
          Statement of Privacy. If you believe that LIQHTWORKS, INC. has not
          adhered to this Statement, please contact LIQHTWORKS, INC. at:
        </Body>
        <Body>
          LIQHTWORKS, INC.
          <br />
          1933 N Bronson Ave.
          <br />
          Hollywood, California 90068
          <br />
          <br />
          Email Address: contact@mochi.game
          <br />
          Telephone number: 818-732-0954
          <br />
          Effective as of September 22, 2021
        </Body>
      </GridBox>
    </GridContainer>
  );
};

export default PrivacyPolicyPage;
