import React from "react";
import { Box } from "../Utility";
import Perk from "./Perk";

export const MembershipPerks = () => {
  return (
    <Box display="grid" gridGap="20px">
      <Perk
        emoji="&#128499;"
        name="Voting rights"
        description="Help make important club decisions"
        boxOne="True"
      />
      <Perk
        emoji="&#129297;"
        name="Mochi Tokens"
        description="Members earn Mochi Tokens with each Pearl of Wisdom they submit Pearls of Wisdom"
        boxOne="True"
        boxTwo="True"
      />
      <Perk
        emoji="&#128176;"
        name="Fund Returns"
        description="Members earn a portion of the Mochi Fund"
        boxOne="True"
      />
      <Perk
        emoji="&#128273;"
        name="Qrchive Access"
        description="Members can use their YubiKey to access private member files"
        boxOne="True"
      />
      <Perk
        emoji="&#128189;"
        name="Club Media Library"
        description="Members can access the Plex music & movie libraries & request new content"
        boxOne="True"
      />
      <Perk
        emoji="&#128085;"
        name="Exclusive Gear"
        description="Members can purchase limited edition goods from the Mochi Shop"
        boxOne="True"
      />
      <Perk
        emoji="&#127903;&#65039;"
        name="Event Guestlist"
        description="Members have 2 guestlist slots per event"
        boxOne="True"
        boxTwo="True"
      />
      <Perk
        emoji="&#128173;"
        name="Private Channels"
        description="Members can create and chat in private channels"
        boxOne="True"
      />
      <Perk
        emoji="&#128226;"
        name="Public Channels"
        description="Members have access to all public channels"
        boxOne="True"
        boxTwo="True"
      />
    </Box>
  );
};
