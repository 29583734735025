import styled from "styled-components";
import {
  space,
  color,
  layout,
  flexbox,
  grid,
  typography,
  border,
  position,
} from "styled-system";

const Box = styled.div(
  {
    minWidth: 0,
  },
  space,
  color,
  layout,
  flexbox,
  grid,
  typography,
  border,
  position
);

export default Box;
