import styled, { css } from "styled-components";
import { space, typography, color } from "styled-system";

const Header = styled.header`
  ${color}
  font-family: ${(props) => props.theme.fonts.headers}
  font-style: normal;
  font-weight: 700;
  font-size: ${(props) => props.theme.fontSize.fz4};
  line-height: 112.5%;
  color: ${(props) => props.color};
  overflow-wrap: break-word;

  ${(props) =>
    props.h1 &&
    css`
      font-size: ${(props) => props.theme.fontSize.fz5};
    `}

  ${(props) =>
    props.h2 &&
    css`
      font-size: ${(props) => props.theme.fontSize.fz4};
    `}

${(props) =>
    props.h3 &&
    css`
      font-size: ${(props) => props.theme.fontSize.fz3};
    `}

${(props) =>
    props.h4 &&
    css`
      font-size: ${(props) => props.theme.fontSize.fz2};
      letter-spacing: 0.01em;
    `}

${(props) =>
    props.h5 &&
    css`
      font-size: ${(props) => props.theme.fontSize.fz0};
      letter-spacing: 0.04em;
    `}

${(props) =>
    props.color &&
    css`
      color: ${(props) => props.theme.color[props.color]};
    `}

${(props) =>
    props.colored &&
    css`
      color: ${(props) => props.theme.color.colored.fg.primary};
    `}

//TODO: to deprecate
${(props) =>
    props.selected &&
    css`
      color: ${(props) => props.theme.color.darkBGSecondary};
    `}

${(props) =>
    props.shine &&
    css`
      @keyframes shine {
        from {
          background-position: 0% center;
        }
        to {
          background-position: 200% center;
        }
      }

      animation: shine 6s linear infinite;
      background: linear-gradient(
        90deg,
        #ab6bff,
        #9ad5f9 15%,
        #575df0 35%,
        #ab6bff 50%,
        #9ad5f9 65%,
        #575df0 85%,
        #ab6bff
      );
      background-size: 200% auto;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    `}

${space}
${typography}
`;

export default Header;
