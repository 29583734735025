import React, { useState } from "react";

import { Box, Flex } from "../Utility";
import { Paragraph } from "../../Storybook";
import nullo from "../../img/home-page/null_o.png";
import qw from "../../img/home-page/qw.png";
import ChatBox from "./ChatBox";

const DaoCarousel = () => {
  const [viewOne, setViewOne] = useState(true);

  const smallWhiteCircle = {
    width: "8px",
    height: "8px",
    borderRadius: "8px",
    margin: "0px 8px",
    backgroundColor: "#FFFFFF",
  };

  return (
    <Box
      style={{
        position: "relative",
        left: 0,
        width: "100%",
        zIndex: 1,
      }}
    >
      {viewOne ? (
        <Box style={{ position: "absolute", left: 0 }}>
          <img
            src={nullo}
            alt="null mochi"
            width="213px"
            style={{ position: "absolute", left: "86px" }}
          />
          <ChatBox color="#8000ff66" top="115.7px" left="0" width="268px">
            <Paragraph label="n_ll" color="#9E54C8" weight={700} size="fz2" />
            <Paragraph
              size="fz2"
              color="darkFGPrimary"
              label="Hey, I haven’t heard from you today, are you there?"
            />
          </ChatBox>
        </Box>
      ) : (
        <Box style={{ position: "absolute", left: 0 }}>
          <img
            src={qw}
            alt="qw mochi"
            style={{ position: "absolute", left: "71px", top: "-21.3px" }}
          />
          <ChatBox color="#C7295866" top="115.7px" left="0" width="268px">
            <Paragraph label="QwwQ" color="#C72958" weight="700" size="fz2" />
            <Paragraph size="fz2" color="darkFGPrimary">
              Thanks for feeding me! 😈
              <br></br>
              <span style={{ color: "#FF9900" }}>anubis </span>
              got slashed .01 ETH
            </Paragraph>
          </ChatBox>
        </Box>
      )}
      <ChatBox
        color="#8000ff66"
        top="209px"
        left="30px"
        width="90%"
        maxWidth="340px"
        onClick={() => setViewOne(!viewOne)}
      >
        <Paragraph
          label="N_LL"
          color="darkFGPrimary"
          size="fz3"
          weight={700}
          mb={2}
        />
        <Paragraph
          size="fz2"
          color="darkFGSecondary"
          label="N_ll is your guiding light as you journey with Mochi, reminding you to submit pearls of wisdom & rewarding you with tokens when you do."
        />
        <Flex justifyContent="center" p={3}>
          <div
            style={{
              ...smallWhiteCircle,
              opacity: viewOne ? 1 : 0.3,
            }}
          />
          <div
            style={{
              ...smallWhiteCircle,
              opacity: viewOne ? 0.3 : 1,
            }}
          />
        </Flex>
      </ChatBox>
    </Box>
  );
};

export default DaoCarousel;
