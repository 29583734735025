import styled, { css } from "styled-components";

const Select = styled.select.attrs((props) => ({
  type: props.type,
  onChange: props.onChange,
  placeholder: props.placeholder,
  value: props.value,
  submitted: props.submitted,
  error:
    props.needed &&
    ((!props.valid && props.touched) || (!props.valid && props.submited)),
  success: props.value && props.valid,
}))`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  border: 1px solid black;
  background: ${(props) => props.theme.color.grey};
  border-radius: 4px;
  padding: 6px 8px;
  color: ${(props) => props.theme.color.white};
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 18px;

  &:focus {
    outline: 0;
  }

  ${(props) =>
    props.error &&
    css`
      border-color: ${(props) => props.theme.color.red};
    `};
  ${(props) =>
    props.success &&
    css`
      border-color: ${(props) => props.theme.color.green};
    `}
`;

export default Select;

// ${props =>
//   (props.type === "checkbox"  || props.type === "radio") &&
//   css`
//     position: absolute;
//     opacity: 0;
//     width: auto;
//     z-index: -1;

//     & + label:after {
//       content: "";
//       display: table;
//       clear: both;
//     }

//     & + label:before {
//       content: '';
//       display: inline-block;
//       vertical-align: text-top;

//       margin-right: 16px;
//       width: 16px;
//       height: 16px;
//       border-radius: 2px;
//       background-color: transparent;
//       border: 1px solid ${props => props.theme.color.teal};
//     }

//     &:disabled + label,
//     &:disabled + label:before,
//     &[disabled] + label,
//     &[disabled] + label:before {
//       opacity: 0.4;
//       cursor: not-allowed;
//     }

//     & + label:before {
//       background-position: center center;
//       background-repeat: no-repeat;
//     }

//     &:checked {
//       & + label:before {
//         background-image: url('data:image/svg+xml;utf8,<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.60547 12.2783C6.09082 12.2783 6.45996 12.0938 6.71973 11.7109L13.084 2.01074C13.2686 1.73047 13.3438 1.46387 13.3438 1.21777C13.3438 0.554688 12.8379 0.0625 12.1543 0.0625C11.6895 0.0625 11.3955 0.233398 11.1152 0.677734L5.57812 9.40039L2.77539 5.96191C2.51562 5.64746 2.22852 5.51074 1.83203 5.51074C1.14844 5.51074 0.642578 6.00293 0.642578 6.67285C0.642578 6.97363 0.731445 7.2334 0.991211 7.52734L4.51855 11.7656C4.8125 12.1143 5.1543 12.2783 5.60547 12.2783Z" fill="white"/></svg>');
//         background-color: ${props => props.theme.color.green};
//         border-color: ${props => props.theme.color.green};
//       }
//     }
// `};
