import React from "react";

import { Flex, Text, Box } from "../Utility";

export const ClubIndicator = ({ clubInfo }) => {
  const nameCapitalized =
    clubInfo.get("name").charAt(0).toUpperCase() +
    clubInfo.get("name").slice(1);
  return (
    <Box mb={3}>
      <Flex alignItems="center" justifyContent="flex-start">
        <Text width="22px">{clubInfo.get("icon")}</Text>
        <Text icon small bold>
          {nameCapitalized} Clubhead
        </Text>
      </Flex>
    </Box>
  );
};

export default ClubIndicator;
