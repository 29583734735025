import React, { useCallback, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAccount, useDisconnect, useNetwork, useSignMessage, useSwitchNetwork } from "wagmi";
import { verifyMessage } from "viem";

import { EXPECTED_WEB3_NETWORK_NAME } from "../../constants/endpoints";
import { WEB3_NETWORK } from "../../constants/endpoints";
import { Button, Header, Modal, Paragraph } from "../../Storybook";
import { Box, Text } from "../Utility";
import { getAuthToken, authenticateUser } from "../../containers/Web3/actions";
import { logOutUser } from "../../containers/Auth/actions";
import { shortenAddress } from "../../utils/mochiHelpers";

const Web3AuthHandler = ({ authenticateUser, logOutUser }) => {
  let history = useHistory();
  const { address, connector, isConnected } = useAccount();
  const { switchNetwork } = useSwitchNetwork();
  const { disconnect } = useDisconnect();
  const { chain, chains } = useNetwork();
  const isConnectedMobile =
    connector?.name === "WalletConnectLegacy" || connector?.name === "Coinbase Wallet";
  const wrongNetwork = chain?.id !== chains[0]?.id;

  const [{ signatureRequested, signatureError, authedEthAddress }, setWeb3State] = useState({
    authedEthAddress: address,
    signatureRequested: !!address,
    signatureError: false,
  });
  const authenticated = useSelector((state) => state.auth.get("auth"));
  const activeCommunityId = useSelector((state) => state.community.get("activeCommunityId"));
  const token = window.localStorage.getItem("token");
  const communityInvitationId = useSelector((state) =>
    state.community.getIn(["communityInvitation", "id"])
  );
  const { signMessage } = useSignMessage({
    onSuccess: async (data, variables) => {
      // Verify signature when sign message succeeds
      const addressMatches = verifyMessage({
        address,
        message: variables.message,
        signature: data,
      });
      if (addressMatches) {
        setWeb3State({
          signatureRequested,
          authedEthAddress: address,
          signatureError,
        });
        let auth_token = variables.message.split(" ~ ")[1];
        const activeCommunityIdOrNull = activeCommunityId ? activeCommunityId : null;
        const authData = await authenticateUser(
          communityInvitationId,
          activeCommunityIdOrNull,
          data,
          auth_token
        );
        if (!communityInvitationId)
          history.push(`/${authData.communityId}/profile/${authData.userId}`);
      } else {
        disconnect();
        setWeb3State({
          signatureRequested: false,
          authedEthAddress,
          signatureError: "Please sign the message in order to authenticate",
        });
      }
    },
    onError(error, variables) {
      // console.log("error.msg", error.message); // "User rejected request"
      disconnect();
      setWeb3State({
        signatureRequested: false,
        authedEthAddress,
        signatureError: "Please sign the message in order to authenticate",
      });
    },
  });

  const throttle = useCallback((cb, delay = 500) => {
    let shouldWait = false;

    return (...args) => {
      if (shouldWait) return;

      cb(...args);
      shouldWait = true;
      setTimeout(() => {
        shouldWait = false;
      }, delay);
    };
  }, []);

  const authSign = useCallback(async () => {
    setWeb3State({
      signatureRequested: true,
      authedEthAddress,
      signatureError: false,
    });
    const message = await getAuthToken();
    signMessage({ message });
  }, [authedEthAddress, signMessage]);

  const logUserOut = () => {
    disconnect();
    // TODO: race condition between disconnect and logOutUser where logOutUser tends to win
    setTimeout(() => {
      logOutUser();
      setWeb3State({
        signatureRequested: false,
        authedEthAddress: null,
        signatureError,
      });
    }, 400);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledSignCall = useCallback(
    throttle(() => authSign()),
    []
  );

  if (isConnected) {
    if (!authenticated && !signatureRequested && !token) throttledSignCall();
  } else {
    if (authedEthAddress) {
      logOutUser();
      setWeb3State({
        authedEthAddress: null,
        signatureRequested: false,
        signatureError: null,
      });
    }
  }
  return (
    <>
      {!!signatureError?.length && (
        <Modal
          onClose={() =>
            setWeb3State({
              signatureRequested,
              authedEthAddress,
              signatureError: false,
            })
          }
        >
          <Header h3 mb={3} label="Something went wrong" />
          <Paragraph label={signatureError} />
        </Modal>
      )}
      {isConnected && authedEthAddress && address !== authedEthAddress && (
        <Modal noClose>
          <Header h3 mb={3} label="Address Mismatch" />
          <Paragraph mb={3}>
            <Text>
              We noticed that you switched to a different wallet address. Since you are
              authenticated with <Text bold>{shortenAddress(authedEthAddress)}</Text> previously,
              please switch back to that address or click the button below to log out.
            </Text>
          </Paragraph>
          <Button primary mb={2} onClick={() => logUserOut()} label="Log Out" width="100%" />
        </Modal>
      )}
      {isConnected && authenticated && wrongNetwork && (
        <Modal noClose>
          <Box textAlign="center">
            <Header data-testid="wrongNetworkError" mb={3}>
              Incorrect Network
            </Header>
            {isConnectedMobile ? (
              <>
                <Paragraph>
                  Since you are connected to a mobile wallet, you will need to change your network
                  to {EXPECTED_WEB3_NETWORK_NAME} on the mobile wallet app.
                </Paragraph>
                <Paragraph mb={0}>
                  Mobile wallets do not yet have support for switching networks automatically. Check
                  the documentation on:
                </Paragraph>
                <a
                  href="https://helpwithpenny.com/change-network-on-metamask/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Metamask
                </a>
                <br />
                <a
                  href="https://help.coinbase.com/en/wallet/getting-started/what-types-of-crypto-does-wallet-support"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Coinbase
                </a>
                <br />
                <a
                  href="https://learn.rainbow.me/manage-connections-and-networks"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Rainbow
                </a>
                <Paragraph>- or -</Paragraph>
                <Button
                  onClick={() => {
                    disconnect();
                    logOutUser();
                  }}
                >
                  Disconnect
                </Button>
              </>
            ) : (
              <>
                <Paragraph>
                  Your wallet is currently connected to the wrong web3 network. In order to
                  continue, click the button below to switch to {EXPECTED_WEB3_NETWORK_NAME}.
                </Paragraph>
                <Button mb={2} onClick={() => switchNetwork(WEB3_NETWORK)}>
                  Switch to {EXPECTED_WEB3_NETWORK_NAME}!
                </Button>
                <Button secondary onClick={() => logUserOut()} label="Log Out" width="100%" />
              </>
            )}
          </Box>
        </Modal>
      )}
    </>
  );
};

export default connect((state) => ({}), { authenticateUser, logOutUser })(Web3AuthHandler);
