import React, { useState, useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

import { Box, Flex, Notification, DropdownSelect } from "../../Utility";
import { Button, Header, Paragraph, Modal, Input } from "../../../Storybook";
import {
  notInJourneyHeader,
  notInJourneyMsgLeader,
  notInJourneyMsgNonLeader,
} from "./notificationMessages";
import { createJourney } from "../../../containers/Community/actions";
import { getPlayerAndTeamDataFromState } from "../../../containers/Community/stateGetterHelpers";

const dateSelection = ["Next Monday", "1 week", "2 weeks", "3 weeks"];

const NotInJourneyNotification = ({
  amLeader,
  teamId,
  isTeamPageNotification,
  createJourney,
  activeCommunityId,
  teamHasTwoOrMorePlayers,
  noPendingInvitations,
}) => {
  const [date, setDate] = useState(1);
  const [infoModal, setInfoModal] = useState("");
  const [readyToStart, setReadyToStart] = useState(false);

  useEffect(() => {
    if (noPendingInvitations && teamHasTwoOrMorePlayers) setReadyToStart(true);
  }, [noPendingInvitations, teamHasTwoOrMorePlayers]);

  const proposeJourneyOnDate = async (weeksUntilStart) => {
    try {
      await createJourney(
        activeCommunityId,
        moment()
          .startOf("isoWeek")
          .add(weeksUntilStart, "week")
          .format("MM/DD/YYYY"),
        teamId,
        false,
        false
      );
    } catch (e) {
      setInfoModal(
        "There was an issue proposing a new journey start date. Please try again or contact support if you experience further issues."
      );
    }
  };

  return (
    <Notification
      width={"100%"}
      bgColor={"darkWarning500"}
      alignContent={"flex-start"}
      data-testid="notInJourneyNotif"
    >
      {infoModal.length > 0 && (
        <Modal onClose={() => setInfoModal("")}>
          <Paragraph fontSize="fz2" label={infoModal} mt={2} />
        </Modal>
      )}
      <Box padding={(0, 10, 0, 10)}>
        <Header h3 colored mt={0} mb={2} label={notInJourneyHeader} />
        <Paragraph
          data-testid="notInJourneyMsg"
          colored
          mb={3}
          label={amLeader ? notInJourneyMsgLeader : notInJourneyMsgNonLeader}
        />
        {!teamHasTwoOrMorePlayers && (
          <Input
            type="checkbox"
            colored
            small
            checked={false}
            readOnly
            textLabel="Have at least one other player on your team"
          />
        )}
        {!noPendingInvitations && (
          <Input
            type="checkbox"
            colored
            small
            checked={false}
            readOnly
            textLabel="Remove the pending teammate invitations"
          />
        )}
      </Box>
      {amLeader &&
        (isTeamPageNotification ? (
          <Flex
            data-testid="proposeJourney"
            justifyContent={["flex-start", "flex-end", "flex-end"]}
            alignContent={"flex-start"}
            alignItems={"flex-start"}
            padding={(0, 10, 0, 10)}
            flexWrap={["wrap"]}
          >
            <DropdownSelect
              menuItems={dateSelection}
              selectAction={(ind) => setDate(ind + 1)}
              ariaLabel={"New Journey"}
              colored="darkYellow500"
              mb={[2, 0, 0]}
              restrictedWidth
              width="300px"
            />
            <Button
              data-testid="proposeJourneyBtn"
              primary
              large
              ml={[0, 3, 3]}
              mt={[2, 0, 0]}
              disabled={!readyToStart}
              colored={"darkWarning500"}
              label={"Propose Journey"}
              onClick={() => proposeJourneyOnDate(date)}
            />
          </Flex>
        ) : (
          <RouterLink to={`/${activeCommunityId}/team/${teamId}`}>
            <Button
              primary
              arrow
              colored={"darkWarning500"}
              label={"Open Team Page"}
            />
          </RouterLink>
        ))}
    </Notification>
  );
};

export default connect(
  (state) => {
    const { playerData, teamData } = getPlayerAndTeamDataFromState(state);
    return {
      teamId: playerData.get("team_id"),
      activeCommunityId: state.community.get("activeCommunityId"),
      noPendingInvitations: teamData?.get("pending_invitations")?.size === 0,
      teamHasTwoOrMorePlayers: teamData?.get("members")?.size >= 2,
    };
  },
  { createJourney }
)(NotInJourneyNotification);
