import { fromJS, List } from "immutable";
import moment from "moment";

import initialState from "./initialState";
import {
  AUTH_SUCCESS,
  LOG_OUT,
  EDIT_PERSON,
  TOP_UP,
  TOP_UP_FOR_PLAYER,
  WITHDRAW,
  SET_PAUSE_DATE,
  DELETE_PAUSE_DATE,
  GET_PERSONAL_STATS,
  GET_CUSTOMER_DATA,
  JOURNEY_INFO,
  TEAM_INFO,
  TEAM_INVITATION,
  TEAM_INVITATION_DELETE,
  TEAM_INVITATION_ACCEPT,
  TEAM_INVITATION_DECLINE,
  TEAM_START_EPOCH,
  TEAM_REMOVE_JOURNEY,
  REMOVE_MEMBERSHIP,
  REMOVE_TEAM_MEMBER,
  EXTERNAL_PLAYER_DATA_FETCH,
  EXTERNAL_PLAYER_STATS,
  TEAM_FETCH,
  ACCEPT_COMMUNITY_INVITATION,
  GET_COMMUNITY_INVITATION,
  COMMUNITY_INVITATION_FAIL,
  EDIT_COMMUNITY_MEMBERSHIP,
  TOGGLE_COMMUNITY,
  EXTERNAL_PLAYER_DATA_FETCH_FAILURE,
  GET_DISCORD_USER_AVATAR,
  GET_DISCORD_COMMUNITY_ICON,
  CONFIRM_ACCOUNT,
  GET_JOURNEY_DETAILS,
  APPLY_SHOP_ITEM,
  SUBMIT_CHECKIN,
  GET_COMMUNITY_INFO,
  JOIN_WAVE,
  LEAVE_WAVE,
  INSTALL_MOCHIBOT,
  START_WAVE,
  BUY_GAME_ITEM,
} from "../../constants/actionTypes";
import { journeyFormatter } from "../Utility/JourneyHelpers";
import { flattenUserCommunityData, createFlatCMExistingUser } from "./helpers";

const getLowestCommIdInActiveComms = (activeCommunities) => {
  let lowestId = 1000000000000;
  activeCommunities.forEach((c) => (lowestId = c.id < lowestId ? c.id : lowestId));
  return lowestId;
};

/* eslint-disable complexity */
const reducer = (state = fromJS(initialState), action) => {
  let authedUserId = state.get("authedUserId");
  const activeCommunityId = state.get("activeCommunityId");
  const authPlayerData = state.getIn(["players", activeCommunityId, authedUserId]);
  const currentTeamId = authPlayerData?.get("team_id");
  const communityMemId = authPlayerData?.get("community_membership_id");

  switch (action.type) {
    case AUTH_SUCCESS:
      authedUserId = action.data.user.user_id;

      let userData;

      if (!!action.data.user.active_communities.length) {
        userData = flattenUserCommunityData(action.data.user);
      } else {
        userData = {
          community_memberships: [
            {
              community_id: 1,
              eth_address: action.data.user.eth_address,
              user_id: authedUserId,
            },
          ],
        };
      }

      const newActiveCommunityId = action.data.activeCommunityIdOrNull
        ? action.data.activeCommunityIdOrNull
        : getLowestCommIdInActiveComms(action.data.user.active_communities);

      action.data.user.active_communities.forEach((c) => {
        state = state.mergeIn(["communityInfo", c.id], fromJS(c));
      });

      return state
        .update("players", (v) => {
          userData.community_memberships?.forEach(
            (cm) => (v = v.setIn([cm.community_id, cm.user_id], fromJS(cm)))
          );
          return v;
        })
        .update("players", (v) => {
          action.data.user.invitations?.forEach(
            (c) =>
              (v = v.updateIn(
                [c.community_id, authedUserId, "teamInvitations"],
                (v = fromJS([])) => (v = v.push(fromJS(c)))
              ))
          );
          return v;
        })
        .set("authedUserId", authedUserId)
        .set("activeCommunityId", parseInt(newActiveCommunityId));

    case CONFIRM_ACCOUNT:
      authedUserId = action.data.user.user_id;
      const flattenedUserData = flattenUserCommunityData(action.data.user);

      return state
        .set("authedUserId", authedUserId)
        .update("communityInfo", (v) => {
          action.data.user.active_communities.forEach((c) => (v = v.set(c.id, fromJS(c))));
          return v;
        })
        .update("players", (v) => {
          flattenedUserData.community_memberships.forEach(
            (cm) => (v = v.setIn([cm.community_id, cm.user_id], fromJS(cm)))
          );
          return v;
        })
        .update("players", (v) => {
          action.data.user.invitations.forEach(
            (c) =>
              (v = v.updateIn(
                [c.community_id, authedUserId, "teamInvitations"],
                (v = fromJS([])) => (v = v.push(fromJS(c)))
              ))
          );
          return v;
        });

    case GET_COMMUNITY_INFO:
      return state.setIn(["communityInfo", action.data.id], fromJS(action.data));
    case TOGGLE_COMMUNITY:
      return state.set("activeCommunityId", action.communityId);

    case INSTALL_MOCHIBOT:
      if (action.data.community_membership) {
        state = state.setIn(
          ["players", action.data.community.id, authedUserId],
          fromJS(
            createFlatCMExistingUser(
              action.data.community_membership,
              authedUserId,
              state.getIn(["players"])
            )
          )
        );
      }
      return state.setIn(
        ["communityInfo", action.data.community.id],
        fromJS(action.data.community)
      );
    case GET_DISCORD_USER_AVATAR:
      return state
        .setIn(
          ["players", action.communityId, action.userId, "details", "image_512"],
          action.data.avatar_url
        )
        .setIn(
          ["players", action.communityId, action.userId, "details", "display_name"],
          action.data.username
        );

    /////////////////////////////
    // Journey Actions
    /////////////////////////////
    case JOURNEY_INFO:
      // const currentJourney = getCurrentJourney(action.data.results) || null;

      return state.setIn(
        ["players", action.communityId, action.userId, "journeyData"],
        fromJS(action.data.results)
      );
    // .setIn(["players", action.communityId, action.userId, "current_journey"], currentJourney);

    case GET_JOURNEY_DETAILS:
      let journey = {
        start: moment(action.data.start, "MM/DD/YYYY"),
        end: moment(action.data.end, "MM/DD/YYYY"),
        id: action.data.id,
      };
      Object.keys(action.data.summary.player_totals).forEach((m) => {
        const formattedJourneyDetails = journeyFormatter(action.data, m);
        journey[Number(m)] = formattedJourneyDetails;
        if (!journey.end || !journey.start) {
          journey.start = moment(formattedJourneyDetails.start, "MM/DD/YYYY");
          journey.end = moment(formattedJourneyDetails.end, "MM/DD/YYYY");
          journey.id = formattedJourneyDetails.id;
        }
      });
      return state.setIn(
        ["journeys", Number(action.communityId), Number(action.data.id)],
        fromJS(journey)
      );

    /////////////////////////////
    // Journey Wave Actions
    /////////////////////////////
    case JOIN_WAVE:
      const authedUser = state.getIn(["players", activeCommunityId, authedUserId]).toJS();

      let newParticipant = {
        color: authedUser.color,
        current_goal: authedUser.current_goal,
        eth_address: authedUser.eth_address,
        game_mode: authedUser.game_mode,
        rank: authedUser.descriptors.rank,
        token_balance: authedUser.token_balance,
        user: authedUser.user_id,
        wei_balance: authedUser.wei_balance,
      };

      return state.updateIn(
        ["communityInfo", action.communityId, "active_wave", "participants"],
        (v) => v.push(fromJS(newParticipant))
      );

    case START_WAVE:
      return state.setIn(["communityInfo", action.communityId, "active_wave"], fromJS(action.data));

    case LEAVE_WAVE:
      let participantIndex;

      state
        .getIn(["communityInfo", action.communityId, "active_wave", "participants"])
        // eslint-disable-next-line array-callback-return
        .find((p, i) => {
          if (p.get("user") === authedUserId) {
            participantIndex = i;
            return p;
          }
        });

      return state.updateIn(
        ["communityInfo", action.communityId, "active_wave", "participants"],
        (v) => v.delete(participantIndex)
      );

    case LOG_OUT:
      return state
        .set("auth", false)
        .set("authedUserId", null)
        .set("activeCommunityId", null)
        .set("customerData", fromJS({}))
        .set("players", fromJS({}))
        .set("teams", fromJS({}))
        .set("communityInfo", fromJS({}))
        .set("communityInvitation", null);
    case EDIT_PERSON:
      const updatedUserData = flattenUserCommunityData(action.data).community_memberships.find(
        (c) => c.community_id === activeCommunityId
      );

      return state.mergeIn(["players", activeCommunityId, authedUserId], fromJS(updatedUserData));
    case TOP_UP:
      return state.updateIn(["players", activeCommunityId, authedUserId], (v) => {
        v = v
          .update("wei_balance", (v) => Number(v) + Number(action.data.weiAmount))
          .set("game_mode", action.data.game_mode);
        return v;
      });
    case TOP_UP_FOR_PLAYER:
      return state.updateIn(["players", activeCommunityId, action.data.userId], (v) => {
        v = v.update("wei_balance", (v) => Number(v) + Number(action.data.weiAmount));
        return v;
      });
    // TODO: not sufficient if the players balance in the contract is different than the backend
    case WITHDRAW:
      return state.updateIn(["players", activeCommunityId, authedUserId], (v) => {
        v = v
          .update("wei_balance", (v) => Number(v) - Number(action.data.weiAmountWithdrawn))
          .set("game_mode", action.data.game_mode);
        return v;
      });
    case SET_PAUSE_DATE:
      return state.setIn(
        ["players", activeCommunityId, authedUserId, "paused"],
        action.data.paused
      );
    case DELETE_PAUSE_DATE:
      return state.setIn(["players", activeCommunityId, authedUserId, "paused"], null);
    case GET_PERSONAL_STATS:
      return state.setIn(
        ["players", action.communityId, authedUserId, "stats"],
        fromJS(action.data)
      );
    case GET_CUSTOMER_DATA:
      const customerId = action.playerId;
      return state.setIn(
        ["players", activeCommunityId, customerId, "customerData"],
        fromJS(action.data)
      );

    ////////////////////////////
    // Community-based Actions
    /////////////////////////////

    case GET_COMMUNITY_INVITATION:
      return state
        .set("communityInvitation", fromJS(action.data))
        .set("activeCommunityId", action.data.community_details.id)
        .setIn(["communityInvitation", "valid"], true);
    case COMMUNITY_INVITATION_FAIL:
      return state.set(
        "communityInvitation",
        fromJS({
          valid: false,
          error: action.error,
        })
      );
    case ACCEPT_COMMUNITY_INVITATION:
      return state
        .setIn(
          ["players", action.communityId, authedUserId],
          fromJS(
            flattenUserCommunityData(action.data).community_memberships.find(
              (c) => c.community_id === action.communityId
            )
          )
        )
        .update("communityInfo", (v) => {
          action.data.active_communities.forEach((c) => (v = v.set(c.id, fromJS(c))));
          return v;
        })
        .setIn(["communityInvitation"], null);

    ////////////////////////////
    // Community-membership Actions
    /////////////////////////////

    case EDIT_COMMUNITY_MEMBERSHIP:
      return state
        .setIn(["players", activeCommunityId, authedUserId, "color"], action.data.color)
        .setIn(
          ["players", activeCommunityId, authedUserId, "community_memberships", communityMemId],
          fromJS(action.data)
        );

    ////////////////////////////
    // Discord Actions
    /////////////////////////////

    case GET_DISCORD_COMMUNITY_ICON:
      return state.setIn(
        ["communityInfo", action.communityId, "icon"],
        action.data.icon_url || null
      );

    /////////////////////////////
    // Team-based Actions
    /////////////////////////////
    case TEAM_INFO:
      action.data.members.forEach((m) => {
        const formattedMember = flattenUserCommunityData(m).community_memberships.find(
          (c) => c.community_id === action.data.community
        );
        if (formattedMember.user_id !== state.get("authedUserId")) {
          state = state.setIn(
            ["players", action.data.community, m.user_id],
            fromJS(formattedMember)
          );
        } else {
          state = state.setIn(
            ["players", action.data.community, m.user_id, "team_id"],
            action.data.team_id
          );
        }
      });

      const sortedJourneys = fromJS(action.data.journeys).sort((a, b) =>
        moment(b.get("start"), "MM/DD/YYYY").diff(moment(a.get("start"), "MM/DD/YYYY"))
      );

      return state
        .setIn(["teams", action.data.community, action.data.team_id], fromJS(action.data))
        .setIn(["teams", action.data.community, action.data.team_id, "journeys"], sortedJourneys)
        .updateIn(["teams", action.data.community, action.data.team_id, "members"], (v) => {
          // ensure members are formatted correctly
          return fromJS(
            action.data.members.map((member) =>
              flattenUserCommunityData(member).community_memberships.find(
                (c) => c.community_id === action.data.community
              )
            )
          );
        });
    // loop through members andd set to
    case REMOVE_MEMBERSHIP:
      const currentJourneyId = authPlayerData.get("current_journey");
      const currentJourneyToQuit = state.getIn([
        "journeys",
        activeCommunityId,
        currentJourneyId,
        authedUserId,
      ]);

      return state
        .setIn(["players", activeCommunityId, authedUserId, "team_id"], null)
        .updateIn(
          ["journeys", activeCommunityId, currentJourneyId, authedUserId],
          (mostActiveJourney) => {
            if (!!currentJourneyToQuit && Object.keys(currentJourneyToQuit).length) {
              return mostActiveJourney.setIn(
                ["player_totals", authedUserId, "quit_date"],
                moment().format("MM/dd/yyyy")
              );
            }
          }
        )
        .setIn(["players", activeCommunityId, authedUserId, "current_journey"], null)
        .updateIn(["teams", activeCommunityId, currentTeamId, "members"], (v) => {
          return v.reduce((list, member) => {
            if (member.get("user_id") !== authedUserId) {
              return list.push(member);
            }
            return list;
          }, new List());
        });
    case REMOVE_TEAM_MEMBER:
      return state
        .setIn(["teams", activeCommunityId, action.data.team_id], fromJS(action.data))
        .setIn(["players", activeCommunityId, action.userId, "team_id"], null);
    case TEAM_INVITATION:
      return state.updateIn(
        [
          "teams",
          activeCommunityId,
          state.getIn(["players", activeCommunityId, authedUserId, "team_id"]),
          "pending_invitations",
        ],
        (v) => v.push(fromJS(action.data))
      );
    case TEAM_INVITATION_DELETE:
      return state.updateIn(
        [
          "teams",
          activeCommunityId,
          state.getIn(["players", activeCommunityId, authedUserId, "team_id"]),
          "pending_invitations",
        ],
        (v) => v.filter((i) => i.get("invitation_id") !== action.invitation_id)
      );
    case TEAM_INVITATION_ACCEPT:
      return state
        .updateIn(["players", activeCommunityId, authedUserId, "teamInvitations"], (v) =>
          v.filter((i) => i.get("invitation_id") !== action.invitation_id)
        )
        .setIn(["players", activeCommunityId, authedUserId, "team_id"], action.data.team_id);
    case TEAM_INVITATION_DECLINE:
      return state.updateIn(["players", activeCommunityId, authedUserId, "teamInvitations"], (v) =>
        v.filter((i) => i.get("invitation_id") !== action.invitation_id)
      );
    case TEAM_START_EPOCH:
      return state.updateIn(
        ["players", activeCommunityId, authedUserId, "journeyData"],
        (v = new List()) => v.splice(0, 0, fromJS(action.data))
      );
    case TEAM_REMOVE_JOURNEY:
      return state.updateIn(["players", activeCommunityId, authedUserId, "journeyData"], (js) =>
        js.filter((j) => j.get("id") !== action.journeyId)
      );

    /////////////////////////////
    // External-User Actions
    /////////////////////////////
    case EXTERNAL_PLAYER_DATA_FETCH:
      const playerId = action.data.playerId;
      const actionCommunityId = action.data.communityId;

      const flattenedPlayerCommunityData = flattenUserCommunityData(
        action.data.playerData
      ).community_memberships.find((c) => c.community_id === actionCommunityId);

      // make sure to set existing avatar and display name to prevent making additional requests
      const avatar =
        state.getIn(["players", actionCommunityId, playerId, "details", "image_512"]) ||
        flattenedPlayerCommunityData.details.image_512;
      const displayName =
        state.getIn(["players", actionCommunityId, playerId, "details", "display_name"]) ||
        flattenedPlayerCommunityData.details.display_name;

      // const playerCurrentJourney =
      //   getCurrentJourney(action.data.playerJourneys?.results, playerId) || null;

      return (
        state
          .setIn(["players", actionCommunityId, playerId], fromJS(flattenedPlayerCommunityData))
          .setIn(["players", actionCommunityId, playerId, "details", "image_512"], avatar)
          .setIn(["players", actionCommunityId, playerId, "details", "display_name"], displayName)
          // .setIn(["players", actionCommunityId, playerId, "current_journey"], playerCurrentJourney)
          .setIn(
            ["players", actionCommunityId, playerId, "journeyData"],
            fromJS(action.data.playerJourneys?.results)
          )
      );

    case EXTERNAL_PLAYER_DATA_FETCH_FAILURE:
      return state.setIn(
        ["players", action.communityId, action.playerId],
        fromJS({ notAMember: true })
      );
    case EXTERNAL_PLAYER_STATS:
      return state.setIn(
        ["players", action.communityId, action.playerId, "stats"],
        fromJS(action.data)
      );
    case TEAM_FETCH:
      action.data.teamData.members.forEach((m) => {
        if (m.user_id !== action.playerId) {
          const formattedMember = flattenUserCommunityData(m).community_memberships.find(
            (c) => c.community_id === action.communityId
          );
          state = state.updateIn(["players", action.communityId, m.user_id], (v = fromJS({})) =>
            v.merge(fromJS(formattedMember))
          );
        }
        state = state.setIn(["players", action.communityId, m.user_id, "team_id"], action.teamId);
      });

      state = state.setIn(
        ["teams", action.communityId, action.teamId],
        fromJS(action.data.teamData)
      );

      const sortedTeamJourneys = fromJS(action.data.journeyData.results).sort((a, b) =>
        moment(b.get("start"), "MM/DD/YYYY").diff(moment(a.get("start"), "MM/DD/YYYY"))
      );

      // update ongoing and upcoming journey teams array with current team ID, if they have either type of journey
      const today = moment().add(24 - moment().hours(), "hours");
      const journey1 = sortedTeamJourneys.get(0);
      const journey2 = sortedTeamJourneys.get(1);
      if (!state.getIn(["upcomingJourneyTeams", action.communityId])?.has(action.teamId)) {
        if (moment(journey1?.get("start"), "MM/DD/YYYY").isAfter(today))
          state = state.updateIn(
            ["upcomingJourneyTeams", action.communityId],
            (v = new List()) => !v.has(action.teamId) && (v = v.push(action.teamId))
          );
      }
      if (!state.getIn(["ongoingJourneyTeams", action.communityId])?.has(action.teamId)) {
        if (
          moment(journey1?.get("end"), "MM/DD/YYYY").isAfter(today) ||
          moment(journey2?.get("end"), "MM/DD/YYYY").isAfter(today)
        )
          state = state.updateIn(
            ["ongoingJourneyTeams", action.communityId],
            (v = new List()) => (v = v.push(action.teamId))
          );
      }

      return state
        .setIn(["teams", action.communityId, action.teamId, "journeys"], sortedTeamJourneys)
        .updateIn(["teams", action.communityId, action.teamId, "members"], (v) => {
          // ensure members are formatted correctly
          return fromJS(
            action.data.teamData.members.map((member) =>
              flattenUserCommunityData(member).community_memberships.find(
                (c) => c.community_id === action.communityId
              )
            )
          );
        });

    /////////////////////////////
    // Shop Player Actions
    /////////////////////////////
    case APPLY_SHOP_ITEM:
      if (!!action.goal) {
        state = state.setIn(
          ["players", action.activeCommunityId, action.playerId, "current_goal"],
          action.goal
        );
      }
      return state;

    case BUY_GAME_ITEM:
      if (!!action.inGameTokens) {
        const currentJourney = state.getIn([
          "players",
          action.activeCommunityId,
          action.playerId,
          "current_journey",
        ]);
        state = state.updateIn(
          [
            "journeys",
            action.activeCommunityId,
            currentJourney,
            action.playerId.toString(),
            "player_totals",
            action.playerId.toString(),
            "total_tokens_spent",
          ],
          (v) => v + action.inGameTokens
        );
      }
      return state;

    /////////////////////////////
    // Check in Player Actions
    /////////////////////////////
    case SUBMIT_CHECKIN:
      return state
        .updateIn(
          [
            "journeys",
            action.communityId,
            action.data.journey,
            String(action.authedUserId),
            "simpleJourney",
          ],
          (v) => v.filter((item) => item.get("id") !== action.data.id).push(fromJS(action.data))
        )
        .setIn(
          ["players", action.communityId, action.authedUserId, "journeyData", 0, "last_checkin"],
          fromJS(moment(action.data.updated).format("MM/DD/YYYY"))
        )
        .updateIn(
          [
            "journeys",
            action.communityId,
            action.data.journey,
            String(action.authedUserId),
            "player_totals",
            String(action.authedUserId),
            "total_check_in",
          ],
          (v) => v + 1
        )
        .updateIn(
          [
            "journeys",
            action.communityId,
            action.data.journey,
            String(action.authedUserId),
            "player_totals",
            String(action.authedUserId),
            "total_tokens_earned",
          ],
          (v) => v + action.data.token_award_amount
        );

    default:
      return state;
  }
};

export default reducer;
