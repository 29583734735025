import React from "react";
import moment from "moment";

import { Box } from "../Utility";
import { genNewId } from "../../utils/mochiHelpers";

export const JourneyProgressIndicator = ({ startDate }) => {
  const jStart = moment(startDate, "MM/DD/YYYY");
  const now = moment();
  const days = now.diff(jStart, "days");
  const sprintsCompleted = Math.floor(days / 14);
  const journeyProgress = buildJourneyProgress();
  const backgroundStyles = buildBackgroundStyles();

  function buildJourneyProgress() {
    const journeyProgress = [];

    for (let step = 0; step < 4; step++) {
      if (step < sprintsCompleted) {
        journeyProgress.push(1);
      } else if (step === sprintsCompleted) {
        journeyProgress.push(0.5);
      } else {
        journeyProgress.push(0);
      }
    }

    return journeyProgress;
  }

  function buildBackgroundStyles() {
    const bgArray = [];

    for (let step = 0; step < journeyProgress.length; step++) {
      if (journeyProgress[step] === 1) {
        bgArray.push(
          "linear-gradient(rgba(255,255,255,1.0) 50%, rgba(255,255,255,1.0) 49%, rgba(255,255,255,1.0))"
        );
      } else if (journeyProgress[step] === 0.5) {
        bgArray.push(
          "linear-gradient(rgba(255,255,255,0.0) 50%, rgba(255,255,255,1.0) 49%, rgba(255,255,255,1.0))"
        );
      } else {
        bgArray.push(
          "linear-gradient(rgba(255,255,255,0.0) 50%, rgba(255,255,255,0.0) 49%, rgba(255,255,255,0.0))"
        );
      }
    }

    return bgArray;
  }

  return (
    <Box style={{ display: "flex" }} role="progressbar">
      {backgroundStyles.map((bg) => (
        <div
          key={genNewId("progress")}
          style={{
            height: "14px",
            width: "14px",
            borderRadius: "7px",
            marginLeft: "4px",
            border: "solid white 2px",
            backgroundImage: bg,
          }}
        />
      ))}
    </Box>
  );
};

export default JourneyProgressIndicator;
