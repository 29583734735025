import React from "react";

const arrowBoldLeft = ({ color }) => (
  <svg
    width="15"
    height="12"
    viewBox="0 0 15 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14 6H2M2 6L7 1M2 6L7 11" stroke={color} stroke-width="2" />
  </svg>
);

export default arrowBoldLeft;
