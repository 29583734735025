import axios from "../../config/axios";

import { API_ENDPOINT } from "../../constants/endpoints";
import {
  BUY_GAME_ITEM,
  FETCH_GAME_ITEMS,
  FETCH_PLAYER_INVENTORY,
  APPLY_SHOP_ITEM,
} from "../../constants/actionTypes";

export const getAllGameItems = () => async (dispatch, getState) => {
  try {
    const activeCommunityId = getState().community.get("activeCommunityId");
    const items = await axios.get(`${API_ENDPOINT}/community/${activeCommunityId}/store/listings/`);

    dispatch({
      type: FETCH_GAME_ITEMS,
      data: items.data.results,
      activeCommunityId,
    });
  } catch (error) {
    throw error.message;
  }
};

export const getPlayerInventory =
  (optionalCommunityId, optionalPlayerId) => async (dispatch, getState) => {
    try {
      const playerId = optionalPlayerId || getState().community.get("authedUserId");
      const activeCommunityId =
        optionalCommunityId || getState().community.get("activeCommunityId");

      if (getState().shop.getIn(["inventory", activeCommunityId, playerId])) return;

      let paginatedInventory = [];
      let next = 1;

      // This request returns paginated reponses. If the next field is not null, there will be more data to request.
      while (next > 0) {
        const inventory = await axios.get(
          `${API_ENDPOINT}/community/${activeCommunityId}/items/${playerId}/?page=${next}`
        );
        paginatedInventory.push(...inventory.data.results);

        if (!!inventory.data.next) next++;
        else next = 0;
      }

      dispatch({
        type: FETCH_PLAYER_INVENTORY,
        data: paginatedInventory,
        playerId,
        activeCommunityId,
      });
    } catch (error) {
      throw error.message;
    }
  };

export const buyGameItem = (itemId, inGameTokens, tx) => async (dispatch, getState) => {
  try {
    const activeCommunityId = getState().community.get("activeCommunityId");
    const playerId = getState().community.get("authedUserId");
    const res = await axios.post(
      `${API_ENDPOINT}/community/${activeCommunityId}/store/transactions/`,
      { listing: itemId, tx: tx }
    );

    dispatch({
      type: BUY_GAME_ITEM,
      data: res.data,
      activeCommunityId,
      playerId,
      itemId,
      inGameTokens,
    });
  } catch (error) {
    throw error.response.data.message;
  }
};

export const applyGameItem = (uuid, goal) => async (dispatch, getState) => {
  try {
    const playerId = getState().community.get("authedUserId");
    const activeCommunityId = getState().community.get("activeCommunityId");

    const res = await axios.patch(`${API_ENDPOINT}/community/${activeCommunityId}/items/${uuid}/`, {
      details: { goal },
    });
    dispatch({
      type: APPLY_SHOP_ITEM,
      data: res.data,
      playerId,
      activeCommunityId,
      goal,
    });
  } catch (error) {
    throw error.response.data.message;
  }
};
