import React from "react";
import { connect } from "react-redux";

import Container from "../components/Utility/Container";
import AuthorizedHOC from "../components/Utility/AuthorizedHOC";
import Box from "../components/Utility/Box";
import Space from "../components/Utility/Space";
import Header from "../components/Utility/Header";
import { SLACK_OATH_ENDPOINT } from "../constants/endpoints";
import { getPlayerAndTeamDataFromState } from "../containers/Community/stateGetterHelpers";

// TODO: remove hardcoding of community #1 from this page
export const SlackOAuthPage = ({ communityMembershipId, communityId }) => (
  <Container>
    <AuthorizedHOC>
      <Box display="grid" gridTemplateColumns={["100%", "25% 50% 25%"]} textAlign="center">
        <div />
        <Box>
          <Header h1>Permission Mochibot</Header>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${SLACK_OATH_ENDPOINT}&state=${communityMembershipId}&team=${communityId}`}
          >
            <span>Grant Permission to Mochibot</span>
            {/* <img
            alt="Add to Slack"
            height="40"
            width="139"
            src="https://platform.slack-edge.com/img/add_to_slack.png"
            srcset="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
          /> */}
          </a>
        </Box>
        <div />
      </Box>
      <Space mb={4} />
    </AuthorizedHOC>
  </Container>
);

export default connect((state) => {
  const { playerData } = getPlayerAndTeamDataFromState(state);
  return {
    communityMembershipId: playerData.get("community_membership_id"),
    communityId: state.community.get("activeCommunityId"),
  };
})(SlackOAuthPage);
