import React, { useState } from "react";
import { connect } from "react-redux";

import * as fieldValidators from "./utils/fieldValidators";
import { Space, Body } from "../Utility";
import useInput from "./utils/useInput";
import { updateTeamName } from "../../containers/Community/actions";
import { Input, Button, Paragraph, Header } from "../../Storybook";

export const EditTeamName = ({ teamData, updateTeamName, communityId }) => {
  const [submited, setSubmited] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const {
    value: teamName,
    bind: bindTeamName,
    valid: validTeamName,
    active: activeTeamName,
  } = useInput(
    teamData?.get("name") || "",
    fieldValidators.basicTextField,
    true
  );

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setSubmited(true);
    setError("");
    if (validTeamName) {
      try {
        setSuccess("Your team has been updated");
        await updateTeamName(teamData?.get("team_id"), teamName, communityId);
      } catch (e) {
        setSuccess("");
        setError(
          `There was an error updating your team name: ${e}. Please try again.`
        );
      }
    }
  };
  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      {success.length > 0 && (
        <Paragraph color="darkPositive500" mb={3} label={success} />
      )}
      <Header mb={3} label="Update Team Name" />
      <Input
        type="text"
        placeholder={teamData.get("name")}
        submited={submited}
        activeInput={activeTeamName}
        error="Please enter a new team name"
        textLabel="Team Name"
        {...bindTeamName}
      />
      <Space mb={3} />
      {error.length > 0 && <Body error>{error}</Body>}
      <Button
        primary
        disabled={!validTeamName}
        type="submit"
        label="Update Team Name"
      />
    </form>
  );
};

export default connect(
  (state) => ({
    communityId: state.community.get("activeCommunityId"),
  }),
  {
    updateTeamName,
  }
)(EditTeamName);
