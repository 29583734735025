import React from "react";
import { connect } from "react-redux";

import { Box } from "../Utility";
import PlayerMochi from "../Mochi/PlayerMochi";
import { Button } from "../../Storybook";
import {
  getPlayerAndTeamDataFromState,
  isCurrentlyInAJourney,
  getCurrentJourneyFromState,
} from "../../containers/Community/stateGetterHelpers";
import SchedulePause from "./SchedulePause";
import QuitJourneyButton from "./Journey/JourneyQuit";
import LeaveTeamButton from "./LeaveTeamButton";

export const TeammateView = ({
  authedUserId,
  playerId,
  isAnotherPlayersProfile,
  currentlyInAJourney,
  isMember,
  email,
  makeLeader,
  amLeader,
  isLeader,
  canRemoveInvite,
  deleteInvite,
  removeTeammate,
  playerData,
}) => {
  if (isMember) {
    return (
      <Box
        width={[1, 1 / 2, 1 / 4]}
        display="inline-block"
        justifyContent="center"
        p={3}
      >
        <Box>
          <PlayerMochi playerId={playerId} />
          {isLeader && (
            <div>
              <Button
                disabled
                primary
                width="100%"
                color="darkFGPrimary"
                bgColor="darkBGSecondary"
                mb={2}
                label="Team Leader 🔑 "
              />
            </div>
          )}
          {!isAnotherPlayersProfile && (
            <Box>
              {!!amLeader && !isLeader && (
                <Button
                  primary
                  arrow
                  width="100%"
                  mb={2}
                  label="Make Leader"
                  onClick={(e) => makeLeader(e)}
                />
              )}
              {!isLeader && authedUserId !== playerData?.get("user_id") && (
                <Button
                  secondary
                  bgColor="darkNegative500"
                  width="100%"
                  onClick={(e) => removeTeammate(e)}
                  mb={2}
                  label="Remove Teammate"
                />
              )}
              {authedUserId === playerData?.get("user_id") &&
                (currentlyInAJourney ? (
                  <>
                    <SchedulePause />
                    <QuitJourneyButton />
                  </>
                ) : (
                  <LeaveTeamButton />
                ))}
            </Box>
          )}
        </Box>
      </Box>
    );
  } else {
    return (
      <Box width={[1, 1 / 2, 1 / 4]} display="inline-block" mb={20} p={2}>
        <PlayerMochi email={email} />
        {!isAnotherPlayersProfile && (
          <Box textAlign="center">
            <Button
              primary
              bgColor="darkNegative700"
              width="100%"
              disabled={!canRemoveInvite}
              onClick={(e) => deleteInvite(e)}
              label="Delete Invite"
            />
          </Box>
        )}
      </Box>
    );
  }
};

export default connect((state, ownProps) => {
  const { playerData } = getPlayerAndTeamDataFromState(
    state,
    ownProps.playerId
  );
  const authedUserId = state.auth.get("authedUserId");
  const currentlyInAJourney = isCurrentlyInAJourney(state);
  return {
    playerData,
    currentlyInAJourney,
    authedUserId,
    currentJourney: getCurrentJourneyFromState(state),
    activeCommunityId: state.community.get("activeCommunityId"),
  };
}, {})(TeammateView);
