import React, { useState } from "react";
import { useSelector } from "react-redux";
import { List } from "immutable";

import WaveParticipant from "./WaveParticipant.js";
import { genNewId } from "../../utils/mochiHelpers";
import { Header, Modal, Paragraph } from "../../Storybook";
import { ApiError, BorderedWrapper } from "../Utility";

const WaveParticipants = ({ authedParticipant, joinedWave }) => {
  const [leftWave, setLeftWave] = useState(false);
  const [error, setError] = useState("");
  const participants = useSelector(
    (state) =>
      state.community.getIn([
        "communityInfo",
        state.community.get("activeCommunityId"),
        "active_wave",
        "participants",
      ]) || new List()
  );

  return (
    <>
      {leftWave && (
        <Modal oncClose={() => setLeftWave(false)}>
          <Paragraph label="You have been removed from the next wave." />
        </Modal>
      )}
      {!!error?.length && (
        <Modal oncClose={() => setError("")}>
          <ApiError msg={error} />
        </Modal>
      )}
      {!!participants?.size && (
        <BorderedWrapper>
          <Header h4 label="Next Wave" mb={3} />
          {!!authedParticipant && (
            <WaveParticipant
              participant={authedParticipant}
              isAuthedPlayer
              isPartOfWave
              leftWave={leftWave}
              setLeftWave={setLeftWave}
              joinedWave={joinedWave}
              setError={setError}
            />
          )}
          {participants.map(
            (p) =>
              p.get("user") !== authedParticipant?.get("user") && (
                <WaveParticipant
                  key={genNewId("free-player")}
                  participant={p}
                  isAuthedPlayer={false}
                />
              )
          )}
        </BorderedWrapper>
      )}
    </>
  );
};
export default WaveParticipants;
