import styled, { css } from "styled-components";
import { typography, color } from "styled-system";

const Text = styled.span`
  font-family: ${(props) => props.theme.fonts.body};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  color: ${(props) => props.theme.color.white};
  ${typography};
  ${color};

  // TODO: do we need red, yellow, and color? or can we just do a color prop
  ${(props) =>
    props.red &&
    css`
      color: ${(props) => props.theme.color.red};
    `}

  ${(props) =>
    props.yellow &&
    css`
      color: ${(props) => props.theme.color.yellow};
    `}

  ${(props) =>
    props.color &&
    css`
      color: ${(props) => props.theme.color[props.color]};
    `}

  ${(props) =>
    props.bold &&
    css`
      font-weight: 600;
    `}

  ${(props) =>
    props.semiBold &&
    css`
      font-weight: 500;
    `}

${(props) =>
    props.small &&
    css`
      font-size: 16px;
    `}

${(props) =>
    props.icon &&
    css`
      margin-left: 6px;
    `}

${(props) =>
    props.leftIcon &&
    css`
      margin-right: 6px;
    `}

${(props) =>
    props.larger &&
    css`
      font-size: 22px;
      font-weight: 700;
    `}

${(props) =>
    props.muted &&
    css`
      opacity: 0.6;
    `}

${(props) =>
    props.black &&
    css`
      color: ${(props) => props.theme.color.black};
    `}
    
${(props) =>
    props.green &&
    css`
      color: ${(props) => props.theme.color.darkPositive};
    `}
`;

export default Text;
