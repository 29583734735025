import React from "react";

import { Button, Paragraph } from "../../Storybook";
import { Flex } from "../Utility";

const FreeAgentItem = ({ title, buttonText, callback }) => {
  return (
    <Flex mb={3} height="42px" alignItems="center" width="100%">
      <Flex
        p={12}
        mr={2}
        justifyContent="flex-start"
        width="100%"
        backgroundColor="#1F1B1B"
        borderRadius="4px"
      >
        <Paragraph label={title} />
      </Flex>
      <Button primary arrow label={buttonText} onClick={callback} />
    </Flex>
  );
};

export default React.memo(FreeAgentItem);
