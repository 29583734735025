import React from "react";
import PropTypes from "prop-types";

import GridedContainer from "../../components/Utility/Grid/GridBox";

const GridBox = ({ span, start, children, ...props }) => {
  const maxColumns = [4, 8, 12, 12];
  let startColumns = start || [0];
  let spanColumns = span || [0];
  // const children = props.children || undefined;
  if (!Array.isArray(startColumns)) startColumns = [startColumns];
  if (!Array.isArray(spanColumns)) spanColumns = [spanColumns];
  const a = Array(4).fill(0);
  const startArray = a.map((s, i) =>
    startColumns[i] ? startColumns[i] : startColumns[startColumns.length - 1]
  );
  const spanArray = a.map((s, i) => {
    spanColumns[i] = spanColumns[i]
      ? spanColumns[i]
      : spanColumns[spanColumns.length - 1];
    let retValue = spanColumns[i]
      ? spanColumns[i]
      : spanColumns[spanColumns.length - 1];
    if (maxColumns[i] + 1 < spanColumns[i] + startArray[i]) {
      retValue = maxColumns[i] + 1 - startArray[i];
    }
    return retValue;
  });
  return (
    <GridedContainer spanArray={spanArray} startArray={startArray} {...props}>
      {children}
    </GridedContainer>
  );
};

GridBox.propTypes = {
  span: PropTypes.arrayOf(PropTypes.number),
  start: PropTypes.arrayOf(PropTypes.number),
  /**,
   * Pass when it's necessary to make fonts responsive. Only pass an array of font sizes corresponsing to the different breakpoints.
   */
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.element,
  ]),
};

GridBox.defaultProps = {
  span: [4, 8, 12, 12],
  start: [0],
  children: null,
};

export default GridBox;
