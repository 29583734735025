import React from "react";

import { genNewId } from "../../utils/mochiHelpers";
import CommunityJourneyStats from "./CommunityJourneyStats";
import EmptyJourneyState from "./EmptyJourneyState";

const UpcomingJourneys = ({
  authedPlayerHasUpcomingJourney,
  authPlayerTeamId,
  upcomingJourneyTeams,
  activeCommunityId,
  journeyWave,
}) => {
  const noOneIsScheduled =
    !journeyWave?.get("participants")?.size &&
    !authedPlayerHasUpcomingJourney &&
    !upcomingJourneyTeams?.size;
  return (
    <>
      {authedPlayerHasUpcomingJourney && (
        <CommunityJourneyStats teamId={authPlayerTeamId} />
      )}
      {!!upcomingJourneyTeams?.size &&
        upcomingJourneyTeams.map(
          (t) =>
            t !== authPlayerTeamId && (
              <CommunityJourneyStats
                teamId={t}
                key={genNewId("upcoming-team-journey")}
                activeCommunityId={activeCommunityId}
              />
            )
        )}
      {noOneIsScheduled && (
        <EmptyJourneyState label="Nobody's scheduled to play next week - start the wave by joining it!" />
      )}
    </>
  );
};

export default UpcomingJourneys;
