import React, { useEffect, useRef } from "react";

import { Avatar, Paragraph, Input, Button } from "../../Storybook";
import { Box, MutedCheckmark, Flex } from "../Utility";
import useInput from "../Forms/utils/useInput";
import * as fieldValidators from "../Forms/utils/fieldValidators";
import null288 from "../../img/null-288.png";
import theme from "../../styles/theme";

const ReportQnA = ({ question, answer, error, setError, last, active, handleAnswer }) => {
  const { value: response, bind: bindResponse, active: activeReponse } = useInput(
    answer || "",
    fieldValidators.basicTextField,
    true
  );
  const questionRef = useRef(null);

  useEffect(() => {
    if (activeReponse) questionRef.current.scrollIntoView();
  }, [activeReponse]);

  useEffect(() => {
    if (response === "" && error) {
      setError(null);
    }
  }, [error, response, setError]);

  const keyEnter = (ev, res) => {
    // if enter was pressed
    if ((ev.keyCode || ev.which) === 13) {
      ev.preventDefault();
      handleAnswer(ev, res);
    }
  };

  return (
    <Box mb={4} style={{ opacity: active ? 1 : 0.7 }}>
      <Flex justifyContent="flex-start" alignItems="center" mb={3}>
        {active ? <Avatar image={null288} large /> : <MutedCheckmark active={true} small inline />}
        <Paragraph fontSize={"fz3"} label={question} color={"darkFGPrimary"} />
      </Flex>
      <Flex mb={4}>
        <Box width={theme.pixels.sz4} mr={2} />
        <Input
          autoFocus
          type="text"
          width={"80%"}
          {...bindResponse}
          disabled={!active}
          onKeyPress={(e) => keyEnter(e, response)}
          overrideError={error}
          touched={error ? true : false}
        />
      </Flex>
      <Box ref={questionRef} ml={theme.pixels.sz4}>
        {active && (
          <Button
            arrow
            primary
            type="button"
            label={last ? "Submit" : "Continue"}
            onClick={(e) => handleAnswer(e, response)}
          />
        )}
      </Box>
    </Box>
  );
};

export default ReportQnA;
