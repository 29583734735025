import styled, { css } from "styled-components";
import { space, color, layout, flexbox, grid, typography, border } from "styled-system";

const Fill = styled.div`
  ${space}
  ${color}
  ${layout}
  ${flexbox}
  ${grid}
  ${typography}
  ${border}

  ${(props) =>
    props.bgColor &&
    css`
      background: ${(props) => props.theme.color[props.bgColor]};
    `}

  ${(props) =>
    props.css &&
    css`
      ${(props) => props.css};
    `}
`;

export default Fill;
