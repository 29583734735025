import { fromJS } from "immutable";

import initialState from "./initialState";
import {
  AUTH_SUCCESS,
  AUTH_FAILURE,
  LOG_OUT,
} from "../../constants/actionTypes";

/* eslint-disable complexity */
const reducer = (state = fromJS(initialState), action) => {
  switch (action.type) {
    case AUTH_SUCCESS:
      return state
        .set("auth", true)
        .set("authAttempted", true)
        .set("authedUserId", action.data.user.user_id);
    case AUTH_FAILURE:
      return state.set("auth", false).set("authAttempted", true);
    case LOG_OUT:
      return state.set("auth", false).set("authedUserId", null);
    default:
      return state;
  }
};

export default reducer;
