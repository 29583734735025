import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { Avatar, Paragraph } from "../../Storybook";
import StyledCommunityTag from "./StyledCommunityTag";
import theme from "../../styles/theme";

const CommunityTag = ({
  image,
  label,
  current,
  theme,
  onClick,
  props,
  hideName,
  narrow,
  color,
}) => {
  return (
    <>
      <StyledCommunityTag
        theme={theme}
        current={current}
        onClick={() => onClick && onClick()}
        narrow={narrow}
        color={color}
        {...props}
      >
        {image && (
          <Avatar image={image} large={!!current} alt="User's community avatar" menuBurger />
        )}
        <Paragraph
          label={["", label]}
          fontSize={current ? "fz2" : "fz1"}
          weight={current ? 600 : 400}
          display={[hideName ? "none" : "flex", "flex"]}
          m={0}
          ml={2}
          {...props}
        />
      </StyledCommunityTag>
    </>
  );
};

CommunityTag.propTypes = {
  /**
   * Pass an image to display on the avatar
   */
  image: PropTypes.string,
  /**
   * Pass the name of the community
   */
  label: PropTypes.string,
  /**
   * Pass to the present community being used
   */
  current: PropTypes.bool,
  /**
   * Pass to make tag the width of the parent
   */
  fullWidth: PropTypes.bool,
  /**
   * Pass to show only icon
   */
  hideName: PropTypes.bool,
  /**
   * Pass when a narrower version is desired, eg: outside of a dropdown menu
   */
  narrow: PropTypes.bool,
  /**
   * Pass a theme color for a different background color
   */
  color: PropTypes.string,
  /**
   * Optional pass a theme other than the default
   */
  theme: PropTypes.object,
};

CommunityTag.defaultProps = {
  image: null,
  label: "",
  theme: theme,
  current: false,
  fullWidth: false,
  hideName: false,
  narrow: false,
  color: null,
};

export default CommunityTag;
