import React, { useState, useEffect, useMemo } from "react";
import { PropTypes } from "prop-types";

import { Box } from "../Utility";
import BondPath from "./BondPath";
import theme from "../../styles/theme";

const HealthMeter = ({
  bondPercent,
  meterColor,
  gameModeId,
  minimumBondColor,
}) => {
  const [minimumBond, setMinimumBond] = useState();
  const [bondMeterFillOffset, setBondMeterFillOffset] = useState();

  const meterLength = 522;
  const minimumBonds = useMemo(
    () => [
      0,
      0.526 * meterLength,
      0.526 * meterLength,
      0.667 * meterLength,
      0.833 * meterLength,
    ],
    [meterLength]
  );

  useEffect(() => {
    setMinimumBond(minimumBonds[gameModeId]);
    let relativeCurrentBond = bondPercent * 3.72;
    if (
      !bondPercent ||
      gameModeId === 1 ||
      meterLength - relativeCurrentBond >= meterLength * 0.95
    ) {
      setBondMeterFillOffset(meterLength * 0.95);
    } else if (meterLength - relativeCurrentBond <= 0) {
      setBondMeterFillOffset(0);
    } else setBondMeterFillOffset(meterLength - relativeCurrentBond);
  }, [bondPercent, gameModeId, minimumBonds]);

  return (
    <Box>
      <svg
        data-testid={`mochi-meter-${meterColor}`}
        id="bondMeter"
        width="100%"
        viewBox="0 0 250 137"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="meter"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="1"
          y="0"
          width="249"
          height="137"
        >
          <path
            d="M17.056 113C16.724 117.406 13.174 121 8.75574 121C4.33747 121 0.728272 117.407 1.0161 112.998C5.13529 49.9022 57.6179 0 121.756 0C183.942 0 235.172 46.9115 241.986 107.276C246.641 110.074 249.756 115.173 249.756 121C249.756 129.837 242.592 137 233.756 137C224.919 137 217.756 129.837 217.756 121C217.756 115.042 221.012 109.844 225.843 107.091C219.038 55.6756 175.03 16 121.756 16C66.4574 16 21.1433 58.7475 17.056 113Z"
            fill="white"
          />
        </mask>
        <g mask="url(#meter)">
          <BondPath
            color="#FFFFFF"
            id="frame"
            d="M8.7558 121C8.7558 58.5918 59.3476 8 121.756 8C184.164 8 234.756 58.5918 234.756 121C234.756 126.776 234.336 132.457 233.5 138"
          />
          <BondPath
            color="#FFFFFF"
            id="frame"
            d="M8.7558 121C8.7558 58.5918 59.3476 8 121.756 8C184.164 8 234.756 58.5918 234.756 121C234.756 126.776 234.336 132.457 233.5 138"
          />
          <BondPath
            id="minimumBond"
            color={minimumBondColor}
            isMinimumBond={true}
            meterLength={meterLength}
            fillOffset={meterLength - minimumBond}
            d="M8.7558 121C8.7558 58.5918 59.3476 8 121.756 8C184.164 8 234.756 58.5918 234.756 121C234.756 126.776 234.336 132.457 233.5 138"
          />
          <BondPath
            id="currentBond"
            color={meterColor}
            meterLength={meterLength}
            fillOffset={bondMeterFillOffset}
            d="M8.7558 121C8.7558 58.5918 59.3476 8 121.756 8C184.164 8 234.756 58.5918 234.756 121C234.756 126.776 234.336 132.457 233.5 138"
          />
        </g>
      </svg>
    </Box>
  );
};

HealthMeter.propTypes = {
  meterColor: PropTypes.oneOf([
    theme.color.negative.red500,
    theme.color.warning.orange500,
    theme.color.positive.green500,
  ]),
  bondPercent: PropTypes.number,
  isProfile: PropTypes.bool,
  minimumBondColor: PropTypes.oneOf([
    theme.color.darkFGSecondary,
    theme.color.warning.orange500,
    theme.color.positive.green500,
  ]),
};

HealthMeter.defaultProps = {
  meterColor: theme.color.negative.red500,
  bondPercent: 0,
  isProfile: true,
  minimumBondColor: theme.color.darkFGSecondary,
};

export default HealthMeter;
