import styled, { css } from "styled-components";

const TabList = styled.ul`
  display: inline-flex;
  flex-direction: row;
  align-self: center;
  list-style-type: none;
  padding: 4px;
  background: ${(props) => props.theme.color.darkBGSecondary};
  border-radius: 16px;

  ${(props) =>
    props.views &&
    css`
      justify-content: space-between;
    `}
`;

export default TabList;
