import styled, { css } from "styled-components";
import { layout, space, typography } from "styled-system";

const Button = styled.button`
  border-radius: 4px;
  padding: 0px 24px;
  height: 42px;
  blend: pass-through;
  border: none;
  background: ${(props) => props.theme.color.darkFGPrimary};
  font-family: ${(props) => props.theme.fonts.body}
  font-style: normal;
  letter-spacing: 0.02em;
  line-height: 110%;
  color: ${(props) => props.color};
  font-weight: 600;
  font-size: ${(props) => props.theme.fontSize.fz2}
  text-align: center;
  ${layout}
  ${space}
  ${typography}

  &:hover {
    cursor: pointer;
    transform: scale(1.04);
    border: ${(props) =>
      !props.secondary
        ? "none"
        : `3px solid ${(props) => props.theme.color.darkFGPrimary}`};
    box-shadow: ${(props) =>
      !props.secondary &&
      !props.colored &&
      `0px 0px ${props.theme.pixels.sz0} ${props.theme.color.darkFGPrimary}`}
  }

  &:focus {
    outline-width: medium
    outline-style: solid
    cursor: pointer;
    transform: scale(1.04);
    box-shadow: ${(props) =>
      !props.secondary &&
      !props.colored &&
      `0px 0px ${props.theme.pixels.sz0} ${props.theme.color.darkFGPrimary}`}

  }


  &:active {
    transform: scale(0.98);
    box-shadow: ${(props) =>
      !props.secondary &&
      !props.colored &&
      `0px 0px ${props.theme.pixels.sz2} ${props.theme.color.darkFGPrimary}`}
  }

  ${(props) =>
    props.noWrap &&
    css`
      white-space: nowrap;
    `};

  ${(props) =>
    props.large &&
    css`
      font-size: ${(props) => props.theme.fontSize.fz3};
      height: 48px;
    `};

  ${(props) =>
    props.textButton &&
    css`
      background: transparent;
      font-weight: 400;
      color: ${(props) =>
        !!props.colored
          ? props.theme.color.darkBGPrimary
          : props.theme.color.darkFGPrimary};
      border: none;
      padding-left: 0;

      &:hover {
        border: none;
        box-shadow: none;
      }
    `};

  ${(props) =>
    !props.colored &&
    !props.bgColor &&
    props.primary &&
    css`
      background: ${(props) => props.theme.color.darkFGPrimary};
      color: ${(props) => props.theme.color.darkBGPrimary};
    `};
  ${(props) =>
    !!props.colored &&
    props.primary &&
    css`
      background: ${(props) => props.theme.color.colorFGPrimary};
      color: ${(props) => props.theme.color[props.colored]};
    `};
  ${(props) =>
    !!props.bgColor &&
    props.primary &&
    css`
      background: ${(props) => props.theme.color[props.bgColor]};
    `};
  ${(props) =>
    props.secondary &&
    css`
      border: 3px solid ${(props) => props.theme.color.darkFGPrimary};
      background: transparent;
      color: ${(props) => props.theme.color.darkFGPrimary};
    `};

  ${(props) =>
    props.secondary &&
    !!props.bgColor &&
    css`
      border: 3px solid ${(props) => props.theme.color[props.bgColor]};
      background: transparent;
      color: ${(props) => props.theme.color[props.bgColor]};
    `};

  ${(props) =>
    props.secondary &&
    !!props.colored &&
    css`
      border: 3px solid ${(props) => props.theme.color.colorFGPrimary};
      background: transparent;
      color: ${(props) => props.theme.color.colorFGPrimary};
    `};

    ${(props) =>
      props.thin &&
      css`
        padding: 4px 8px;
      `};

  ${(props) =>
    props.disabled &&
    css`
      &,
      &:hover,
      &:active {
        transform: none;
        transition: none;
        cursor: not-allowed;
        box-shadow: none;

        ${(props) =>
          props.primary &&
          !props.colored &&
          !props.bgColor &&
          css`
            background-color: ${(props) => props.theme.color.darkFGTertiary};
            color: ${(props) => props.theme.color.darkBGPrimary};
          `};

        ${(props) =>
          !!props.colored &&
          props.primary &&
          css`
            background: ${(props) => props.theme.color.colorFGTertiary};
            color: ${(props) => props.theme.color[props.colored]};
          `};

        ${(props) =>
          props.primary &&
          !!props.bgColor &&
          css`
            background: ${(props) => props.theme.color[props.bgColor]};
            opacity: 0.7;
          `};

        ${(props) =>
          props.secondary &&
          !props.colored &&
          css`
            color: ${(props) => props.theme.color.darkFGTertiary};
            border-color: ${(props) => props.theme.color.darkFGTertiary};
          `};

        ${(props) =>
          props.secondary &&
          !!props.colored &&
          css`
            color: ${(props) => props.theme.color.colorFGTertiary};
            border-color: ${(props) => props.theme.color.colorFGTertiary};
          `};

        ${(props) =>
          props.secondary &&
          !!props.bgColor &&
          css`
            color: ${(props) => props.theme.color[props.bgColor]};
            border-color: ${(props) => props.theme.color[props.bgColor]};
            opacity: 0.9;
          `};
      }
    `};

  // TODO : replace all instances using this property with 'bgColor= "darkNegative500" || "darkNegative700" '
  ${(props) =>
    props.danger &&
    css`
      border: 2px solid ${(props) => props.theme.color.red};
      color: ${(props) => props.theme.color.white};
      &:hover {
        box-shadow: 0px 0px 16px ${(props) => props.theme.color.red};
      }
    `};

  // TODO : replace all instances using this property with 'bgColor= "darkNegative500" || "darkNegative700" '
  ${(props) =>
    props.warning &&
    css`
      padding: 8px 16px;
    `};

  // TODO : replace all instances using this property with 'colored= "themeColorString"'
  ${(props) =>
    props.notification &&
    css`
      border: none;
      background: ${(props) => props.theme.color.black};
      color: ${(props) => props.theme.color[props.color]};
    `};
`;

export default Button;
