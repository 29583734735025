import React from "react";

const arrowBoldRight = ({ color }) => (
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 6H12M12 6L7 1M12 6L7 11" stroke={color} strokeWidth="2" />
  </svg>
);

export default arrowBoldRight;
