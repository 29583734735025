import React, { useMemo, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment";

import CreateAccount from "../Forms/CreateAccount";
import { getPlayerAndTeamDataFromState } from "../../containers/Community/stateGetterHelpers";
import { Modal, Paragraph, Button, Header } from "../../Storybook";
import { Box } from "../Utility";

const NewProfileModals = ({ playerData, teamData, communityName }) => {
  const location = useLocation();
  const [greeted, setGreeted] = useState(false);
  const [hideModals, setHideModals] = useState(false);

  const isNewUser =
    !!playerData?.get("community_membership_id") && !playerData?.get("email")?.length;

  useEffect(() => {
    if (
      location.pathname.includes("/install-mochibot") ||
      location.pathname.includes("/mochi-invite") ||
      location.pathname.includes("/community-invitation")
    )
      setHideModals(true);
  }, [location.pathname, setHideModals]);

  const queryParams = useMemo(() => {
    // TODO: look into using useSearchParams hook in react router
    const params = new URLSearchParams(location.search);
    return {
      actions: params.getAll("actions"),
    };
  }, [location.search]);

  if (hideModals) return <></>;
  return (
    <>
      {!!queryParams.actions.length && !greeted && !!teamData ? (
        <Modal onClose={() => setGreeted(true)}>
          <Box>
            <Header h3 label="Welcome to Mochi!" mb={3} />
            <Paragraph
              label={`Currently, you’re on team ${teamData?.get(
                "name"
              )} and your journey with ${communityName} will start on ${moment(
                teamData?.getIn(["journeys", 0, "start"]),
                "MM/DD/YYYY"
              ).format("MMMM Do")}.`}
              mb={3}
            />
            <Paragraph>
              Next, you should set a goal for your journey in Discord using the command
            </Paragraph>
            <Paragraph label=" /goal" weight={700} color="darkCyan500" />
            <Button
              primary
              label={isNewUser ? "Next" : "Dismiss"}
              mt={4}
              onClick={() => setGreeted(true)}
            />
          </Box>
        </Modal>
      ) : (
        isNewUser && (
          <Modal noClose>
            <CreateAccount />
          </Modal>
        )
      )}
    </>
  );
};

export default connect((state) => {
  const activeCommunityId = state.community.get("activeCommunityId");
  const { playerData, teamData } = getPlayerAndTeamDataFromState(state);
  return {
    playerData,
    teamData,
    communityName: state.community.getIn(["communityInfo", activeCommunityId, "name"]),
  };
})(NewProfileModals);
