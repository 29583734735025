import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { Box, Flex } from "../Utility";
import { Header, Button, Avatar } from "../../Storybook";
import CenterEllipsisText from "../Utility/CenterEllipsisText";
import {
  generateCommunityLink,
  getDisplayName,
} from "../../utils/mochiHelpers";

const PlayerSpecifics = ({
  playerData,
  isAnotherPlayersProfile,
  activeCommunityId,
  communityInfo,
  isProfile,
}) => {
  const platformDetails = !!playerData && playerData.get("details");

  const { platformUserLink, platformName } = generateCommunityLink(
    communityInfo,
    platformDetails
  );

  const viewPlatformPrompt = !!platformName && !!platformDetails?.get("id");
  const displayName = !!playerData && getDisplayName(playerData);

  const defaultImage =
    "https://res.cloudinary.com/delu9m4xu/image/upload/v1643398243/mochi/Mochi-Icon_qkewbf.png";

  return (
    <Box textAlign="center">
      <Flex alignItems="center" justifyContent="center">
        {!!platformDetails?.get("image_512") && (
          <Avatar
            large
            image={platformDetails.get("image_512") || defaultImage}
            alt={`${displayName}'s ${platformName} avatar`}
          />
        )}
        <RouterLink
          to={`/${activeCommunityId}/profile/${playerData.get("user_id")}`}
        >
          <Header
            h2
            mb={!!isProfile && !viewPlatformPrompt && 4}
            style={{ textOverflow: "ellipsis", wordBreak: "break-all" }}
          >
            {!isProfile && displayName?.length > 7 ? (
              <CenterEllipsisText originalText={displayName} length={9} />
            ) : (
              displayName
            )}
          </Header>
        </RouterLink>
      </Flex>
      {!!isProfile && viewPlatformPrompt && (
        <Box align="center" ml={3}>
          <Button
            textButton
            arrow
            color="darkBGPrimary"
            type="button"
            label={
              isAnotherPlayersProfile
                ? `Message on ${platformName}`
                : `View your ${platformName} profile`
            }
            noreferrer="true"
            noopener="true"
            onClick={() => window.open(platformUserLink, "_blank")}
          />
        </Box>
      )}
    </Box>
  );
};

export default PlayerSpecifics;
