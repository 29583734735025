import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import createSentryMiddleware from "redux-sentry-middleware";
import { getPlayerAndTeamDataFromState } from "../containers/Community/stateGetterHelpers";

const dsn = "https://7f32548c221a40369fa55ad85573445e@o4504512166887424.ingest.sentry.io/4504512169181184";

const sentryMiddlewareCreator = () => {
  Sentry.init({
    dsn,
    environment: process.env.REACT_APP_VERSION,
    release: "mochi-frontend@" + process.env.REACT_APP_VERSION_NUMBER,
    integrations: [new BrowserTracing()],
  });

  return createSentryMiddleware(Sentry, {
    getUserContext: (state) => {
      if (state.auth.get("auth")) {
        const { playerData } = getPlayerAndTeamDataFromState(state);
        return {
          id: playerData.get("user_id"),
          name: `${playerData.get("first_name")} ${playerData.get("last_name")}`,
          email: playerData.get("email"),
        };
      }
      return { name: "Not Yet Identified or Not Registered" };
    },
    stateTransformer: (state) => ({
      web3: state.web3.toJS(),
      community: state.community.toJS(),
      freeAgent: state.freeAgent.toJS(),
      gameModes: state.gameModes.toJS(),
      auth: state.auth.toJS(),
    }),
  });
};

export default sentryMiddlewareCreator;
