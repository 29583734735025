import moment from "moment";

export const backgroundRenderColors = (color, active, completed) => {
  if (active && !completed) return color;
  else {
    if (color === "darkMagenta500") return "darkMagenta700";
    else if (color === "darkCyan500") return "darkCyan700";
    else if (color === "darkOrange500") return "darkOrange700";
  }
};

export const isComplete = (lastCheckin, nextCheckin) => {
  return (
    nextCheckin === lastCheckin ||
    moment().isAfter(nextCheckin, "day") ||
    moment(lastCheckin).isAfter(moment(nextCheckin), "Date")
  );
};
