import moment from "moment";

import { JOURNEY_GRACE_PERIOD_DAYS } from "../../constants/gameValues";

export const getPlayerAndTeamDataFromState = (state, anotherPlayersId = null) => {
  const retObj = {};
  const playerId = Number(anotherPlayersId) || state.auth.get("authedUserId");
  const activeCommunityId = state.community.get("activeCommunityId");

  if (playerId && activeCommunityId) {
    retObj.playerData = state.community.getIn(["players", activeCommunityId, playerId]);
    const teamId = retObj.playerData?.get("team_id");
    retObj.teamData = state.community.getIn(["teams", activeCommunityId, teamId]);
    return retObj;
  } else return { teamData: null, playerData: null };
};

export const isCurrentlyInAJourney = (state, optionalPlayerId) => {
  const playerId = optionalPlayerId || state.auth.get("authedUserId");
  const activeCommunityId = state.community.get("activeCommunityId");
  if (!state.community.getIn(["players", activeCommunityId, playerId])) return;
  const now = moment();

  const currentJourney = getCurrentJourney(state, activeCommunityId, playerId);
  if (!currentJourney) return false;
  const journeyStart = moment(currentJourney?.get("start"), "MM/DD/YYYY");
  const journeyEnd = moment(currentJourney?.get("end"), "MM/DD/YYYY");
  return journeyStart?.isSameOrBefore(now, "day") && journeyEnd?.isSameOrAfter(now, "day");
};

export const withinGracePeriod = (state, optionalPlayerId) => {
  const playerId = optionalPlayerId || state.auth.get("authedUserId");
  const activeCommunityId = state.community.get("activeCommunityId");
  if (!state.community.getIn(["players", activeCommunityId, playerId])) return;
  const now = moment();

  const currentJourney = getCurrentJourney(state, activeCommunityId, playerId);
  if (!currentJourney) return false;
  const journeyStart = moment(currentJourney.get("start"), "MM/DD/YYYY");
  const journeyGraceEnd = journeyStart.clone().add(JOURNEY_GRACE_PERIOD_DAYS, "days");
  return journeyStart.isSameOrBefore(now, "day") && journeyGraceEnd.isSameOrAfter(now, "day");
};

const getCurrentJourney = (state, activeCommunityId, playerId) => {
  if (!state.community.getIn(["players", activeCommunityId, playerId])) return;
  const { playerData } = getPlayerAndTeamDataFromState(state, playerId);
  const currentJourneyId = playerData.get("current_journey");

  if (!currentJourneyId) return false;
  else {
    const currentJourney = getCurrentJourneyFromState(state, playerId);
    const playerQuitJourney =
      !!currentJourney &&
      currentJourney?.getIn(["player_totals", playerId.toString(), "quit_date"]);
    if (playerQuitJourney) return false;
    return currentJourney;
  }
};

export const getCurrentJourneyPlayerTotal = (state, activeCommunityId, playerId) => {
  const currentJourneyId = state.community.getIn([
    "players",
    activeCommunityId,
    playerId,
    "current_journey",
  ]);

  return state.community.getIn([
    "journeys",
    activeCommunityId,
    currentJourneyId,
    playerId.toString(),
    "player_totals",
    playerId.toString(),
  ]);
};

export const hasUpcomingJourney = (state, optionalPlayerId) => {
  const playerId = optionalPlayerId || state.community.get("authedUserId");
  const { playerData } = getPlayerAndTeamDataFromState(state, playerId);
  const newestStartDate = playerData?.getIn(["journeyData", 0, "start"]);
  return newestStartDate ? moment().isBefore(moment(newestStartDate, "MM/DD/YYYY")) : false;
};

export const getLatestJourney = (state, optionalPlayerId) => {
  const playerId = optionalPlayerId || state.community.get("authedUserId");
  const activeCommunityId = state.community.get("activeCommunityId");
  const { playerData } = getPlayerAndTeamDataFromState(state, playerId);
  const latestJourneyId = playerData.getIn(["journeyData", 0, "id"]);
  return state.community.getIn([
    "journeys",
    activeCommunityId,
    latestJourneyId,
    playerId.toString(),
  ]);
};

export const getCurrentJourneyFromState = (state, optionalPlayerId) => {
  const playerId = optionalPlayerId || state.community.get("authedUserId");
  const activeCommunityId = state.community.get("activeCommunityId");
  const { playerData } = getPlayerAndTeamDataFromState(state, playerId);
  const currentJourneyId = playerData?.get("current_journey");
  return state.community.getIn([
    "journeys",
    activeCommunityId,
    currentJourneyId,
    playerId.toString(),
  ]);
};

// TODO: remove this, only needed because upcoming journeys don't have playerId objects nested in them
export const getOverallCurrentJourneyFromState = (state, optionalPlayerId) => {
  const playerId = optionalPlayerId || state.community.get("authedUserId");
  const activeCommunityId = state.community.get("activeCommunityId");
  const { playerData } = getPlayerAndTeamDataFromState(state, playerId);
  const currentJourneyId = playerData?.get("current_journey");
  return state.community.getIn(["journeys", activeCommunityId, currentJourneyId]);
};

export const getTeamsLatestJourneyId = (state, teamId) => {
  const activeCommunityId = state.community.get("activeCommunityId");
  const teamJourneys = state.community.getIn(["teams", activeCommunityId, teamId, "journeys"]);
  if (!teamJourneys?.size) return null;
  const now = moment();
  const latestJourneyStart = teamJourneys.getIn([0, "start"]);
  const latestStartDate = moment(latestJourneyStart, "MM/DD/YYYY");
  if (latestStartDate.isBefore(now)) return teamJourneys.getIn([0, "id"]);
  else return teamJourneys.getIn([1, "id"]);
};
