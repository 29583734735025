import React, { useState } from "react";
import moment from "moment";
import { connect } from "react-redux";

import { Button, Header, Paragraph, Modal } from "../../../Storybook";
import { Box, Notification } from "../../Utility";
import {
  pendingJourneyHeader,
  pendingJourneyMsg,
} from "./notificationMessages";
import { deleteUpcomingJourney } from "../../../containers/Community/actions";

const PendingJourneyNotification = ({
  newestStartDate,
  upcomingJourneyId,
  amLeader,
  deleteUpcomingJourney,
}) => {
  const [infoModal, setInfoModal] = useState("");
  const now = moment.utc();

  const removeUpcomingJourney = async (id) => {
    try {
      await deleteUpcomingJourney(id);
    } catch (e) {
      setInfoModal(
        "There was an issue deleting the current journey. Please try again or contact support if you experience further issues."
      );
    }
  };
  const daysUntilJourneyStart = moment
    .utc(newestStartDate, "MM/DD/YYYY")
    .startOf("day")
    .diff(now, "days");

  return (
    <Notification
      alignContent={"flex-start"}
      bgColor={"darkMagenta500"}
      width={"100%"}
      mb={4}
    >
      {infoModal.length > 0 && (
        <Modal onClose={() => setInfoModal("")}>
          <Paragraph fontSize="fz2" label={infoModal} mt={2} />
        </Modal>
      )}
      <Box>
        <Header
          h3
          colored
          mb={1}
          label={pendingJourneyHeader(daysUntilJourneyStart)}
        />
        <Paragraph colored label={pendingJourneyMsg(daysUntilJourneyStart)} />
      </Box>
      {amLeader && (
        <Button
          data-testid="cancelPendingJourneyBtn"
          secondary
          large
          ml={[0, 3]}
          mt={[2, 0]}
          colored={"darkMagenta500"}
          label={"Cancel"}
          onClick={() => removeUpcomingJourney(upcomingJourneyId)}
        />
      )}
    </Notification>
  );
};
export default connect((state, props) => ({}), { deleteUpcomingJourney })(
  PendingJourneyNotification
);
