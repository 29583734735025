import React from "react";
import { EthereumClient, w3mConnectors, w3mProvider } from "@web3modal/ethereum";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { optimism, optimismGoerli } from "wagmi/chains";
import { Web3Modal } from "@web3modal/react";
import { Buffer } from "buffer";

import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { WC_ID, ALCHEMY_API_KEY } from "../../constants/endpoints";

window.Buffer = window.Buffer || Buffer;
const CHAIN = process.env.REACT_APP_VERSION === "production" ? optimism : optimismGoerli;
// const CHAIN = optimism;
const projectId = WC_ID;
const chains = [CHAIN];

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const connectors = w3mConnectors({ projectId, version: 1, chains });
connectors.push(
  new CoinbaseWalletConnector({
    chains,
    options: {
      appName: "mochi",
      jsonRpcUrl: `https://eth-mainnet.alchemyapi.io/v2/${ALCHEMY_API_KEY}`,
    },
  })
);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);

export const WalletConnect = ({ children }) => {
  return (
    <>
      <WagmiConfig config={wagmiConfig}>{children}</WagmiConfig>
      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </>
  );
};
