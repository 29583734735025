import styled from "styled-components";
import { typography, space } from "styled-system";

const SubPara = styled.p`
  font-family: ${(props) => props.theme.fonts.body};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  color: ${(props) => props.theme.color.white};
  ${typography};
  ${space};
`;

export default SubPara;
