import React from "react";
import PropTypes from "prop-types";

import StyledParagraph from "../../components/Utility/Paragraph";
import darkTheme from "../../styles/theme";

const Paragraph = ({
  fontSize,
  weight,
  height,
  link,
  label,
  color,
  colored,
  theme,
  children,
  mono,
  ...props
}) => {
  const overwriteColor =
    !!color && darkTheme.color[color]
      ? darkTheme.color[color]
      : !color
      ? darkTheme.color.darkFGPrimary
      : color;
  let fontSizes = Array.isArray(fontSize) ? fontSize : [fontSize];
  fontSizes = fontSizes.map((size) =>
    size?.indexOf("px") > 0 ? size : darkTheme.fontSize[size]
  );
  return (
    <StyledParagraph
      fontSize={fontSizes}
      weight={weight}
      height={height}
      link={link}
      color={overwriteColor}
      colored={colored}
      theme={theme}
      mono={mono}
      {...props}
    >
      {children || label}
    </StyledParagraph>
  );
};

const themeSizes = [...Object.values(darkTheme.fontSize)];
Paragraph.propTypes = {
  /**
   * Paragraphs can be passed an array of fontsizes corresponding to the breakpoints, or a size string from the theme
   */
  fontSize: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOf(themeSizes)),
    PropTypes.oneOf(themeSizes),
    PropTypes.arrayOf(PropTypes.oneOf(Object.keys(darkTheme.fontSize))),
    PropTypes.oneOf(Object.keys(darkTheme.fontSize)),
  ]),
  /**
   * Paragraphs can have any of the following weights
   */
  weight: PropTypes.oneOf([400, 600, 700, 800, 900]),
  /**
   * Pass a height to define the font line height
   */
  height: PropTypes.string,
  /**
   * Pass `mono` to use a monotype font
   */
  mono: PropTypes.bool,
  /**
   * Pass a color if the Paragraph should be rendered in that color
   */
  color: PropTypes.string,
  /**
   * Pass `link` if the paragraph contains a link
   */
  link: PropTypes.bool,
  /**
   * Pass `colored` if the paragraph is inside a colored component, and should be rendered in black
   */
  colored: PropTypes.bool,
  /**
   * Pass a string to label property the label wthe paragraph without passing children
   */
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  /**
   * Pass a children like a string, number, array or a component, instead of a label
   */
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.element,
  ]),
  /**
   * Optional, pass a different theme
   */
  theme: PropTypes.object,
};

Paragraph.defaultProps = {
  fontSize: "16px",
  weight: 400,
  color: null,
  link: false,
  colored: false,
  label: null,
  children: null,
  theme: darkTheme,
};

export default Paragraph;
