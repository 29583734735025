import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { connect, useSelector } from "react-redux";

import { GridContainer, TabController } from "../components/Utility";
import { GridBox, Header } from "../Storybook";
import GameItems from "../components/Shop/GameItems";
import AuthorizedHOC from "../components/Utility/AuthorizedHOC";
import { getJourneysForUser } from "../containers/Community/actions";

const ShopPage = ({ getJourneysForUser }) => {
  let location = useLocation();
  const activeCommunityId = useSelector((state) => state.community.get("activeCommunityId"));
  const authedPlayerId = useSelector((state) => state.community.get("authedUserId"));

  useEffect(() => {
    getJourneysForUser(authedPlayerId, activeCommunityId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabs = [
    {
      name: "Game Items",
      path: "/:communityId/shop",
      url: location.pathname,
      selected: true,
      component: GameItems,
    },
  ];
  return (
    <AuthorizedHOC>
      <GridContainer alignContent="flex-start" alignItems="flex-start">
        <GridBox id="shop" mb={-3}>
          <Header h1 label="Shop" />
        </GridBox>
        <TabController tabControllerJustifyContent="flex-start" tabs={tabs} gridStart={[1]} />
      </GridContainer>
    </AuthorizedHOC>
  );
};

export default connect(
  (state) => {
    return {};
  },
  { getJourneysForUser }
)(ShopPage);
