import { fromJS } from "immutable";

import initialState from "./initialState";
import {
  GAME_MODE_FETCH,
  GAME_MODE_SUCCESS,
  GAME_MODE_FAILURE,
} from "../../constants/actionTypes";

/* eslint-disable complexity */
const reducer = (state = fromJS(initialState), action) => {
  switch (action.type) {
    case GAME_MODE_FETCH:
      return state.set("loading", action.data);
    case GAME_MODE_SUCCESS:
      // state.set('loading', false)
      return state.set("all", fromJS(action.data));
    case GAME_MODE_FAILURE:
      return state.set("loading", false);
    default:
      return state;
  }
};

export default reducer;
