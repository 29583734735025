import React, { useEffect } from "react";
import { connect } from "react-redux";

import { getAllGameItems, getPlayerInventory } from "../../containers/Shop/actions";
import { GridBox } from "../../Storybook";
import { genNewId } from "../../utils/mochiHelpers";
import { Flex } from "../Utility";
import { gameItemDetails } from "./itemDetails";
import ShopItem from "./ShopItem";

const GameItems = ({ gameItems, getAllGameItems, getPlayerInventory }) => {
  useEffect(() => {
    if (!gameItems?.size) getAllGameItems();
  }, [gameItems, getAllGameItems]);

  useEffect(() => {
    getPlayerInventory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GridBox id="game-items">
      <Flex
        flexWrap="wrap"
        alignItems="stretch"
        alignContent="flex-start"
        justifyContent="center"
        mt={4}
      >
        {gameItems?.map((item) => (
          <ShopItem
            item={item}
            key={genNewId("shop-item-")}
            itemDetails={gameItemDetails[item.get("item_type")]}
          />
        ))}
      </Flex>
    </GridBox>
  );
};

export default connect(
  (state) => {
    const activeCommunityId = state.community.get("activeCommunityId");
    return {
      gameItems: state.shop.getIn(["gameItems", activeCommunityId]),
    };
  },
  { getAllGameItems, getPlayerInventory }
)(GameItems);
