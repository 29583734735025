import { createPublicClient, http, getContract, parseEther, stringToBytes, toHex } from "viem";
import { optimism, optimismGoerli } from "viem/chains";
import { fromJS } from "immutable";

import axios from "../../config/axios";
import { MM_ADDCHAIN_DATA } from "./web3modal";

import {
  API_ENDPOINT,
  ALCHEMY_API_KEY,
  DEFAULT_MOCHI_INVITE,
  WEB3_NETWORK,
} from "../../constants/endpoints";
import MochiProdContract from "../../contracts/Mochi.js";
import MochiStagingContract from "../../contracts/Mochi-NEW.js";
import MochiTokenContract from "../../contracts/MochiToken.json";
import {
  WEB3_INIT,
  CONTRACT_INIT,
  AUTH_SUCCESS,
  DISCONNECT_WEB3,
  SET_CURRENT_NETWORK,
  MOCHI_TOKEN_BALANCE,
} from "../../constants/actionTypes";
import { fetchProfileInfo } from "../Community/actions";
import {
  sendNetworkErrorToSentry,
  sendSignInErrorToSentry,
  sendTxErrorToSentry,
} from "../Utility/sentryHelpers";

let ethProvider;
let currentNetwork;
let chain;
let MochiContract;

if (process.env.REACT_APP_VERSION === "production") MochiContract = MochiProdContract;
else MochiContract = MochiStagingContract;

if (process.env.REACT_APP_VERSION === "production") chain = optimism;
else chain = optimismGoerli;

const transport = http(`https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_API_KEY}`);

const client = createPublicClient({
  chain,
  transport,
});

const getMochiContractInstances = (dispatch, getState) => {
  const activeCommunityId = getState().community.get("activeCommunityId");
  const existingContractInstance = getState().web3.getIn(["contractInstances", activeCommunityId]);
  if (existingContractInstance) return existingContractInstance;
  else {
    const mochiGameContractInstance = getContract({
      ...MochiContract.abi,
      publicClient: client,
    });

    const mochiTokenContractInstance = getContract({
      ...MochiTokenContract.abi,
      publicClient: client,
    });

    dispatch({
      type: CONTRACT_INIT,
      activeCommunityId,
      gameContract: mochiGameContractInstance,
      tokenContract: mochiTokenContractInstance,
    });

    return fromJS({
      gameContract: mochiGameContractInstance,
      tokenContract: mochiTokenContractInstance,
    });
  }
};

export const initializeWeb3 =
  (communityInvitationId, activeCommunityIdOrNull) => async (dispatch, getState) => {
    let ethereumAddress = "";
    let currentNetwork;

    dispatch({
      type: WEB3_INIT,
      address: ethereumAddress,
      // web3Modal,
      provider: ethProvider,
      currentNetwork,
    });

    let token = window.localStorage.getItem("token") || "";
    let loginRes;

    try {
      if (!token && ethProvider) {
        const auth_token = await (await axios.get(`${API_ENDPOINT}/login/request/`)).data;
        const signer = ethProvider.getSigner();
        const message = `all connections are reflections ~ ${auth_token}`;
        let signedMessage = "";
        if (ethProvider.provider.wc) {
          signedMessage = await ethProvider.send("personal_sign", [
            toHex(stringToBytes(message)),
            ethereumAddress.toLowerCase(),
          ]);
        } else {
          signedMessage = await signer.signMessage(message);
        }

        loginRes = await axios.post(`${API_ENDPOINT}/login-sign/`, {
          signature: signedMessage,
          auth_token,
          community_invitation: communityInvitationId || DEFAULT_MOCHI_INVITE,
        });
        token = loginRes.data.token;
        window.localStorage.setItem("token", token);

        axios.defaults.headers.common = {
          Authorization: `Token ${token}`,
        };
      }

      if (loginRes?.data) {
        dispatch({
          type: AUTH_SUCCESS,
          data: {
            user: loginRes.data,
            activeCommunityIdOrNull,
          },
        });
        fetchProfileInfo(loginRes.data, dispatch, getState);
      }

      getMochiContractInstances(dispatch, getState);
    } catch (e) {
      sendSignInErrorToSentry(e, communityInvitationId);
      throw e;
    }
  };

export const getAuthToken = async () => {
  const auth_token = await (await axios.get(`${API_ENDPOINT}/login/request/`)).data;
  const message = `all connections are reflections ~ ${auth_token}`;
  return message;
};

export const authenticateUser =
  (communityInvitationId, activeCommunityIdOrNull, signature, auth_token) =>
  async (dispatch, getState) => {
    const loginRes = await axios.post(`${API_ENDPOINT}/login-sign/`, {
      signature,
      auth_token,
      community_invitation: communityInvitationId || DEFAULT_MOCHI_INVITE,
    });

    window.localStorage.setItem("token", loginRes.data.token);
    axios.defaults.headers.common = {
      Authorization: `Token ${loginRes.data.token}`,
    };

    if (loginRes?.data) {
      dispatch({
        type: AUTH_SUCCESS,
        data: {
          user: loginRes.data,
          activeCommunityIdOrNull,
        },
      });
      fetchProfileInfo(loginRes.data, dispatch, getState);
      let communityId = activeCommunityIdOrNull
        ? activeCommunityIdOrNull
        : loginRes.data.active_communities.reduce((id, i) => (i.id < id ? i.id : id), 1000000);
      return {
        communityId,
        userId: loginRes.data.user_id,
      };
    }
  };

export const switchToCorrectNetwork = () => async (dispatch) => {
  const { ethereum } = window;
  if (!!ethereum) await switchWeb3Network();
  dispatch({
    type: SET_CURRENT_NETWORK,
    currentNetwork: WEB3_NETWORK,
  });
};

const switchWeb3Network = async () => {
  const { ethereum } = window;
  try {
    await ethereum.request({
      id: "1",
      jsonrpc: "2.0",
      method: "wallet_switchEthereumChain",
      params: [{ chainId: MM_ADDCHAIN_DATA[WEB3_NETWORK].chainId }],
    });
  } catch (e) {
    sendNetworkErrorToSentry(e, currentNetwork);
    try {
      await ethereum.request({
        id: "1",
        jsonrpc: "2.0",
        method: "wallet_addEthereumChain",
        params: [MM_ADDCHAIN_DATA[WEB3_NETWORK]],
      });
    } catch (e) {
      throw e.message;
    }
  }
};

export const depositEth =
  (amount, depositingFromDifferentAccount = false, depositingFor = "") =>
  async (dispatch, getState) => {
    return new Promise(async (res, rej) => {
      const weiAmount = parseEther(amount);
      // send money to multisig if in prod
      let tx;
      const gameContract = getMochiContractInstances(dispatch, getState).get("gameContract");
      if (!depositingFromDifferentAccount) {
        try {
          tx = await gameContract.deposit({ value: weiAmount });
          await tx.wait();
          res(tx);
        } catch (err) {
          sendTxErrorToSentry(err, tx);
          rej(err);
        }
      } else {
        try {
          tx = await gameContract.topUpForPlayer(depositingFor, {
            value: weiAmount,
          });
          await tx.wait();
          res(tx);
        } catch (err) {
          sendTxErrorToSentry(err, tx);
          rej(err);
        }
      }
    });
  };

export const disconnectWeb3 = () => async (dispatch, getState) => {
  const web3Modal = getState().web3.get("web3Modal");
  web3Modal.clearCachedProvider();

  dispatch({
    type: DISCONNECT_WEB3,
  });
};

export const getMochiTokenBalance = () => async (dispatch, getState) => {
  if (window.ethereum) {
    try {
      const ethereumAddress = getState().web3.get("ethereumAddress");
      const tokenContract = getMochiContractInstances(dispatch, getState).get("tokenContract");

      let mochiTokenBalance = 0;
      mochiTokenBalance = await tokenContract.balanceOf(ethereumAddress);

      dispatch({
        type: MOCHI_TOKEN_BALANCE,
        mochiTokenBalance,
      });
    } catch (error) {
      console.log(error);
    }
  }
};
