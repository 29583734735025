/////////////////////////////
// Web3 Actions
/////////////////////////////
export const WEB3_INIT = "WEB3_INIT";
export const WEB3_NOT_EXIST = "WEB3_NOT_EXIST";
export const CONTRACT_INIT = "CONTRACT_INIT";
export const DISCONNECT_WEB3 = "DISCONNECT_WEB3";
export const CORRECT_NETWORK = "CORRECT_NETWORK";
export const MOCHI_TOKEN_BALANCE = "MOCHI_TOKEN_BALANCE";
export const SET_CURRENT_NETWORK = "SET_CURRENT_NETWORK";

/////////////////////////////
// User Actions
/////////////////////////////
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILURE = "AUTH_FAILURE";
// TODO: this is never used in an action and should be removed
export const SET_ACTIVE_COMMUNITY = "SET_ACTIVE_COMMUNITY";
export const LOG_OUT = "LOG_OUT";
export const CONFIRM_ACCOUNT = "CONFIRM_ACCOUNT";
export const EDIT_PERSON = "EDIT_PERSON";
export const GET_PERSONAL_STATS = "GET_PERSONAL_STATS";
export const TOP_UP = "TOP_UP";
export const TOP_UP_FOR_PLAYER = "TOP_UP_FOR_PLAYER";
export const WITHDRAW = "WITHDRAW";
export const GET_CUSTOMER_DATA = "GET_CUSTOMER_DATA";
export const SET_PAUSE_DATE = "SET_PAUSE_DATE";
export const DELETE_PAUSE_DATE = "DELETE_PAUSE_DATE";
export const GET_JOURNEY_DETAILS = "GET_JOURNEY_DETAILS";

/////////////////////////////
// Community-Membership Actions
/////////////////////////////
export const EDIT_COMMUNITY_MEMBERSHIP = "EDIT_COMMUNITY_MEMBERSHIP";

/////////////////////////////
// Community-based Actions
/////////////////////////////
export const ACCEPT_COMMUNITY_INVITATION = "ACCEPT_COMMUNITY_INVITATION";
export const GET_COMMUNITY_INVITATION = "GET_COMMUNITY_INVITATION";
export const COMMUNITY_INVITATION_FAIL = "COMMUNITY_INVITATION_FAIL";
export const TOGGLE_COMMUNITY = "TOGGLE_COMMUNITY";
export const GET_COMMUNITY_INFO = "GET_COMMUNITY_INFO";
export const INSTALL_MOCHIBOT = "INSTALL_MOCHIBOT";

/////////////////////////////
// Discord-based Actions
/////////////////////////////
export const GET_DISCORD_USER_AVATAR = "GET_DISCORD_USER_AVATAR";
export const GET_DISCORD_COMMUNITY_ICON = "GET_DISCORD_COMMUNITY_ICON";

/////////////////////////////
// Team-based Actions
/////////////////////////////
export const TEAM_INFO = "TEAM_INFO";
export const JOURNEY_INFO = "JOURNEY_INFO";
export const REMOVE_MEMBERSHIP = "REMOVE_MEMBERSHIP";
export const REMOVE_TEAM_MEMBER = "REMOVE_TEAM_MEMBER";

export const TEAM_START_EPOCH = "TEAM_START_EPOCH";
export const TEAM_REMOVE_JOURNEY = "TEAM_REMOVE_JOURNEY";

export const TEAM_INVITATION = "TEAM_INVITATION";
export const TEAM_INVITATION_DELETE = "TEAM_INVITATION_DELETE";
export const TEAM_INVITATION_ACCEPT = "TEAM_INVITATION_ACCEPT";
export const TEAM_INVITATION_DECLINE = "TEAM_INVITATION_DECLINE";

/////////////////////////////
// Journey Wave Actions
/////////////////////////////
export const JOIN_WAVE = "JOIN_WAVE";
export const LEAVE_WAVE = "LEAVE_WAVE";
export const START_WAVE = "START_WAVE";
/////////////////////////////
// General Game Actions
/////////////////////////////
export const GAME_MODE_FETCH = "GAME_MODE_FETCH";
export const GAME_MODE_SUCCESS = "GAME_MODE_SUCCESS";
export const GAME_MODE_FAILURE = "GAME_MODE_FAILURE";
export const FREE_USER_LOAD = "FREE_USER_LOAD";
export const FREE_TEAM_LOAD = "FREE_TEAM_LOAD";

/////////////////////////////
// Mochi Player Actions
/////////////////////////////
export const EXTERNAL_PLAYER_DATA_FETCH = "EXTERNAL_PLAYER_DATA_FETCH";
export const EXTERNAL_PLAYER_DATA_FETCH_FAILURE = "EXTERNAL_PLAYER_DATA_FETCH_FAILURE";
export const EXTERNAL_PLAYER_STATS = "EXTERNAL_PLAYER_STATS";

/////////////////////////////
// Mochi Team Actions
/////////////////////////////
export const TEAM_FETCH = "TEAM_FETCH";

/////////////////////////////
// Shop Actions
/////////////////////////////
export const FETCH_GAME_ITEMS = "FETCH_GAME_ITEMS";
export const BUY_GAME_ITEM = "BUY_GAME_ITEM";
export const FETCH_PLAYER_INVENTORY = "FETCH_PLAYER_INVENTORY";
export const APPLY_SHOP_ITEM = "APPLY_SHOP_ITEM";

/////////////////////////////
// CheckIn Report Actions
/////////////////////////////
export const GET_CHECKINS = "GET_CHECKINS";
export const INITIATE_REPORT = "INITIATE_REPORT";
export const SUBMIT_CHECKIN = "SUBMIT_CHECKIN";
export const UPDATE_CHECKIN = "UPDATE_CHECKIN";
