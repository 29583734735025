const freeAgentInitialState = {
  userData: {},
  userCount: {},
  userPage: 1,
  teamData: {},
  teamCount: {},
  teamPage: 1,
};

export default freeAgentInitialState;
