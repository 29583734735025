import styled, { css } from "styled-components";

const Avatar = styled.img`
  position: static;
  height: 24px;
  width: 24px;
  border-radius: 100%;
  margin-right: ${(props) => (props.menuBurger ? 0 : props.theme.pixels.sz2)};
  object-fit: cover no-repeat;

  ${(props) =>
    props.square &&
    css`
      border-radius: 0;
    `}

  ${(props) =>
    props.large &&
    css`
      height: ${(props) => props.theme.pixels.sz4};
      width: ${(props) => props.theme.pixels.sz4};
    `};
`;

export default Avatar;
