import { fromJS } from "immutable";
import {
  GET_CHECKINS,
  INITIATE_REPORT,
  SUBMIT_CHECKIN,
  UPDATE_CHECKIN,
  JOURNEY_INFO,
} from "../../constants/actionTypes";
import initialState from "./initialState";
import moment from "moment";

const reducer = (state = fromJS(initialState), action) => {
  switch (action.type) {
    case GET_CHECKINS:
      return state.update("outstanding", (v) => {
        action.data?.forEach((c) => (v = v.setIn([c.community], fromJS(c))));
        return v;
      });
    case INITIATE_REPORT:
      return state.update("outstanding", (s) => {
        return s.setIn([action.data.community], fromJS(action.data));
      });
    case SUBMIT_CHECKIN:
      const indexOfActiveGoalToUpdate = state.get("activeGoals").findIndex((item) => {
        return item.get("id") === action.data.journey;
      });
      return state
        .setIn(
          ["activeGoals", indexOfActiveGoalToUpdate, "last_checkin"],
          fromJS(moment(action.data.updated).format("MM/DD/YYYY"))
        )
        .setIn(["outstanding", action.communityId], fromJS(action.data));
    case UPDATE_CHECKIN:
      return state.setIn(["outstanding", action.communityId], fromJS(action.data));
    case JOURNEY_INFO:
      const data = action.data?.results?.find((journey) => journey.id === action.currentJourney);
      if (
        data == null ||
        data?.next_checkin === null ||
        action?.journeyGoal === null ||
        action?.currentJourney === null
      ) {
        return state;
      }
      const new_data = {
        id: data.id,
        last_checkin: data.last_checkin,
        next_checkin: data.next_checkin,
        start: data.start,
        end: data.end,
        goal: action.journeyGoal,
        community_id: action.communityId,
      };
      return state.update("activeGoals", (s) => {
        return [...s.filter((item) => item.get("id") !== data.id), fromJS(new_data)];
      });
    default:
      return state;
  }
};

export default reducer;
