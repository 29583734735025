import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import axios from "../../config/axios";
import { reviveSession } from "../../containers/Auth/actions";
import { fetchGameModes } from "../../containers/GameMode/actions";

const AppWrapper = ({ children, reviveSession, fetchGameModes, gameModes }) => {
  let history = useHistory();
  const [authAttempted, setAuthAttempted] = useState(false);

  const getCommunityIdFromPath = useCallback(() => {
    let activeCommunityId = history.location.pathname.split("/")[1];
    return isNaN(activeCommunityId) || parseInt(activeCommunityId) === 404
      ? null
      : parseInt(activeCommunityId);
  }, [history]);

  useEffect(() => {
    if (!gameModes?.size) fetchGameModes();
  }, [fetchGameModes, gameModes]);

  useEffect(() => {
    if (axios.defaults.headers.common?.["Authorization"] && !authAttempted) {
      let activeCommunityId = getCommunityIdFromPath();
      setAuthAttempted(true);
      reviveSession(activeCommunityId);
    }
  }, [authAttempted, setAuthAttempted, getCommunityIdFromPath, reviveSession]);

  return <div>{children}</div>;
};

export default connect(
  (state) => ({
    gameModes: state.gameModes.get("all"),
  }),
  {
    reviveSession,
    fetchGameModes,
  }
)(AppWrapper);
