import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { Box, Flex } from "../Utility";
import {
  fetchDiscorduserAvatar,
  getExternalPlayerInfo,
} from "../../containers/Community/actions";
import { Avatar, Paragraph, Button } from "../../Storybook";
import * as rank from "../../img/rank";
import defaultImage from "../../img/logo/mochi/Mochi-Icon.png";

const PlayerListItem = ({
  playerData,
  playerOutline,
  buttonText,
  buttonAction,
  fetchDiscorduserAvatar,
  getExternalPlayerInfo,
  activeCommunityId,
  playerId,
}) => {
  useEffect(() => {
    if (!playerData?.getIn(["details", "image_512"]))
      fetchDiscorduserAvatar(activeCommunityId, playerId);
  });

  const playerName = playerData?.getIn(["details", "display_name"]);
  const playerRank = playerOutline.get("rank");

  return (
    <Flex
      flexDirection={["column", "row"]}
      justifyContent={["flex-start", "space-between"]}
      alignContent="flex-start"
      alignItems="center"
      mb={3}
    >
      <Flex
        flexDirection={["column", "row"]}
        justifyContent="flex-start"
        alignContent="flex-start"
        alignItems="center"
        width={["100%", "auto"]}
        mb={[1, 0]}
      >
        <Flex
          justifyContent="flex-start"
          alignContent="flex-start"
          alignItems="center"
          width={["100%", "auto"]}
        >
          {!!rank[playerRank] ? (
            <img
              src={rank[playerRank]}
              alt={`${playerName}'s rank ${playerRank}`}
              width="22px"
              height="22px"
            />
          ) : (
            <Box width="22px" height="22px" />
          )}
          <Flex ml={3} mr={2} alignItems="flex-end">
            <Avatar
              image={
                playerData?.getIn(["details", "image_512"]) || defaultImage
              }
              alt={`${playerName}'s avatar`}
            />
          </Flex>
          <Link
            to={`/${activeCommunityId}/profile/${playerId}`}
            onClick={() => getExternalPlayerInfo(playerId, activeCommunityId)}
          >
            <Paragraph label={playerName} weight={600} mr={2} />
          </Link>
        </Flex>
        <Flex
          justifyContent="flex-start"
          alignContent="flex-start"
          alignItems="center"
          width={["100%", "auto"]}
        >
          <Paragraph
            label={playerOutline.get("current_goal")}
            fontSize="fz1"
            color="darkFGSecondary"
            alignText="left"
            width={["100%", "auto"]}
          />
        </Flex>
      </Flex>
      {!!buttonText && (
        <Flex
          justifyContent={["flex-start", "flex-end"]}
          alignItems="center"
          width={["100%", "auto"]}
        >
          <Button
            secondary
            label={buttonText}
            noWrap
            thin
            height="100%"
            onClick={() => buttonAction()}
            mt={[2, 0]}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default connect(
  (state, props) => {
    const playerId = props.playerOutline.get("user");
    const activeCommunityId = state.community.get("activeCommunityId");
    return {
      activeCommunityId,
      playerId,
      playerData: state.community.getIn([
        "players",
        activeCommunityId,
        playerId,
      ]),
    };
  },
  { fetchDiscorduserAvatar, getExternalPlayerInfo }
)(PlayerListItem);
