import React from "react";

import Alpha from "../../img/batch/alpha.svg";
import Beta from "../../img/batch/beta.svg";
import Passionfruit from "../../img/batch/passionFruit.svg";
import OrangeMango from "../../img/batch/orangeMango.svg";
import Jackfruit from "../../img/batch/jackfruit.png";
import { Flex } from "../Utility";
import { Header } from "../../Storybook";

const images = {
  alpha: Alpha,
  beta: Beta,
  passionfruit: Passionfruit,
  "orange-mango": OrangeMango,
  jackfruit: Jackfruit,
};

export const BatchIndicator = ({ batchName }) => {
  const batchNameArray = batchName.split("-");
  const nameCapitalized = batchNameArray
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1))
    .join(" ");

  const altText = "The user's batch is " + nameCapitalized;

  return (
    <Flex mb={3} alignItems="center" justifyContent="flex-start">
      <img src={images[batchName]} alt={altText} width="22px" />
      <Header h4 fontWeight={600} ml={2}>
        Batch {nameCapitalized}
      </Header>
    </Flex>
  );
};

export default BatchIndicator;
