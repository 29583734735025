import React, { useMemo } from "react";
import { GridBox } from "../../Storybook";
import theme from "../../styles/theme";
import { Flex, Box } from "../Utility";
import CheckInGoal from "./CheckInGoal";

const CheckInGoals = ({ currentGoals, checkInGoal, setCheckInGoal, showComplete }) => {
  const colors = ["darkMagenta500", "darkCyan500", "darkOrange500"];

  // Ensure that goal colors remain consistent as they are completed
  const goals = useMemo(
    () =>
      currentGoals.filter((g, i) => {
        g.originalIndex = i;
        return showComplete || g.get("next_checkin") !== g.get("last_checkin");
      }),
    [currentGoals, showComplete]
  );

  return (
    <GridBox display="flex" flexDirection="row" justifyContent="flex-start">
      <Box width={theme.pixels.sz4} mr={theme.pixels.sz2} />
      <Flex style={{ gridGap: "20px" }} alignItems="stretch">
        {goals?.map((g, i) => (
          <CheckInGoal
            key={g.get("id")}
            goal={g}
            color={colors[g.originalIndex % 3]}
            communityId={g.get("community_id")}
            nextCheckin={g.get("next_checkin")}
            lastCheckin={g.get("last_checkin")}
            selected={checkInGoal && checkInGoal?.get("id") === g.get("id")}
            setCheckInGoal={setCheckInGoal}
          />
        ))}
      </Flex>
    </GridBox>
  );
};

// width={[1 / 2, 1]}
export default CheckInGoals;
