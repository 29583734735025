import React from "react";

import AuthorizedHOC from "../components/Utility/AuthorizedHOC";
import { GridContainer, Box, Flex } from "../components/Utility";
import { GridBox } from "../Storybook";
import { Paragraph, Header } from "../Storybook";
import { MembershipPerks } from "../components/Onboarding/MembershipPerks";
import PriceRouting from "../components/Onboarding/PriceRouting";

export const MembershipPage = () => {
  return (
    <AuthorizedHOC>
      <GridContainer>
        <GridBox span={[2, 4, 6]} start={[2, 3, 4]} mb={4}>
          <Flex justifyContent="center">
            <Header h1 mr={2} label="Mochi" />
            <Header h1 mr={2} label="&#128184;" />
          </Flex>
          <Flex justifyContent="center">
            <Header h1 textAlign="center" label="Membership" />
          </Flex>
        </GridBox>
        <GridBox span={[2, 4, 6]} start={[2, 3, 4]}>
          <Paragraph
            mb={3}
            label="We like you. You like us. Why don't we make it official?"
          />
          <Paragraph
            mb={40}
            label="You've done a batch, and met the gang-it's mutual. Mochi
                Membership is the last step to take on the journey to becoming
                the superfirm rice cake you know you are."
          />
        </GridBox>
        <GridBox
          display="grid"
          m="0 20px"
          gridTemplateColumns={[["100%"], ["10% 30% 60%"]]}
        >
          <div />
          <Header label="Membership Benefits" mb={2} h4 />
          <Flex alignItems="center">
            <Paragraph
              opacity={["0%", "100%"]}
              fontSize="fz1"
              label="Full Members"
            />
            <Paragraph
              opacity={["0%", "100%"]}
              fontSize="fz1"
              label="Batch Candidates"
            />
            <Paragraph
              opacity={["0%", "100%"]}
              fontSize="fz1"
              label="Non-members"
            />
          </Flex>
        </GridBox>
        <GridBox>
          <MembershipPerks />
          <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
            <PriceRouting />
          </Box>
        </GridBox>
      </GridContainer>
    </AuthorizedHOC>
  );
};

export default MembershipPage;
