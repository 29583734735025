import React from "react";
import { PropTypes } from "prop-types";
import { Link, useParams, useLocation } from "react-router-dom";

import { plain } from "../../img/colors";
import { Flex } from "../Utility";
import { colors } from "../../utils/mochiHelpers";

export const MochiCharacter = ({ mochiColor, isAnotherPlayersProfile, match }) => {
  let colorImg;
  let mochiAlt = mochiColor;
  let { communityId } = useParams();
  let location = useLocation();

  // match color in list of all colors
  const currentColor = colors.find((color) => color.get("name") === mochiColor);

  if (currentColor) colorImg = currentColor.get("img");
  else {
    colorImg = plain;
    mochiAlt = "Question Mark on Blank Mochi";
  }
  const colorLink = `/${communityId}/color`;

  return (
    <Link
      to={
        location.pathname.includes("profile") && !isAnotherPlayersProfile
          ? colorLink
          : location.pathname
      }
    >
      <Flex id="mochi-character">
        <img
          width="65%"
          height="65%"
          src={colorImg}
          alt={mochiAlt}
          style={{
            marginRight: "1.5%",
            marginTop: colorImg === plain ? "-1%" : 0,
            marginBottom: colorImg === plain ? "2%" : 0,
          }}
        />
      </Flex>
    </Link>
  );
};

MochiCharacter.propTypes = {
  mochiColor: PropTypes.string,
  isAnotherPlayersProfile: PropTypes.bool,
};

MochiCharacter.defaultProps = {
  mochiColor: null,
  isAnotherPlayersProfile: null,
};

export default MochiCharacter;
