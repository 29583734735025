import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

import StyledInput from "../../components/Utility/Input";
import darkTheme from "../../styles/theme";
import { Box, Flex } from "../../components/Utility";
import { WarningSmall } from "../../img/svg/icons";
import { Header, Paragraph } from "../index";

const Input = ({
  theme,
  type,
  value,
  valid,
  touched,
  activeInput,
  needed,
  placeholder,
  textLabel,
  icon,
  colored,
  submited,
  small,
  error,
  overrideError,
  bottomLabel,
  specificId,
  hideSpin,
  ...props
}) => {
  const [alteredType, setAlteredType] = useState(type);

  const id = specificId || uuidv4();
  const renderColor = colored ? "colorFGPrimary" : "darkNegative500";
  const checkboxOrRadio = type === "radio" || type === "checkbox";
  const displayError =
    (needed && touched && !activeInput && !valid) ||
    (submited && !valid) ||
    (type === "date" && !valid && touched);
  const success = value && valid;
  const iconPath = !!theme.icons[icon] ? theme.icons[icon] : icon;

  const togglePassword = () => {
    if (type === "password" && alteredType === "password") setAlteredType("text");
    else if (type === "password" && alteredType === "text") setAlteredType(type);
  };

  const role = type === "checkbox" ? "checkbox" : "textbox";

  return (
    <Fragment>
      <Box
        display={checkboxOrRadio ? "flex" : "block"}
        style={{
          width: "100%",
          position: "relative",
        }}
      >
        <StyledInput
          type={alteredType}
          password={type === "password"}
          placeholder={placeholder}
          theme={theme}
          icon={iconPath}
          error={displayError}
          overrideError={overrideError}
          success={success}
          colored={colored}
          small={small}
          bottomLabel={bottomLabel}
          submited={submited}
          hideSpin={hideSpin}
          aria-labelledby={textLabel}
          id={id}
          value={value}
          role={role}
          {...props}
        ></StyledInput>
        {type === "password" && (
          <div
            onClick={() => type === "password" && togglePassword()}
            style={{
              position: "absolute",
              left: "0",
              top: "0",
              height: "36px",
              width: "36px",
            }}
          />
        )}
        <label htmlFor={id}>
          {textLabel &&
            (!checkboxOrRadio ? (
              <Header
                h5
                label={textLabel}
                theme={theme}
                color={colored ? "colorFGPrimary" : "darkFGPrimary"}
                textAlign="left"
              />
            ) : (
              <Paragraph
                fontSize={small ? "fz1" : "fz3"}
                weight={small ? 600 : 400}
                label={textLabel}
                color={colored ? "darkBGPrimary" : "darkFGPrimary"}
              />
            ))}
        </label>
        <Box height="40px">
          {((displayError && error) || (overrideError && touched)) && (
            <Flex
              flexDirection="row"
              flexWrap="nowrap"
              alignContent="flex-start"
              justifyContent="flex-start"
              alignItems="center"
              pt={2}
              pb={2}
            >
              <Box width="20px" alignSelf="flex-start">
                <WarningSmall color={renderColor} />
              </Box>
              <Paragraph
                pl={error && error.length > 55 ? 3 : 2}
                color={renderColor}
                textAlign="left"
                fontSize="fz1"
                m={0}
              >
                {error || overrideError}
              </Paragraph>
            </Flex>
          )}
        </Box>
      </Box>
    </Fragment>
  );
};

Input.propTypes = {
  /**
   * Input component requires one of these types
   */
  type: PropTypes.oneOf([
    "button",
    "checkbox",
    "color",
    "date",
    "datetime-local",
    "email",
    "hidden",
    "image",
    "month",
    "number",
    "password",
    "radio",
    "range",
    "reset",
    "search",
    "submit",
    "tel",
    "text",
    "time",
    "url",
    "week",
  ]).isRequired,
  /**
   * Pass a string to display, valid for all types instead checkbox and radio
   */
  placeholder: PropTypes.string,
  /**
   * pass the input label you'd like to display
   */
  textLabel: PropTypes.string,
  /**
   * Pass an error message to display. Display is determined in this component by built in props
   */
  error: PropTypes.string,
  /**
   * Pass an error message to display an error state, when it otherwise
   */
  overrideError: PropTypes.string,
  /**
   * If expecting to display an error, pass this active prop from useInput
   */
  activeInput: PropTypes.bool,
  /**
   * Pass an icon name from the theme icons
   */
  icon: PropTypes.string,
  /**
   * Check type default is large, pass small if need smaller size
   */
  small: PropTypes.bool,
  /**
   * Pass colored if Input is on a colored container
   */
  colored: PropTypes.bool,
  /**
   * Pass if need label placed below *checkbox* type input
   */
  bottomLabel: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.bool)]),
  /**
   * Pass an specific id, if you want to be able to reference it from other files.
   */
  specificId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Optional pass another theme once we have Light
   */
  theme: PropTypes.object,
  /**
   * Hides arrows on number type
   */
  hideSpin: PropTypes.bool,

  // These props are only used for simulating state
  /**
   * Built-in prop. Don't pass unless simulating a states
   */
  submited: PropTypes.bool,
  /**
   * Built-in prop. Don't pass unless simulating a states
   */
  needed: PropTypes.bool,
  /**
   * Built-in prop. Don't pass unless simulating a states
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Built-in prop. Don't pass unless simulating a touched state
   */
  touched: PropTypes.bool,
  /**
   * Built-in prop. Don't pass unless simulating a valid state
   */
  valid: PropTypes.bool,
};

Input.defaultProps = {
  theme: darkTheme,
  type: null,
  activeInput: false,
  placeholder: "",
  textLabel: "",
  icon: null,
  colored: false,
  small: false,
  error: null,
  bottomLabel: false,
  specificId: null,
  hideSpin: false,
};

export default Input;
