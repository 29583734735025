import styled, { css } from "styled-components";

const Step = styled.div`
font-family: ${(props) => props.theme.fonts.headers}
font-weight: 600;
font-size: 12px;
line-height: 14px;
margin: 20px 20px 20px 0px;
color: ${(props) => props.theme.color.white}

${(props) =>
  props.active &&
  css`
    & > span {
      border-bottom: 1px solid ${(props) => props.theme.color.green};
    }
  `}
${(props) =>
  props.finished &&
  css`
    opacity: 0.6;
  `}

${(props) =>
  props.stacked &&
  css`
    margin: 10px 0;
  `}

${(props) =>
  !props.last &&
  !props.stacked &&
  css`
    &:after {
      content: ">";
      margin-left: 20px;
    }
  `}
`;

export default Step;
