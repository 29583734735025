import React from "react";

import "./checkInAni.css";

const CheckInAni = () => (
  <div className="checkin-anim-container">
    <div className="checkin-form">
      <div className="checkin-thread">
        <div id="checkin-m7" className="checkin-message checkin-null">
          <p className="checkin-sender">n_ll</p>
          <p className="checkin-content">
            Thanks for showing up - take some tokens ✨
          </p>
        </div>
        <div id="checkin-m6" className="checkin-message checkin-player">
          <p className="checkin-sender">4nubis</p>
          <p className="checkin-content">
            gonna schedule some time with lucy, she said she wants to help out
            too
          </p>
        </div>
        <div id="checkin-m5" className="checkin-message checkin-null">
          <p className="checkin-sender">n_ll</p>
          <p className="checkin-content">What will you do today?</p>
        </div>
        <div id="checkin-m4" className="checkin-message checkin-player">
          <p className="checkin-sender">4nubis</p>
          <p className="checkin-content">
            not a ton, i did get the repo up and running tho
          </p>
        </div>
        <div id="checkin-m3" className="checkin-message checkin-null">
          <p className="checkin-sender">n_ll</p>
          <p className="checkin-content">
            What progress did you make yesterday?
          </p>
        </div>
        <div id="checkin-m2" className="checkin-message checkin-player">
          <p className="checkin-sender">4nubis</p>
          <p className="checkin-content">
            feeling OK null - got a good start this morning
          </p>
        </div>
        <div id="checkin-m1" className="checkin-message checkin-null">
          <p className="checkin-sender">n_ll</p>
          <p className="checkin-content">How are you doing today?</p>
        </div>
      </div>
      <div className="checkin-info">
        <div className="checkin-avatar checkin-null">
          <img
            src="https://res.cloudinary.com/dh1l1uwde/image/upload/v1642107906/null_o-removebg-preview_nrw4vo.png"
            alt=""
          />
        </div>
        <p className="checkin-player-info">Extra-firm - 2x tokens</p>
        <div className="token-box">
          <div id="t1" className="token">
            <svg
              width="12"
              height="12"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.0861 2.92553C11.8152 2.93636 11.5226 2.96888 11.2408 3.0339C10.4605 4.91961 9.58265 6.75114 8.62895 8.55016C7.54521 6.9137 6.68905 5.10385 5.85457 3.32651C5.52945 3.294 5.20432 3.31567 4.8792 3.32651C4.85753 3.32651 4.85753 3.31567 4.84669 3.31567C4.58659 3.32651 4.15309 3.36986 3.89299 3.43489C3.43782 6.25262 2.16984 11.9856 1.73634 13.8172C1.95309 14.5107 5.0526 13.9364 5.0526 13.7955C5.09595 11.9748 5.43191 10.3817 5.80038 8.71272C6.50482 9.94819 7.31762 11.162 8.13043 12.3216C8.39053 12.3433 8.6723 12.3433 8.9324 12.3324C9.17083 12.3216 9.49595 12.2891 9.72354 12.2457C10.3088 10.9886 10.8073 9.69893 11.425 8.46346C12.0644 10.0024 12.7363 12.6034 12.8014 13.2319C12.823 13.3945 15.229 13.5137 15.8792 12.8851C14.6437 9.51469 13.8959 6.43686 13.1048 2.9472C12.823 2.90385 12.3679 2.91469 12.0861 2.92553Z"
                fill="#FFB800"
              />
            </svg>
          </div>
          <div id="t2" className="token">
            <svg
              width="12"
              height="12"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.0861 2.92553C11.8152 2.93636 11.5226 2.96888 11.2408 3.0339C10.4605 4.91961 9.58265 6.75114 8.62895 8.55016C7.54521 6.9137 6.68905 5.10385 5.85457 3.32651C5.52945 3.294 5.20432 3.31567 4.8792 3.32651C4.85753 3.32651 4.85753 3.31567 4.84669 3.31567C4.58659 3.32651 4.15309 3.36986 3.89299 3.43489C3.43782 6.25262 2.16984 11.9856 1.73634 13.8172C1.95309 14.5107 5.0526 13.9364 5.0526 13.7955C5.09595 11.9748 5.43191 10.3817 5.80038 8.71272C6.50482 9.94819 7.31762 11.162 8.13043 12.3216C8.39053 12.3433 8.6723 12.3433 8.9324 12.3324C9.17083 12.3216 9.49595 12.2891 9.72354 12.2457C10.3088 10.9886 10.8073 9.69893 11.425 8.46346C12.0644 10.0024 12.7363 12.6034 12.8014 13.2319C12.823 13.3945 15.229 13.5137 15.8792 12.8851C14.6437 9.51469 13.8959 6.43686 13.1048 2.9472C12.823 2.90385 12.3679 2.91469 12.0861 2.92553Z"
                fill="#FFB800"
              />
            </svg>
          </div>
          <div id="t3" className="token">
            <svg
              width="12"
              height="12"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.0861 2.92553C11.8152 2.93636 11.5226 2.96888 11.2408 3.0339C10.4605 4.91961 9.58265 6.75114 8.62895 8.55016C7.54521 6.9137 6.68905 5.10385 5.85457 3.32651C5.52945 3.294 5.20432 3.31567 4.8792 3.32651C4.85753 3.32651 4.85753 3.31567 4.84669 3.31567C4.58659 3.32651 4.15309 3.36986 3.89299 3.43489C3.43782 6.25262 2.16984 11.9856 1.73634 13.8172C1.95309 14.5107 5.0526 13.9364 5.0526 13.7955C5.09595 11.9748 5.43191 10.3817 5.80038 8.71272C6.50482 9.94819 7.31762 11.162 8.13043 12.3216C8.39053 12.3433 8.6723 12.3433 8.9324 12.3324C9.17083 12.3216 9.49595 12.2891 9.72354 12.2457C10.3088 10.9886 10.8073 9.69893 11.425 8.46346C12.0644 10.0024 12.7363 12.6034 12.8014 13.2319C12.823 13.3945 15.229 13.5137 15.8792 12.8851C14.6437 9.51469 13.8959 6.43686 13.1048 2.9472C12.823 2.90385 12.3679 2.91469 12.0861 2.92553Z"
                fill="#FFB800"
              />
            </svg>
          </div>
          <div id="t4" className="token">
            <svg
              width="12"
              height="12"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.0861 2.92553C11.8152 2.93636 11.5226 2.96888 11.2408 3.0339C10.4605 4.91961 9.58265 6.75114 8.62895 8.55016C7.54521 6.9137 6.68905 5.10385 5.85457 3.32651C5.52945 3.294 5.20432 3.31567 4.8792 3.32651C4.85753 3.32651 4.85753 3.31567 4.84669 3.31567C4.58659 3.32651 4.15309 3.36986 3.89299 3.43489C3.43782 6.25262 2.16984 11.9856 1.73634 13.8172C1.95309 14.5107 5.0526 13.9364 5.0526 13.7955C5.09595 11.9748 5.43191 10.3817 5.80038 8.71272C6.50482 9.94819 7.31762 11.162 8.13043 12.3216C8.39053 12.3433 8.6723 12.3433 8.9324 12.3324C9.17083 12.3216 9.49595 12.2891 9.72354 12.2457C10.3088 10.9886 10.8073 9.69893 11.425 8.46346C12.0644 10.0024 12.7363 12.6034 12.8014 13.2319C12.823 13.3945 15.229 13.5137 15.8792 12.8851C14.6437 9.51469 13.8959 6.43686 13.1048 2.9472C12.823 2.90385 12.3679 2.91469 12.0861 2.92553Z"
                fill="#FFB800"
              />
            </svg>
          </div>
          <div id="t5" className="token">
            <svg
              width="12"
              height="12"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.0861 2.92553C11.8152 2.93636 11.5226 2.96888 11.2408 3.0339C10.4605 4.91961 9.58265 6.75114 8.62895 8.55016C7.54521 6.9137 6.68905 5.10385 5.85457 3.32651C5.52945 3.294 5.20432 3.31567 4.8792 3.32651C4.85753 3.32651 4.85753 3.31567 4.84669 3.31567C4.58659 3.32651 4.15309 3.36986 3.89299 3.43489C3.43782 6.25262 2.16984 11.9856 1.73634 13.8172C1.95309 14.5107 5.0526 13.9364 5.0526 13.7955C5.09595 11.9748 5.43191 10.3817 5.80038 8.71272C6.50482 9.94819 7.31762 11.162 8.13043 12.3216C8.39053 12.3433 8.6723 12.3433 8.9324 12.3324C9.17083 12.3216 9.49595 12.2891 9.72354 12.2457C10.3088 10.9886 10.8073 9.69893 11.425 8.46346C12.0644 10.0024 12.7363 12.6034 12.8014 13.2319C12.823 13.3945 15.229 13.5137 15.8792 12.8851C14.6437 9.51469 13.8959 6.43686 13.1048 2.9472C12.823 2.90385 12.3679 2.91469 12.0861 2.92553Z"
                fill="#FFB800"
              />
            </svg>
          </div>
          <div id="t6" className="token">
            <svg
              width="12"
              height="12"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.0861 2.92553C11.8152 2.93636 11.5226 2.96888 11.2408 3.0339C10.4605 4.91961 9.58265 6.75114 8.62895 8.55016C7.54521 6.9137 6.68905 5.10385 5.85457 3.32651C5.52945 3.294 5.20432 3.31567 4.8792 3.32651C4.85753 3.32651 4.85753 3.31567 4.84669 3.31567C4.58659 3.32651 4.15309 3.36986 3.89299 3.43489C3.43782 6.25262 2.16984 11.9856 1.73634 13.8172C1.95309 14.5107 5.0526 13.9364 5.0526 13.7955C5.09595 11.9748 5.43191 10.3817 5.80038 8.71272C6.50482 9.94819 7.31762 11.162 8.13043 12.3216C8.39053 12.3433 8.6723 12.3433 8.9324 12.3324C9.17083 12.3216 9.49595 12.2891 9.72354 12.2457C10.3088 10.9886 10.8073 9.69893 11.425 8.46346C12.0644 10.0024 12.7363 12.6034 12.8014 13.2319C12.823 13.3945 15.229 13.5137 15.8792 12.8851C14.6437 9.51469 13.8959 6.43686 13.1048 2.9472C12.823 2.90385 12.3679 2.91469 12.0861 2.92553Z"
                fill="#FFB800"
              />
            </svg>
          </div>
        </div>
        <div className="checkin-avatar checkin-player">
          <img
            src="https://res.cloudinary.com/dh1l1uwde/image/upload/v1642464785/image_1_lqdzip.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
);

export default CheckInAni;
