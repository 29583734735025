import Soft from "../../img/firmness/soft.svg";
import Firm from "../../img/firmness/firm.svg";
import ExtraFirm from "../../img/firmness/extrafirm.svg";
import Superfirm from "../../img/firmness/superfirm.svg";

const gameModeInitialState = {
  all: [],
  loading: false,
  imgs: {
    1: Soft,
    2: Firm,
    3: ExtraFirm,
    4: Superfirm,
  },
};

export default gameModeInitialState;
