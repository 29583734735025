import React from "react";
import { Input, Paragraph } from "../../Storybook";
import { Box, Flex } from "../Utility";

const Perk = ({ emoji, name, description, boxOne, boxTwo, boxThree }) => {
  return (
    <Box
      gridTemplateColumns={[["100%"], ["10% 30% 60%"]]}
      padding="20px"
      backgroundColor="#3D3D3D"
      display={["block", "inline-grid"]}
      borderRadius="10px"
    >
      <Flex justifyContent={["flex-start", "center"]} alignItems="center">
        <Paragraph fontSize={["fz2", "fz4"]} mr={["0", "20px"]}>
          {emoji}
        </Paragraph>
        <Paragraph
          marginTop={["0px", "16px"]}
          marginLeft={["10px", "0px", "0px"]}
          display={["block", "none"]}
          weight={700}
        >
          {name}
        </Paragraph>
      </Flex>
      <Flex flexDirection="column">
        <Paragraph fontSize="fz2" weight={700} display={["none", "block"]}>
          {name}
        </Paragraph>
        <Paragraph fontSize="fz2" mb={[16, 0, 0]}>
          {description}
        </Paragraph>
      </Flex>
      <Flex alignItems="center">
        <Flex marginTop={["0px", "16px"]} padding="8px" flexDirection="column">
          <Input
            type="checkbox"
            small
            defaultChecked={boxOne}
            bottomLabel={true}
          />
          <Paragraph
            display={["block", "none"]}
            fontSize="fz2"
            textAlign="center"
          >
            Full Members
          </Paragraph>
        </Flex>
        <Flex marginTop={["0px", "16px"]} padding="8px" flexDirection="column">
          <Input
            type="checkbox"
            small
            defaultChecked={boxTwo}
            bottomLabel={true}
          />
          <Paragraph
            display={["block", "none"]}
            fontSize="fz2"
            textAlign="center"
          >
            Batch Candidates
          </Paragraph>
        </Flex>
        <Flex marginTop={["0px", "16px"]} padding="8px" flexDirection="column">
          <Input
            type="checkbox"
            small
            checked={boxThree}
            marginTop="16px"
            bottomLabel={true}
          />
          <Paragraph
            display={["block", "none"]}
            fontSize="16px"
            textAlign="center"
          >
            Non-members
          </Paragraph>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Perk;
