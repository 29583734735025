import React, { useEffect, useState } from "react";
import { Route, Switch, useRouteMatch, useHistory, useParams, Redirect } from "react-router";
import { connect } from "react-redux";

import { Flex, RoutingMessage } from "../components/Utility";
import ProfilePage from "./ProfilePage";
import TeamMgmtPage from "./TeamMgmtPage";
import BondPage from "./BondPage";
import ColorSelectionPage from "./ColorSelectionPage";
import { toggleCommunity } from "../containers/Community/actions";
import AuthorizedHOC from "../components/Utility/AuthorizedHOC";
import CommunityPoolPage from "./CommunityPoolPage";
import ShopPage from "./ShopPage";
import JourneyPage from "./JourneyPage";
import GoalPage from "./GoalPage";

const CommunityRouter = ({ activeCommunityId, communityInfo, authedUserId, toggleCommunity }) => {
  let history = useHistory();
  let { communityId } = useParams();
  const [toggling, setToggling] = useState(false);
  const [member, setMember] = useState(true);

  const { path } = useRouteMatch();
  const urlParamCommunityId = Number(communityId);

  useEffect(() => {
    // if the id's dont match
    if (
      !!activeCommunityId &&
      !!urlParamCommunityId &&
      !!authedUserId &&
      Number(activeCommunityId) !== urlParamCommunityId
    ) {
      // if the user doesn't already belong to the community specificed in the url
      if (!!communityInfo.size) {
        if (!communityInfo.get(urlParamCommunityId)) setMember(false);
        else {
          setToggling(true);
          setTimeout(() => {
            toggleCommunity(urlParamCommunityId);
            setToggling(false);
          }, 1000);
          setMember(true);
        }
      }
    } else if (Number(activeCommunityId) === urlParamCommunityId) setMember(true);
  }, [path, urlParamCommunityId, activeCommunityId, communityInfo, authedUserId, toggleCommunity]);
  if (!urlParamCommunityId) return <Redirect to="/404" />;
  return (
    <AuthorizedHOC>
      <Flex justifyContent="center">
        {!member && (
          <RoutingMessage
            title="Looking for something?"
            message="This community may not exist or you may not be a part of it. Check your link you entered &amp; try again."
          />
        )}
        {toggling && (
          <RoutingMessage
            title="Just a moment..."
            message="You’ve opened a link in a different community. We’re redirecting you now."
          />
        )}
      </Flex>
      {!!member && !toggling && !!activeCommunityId && (
        <Switch>
          <>
            {!!authedUserId && (
              <Route exact path={`${path}/profile`}>
                <Redirect to={`${history.location.pathname}/${authedUserId}`} />
              </Route>
            )}
            <Route path={`${path}/profile/:profileUserId`}>
              <ProfilePage />
            </Route>
            <Route path={`${path}/team/:teamId`}>
              <TeamMgmtPage />
            </Route>
            <Route path={`${path}/journey/:journeyId/:playerId/:goalId?/:teamId?`}>
              <JourneyPage />
            </Route>
            <Route path={`${path}/goal/:goalId/:playerId`}>
              <GoalPage />
            </Route>
            <Route path={`${path}/bond`}>
              <BondPage />
            </Route>
            <Route path={`${path}/color`}>
              <ColorSelectionPage />
            </Route>
            <Route path={`${path}/shop`}>
              <ShopPage />
            </Route>
            <Route path={`${path}/pool`}>
              <CommunityPoolPage />
            </Route>
            {/* <Route path="/404" component={Error404} redner={() => <Redirect to="/404" />} /> */}
          </>
        </Switch>
      )}
    </AuthorizedHOC>
  );
};

export default connect(
  (state) => ({
    activeCommunityId: state.community.get("activeCommunityId"),
    communityInfo: state.community.get("communityInfo"),
    authedUserId: state.community.get("authedUserId"),
    authed: state.auth.get("auth"),
  }),
  { toggleCommunity }
)(CommunityRouter);
