import React, { useState } from "react";
import { connect } from "react-redux";
import { useDisconnect } from "wagmi";

import useInput from "./utils/useInput";
import * as fieldValidators from "./utils/fieldValidators";
import { Header, Paragraph, Input, Button } from "../../Storybook";
import { Flex } from "../Utility";
import { logOutUser } from "../../containers/Auth/actions";
import {
  firstNameError,
  lastNameError,
  validEmailAddressError,
  incompleteConfirmAccountFormError,
} from "./utils/formMessageStrings";
import { confirmAccount } from "../../containers/Community/actions";
import { useHistory } from "react-router-dom";
import { getPlayerAndTeamDataFromState } from "../../containers/Community/stateGetterHelpers";

const CreateAccount = ({
  logOutUser,
  authedUserId,
  activeCommunityId,
  ethAddress,
  confirmAccount,
  communityInvitation,
  communityMembershipId,
}) => {
  let history = useHistory();
  const { disconnect } = useDisconnect();
  const {
    value: firstName,
    bind: bindFirstName,
    valid: validFirstName,
    active: activeFirstName,
  } = useInput("", fieldValidators.basicTextField, true);
  const {
    value: lastName,
    bind: bindLastName,
    valid: validLastName,
    active: activeLastName,
  } = useInput("", fieldValidators.basicTextField, true);
  const { value: email, bind: bindEmail, valid: validEmail, active: activeEmail } = useInput(
    "",
    fieldValidators.emailField,
    true
  );

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const submitData = {
    id: authedUserId,
    first_name: firstName,
    last_name: lastName,
    email: email.toLowerCase(),
    eth_address: ethAddress,
    token: window.localStorage.getItem("token"),
  };
  const handleCreateAccount = async (e) => {
    e.preventDefault();
    if (validFirstName && validLastName && validEmail) {
      setSubmitted(true);
      setError("");
      try {
        await confirmAccount(submitData, communityMembershipId, tz);
        history.push(`/${activeCommunityId}/profile/${authedUserId}`);
      } catch (e) {
        setError(e);
      }
    } else {
      setError(incompleteConfirmAccountFormError);
    }
  };

  const handleSwapWallet = async (e) => {
    e.preventDefault();
    const redirect = !!communityInvitation ? `/community-invitation/${communityInvitation}` : "/";
    history.push(redirect);
    logOutUser();
    disconnect();
  };

  return (
    <form>
      <Header h3 mb={3} label="Create Account" />
      <Paragraph
        fontSize="fz2"
        mb={3}
        label="To begin playing Mochi, enter your email & name below."
        textAlign="left"
      />
      <Flex mt={2} flexDirection="column">
        <Input
          type="email"
          placeholder={email || "Your Email Address"}
          textLabel="Email - you will use this to claim your account in Discord"
          submited={submitted}
          activeInput={activeEmail}
          error={validEmailAddressError}
          {...bindEmail}
        />
        <Input
          type="text"
          placeholder={firstName || "First Name"}
          textLabel="First Name"
          submited={submitted}
          activeInput={activeFirstName}
          error={firstNameError}
          {...bindFirstName}
        />
        <Input
          type="text"
          placeholder="Last Name"
          textLabel="Last Name"
          submited={submitted}
          activeInput={activeLastName}
          error={lastNameError}
          {...bindLastName}
        />
        <Paragraph fontSize="fz3" label={tz} bgColor="darkBGSecondary" mb={1} />
        <Paragraph fontSize="fz1" textAlign="center" mb={1}>
          According to your browser, your timezone is the above. If this is incorrect, please change
          it in your browser settings and refresh this page.
        </Paragraph>

        {error.length > 0 && (
          <Paragraph color="darkNegative700" fontSize="fz1" textAlign="center" error>
            {error}
          </Paragraph>
        )}
        <Button primary onClick={(e) => handleCreateAccount(e)} mt={3}>
          Save Information
        </Button>
        <Button textButton leftArrow onClick={(e) => handleSwapWallet(e)}>
          Swap Wallets
        </Button>
      </Flex>
    </form>
  );
};

export default connect(
  (state) => {
    const authedUserId = state.community.get("authedUserId");
    const activeCommunityId = state.community.get("activeCommunityId");
    const { playerData } = getPlayerAndTeamDataFromState(state);
    return {
      authedUserId,
      activeCommunityId,
      communityMembershipId: playerData.get("community_membership_id"),
      ethAddress: playerData.get("eth_address"),
      communityInvitation: state.community.get(["communityInvitation", "community_details", "id"]),
    };
  },
  { logOutUser, confirmAccount }
)(CreateAccount);
