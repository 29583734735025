import React from "react";

const CenterEllipsisText = ({ originalText, length }) => {
  const lengthPart1 = (length - 3) / 2;

  const textPart1 = originalText.slice(0, lengthPart1);
  const textPart2 = originalText.slice(
    originalText.length - lengthPart1,
    originalText.length
  );

  return (
    <>
      <span>
        {textPart1}&#8230;{textPart2}
      </span>
    </>
  );
};

export default CenterEllipsisText;
