import React from "react";
import { connect } from "react-redux";

import { Box, Notification } from "../../Utility";
import { Button, Header, Paragraph } from "../../../Storybook";

// TODO: this is using invitation links when it should be using workspace urls.
// Update so that there is helper which can output the Slack/Discord workspace url
const NotYetClaimedNotification = ({
  communityInvitationLink,
  communityPlatform,
  communityId,
}) => {
  const platform = communityPlatform === "S" ? "Slack" : "Discord";
  const social_default_page = {
    S: `https://app.slack.com/client/${communityId}/`,
    D: `https://discord.com/channels/${communityId}/`,
  };
  const default_community_url =
    social_default_page[communityPlatform] ?? "_blank";
  const button_link =
    (communityInvitationLink !== "") & communityInvitationLink
      ? communityInvitationLink
      : default_community_url;
  return (
    <Notification bgColor={"darkOrange500"}>
      <Box width={[1, 1 / 2]}>
        <Header h3 colored mt={0} mb={1} label="Join and claim your account" />
        <Paragraph
          colored
        >{`In order to play mochi, you will have to join our ${platform} community and use /claim your@emailaddress.com`}</Paragraph>
      </Box>
      <Box
        mt={[2, 0]}
        width={[1, 1 / 2]}
        pl={[0, 2]}
        display="flex"
        justifyContent={["flex-start", "flex-end"]}
      >
        <Button
          primary
          arrow
          colored="darkOrange500"
          label={`Head to ${platform}`}
          onClick={() => window.open(button_link)}
        />
      </Box>
    </Notification>
  );
};

export default connect((state) => {
  const activeCommunityId = state.community.get("activeCommunityId");
  const communityData = state.community.getIn([
    "communityInfo",
    activeCommunityId,
  ]);
  return {
    communityInvitationLink: communityData?.get("invite_link"),
    communityPlatform: communityData?.get("community_type"),
    communityId: communityData?.get("community_id"),
  };
}, {})(NotYetClaimedNotification);
