import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import { getPlayerAndTeamDataFromState } from "../containers/Community/stateGetterHelpers";
import { getExternalPlayerInfo } from "../containers/Community/actions";
import { GridContainer, Loading, Report } from "../components/Utility";
import { GridBox, Header, Paragraph } from "../Storybook";
import { genNewId, getDisplayName } from "../utils/mochiHelpers";
import AuthorizedHOC from "../components/Utility/AuthorizedHOC";
import JourneyOverviewForGoal from "../components/Journey/JourneyOverviewForGoal";

const GoalPage = ({ activeCommunityId, getExternalPlayerInfo }) => {
  const location = useLocation();
  let { playerId, goalId } = useParams();
  playerId = Number(playerId);
  goalId = Number(goalId);
  const authedUserId = useSelector((state) => state.community.get("authedUserId"));
  const { playerData } = useSelector((state) => getPlayerAndTeamDataFromState(state, playerId));
  const goal = !!playerData && playerData?.get("goals")?.find((g) => g.get("id") === goalId);
  const isAnotherPlayersGoalPage = playerId !== authedUserId;
  // get the background color that matches the previous component's background color from URL or assign a default
  const color = new URLSearchParams(location.search)?.get("color") || "darkMagenta500";
  const displayName = getDisplayName(playerData);
  const journeysIds = !!goal?.get("journeys") && goal?.get("journeys")?.sort((a, b) => a > b);

  // In the instances when player is navigating from an external site or refreshes the page and there is no player information on state, fetch it
  useEffect(() => {
    if (!playerData) getExternalPlayerInfo(playerId, activeCommunityId);
  }, [playerData, playerId, activeCommunityId, getExternalPlayerInfo]);

  // If the goal hasn't yet loaded, display the Loading animation
  if (!goal) return <Loading />;

  return (
    <AuthorizedHOC>
      <GridContainer
        alignContent="flex-start"
        alignItems="stretch"
        style={{ gridGap: "20px" }}
        mt={4}
      >
        <GridBox>
          <Header
            h4
            color="darkFGSecondary"
            label={`${displayName}${displayName[displayName.length - 1] === "s" ? "'" : "'s"} Goal`}
          />
          <Header h1 mb={3} label={goal?.get("goal")} />
          {/* // TODO: Uncomment when endpoint is available */}
          {/* {!isAnotherPlayersGoalPage && (
            <Button primary mb={2} label="Set as Goal" />
          )} */}
        </GridBox>

        {!!journeysIds?.size ? (
          // render a Journey overview component for every journey associated with this goal
          journeysIds.map((j, i) => (
            <JourneyOverviewForGoal
              key={genNewId("goal-journey-overview")}
              playerId={playerId}
              journeyNum={i + 1}
              color={color}
              goalId={goalId}
              journeyId={j}
            />
          ))
        ) : (
          // If the player doesn't have any journeys associated with this goal, load a warning message
          <GridBox>
            <Report m={2} borderRadius="2px" justifyContent="flex-start">
              <Paragraph
                color="darkFGTertiary"
                fontSize="fz1"
                my={3}
                label={`Once ${
                  isAnotherPlayersGoalPage ? `${displayName} begins` : "you begin"
                } using this goal, the associated journeys will appear here.`}
              />
            </Report>
          </GridBox>
        )}
      </GridContainer>
    </AuthorizedHOC>
  );
};

export default connect((state, props) => ({}), { getExternalPlayerInfo })(GoalPage);
