import React from "react";

import { GridContainer } from "..";
import { GridBox } from "../../../Storybook";
import LoadingAni from "./LoadingAni";

const Loading = () => (
  <GridContainer>
    <GridBox>
      <LoadingAni />
    </GridBox>
  </GridContainer>
);

export default Loading;
