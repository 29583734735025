import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import { Box, Notification } from "../Utility";
import { Header, Paragraph, Button } from "../../Storybook";
import { getGoalReportPercentage } from "../../utils/reportRateHelpers";
import { determineWhenGoalWasLastUsed } from "../../utils/mochiHelpers";

const GoalOverview = ({ goal, color, profileUserId, journeyDataMap, goalJourneyLink }) => {
  const goalLength = goal.get("journeys")?.size;
  const journeysForGoalStr = `${goalLength} Journey${goalLength === 1 ? "" : "s"}`;
  const goalReportPercentage =
    getGoalReportPercentage(goal.get("journeys"), journeyDataMap, profileUserId) || 0;
  const lastActive = useMemo(() => determineWhenGoalWasLastUsed(goal, journeyDataMap), [
    journeyDataMap,
    goal,
  ]);

  return (
    <Notification
      bgColor={color}
      height={"100%"}
      flexDirection="column"
      alignContent="space-between"
      css="padding: 10px 20px"
    >
      <Box mb={2}>
        <Paragraph colored mb={1} label={lastActive} />
        <Link to={goalJourneyLink}>
          <Header h3 colored mb={2} label={goal.get("goal")} />
        </Link>
        <Paragraph colored label={`${journeysForGoalStr} • ${goalReportPercentage}% reported`} />
      </Box>
      <Link to={goalJourneyLink}>
        <Button textButton arrow textAlign="left" colored={color} label="See past Journeys" />
      </Link>
    </Notification>
  );
};

export default GoalOverview;
