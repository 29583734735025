import { API_ENDPOINT } from "../../constants/endpoints";
import axios from "../../config/axios";

import {
  GAME_MODE_FETCH,
  GAME_MODE_SUCCESS,
  GAME_MODE_FAILURE,
} from "../../constants/actionTypes";

export const fetchGameModes = () => async (dispatch, getState) => {
  if (getState().gameModes.get("all").size) return;
  dispatch({
    type: GAME_MODE_FETCH,
    data: true,
  });

  try {
    const res = await axios.get(`${API_ENDPOINT}/gamemodes/`);
    dispatch({
      type: GAME_MODE_SUCCESS,
      data: res.data,
    });
  } catch (e) {
    dispatch({
      type: GAME_MODE_FAILURE,
      data: e,
    });
  }
};
