let API_URL = "http://localhost:8000/api";
let REACT_APP_RPC_KEY = "2baa40e4557e4cdc8239c2d659a6f793";
let EXPECTED_WEB3_NETWORK = 420; // optimism goerli
// let EXPECTED_WEB3_NETWORK = 10; // optimism goerli
let EXPECTED_WEB3_NETWORK_NETWORK = "Optimism Goerli";
let SLACK_OATH_URL =
  "https://slack.com/oauth/v2/authorize?scope=channels:read,chat:write,commands,groups:history,groups:read,groups:write,im:history,im:read,im:write,incoming-webhook,mpim:history,mpim:read,mpim:write,reactions:read,reactions:write,team:read,users:read,users:read.email,users:write&user_scope=channels:history,channels:read,channels:write,chat:write,groups:read,groups:write,im:history,im:write,mpim:write,users:read,users:read.email";
let STRIPE_PUBLIC_KEI =
  "pk_test_51HjnkkDBs6ED6M9vU8RkKKr83TrdeNWvwjbOZnrkfGw5z1iUlZ0aYR65X8Iw540CL6PsFrMPM3VpqWnyGdH5dC0D001Jc38Rfw";
let ANNUAL_STRIPE_PRICE_ID = "price_1JIczuDBs6ED6M9vYIS1MfgS";
let QUARTERLY_STRIPE_PRICE_ID = "price_1JIczuDBs6ED6M9vMt0I0u4r";
let TOKEN_ADDRESS = "0x7Af72aD5E8793023DC722d617fe3338ac6b5ccE9";
let TOKEN_WALLET = "0x4a11975b13addbdd7b1aea420a9fe4d9ee0b1a3a";
let DFLT_MOCHI_INVITE = "06f34596-35c2-45f0-96fb-233895666fab";
// DFLT_MOCHI_INVITE = "16e76190-9df8-4c04-ae28-5ed1e55e6524";
API_URL = "https://mochi-backend-staging.herokuapp.com/api";

if (process.env.REACT_APP_VERSION === "production") {
  API_URL = "https://mochi-backend-prod.herokuapp.com/api";
  SLACK_OATH_URL += `&client_id=498092246560.688350331296&redirect_uri=https://mochi-backend-prod.herokuapp.com/slack-oauth/`;
  EXPECTED_WEB3_NETWORK = 10; // optimism
  EXPECTED_WEB3_NETWORK_NETWORK = "Optimism";
  ANNUAL_STRIPE_PRICE_ID = "price_1JLYclDWVU1qmWwpxDc2va3f";
  QUARTERLY_STRIPE_PRICE_ID = "price_1JLYclDWVU1qmWwpK1Jp8Xxp";
  STRIPE_PUBLIC_KEI =
    "pk_live_51JDxIbDWVU1qmWwpCH1tSFsMnnmagHCa8ROKVld4OGURiH99CKeeGlRy6npXDwFMKZFZoNqAInnS9j2StJCPquTe00mticObrD";
  DFLT_MOCHI_INVITE = "16e76190-9df8-4c04-ae28-5ed1e55e6524";
  TOKEN_ADDRESS = "0x77D40CBc27f912dcDbF4348cAf87B427c4D02486";
  TOKEN_WALLET = "0x41693B7c0E41CBEa0d4F68Dc73898CB79e390f29";
} else if (process.env.REACT_APP_VERSION === "staging") {
  // use defaults for all else
  API_URL = "https://mochi-backend-staging.herokuapp.com/api";
  SLACK_OATH_URL += `&client_id=687769122657.819322829655&redirect_uri=https://mochi-backend-staging.herokuapp.com/slack-oauth/`;
  ANNUAL_STRIPE_PRICE_ID = "price_1JDxKkDWVU1qmWwpnOLXCWdt";
  QUARTERLY_STRIPE_PRICE_ID = "price_1JDxKkDWVU1qmWwptQ24RICx";
  STRIPE_PUBLIC_KEI =
    "pk_test_51JDxIbDWVU1qmWwp1sSsyPLKAIBUYrhndJlmXbcj3uPc9CrRUOtrI09SswI48FdlD083OsP9lqyiDwVNwqDb0xr100UMl6vM8m";
  DFLT_MOCHI_INVITE = "06f34596-35c2-45f0-96fb-233895666fab";
} else {
  SLACK_OATH_URL += `&client_id=687769122657.819322829655&redirect_uri=https://609d5c007cbf.ngrok.io/slack-oauth/`;
}

export const RPC_KEY = REACT_APP_RPC_KEY;
export const WEB3_NETWORK = EXPECTED_WEB3_NETWORK;
export const API_ENDPOINT = API_URL;
export const SLACK_OATH_ENDPOINT = SLACK_OATH_URL;
export const EXPECTED_WEB3_NETWORK_NAME = EXPECTED_WEB3_NETWORK_NETWORK;
// TODO: need to figure out the replacement for workplace urls
export const STRIPE_PUBLIC_KEY = STRIPE_PUBLIC_KEI;
export const ANNUAL_PRICE_ID = ANNUAL_STRIPE_PRICE_ID;
export const QUARTERLY_PRICE_ID = QUARTERLY_STRIPE_PRICE_ID;
export const POMELO_MULTISIG_ADDRESS = "0x41693B7c0E41CBEa0d4F68Dc73898CB79e390f29";
export const DEFAULT_MOCHI_INVITE = DFLT_MOCHI_INVITE;
export const DEFAULT_TOKEN_WALLET = TOKEN_WALLET;
export const MOCHI_TOKEN_ADDRESS = TOKEN_ADDRESS;
export const WC_ID = "83f0d2a7ac656d1dd31c5c45e9c14a9d";
export const ALCHEMY_API_KEY = "D6mLgf2QQGE8iV3QwaZIbI-5hDhKneiJ";
