import React from "react";
import { connect } from "react-redux";

import ReportQnA from "./ReportQnA";

const CheckInReport = ({ reportingGoal, questionList, onSubmit, answers, error, setError }) => {
  if (!questionList || !reportingGoal) return <></>;
  return (
    <>
      {questionList.map(
        (q, i) =>
          i <= answers.size && (
            <ReportQnA
              key={i}
              question={q}
              answer={answers?.get(i)}
              active={i === answers.size}
              last={i === questionList.size - 1}
              error={i === Number(error?.index) ? `${error?.message}` : ""}
              setError={setError}
              handleAnswer={onSubmit}
            />
          )
      )}
    </>
  );
};

export default connect((state, props) => {
  return {
    questionList: state.checkIn.getIn([
      "outstanding",
      props.reportingGoal?.get("community_id"),
      "details",
      "prompts",
    ]),
    answers: state.checkIn.getIn([
      "outstanding",
      props.reportingGoal?.get("community_id"),
      "details",
      "answers",
    ]),
  };
})(CheckInReport);
