import React from "react";

import { Button } from "../../Storybook";
import { Flex } from ".";
import discordLogo from "../../img/logo/discord-logo-mark-white.svg";

const DiscordButton = ({ label }) => (
  <Button
    primary
    color="darkFGPrimary"
    bgColor="discordBlurple"
    width={["100%", "auto"]}
    onClick={() => window.open("https://discord.gg/bEj6NzXy6m", "_blank")}
  >
    <Flex mr={2} alignContent="center">
      <img src={discordLogo} alt="Discord Logo Mark" width="19px" />
    </Flex>
    {label}
  </Button>
);

export default DiscordButton;
