/////////////////////////////
// Not on Team
/////////////////////////////
export const notOnTeamHeader = "You aren't on a team.";
export const notOnTeamMsg = "Get matched with other players by joining a wave.";

/////////////////////////////
// Not in Journey
/////////////////////////////
export const notInJourneyHeader = "You aren't in a journey currently.";
export const notInJourneyMsgLeader =
  "Propose when you should begin your next journey.";
export const notInJourneyMsgNonLeader =
  "If you want to begin a journey, ask your team leader to start one.";

/////////////////////////////
// Pending Journey
/////////////////////////////
export const pendingJourneyHeader = (days) =>
  `Your new journey begins in ${days} days.`;
export const pendingJourneyMsg = (days) =>
  `Take the next ${days} days to set your goal and bond with Mochi.`;

/////////////////////////////
// In journey
/////////////////////////////
export const showNTellHeader = (days) =>
  days < 7 ? "Due soon!" : "Due next week";
export const showNTellMsg = (days) =>
  `Your next Show & Tell is due in ${days} days.
  Learn more about Show & Tells`;
export const daysUntilEndOfJourneyMsg = (days) => {
  if (days > 30) return `Only ${days} days left! Wait, that's a lot.`;
  if (days > 15) return `${days} days left! Keep it up!`;
  if (days < 14 && days > 7)
    return `Less than 2 weeks left in this journey, ${days} days to go!`;
  if (days < 7) return `Your journey is ending soon, ${days} days to go!`;
};
