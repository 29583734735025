import styled, { css } from "styled-components";

import { layout, space, color, typography } from "styled-system";

const StyledNavItem = styled.button`
  position: relative;
  z-index: 2;
  padding: 4px 16px;
  border: none;
  border-radius: 4px;
  margin: 0;
  blend: pass-through;
  text-decoration: none;
  white-space: nowrap;
  font-family: ${(props) => props.theme.fonts.headers}
  font-size: ${(props) => props.theme.fontSize.fz2};
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.01em;
  line-height: 112.5%;
  color: ${(props) => props.theme.color.darkFGPrimary};

  &:hover {
    background: ${(props) => props.theme.color.darkFGPrimary};
    color: ${(props) => props.theme.color.darkBGSecondary};
  }

  ${(props) =>
    props.active &&
    css`
      background: ${(props) => props.theme.color.darkBGSecondary};
    `};

  ${(props) =>
    !props.active &&
    css`
      background: none;
    `};



  ${layout}
  ${space}
  ${color}
  ${typography}
`;

export default StyledNavItem;
