import React from "react";
import { connect } from "react-redux";

import CommunityTag from "./CommunityTag";

const image =
  "https://res.cloudinary.com/delu9m4xu/image/upload/v1643398243/mochi/Mochi-Icon_qkewbf.png";

const ActiveCommunityTag = (props) => (
  <CommunityTag
    image={props.communityImg || image}
    label={props.communityName}
    // onClick={() => setViewMenu(!viewMenu)}
    current
    ml={[-2, 0]}
    {...props}
  />
);

export default connect((state) => {
  const activeCommunityId = state.community.get("activeCommunityId");
  return {
    communityName: state.community.getIn([
      "communityInfo",
      activeCommunityId,
      "name",
    ]),
    communityImg: state.community.getIn([
      "communityInfo",
      activeCommunityId,
      "icon",
    ]),
  };
}, {})(ActiveCommunityTag);
