import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const Anchor = styled(Link)`
  font-family: ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.color.white};
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    border-bottom: 1px solid ${(props) => props.theme.color.darkFGPrimary};
    color: ${(props) => props.theme.color.white};
  }
  ${(props) =>
    props.active &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.color.darkFGPrimary};
    `}
`;

export default Anchor;
