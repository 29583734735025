import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import useSWR from "swr";

import fetcher from "../../config/fetcher";
import { Paginator, ApiError, Report, Flex } from "../Utility";
import { FreeAgentActions } from "../../containers/FreeAgent";
import { Header, Modal, Paragraph } from "../../Storybook";
import { GridBox } from "../../Storybook";
import { genNewId } from "../../utils/mochiHelpers";
import FreeAgentItem from "./FreeAgentItem";

export const FreeTeamPage = ({ fetchFreeTeams }) => {
  let history = useHistory();
  let { communityId } = useParams();
  const dispatch = useDispatch();
  const teamCount = useSelector((state) => state.freeAgent.getIn(["teamCount", communityId]));
  const teamsData = useSelector((state) => state.freeAgent.getIn(["teamsData", communityId]));

  const pages = teamCount ? Math.ceil(teamCount / 12) : 1;
  const [selectedPage, setPage] = useState(1);
  const [error, setError] = useState("");
  const currentPageTeams = teamsData?.get(selectedPage);

  const { data, isLoading } = useSWR(
    `/community/${communityId}/free-agents/teams/?page=${selectedPage}`,
    fetcher
  );

  useEffect(() => {
    if (data) dispatch(FreeAgentActions.setFreeTeams(data, selectedPage, communityId));
  }, [communityId, data, dispatch, selectedPage]);

  if (error) return <div>failed to load</div>;
  if (isLoading) return <div>loading...</div>;

  if (!currentPageTeams) return <></>;
  return (
    <GridBox span={[4, 6]} start={[1, 2, 4]} justifyContent="Center">
      <Header h1 mb={4} textAlign="center" label="Open Teams" />
      {error?.length > 0 && (
        <Modal onClose={setError("")}>
          <ApiError msg={error} />
        </Modal>
      )}
      {currentPageTeams?.size ? (
        currentPageTeams.map((team) => (
          <FreeAgentItem
            key={genNewId("open-teams")}
            title={team.get("name")}
            buttonText={"Visit"}
            callback={() => history.push(`/${communityId}/team/${team.get("team_id")}`)}
          />
        ))
      ) : (
        <Report mt={5} py={4} borderRadius="4px" justifyContent="center" width="100%">
          <Paragraph
            label={"There aren't players free to play."}
            color="darkFGTertiary"
            fontSize="fz1"
          />
        </Report>
      )}
      <Flex justifyContent="center">
        {teamCount > 0 ? (
          <Paginator
            currentPage={selectedPage}
            totalPages={pages}
            callback={(v) => setPage(v)}
            length={5}
          />
        ) : (
          <Header h4 label="End of results" textAlign="center" mt={5} />
        )}
      </Flex>
    </GridBox>
  );
};

export default FreeTeamPage;
