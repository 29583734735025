import React from "react";

import { Flex, Container } from "./";
import { Header, Paragraph } from "../../Storybook";

export const RoutingMessage = ({ title, message }) => (
  <Container>
    <Flex width="100%">
      <Flex alignItems="center" flexDirection="column" width="300px">
        <Header h1 m={3} label={title} color="darkFGTertiary" />
        <Paragraph
          m={3}
          fontSize="16px"
          color="darkFGTertiary"
          textAlign="center"
          label={message}
        />
      </Flex>
    </Flex>
  </Container>
);

export default RoutingMessage;
