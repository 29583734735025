import React from "react";

import { Container, Box, Space, Header } from "../components/Utility";
import RecoverAccount from "../components/Forms/RecoverAccount";

export const RecoverAccountPage = () => (
  <Container>
    <Box
      display="grid"
      gridTemplateColumns={["100%", "25% 50% 25%"]}
      textAlign="center"
    >
      <div />
      <Box maxWidth="466px" width="100%" margin="0 auto">
        <Header h1 mb={3}>
          Reset Password
        </Header>
        <RecoverAccount />
      </Box>
      <div />
    </Box>
    <Space mb={4} />
  </Container>
);

export default RecoverAccountPage;
