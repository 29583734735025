import React from "react";
import { connect, useSelector } from "react-redux";
import { useContractRead } from "wagmi";
import { getAddress } from "viem";

import { getPlayerAndTeamDataFromState } from "../../containers/Community/stateGetterHelpers";
import {
  ProfileCard,
  Membership,
  BatchIndicator,
  RankIndicator,
  ClubIndicator,
  TokenIndicator,
} from "../Profile";
import Space from "../Utility/Space";
import MochiTokenContract from "../../contracts/MochiToken.json";
import { MOCHI_TOKEN_ADDRESS } from "../../constants/endpoints";

const PlayerInfo = ({ playerData, activeCommunityId, playerId }) => {
  const batchName = playerData?.getIn(["descriptors", "batch"]) || false;
  const rankName = playerData?.getIn(["descriptors", "rank"]) || false;
  const clubInfo = playerData?.getIn(["descriptors", "club"]) || false;
  const playerAddress = useSelector((state) => {
    const activeCommId = state.community.get("activeCommunityId");
    return state.community.getIn(["players", activeCommId, playerId, "eth_address"]);
  });
  // TODO: we should consider saving checksum addresses on backend
  const checksumAddress = playerAddress ? getAddress(playerAddress) : null;
  const { data } = useContractRead({
    address: MOCHI_TOKEN_ADDRESS,
    abi: MochiTokenContract.abi,
    functionName: "balanceOf",
    args: [checksumAddress],
  });

  return (
    <ProfileCard heading="Player Info">
      {activeCommunityId === 1 && <Membership customerData={playerData.get("customerData")} />}
      {!!batchName && <BatchIndicator batchName={playerData.getIn(["descriptors", "batch"])} />}
      {!!clubInfo && <ClubIndicator clubInfo={clubInfo} />}
      {!!rankName && <RankIndicator rankName={playerData.getIn(["descriptors", "rank"])} />}
      <Space mb={3} />
      {data && <TokenIndicator inWallet amountOfTokensBN={data} />}
    </ProfileCard>
  );
};

export default connect((state, props) => {
  const activeCommunityId = state.community.get("activeCommunityId");
  const { playerData } = getPlayerAndTeamDataFromState(state, props.playerId);
  return {
    activeCommunityId,
    playerData,
  };
}, {})(PlayerInfo);
