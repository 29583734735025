import React from "react";

import { genNewId } from "../../utils/mochiHelpers";
import CommunityJourneyStats from "./CommunityJourneyStats";
import EmptyJourneyState from "./EmptyJourneyState";

const CurrentJourneys = ({
  authPlayerHasCurrentJourney,
  authPlayerTeamId,
  ongoingJourneyTeams,
}) => {
  return (
    <>
      {authPlayerHasCurrentJourney && (
        <CommunityJourneyStats teamId={authPlayerTeamId} isCurrentJourney />
      )}
      {!!ongoingJourneyTeams?.size &&
        ongoingJourneyTeams.map(
          (t) =>
            t !== authPlayerTeamId && (
              <CommunityJourneyStats
                teamId={t}
                isCurrentJourney
                key={genNewId("ongoing-team-journey")}
              />
            )
        )}
      {!authPlayerHasCurrentJourney && !ongoingJourneyTeams?.size && (
        <EmptyJourneyState label="There are no active journeys currently." />
      )}
    </>
  );
};

export default CurrentJourneys;
