import React, { useState } from "react";

import { Button, Flex, Box } from "../Utility";
import EditUserProfile from "./EditUserProfile";
import NewEmailAddress from "./NewEmailAddress";

export const ProfileForms = () => {
  const [formViewed, setFormViewed] = useState("profile");

  return (
    <Box mt={2}>
      {formViewed === "profile" && <EditUserProfile />}
      {formViewed === "email" && <NewEmailAddress />}
      <Flex flexDirection="column" justifyContent="space-between" mt={3}>
        {formViewed !== "profile" && (
          <Button secondary mb={3} onClick={() => setFormViewed("profile")}>
            Change Name
          </Button>
        )}
        {formViewed !== "email" && (
          <Button secondary mb={3} onClick={() => setFormViewed("email")}>
            Change Email
          </Button>
        )}
      </Flex>
    </Box>
  );
};

export default ProfileForms;
