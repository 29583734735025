import React, { useState } from "react";

import { GridBox, Header, Button } from "../../Storybook";
import { Box } from "../Utility";
import EditTeamName from "../Forms/EditTeamName";
import Modal from "../Utility/Modal";

export const TeamName = ({ teamData, onTeam, amLeader }) => {
  const [editTeamName, setEditTeamName] = useState(false);

  return (
    <GridBox>
      <Header h1 mt={4} mb={2}>
        {teamData.get("name")}
      </Header>
      {onTeam && (
        <Box width={[1, 1 / 2, 1 / 3]} mb={4}>
          {editTeamName && (
            <Modal onClose={() => setEditTeamName(false)}>
              <EditTeamName
                teamData={teamData}
                onClose={() => setEditTeamName(false)}
              />
            </Modal>
          )}
          {amLeader && (
            <Button
              primary
              arrow
              width="100%"
              mb={2}
              onClick={() => setEditTeamName(true)}
              label="Change Team Name"
            />
          )}
        </Box>
      )}
    </GridBox>
  );
};
