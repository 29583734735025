import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { fromJS } from "immutable";

import useInput from "../Forms/utils/useInput";
import * as fieldValidators from "../Forms/utils/fieldValidators";
import { Header, Paragraph, Input, Button } from "../../Storybook";
import {
  getPlayerAndTeamDataFromState,
  isCurrentlyInAJourney,
} from "../../containers/Community/stateGetterHelpers";
import { Box, Flex, DropdownSelect } from "../Utility";
import { applyGameItem } from "../../containers/Shop/actions";
import {
  getNewProfileData,
  getJourneysForUser,
} from "../../containers/Community/actions";

const ChangeGoal = ({
  wishItem,
  postAction,
  exit,
  playerData,
  currentlyInAJourney,
  activeCommunityId,
  applyGameItem,
  getJourneysForUser,
  getNewProfileData,
}) => {
  const [goalSelected, setGoalSelected] = useState();
  const [error, setError] = useState("");

  const goals = playerData.get("goals") || fromJS([]);
  const playerId = playerData.get("user_id");

  const { value: newGoal, bind: bindGoal, active: activeGoal } = useInput(
    "",
    fieldValidators.basicTextField,
    true
  );

  const formattedGoals = useMemo(() => goals.map((g) => g.get("goal")).toJS(), [
    goals,
  ]);

  useEffect(() => {
    const goalSelectedIsPastGoal = !!formattedGoals.filter(
      (g) => g === goalSelected
    )?.length;
    if (newGoal !== "") setGoalSelected(newGoal);
    else if (newGoal === "" && !goalSelectedIsPastGoal) setGoalSelected("");
  }, [newGoal, formattedGoals, goalSelected]);

  const changeGoal = async () => {
    try {
      if (!!wishItem) {
        if (!!currentlyInAJourney) {
          await applyGameItem(wishItem.get("id"), goalSelected);
          setTimeout(async () => {
            await getNewProfileData();
            await getJourneysForUser(playerId, activeCommunityId);
          }, 3000);
          postAction();
        } else
          setError(
            "You're not in a journey currently, you can set your goal on Discord without using a Wish Item."
          );
      }
    } catch (er) {
      setError(er);
    }
  };

  return (
    <>
      {error.length ? (
        <Box>
          <Header h3 label="Something Went Wrong" mb={3} />
          <Paragraph label={error} />
        </Box>
      ) : (
        <Box>
          <Header h3 label="Change Your Goal" mb={3} />
          {!!wishItem && (
            <Paragraph
              label="Your wish is my command... Save your new goal to use your Wish."
              mb={3}
            />
          )}
          <DropdownSelect
            menuItems={formattedGoals}
            selectAction={(ind) => setGoalSelected(formattedGoals[ind])}
            ariaLabel={"Use an Existing Goal"}
            restrictedWidth
            modal
          />

          {/* type out new goal */}
          <Input
            type="text"
            placeholder={newGoal || "Enter a new goal"}
            textLabel="Create a New Goal"
            activeInput={activeGoal}
            error={!goalSelected ? "You must select a goal to proceed" : ""}
            mt={3}
            {...bindGoal}
          />

          <Flex mt={3}>
            <Button
              primary
              label="Save Goal"
              disabled={!goalSelected?.length}
              onClick={() => changeGoal()}
            />
            <Button secondary label="Cancel" onClick={() => exit()} />
          </Flex>
        </Box>
      )}
    </>
  );
};

export default connect(
  (state) => {
    const { playerData } = getPlayerAndTeamDataFromState(state);
    return {
      playerData,
      currentlyInAJourney: isCurrentlyInAJourney(state),
      activeCommunityId: state.community.get("activeCommunityId"),
    };
  },
  { applyGameItem, getNewProfileData, getJourneysForUser }
)(ChangeGoal);
