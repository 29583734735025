import styled from "styled-components";
import { space, layout, flexbox } from "styled-system";

const StyledDropdownSelect = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  border: none;
  font-family: ${(props) => props.theme.fonts.body};
  font-size: ${(props) => props.theme.fontSize.fz2};
  font-weight: 400;
  position: relative;
  width: 100%;
  ${space};
  ${layout};
  ${flexbox};
`;

export default StyledDropdownSelect;
