import React, { Fragment } from "react";

import { slashProtectionInfo } from "./bondingMessages";
import { Header } from "../../Storybook";
import { Space, Tab, TabList, Text } from "../Utility";
import { genNewId } from "../../utils/mochiHelpers";

export const SlashProtection = ({
  selectSlashProtection,
  slashProtectionId,
  slashProtectionObj,
}) => {
  return (
    <Fragment>
      <Header h3 data-testid={"slashProtection"}>
        Get Protected
      </Header>
      <Space mb={20} />
      <Text>{slashProtectionInfo}</Text>
      <br />
      <TabList>
        {Object.values(slashProtectionObj).map((m, i) => (
          <Tab
            key={genNewId("slash-protections")}
            data-testid={m.label + " Tab"}
            onClick={() => selectSlashProtection(i, m.divisor)}
            selected={i === slashProtectionId}
          >
            <Header h5 selected={i === slashProtectionId}>
              {m.label}
            </Header>
          </Tab>
        ))}
      </TabList>
    </Fragment>
  );
};
