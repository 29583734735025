import React from "react";
import styled, { css } from "styled-components";
import { position } from "styled-system";

import { Paragraph } from "../../Storybook";

const CounterBackGround = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  height: 19px;
  width: 19px;
  border-radius: 100%;
  background-color: #000;
  ${(props) =>
    props.size &&
    css`
      height: ${(props) => props.size};
      width: ${(props) => props.size};
    `};
  ${position};
`;

const Counter = ({ number, color, size, ...props }) => {
  return (
    <CounterBackGround size={size} color={color} {...props}>
      <Paragraph
        label={number}
        weight={600}
        fontSize={"fz1"}
        textAlign="center"
      />
    </CounterBackGround>
  );
};
export default Counter;
