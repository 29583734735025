import React from "react";
import { useRouteMatch } from "react-router-dom";

import { TabController } from "../Utility";
import { PlayerJourney, AllGoals } from "./index";

const ProfileRouting = () => {
  const match = useRouteMatch();
  const tabs = [
    {
      name: "Current Goal",
      path: match.path,
      url: match.url,
      selected: true,
      component: PlayerJourney,
    },
    {
      name: "All Goals",
      path: `${match.path}/goals`,
      url: `${match.url}/goals`,
      selected: false,
      component: AllGoals,
    },
  ];

  return (
    <TabController
      tabControllerJustifyContent="flex-start"
      tabs={tabs}
      gridSpan={[4, 4, 8]}
      gridStart={[1, 5, 5]}
      routeInline
    />
  );
};

export default ProfileRouting;
