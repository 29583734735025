import moment from "moment";
import { Map } from "immutable";

// simplify journey data for view
export const journeyFormatter = (journey, userId) => {
  let simpleJourney = [];
  let paused = false;
  let didNotPlay = false;
  let noReportCount = 0;
  let gameModeId = journey.summary.player_totals[userId].game_mode_id;
  const startDate = moment(journey.summary.start * 1000).utc();
  const endDate = moment(journey.summary.end * 1000).utc();

  // get show and tell reports from raw data
  const playerShowNTellData = Object.keys(journey.summary.show_and_tells).reduce((map, k, v) => {
    if (journey.summary.show_and_tells[k][userId.toString()])
      map = map.set(k, journey.summary.show_and_tells[k][userId.toString()].report);
    return map;
  }, Map());

  Object.keys(journey.summary.reports)
    .sort((a, b) => moment(a, "MM/DD/YYYY").diff(moment(b, "MM/DD/YYYY"), "days"))
    .forEach((v, k) => {
      // only keep main users info
      let dayInfo = journey.summary.reports[v][userId];

      // The user was not in this journey
      if (dayInfo === undefined) {
        noReportCount += 1;
        dayInfo = {
          paused: false,
          check_in_success: false,
          token_award: false,
          date: v,
          team_misses: [],
          player_slash_amount: 0,
          conversation: [],
        };
      }

      dayInfo.date = v;
      simpleJourney.push(dayInfo);
      // check if user has ever paused
      if (!paused && dayInfo.paused) paused = true;
      // will set journey's game mode as first day of journey
      if (!gameModeId) gameModeId = dayInfo.game_mode_id;
    });

  if (noReportCount === Object.keys(journey.summary.reports).length) didNotPlay = true;

  return {
    start: startDate.format("MM/DD/YYYY"),
    end: endDate.format("MM/DD/YYYY"),
    paused,
    didNotPlay,
    simpleJourney,
    playerShowNTellData,
    showAndTellData: journey.summary.show_and_tells,
    id: journey.id,
    gameModeId,
    player_totals: journey.summary.player_totals,
    teamId: journey.team_id,
  };
};
