import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { Box, Notification } from "../../Utility";
import { Button, Header, Paragraph } from "../../../Storybook";
import { notOnTeamHeader, notOnTeamMsg } from "./notificationMessages";

const NotOnTeamNotification = ({ activeCommunityId }) => {
  return (
    <Notification bgColor={"darkOrange500"} width="100%">
      <Box width={[1, 1 / 2]}>
        <Header h3 colored mt={0} mb={1} label={notOnTeamHeader} />
        <Paragraph colored label={notOnTeamMsg} />
      </Box>
      <Box
        mt={[2, 0]}
        width={[1, 1 / 2]}
        pl={[0, 2]}
        display="flex"
        justifyContent={["flex-start", "flex-end"]}
      >
        <Link to={`/${activeCommunityId}/pool`}>
          <Button primary arrow colored="darkOrange500" label={`Join Wave`} />
        </Link>
      </Box>
    </Notification>
  );
};

export default connect((state) => {
  const activeCommunityId = state.community.get("activeCommunityId");
  return {
    activeCommunityId,
  };
})(NotOnTeamNotification);
