import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { fromJS } from "immutable";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";

import { PlayerInfo, TeamCard, InventoryCard, ProfileRouting } from "../components/Profile";
import ProfileForms from "../components/Forms/ProfileForms";
import AuthorizedHOC from "../components/Utility/AuthorizedHOC";
import { GridContainer, Box, Loading } from "../components/Utility";
import { fetchRemainingCheckIns } from "../containers/CheckIn/actions";
import {
  fetchCustomerData,
  getStats,
  getExternalPlayerInfo,
  getallDataForProfilePage,
} from "../containers/Community/actions";
import PlayerMochi from "../components/Mochi/PlayerMochi";
import { Button, Modal, GridBox } from "../Storybook";
import CreateATeam from "../components/Forms/CreateATeam";
import { getPlayerAndTeamDataFromState } from "../containers/Community/stateGetterHelpers";
import TeamInvitation from "../components/Profile/TeamInvitation";

export const ProfilePage = ({
  authUserId,
  fetchCustomerData,
  fetchRemainingCheckIns,
  getExternalPlayerInfo,
  getStats,
  communityType,
  activeCommunityId,
  getallDataForProfilePage,
}) => {
  let history = useHistory();
  let { profileUserId, communityId } = useParams();
  profileUserId = Number(profileUserId);
  const { playerData, teamData } = useSelector((state) =>
    getPlayerAndTeamDataFromState(state, profileUserId || authUserId)
  );

  const isAnotherPlayersProfile = Number(profileUserId) !== authUserId;
  const stats = playerData?.get("stats");
  const invitationsToTeam = playerData?.get("teamInvitations") || fromJS([]);
  const hasJourneyData = !!playerData && playerData.get("journeyData")?.size;
  const customerData = useSelector((state) =>
    state.community.getIn(["players", activeCommunityId, profileUserId, "customerData"])
  );

  const [editing, setEditProfile] = useState(false);
  const [createTeamModal, setCreateTeamModal] = useState();
  const [requestedUser, setRequestedUser] = useState();
  const [requestedStats, setRequestedStats] = useState();

  const isNewUser = !!playerData?.get("eth_address") && !playerData?.get("email");
  const playerIsNotMember = !!playerData && !!playerData?.get("notAMember");
  const missingJourneyInfo = !playerData?.get("journeyData");
  const missingTeamData = playerData?.get("team_id") && !teamData;
  const missingSomeInfo = !playerData?.get("eth_address") || missingTeamData || missingJourneyInfo;

  useEffect(() => {
    if (!history.location.hash) window.scrollTo(0, 0);
  }, [history.location.hash]);

  useEffect(() => {
    if (playerIsNotMember) history.push("/404");
    else if (missingSomeInfo && !requestedUser && !isNewUser) {
      if (isAnotherPlayersProfile)
        getExternalPlayerInfo(Number(profileUserId), Number(communityId));
      // else if (!isAnotherPlayersProfile) getallDataForProfilePage();
      setRequestedUser(true);
    }
  }, [
    requestedUser,
    playerIsNotMember,
    missingSomeInfo,
    isAnotherPlayersProfile,
    activeCommunityId,
    getExternalPlayerInfo,
    profileUserId,
    communityId,
    history,
    isNewUser,
    getallDataForProfilePage,
  ]);

  useEffect(() => {
    if (
      !requestedStats &&
      !isAnotherPlayersProfile &&
      hasJourneyData &&
      !stats?.has("slash_count")
    ) {
      getStats(activeCommunityId);
      setRequestedStats(true);
    }
  }, [isAnotherPlayersProfile, requestedStats, hasJourneyData, activeCommunityId, getStats, stats]);

  useEffect(() => {
    if (!customerData && activeCommunityId === 1) {
      fetchCustomerData(parseInt(profileUserId));
    }
  }, [customerData, activeCommunityId, fetchCustomerData, profileUserId]);

  useEffect(() => {
    if (!isAnotherPlayersProfile) {
      fetchRemainingCheckIns();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const leader = teamData?.get("leader") === playerData?.get("user_id") ? true : false;
  const invited = invitationsToTeam.size ? true : false;
  const onTeam = !!teamData && teamData.get("team_id");

  if (isAnotherPlayersProfile && !playerData) return <Loading />;

  return (
    <AuthorizedHOC>
      {!isAnotherPlayersProfile && (
        <Box>
          {editing && (
            <Modal onClose={() => setEditProfile(false)}>
              <ProfileForms customerData={customerData} />
            </Modal>
          )}
          {!isNewUser &&
            invitationsToTeam.map((inv) => (
              <Box key={inv.get("invitation_id")}>
                <TeamInvitation invitation={inv} />
              </Box>
            ))}
          {createTeamModal && (
            <Modal onClose={() => setCreateTeamModal(false)}>
              <CreateATeam />
            </Modal>
          )}
        </Box>
      )}
      <GridContainer alignItems="flex-start">
        <GridBox span={[4]} position={["auto", "sticky"]} top="30px">
          <PlayerMochi playerId={profileUserId} isProfile={true} />
          {!isAnotherPlayersProfile ? (
            <Box>
              <RouterLink to={`/${activeCommunityId}/bond/top-up`}>
                <Button primary arrow label="Bond with Mochi" width="100%" mb={2} />
              </RouterLink>
              <Button
                secondary
                arrow
                label="Edit Your Profile"
                width="100%"
                onClick={() => setEditProfile(true)}
                mb={2}
              />
            </Box>
          ) : (
            <Box>
              <RouterLink to={`/${activeCommunityId}/top-up-for-player/${profileUserId}/`}>
                <Button primary arrow label="Top up for Player" width="100%" mb={2} />
              </RouterLink>
            </Box>
          )}
          <PlayerInfo playerId={profileUserId} />
          <TeamCard
            leader={leader}
            invited={invited}
            onTeam={onTeam}
            teamId={teamData?.get("team_id")}
            teamName={teamData?.get("name")}
            isAnotherPlayersProfile={isAnotherPlayersProfile}
            createTeamModal={() => setCreateTeamModal(true)}
            activeCommunityId={activeCommunityId}
          />
          {!isAnotherPlayersProfile && <InventoryCard />}
          {!isAnotherPlayersProfile &&
            !playerData?.getIn(["details", "user_oauth_token"]) &&
            communityType === "S" && (
              <RouterLink to="/slack-oauth">
                <Button primary width="100%" label="Enable Slack Oauth" mt={20} />
              </RouterLink>
            )}
        </GridBox>
        <ProfileRouting />
      </GridContainer>
    </AuthorizedHOC>
  );
};

export default connect(
  (state, props) => {
    const activeCommunityId = state.community.get("activeCommunityId");
    return {
      activeCommunityId,
      authUserId: state.auth.get("authedUserId"),
      communityType: state.community.getIn([activeCommunityId, "community_type"]),
    };
  },
  {
    fetchCustomerData,
    fetchRemainingCheckIns,
    getStats,
    getExternalPlayerInfo,
    getallDataForProfilePage,
  }
)(ProfilePage);
