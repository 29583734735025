import styled, { css } from "styled-components";

const BondPath = styled.path`
  stroke: ${(props) => props.color};
  stroke-width: 40;

  ${(props) =>
    props.isMinimumBond &&
    css`
      stroke-opacity: 0.4;
    `};

  ${(props) =>
    props.meterLength &&
    css`
      stroke-dasharray: ${(props) => props.meterLength};
    `};

  ${(props) =>
    props.fillOffset &&
    css`
      stroke-dashoffset: ${(props) => props.fillOffset};
    `};

  transition: stroke-dashoffset 400ms ease-in-out,
    stroke-dasharray 400ms ease-in-out, stroke 400ms ease-in-out;
`;

export default BondPath;
