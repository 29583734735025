import React from "react";

const ethLogo = ({ h = 14, w = 18, color = "white", opacity = "1" }) => (
  <svg
    width={w}
    height={h}
    opacity={opacity}
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ margin: "-1px 0" }}
  >
    <g>
      <path
        opacity="0.4"
        style={{ fill: color }}
        d="M10 0L17 11.5L10 15.5L3 11.5L10 0Z"
      />
      <path
        opacity="0.4"
        style={{ fill: color }}
        d="M10 0L17 11.5L10 15.5L10 0Z"
      />
      <path
        opacity="0.4"
        style={{ fill: color }}
        d="M10 22L17 12.5L10 16.5L3 12.5L10 22Z"
      />
      <path
        opacity="0.4"
        style={{ fill: color }}
        d="M10 22L17 12.5L10 16.5V22Z"
      />
      <path
        opacity="0.4"
        style={{ fill: color }}
        d="M10 8.5L17 11.5L10 15.5L3 11.5L10 8.5Z"
      />
    </g>
  </svg>
);

export default ethLogo;
