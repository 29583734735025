import React from "react";
import TabList from "./TabList";
import Tab from "./Tab";
import Header from "./Header";
import { genNewId } from "../../utils/mochiHelpers";

const Paginator = ({ currentPage, totalPages, callback, length = 5 }) => {
  const FIRST = 0;
  const SECOND = 1;
  const PENULTIMATE = length - 2;
  const LAST = length - 1;
  const MIDDLE = Math.floor(length / 2);

  if (length < 5) {
    throw new Error("Minimum Paginator size is 5");
  }
  if (!length % 2) {
    throw new Error("Paginator size must be odd");
  }

  const shortPageList = (_, i) => {
    return {
      text: i + 1,
      number: i + 1,
      selected: i + 1 === currentPage,
    };
  };

  const longPageList = (_, i) => {
    switch (i) {
      case FIRST:
        return {
          text: 1,
          number: 1,
          selected: 1 === currentPage,
        };
      case SECOND:
        if (currentPage > MIDDLE + 1) {
          return {
            text: "...",
            number: currentPage - 1,
            selected: false,
          };
        } else {
          return {
            text: i + 1,
            number: i + 1,
            selected: i + 1 === currentPage,
          };
        }
      case PENULTIMATE:
        if (currentPage < 4 || currentPage < totalPages - MIDDLE) {
          return {
            text: "...",
            number: currentPage + 1,
            selected: false,
          };
        } else {
          // below line was original version, no issues with changing to else
          // } else if (currentPage >= 3) {
          return {
            text: totalPages - 1,
            number: totalPages - 1,
            selected: totalPages - 1 === currentPage,
          };
        }
      case LAST:
        return {
          text: totalPages,
          number: totalPages,
          selected: totalPages === currentPage,
        };
      default:
        let pageOffset = i + 1;
        if (currentPage <= MIDDLE) {
          pageOffset = i + 1;
        } else if (currentPage > totalPages - MIDDLE - 1) {
          pageOffset = i - (2 * MIDDLE - totalPages);
        } else {
          pageOffset = currentPage + i - MIDDLE;
        }
        return {
          text: pageOffset,
          number: pageOffset,
          selected: pageOffset === currentPage,
        };
    }
  };

  const arr = [...Array(length).keys()];
  const isShorterThanLength = totalPages <= length;
  const pageLimit = isShorterThanLength ? totalPages : length;
  const elements = isShorterThanLength
    ? arr.splice(0, pageLimit).map(shortPageList)
    : arr.map(longPageList);

  return (
    <TabList>
      {elements.map((element) => (
        <Tab
          key={genNewId("tabs")}
          onClick={() => callback(element.number)}
          selected={element.selected}
        >
          <Header h5 selected={element.selected}>
            {element.text}
          </Header>
        </Tab>
      ))}
    </TabList>
  );
};

export default React.memo(Paginator);
