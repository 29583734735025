import React, { useMemo } from "react";
import moment from "moment";
import { connect } from "react-redux";

import { Box, Report } from "../Utility";
import DailyReportsStats from "./DailyReportStats";
import JourneyReportButton from "./JourneyReportButton";
import MochiBotConversation from "./MochiBotConversation";
import { processShowAndTell, shouldReportBeRendered } from "../../utils/mochiHelpers";
import { genNewId } from "../../utils/mochiHelpers";
import { Paragraph } from "../../Storybook";

const JourneyReportsView = ({
  journeyDetails,
  playerName,
  playerId,
  isAnotherPlayersProfile,
  outstandingReport,
}) => {
  const { showAndTellDataAsAList, showAndTellDataObj } = useMemo(
    () => processShowAndTell(journeyDetails, playerId),
    [journeyDetails, playerId]
  );

  const beforeDeadline = outstandingReport
    ? moment(outstandingReport?.get("checkin_date")).add(8, "hours").isAfter(moment())
    : false;

  const simpleJourney = useMemo(
    () =>
      journeyDetails
        ?.get("simpleJourney")
        ?.concat(showAndTellDataAsAList)
        .sortBy((report) => moment(report.get("date"), "MM/DD/YYYY").format("YYYY/MM/DD")),
    [journeyDetails, showAndTellDataAsAList]
  );

  const quitDate = moment(
    journeyDetails?.getIn(["player_totals", playerId.toString(), "quit_date"]),
    "MM/DD/YYYY"
  );
  const journeyQuitDate =
    !!quitDate &&
    quitDate.isAfter(moment(journeyDetails?.get("start"), "MM/DD/YYYY").subtract(1, "days")) &&
    quitDate;

  if (!journeyDetails?.get("simpleJourney")?.size)
    return (
      <Report mt={2} borderRadius="2px" justifyContent="flex-start">
        <Paragraph
          color="darkFGTertiary"
          fontSize="fz1"
          label={`Once ${
            isAnotherPlayersProfile ? `${playerName} begins` : "you begin"
          } submitting Pearls of Wisdom, they will appear here.`}
        />
      </Report>
    );

  return (
    <Box display="flex" flexDirection="column-reverse">
      {simpleJourney.map(
        (d, index) =>
          shouldReportBeRendered(
            d,
            journeyQuitDate,
            showAndTellDataObj.get(d?.get("date"))?.get("report_date")
          ) && (
            <Box key={genNewId("report")}>
              <Report>
                <DailyReportsStats
                  playerId={playerId}
                  playerName={playerName}
                  teamInfo={journeyDetails.get("player_totals")}
                  ethLost={(d.get("team_slash_amount") || 0) + (d.get("player_slash_amount") || 0)}
                  isPaused={d.get("paused")}
                  displayFailure={d.get("check_in_success") === false}
                  isBeforeCheckInDeadline={
                    d.get("check_in_success") !== false && !!!d.get("player_slash_amount")
                  }
                  displayTokens={d.get("token_award") > 0}
                  checkInSuccess={d.get("check_in_success")}
                  showAndTell={
                    showAndTellDataObj.get(d?.get("report_date")) ||
                    showAndTellDataObj.get(d.get("date"))
                  }
                  tokenAward={d.get("token_award")}
                  teamMisses={d.get("team_misses")}
                  journeyQuitDate={!!journeyQuitDate && journeyQuitDate?.format("MM/DD/YYYY")}
                  quitEthLost={journeyDetails.getIn([
                    "player_totals",
                    playerId.toString(),
                    "quit_wei_penalty",
                  ])}
                  reportDate={d.get("date")}
                  isAnotherPlayersProfile={isAnotherPlayersProfile}
                  outstandingReport={index === simpleJourney.size - 1 ? outstandingReport : null}
                />
                <Box width={[1, 1, 1 / 2]}>
                  {!isAnotherPlayersProfile &&
                    index === simpleJourney.size - 1 &&
                    d.get("check_in_success") !== true &&
                    !!!d.get("player_slash_amount") &&
                    beforeDeadline &&
                    !d.get("paused") && <JourneyReportButton />}
                  <MochiBotConversation
                    day={d}
                    showAndTell={
                      showAndTellDataObj.get(d?.get("report_date")) ||
                      showAndTellDataObj.get(d.get("date"))
                    }
                  />
                </Box>
              </Report>
            </Box>
          )
      )}
    </Box>
  );
};

// export default JourneyReportsView;

export default connect((state, props) => {
  return {
    isAnotherPlayersProfile: state.auth.get("authedUserId") !== props.playerId,
    outstandingReport: state.checkIn.getIn([
      "outstanding",
      state.community.get("activeCommunityId"),
    ]),
  };
})(JourneyReportsView);
