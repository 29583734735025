import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";

import { GridContainer } from "../components/Utility";
import { GridBox, Header, Modal, Paragraph } from "../Storybook";
import AuthorizedHOC from "../components/Utility/AuthorizedHOC";
import CheckInStatusMessage from "../components/CheckIns/CheckInStatusMessage";
import CheckInGoals from "../components/CheckIns/CheckInGoals";
import { fetchRemainingCheckIns } from "../containers/CheckIn/actions";
import { fetchJourneyDetails, toggleCommunity } from "../containers/Community/actions";
import CheckInReports from "../components/CheckIns/CheckInReports";
import { isComplete } from "../components/CheckIns/utilities";

const CheckInPage = ({
  authedPlayerId,
  activeCommunityId,
  currentGoals,
  journeyDetails,
  remainingCheckIns,
  fetchRemainingCheckIns,
  fetchJourneyDetails,
  toggleCommunity,
}) => {
  // set to null and update when player clicks goal
  const [checkInGoal, setCheckInGoal] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Ensure Journey Details have been loaded for selected Goal
    if (checkInGoal && !journeyDetails.get(checkInGoal?.get("community_id"))) {
      const journey_id = checkInGoal?.get("id");
      fetchJourneyDetails(journey_id, checkInGoal.get("community_id"));
    }
    // Ensure active community is set to be the goal community
    if (checkInGoal && activeCommunityId !== checkInGoal?.get("community_id")) {
      toggleCommunity(checkInGoal?.get("community_id"));
    }
  }, [checkInGoal, journeyDetails, activeCommunityId, fetchJourneyDetails, toggleCommunity]);

  useEffect(() => {
    try {
      fetchRemainingCheckIns();
    } catch (e) {
      if (e?.data?.message) {
        setError(e?.data?.message);
      } else {
        setError(e);
      }
    }
  }, [fetchRemainingCheckIns]);

  const allComplete = useMemo(
    () => currentGoals.every((v) => isComplete(v.get("last_checkin"), v.get("next_checkin"))),
    [currentGoals]
  );

  return (
    <AuthorizedHOC>
      <GridContainer alignContent="flex-start" alignItems="flex-start">
        {error && (
          <Modal onClose={() => setError(null)}>
            <Header h3 mb={3} label="Something Went Wrong" />
            <Paragraph color="darkNegative500" label={error} overflow="hidden" />
          </Modal>
        )}
        <GridBox>
          <Header h1 label="Check In" />
        </GridBox>
        <CheckInStatusMessage
          numberOfGoals={currentGoals?.length}
          allComplete={allComplete}
          checkInGoal={checkInGoal}
        />
        {!!currentGoals?.length && (
          <>
            <CheckInGoals
              checkInGoal={checkInGoal}
              currentGoals={currentGoals}
              setCheckInGoal={setCheckInGoal}
              showComplete={true}
            />
            <CheckInReports
              checkInGoal={checkInGoal}
              currentGoals={currentGoals}
              setCheckInGoal={setCheckInGoal}
              remainingCheckIns={remainingCheckIns}
              setModalError={setError}
              allComplete={allComplete}
            />
          </>
        )}
      </GridContainer>
    </AuthorizedHOC>
  );
};

export default connect(
  (state) => {
    return {
      authedPlayerId: state.auth.get("authedUserId"),
      activeCommunityId: state.community.get("activeCommunityId"),
      journeyDetails: state.community.get("journeys"),
      currentGoals: state.checkIn.get("activeGoals"),
      remainingCheckIns: state.checkIn.get("outstanding"),
    };
  },
  {
    fetchRemainingCheckIns,
    fetchJourneyDetails,
    toggleCommunity,
  }
)(CheckInPage);
