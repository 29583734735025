import { React, useState } from "react";
import { Modal, Header, Body, Space } from "../Utility";
import sptBond from "../../img/support-bond.png";

const MembershipConfirmationModal = () => {
  const [confirmationModal, setConfirmationModal] = useState(true);

  if (confirmationModal) {
    return (
      <Modal onClose={() => setConfirmationModal(false)}>
        <img
          src={sptBond}
          style={{ width: "60%" }}
          alt="Mochi Pearls of Wisdom"
        />
        <Header fontSize="30px" mb={40}>
          Welcome Aboard!
        </Header>
        <Body fontSize="18px" maxWidth="280px" textAlign="left">
          Congratulations on becoming the newest Mochi member!
        </Body>
        <Space mb={20} />
        <Body fontSize="18px" maxWidth="280px" textAlign="left">
          Check your email for more information about privileges, payments, and
          more.
        </Body>
      </Modal>
    );
  } else return null;
};
export default MembershipConfirmationModal;
