import styled, { css } from "styled-components";
import {
  space,
  color,
  layout,
  flexbox,
  grid,
  typography,
  border,
} from "styled-system";

const ItemMat = styled.button`
  ${space};
  ${color};
  ${layout};
  ${flexbox};
  ${grid};
  ${typography};
  ${border};
  border-radius: ${(props) => props.borderRadius || "16px"};
  display: flex;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "center"};
  align-items: center;
  flex-direction: column;
  border: none;
  width: 220px;

  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0px 0px 32px rgba(255, 255, 255, 0.25);
  }
  ${(props) =>
    props.selected &&
    css`
      border: 1px solid rgba(255, 255, 255, 0.1);
      box-shadow: 0px 0px ${(props) => props.selectBoxShadow || "32px"}
        rgba(255, 255, 255, 0.25);
    `};

  ${(props) =>
    props.bgColor &&
    css`
      background: ${(props) => props.theme.color[props.bgColor]};
    `};

  ${(props) =>
    props.css &&
    css`
      ${(props) => props.css};
    `};
`;

export default ItemMat;
