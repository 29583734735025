import { useState } from "react";

const useInput = (
  initialValue,
  validationFn = () => true,
  needed = false,
  valueTransformer = (val) => val
) => {
  const [value, setValue] = useState(initialValue);
  const [touched, setTouched] = useState(false);
  const [valid, setValid] = useState(
    initialValue ? validationFn(initialValue) : false
  );
  const [active, setActive] = useState(false);
  return {
    value,
    valid,
    touched,
    active,
    setValue,
    reset: (v = "") => {
      setValue(v);
      if (v.length) setValid(validationFn(v));
    },
    bind: {
      touched,
      needed,
      value,
      valid,
      onChange: (event) => {
        setValid(validationFn(event.target.value));
        setValue(valueTransformer(event.target.value));
        setTouched(true);
        setActive(true);
      },
      onBlur: (event) => {
        setActive(false);
        setValid(validationFn(event.target.value));
      },
    },
  };
};

export default useInput;
