import React, { useState } from "react";
import { connect } from "react-redux";

import useInput from "./utils/useInput";
import { editProfile } from "../../containers/Community/actions";
import * as fieldValidators from "./utils/fieldValidators";
import { Space } from "../Utility";
import { Input, Button, Header, Paragraph } from "../../Storybook";
import { getPlayerAndTeamDataFromState } from "../../containers/Community/stateGetterHelpers";
import {
  contactSupportError,
  updatedProfileMsg,
} from "./utils/formMessageStrings";

export const EditUserProfile = ({ userData, editProfile }) => {
  const {
    value: firstName,
    bind: bindFirstName,
    valid: validFirstName,
    active: activeFirstName,
  } = useInput(
    userData.get("first_name"),
    fieldValidators.basicTextField,
    true
  );
  const {
    value: lastName,
    bind: bindLastName,
    valid: validLastName,
    active: activeLastName,
  } = useInput(userData.get("last_name"), fieldValidators.basicTextField, true);
  const [submited, setSubmited] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const inputError = "Every field must be filled out";

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setSubmited(true);
    try {
      setError("");
      const submitData = {
        id: userData.get("user_id"),
        first_name: firstName,
        last_name: lastName,
      };
      await editProfile(submitData);
      setSuccess(updatedProfileMsg);
    } catch (e) {
      setError(contactSupportError);
    }
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <Header h3 mt={0}>
        Edit Name
      </Header>
      <Space mb={30} />
      {submited && (
        <Paragraph
          color={success ? "darkPositive500" : "darkNegative500"}
          mb={3}
        >
          {success.length > 0 && !error.length ? success : error}
        </Paragraph>
      )}
      <Input
        type="text"
        placeholder={firstName}
        submited={submited}
        error={inputError}
        textLabel="First Name"
        activeInput={activeFirstName}
        {...bindFirstName}
      />
      <Input
        type="text"
        placeholder={lastName}
        submited={submited}
        error={inputError}
        textLabel="Last Name"
        activeInput={activeLastName}
        {...bindLastName}
      />
      {error.length > 0 && (
        <Paragraph
          fontSize="fz2"
          color="darkNegative500"
          textAlign="center"
          mt={2}
          mb={3}
        >
          {error}
        </Paragraph>
      )}
      <Button
        primary
        disabled={!validFirstName || !validLastName}
        type="submit"
      >
        Submit
      </Button>
    </form>
  );
};

export default connect(
  (state) => {
    const { playerData } = getPlayerAndTeamDataFromState(state);
    return {
      userData: playerData,
    };
  },
  {
    editProfile,
  }
)(EditUserProfile);
