import styled from "styled-components";
import {
  space,
  color,
  layout,
  flexbox,
  grid,
  border,
  position,
} from "styled-system";

const Flex = styled.div(
  {
    display: "flex",
    justifyContent: "space-around",
  },
  flexbox,
  color,
  layout,
  grid,
  border,
  space,
  position
);

export default Flex;
