import React, { useState } from "react";
import { connect } from "react-redux";
import { List } from "immutable";

import TeammateView from "./TeammateView";
import InviteTeammateView from "../Profile/InviteTeammateView";
import { Modal, Header, Space, Body, Flex, Box } from "../Utility";
import TeamInviteForm from "../Forms/TeamInvite";
import { deleteInvite, updateTeamLead, removeTeamMember } from "../../containers/Community/actions";
import { genNewId } from "../../utils/mochiHelpers";

const formatTeamData = (td, selfId) => {
  if (td && td.get("team_id")) {
    let teammates = new List();
    td.get("members").forEach((m) => {
      // if (m.get("user_id") !== selfId) {
      m = m.set("isMember", true);
      if (m.get("game_mode") && m.get("is_onboarded")) m = m.set("isReady", true);
      teammates = teammates.push(m);
      // }
    });
    td.get("pending_invitations")?.map((i) => (teammates = teammates.push(i)));
    return teammates;
  }

  return new List([]);
};

export const TeammatesView = ({
  teamData,
  playerData,
  isAnotherPlayersProfile,
  amLeader,
  canInvite,
  deleteInvite,
  updateTeamLead,
  removeTeamMember,
}) => {
  const formattedTeamData = formatTeamData(teamData, playerData?.get("user_id"));
  const [inviteModal, setInviteModal] = useState(false);
  const [infoModal, setInfoModal] = useState("");

  const deleteInviteId = (e, id) => {
    e.preventDefault();
    deleteInvite(id);
    // setSuccess('Your team has been updated');
  };

  const makeLeader = async (e, id) => {
    e.preventDefault();
    try {
      await updateTeamLead(teamData, id);
      setInfoModal("Your team has been updated successfully.");
    } catch (e) {
      setInfoModal(
        "There was an issue updating your team. Please try again or contact support if you experience further issues."
      );
    }
  };

  const removeTeammate = async (e, id) => {
    e.preventDefault();
    try {
      await removeTeamMember(id);
      setInfoModal("Your team has been updated successfully.");
    } catch (e) {
      setInfoModal(e);
    }
  };

  return (
    <>
      {!isAnotherPlayersProfile && (
        <Box>
          {inviteModal && (
            <Modal onClose={() => setInviteModal(false)}>
              <Header mt={0}>Invite Teammate</Header>
              <Space mb={20} />
              <TeamInviteForm
                teamId={teamData.get("team_id")}
                afterInvite={() => setInviteModal(false)}
              />
            </Modal>
          )}
          {infoModal.length > 0 && (
            <Modal onClose={() => setInfoModal("")}>
              <Space mb={20} />
              <Body>{infoModal}</Body>
            </Modal>
          )}
        </Box>
      )}
      <Flex alignItems="start" justifyContent="start" flexWrap="wrap">
        {[0, 1, 2, 3].map((n) =>
          !!formattedTeamData.getIn([n, "eth_address"]) ? (
            <TeammateView
              key={genNewId("team-mate")}
              playerId={formattedTeamData.getIn([n, "user_id"])}
              isAnotherPlayersProfile={isAnotherPlayersProfile}
              isMember={formattedTeamData.getIn([n, "isMember"])}
              email={formattedTeamData.getIn([n, "email"])}
              isReady={formattedTeamData.getIn([n, "isReady"])}
              isOnboarded={!!formattedTeamData.getIn([n, "is_onboarded"])}
              hasGameMode={!!formattedTeamData.getIn([n, "game_mode"])}
              canRemoveInvite={
                playerData.get("email") === formattedTeamData.getIn([n, "inviter_email"])
              }
              deleteInvite={(e) => deleteInviteId(e, formattedTeamData.getIn([n, "invitation_id"]))}
              amLeader={amLeader}
              isLeader={teamData.get("leader") === formattedTeamData.getIn([n, "user_id"])}
              makeLeader={(e) => makeLeader(e, formattedTeamData.getIn([n, "user_id"]))}
              removeTeammate={(e) => removeTeammate(e, formattedTeamData.getIn([n, "user_id"]))}
            />
          ) : (
            !isAnotherPlayersProfile && (
              <InviteTeammateView
                key={genNewId("invite-team-mate")}
                canInvite={canInvite}
                setInviteModal={() => setInviteModal(true)}
              />
            )
          )
        )}
      </Flex>
    </>
  );
};

export default connect((state) => ({}), {
  deleteInvite,
  updateTeamLead,
  removeTeamMember,
})(TeammatesView);
