/* eslint-disable no-undef */
import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useAccount, useContractRead, useContractWrite, usePrepareContractWrite } from "wagmi";
import { formatEther, parseEther } from "viem";

import useInput from "../Forms/utils/useInput";
import * as fieldValidators from "../Forms/utils/fieldValidators";
import * as valueTransformer from "../Forms/utils/valueTransformer";
import { withdraw } from "../../containers/Community/actions";
import loading from "../../img/load.gif";
import {
  pendingTransaction,
  transactionFailed,
  cantWithdrawNoBalance,
  cantWithdrawInJourney,
  cantWithdrawInsufficientBalance,
  withdrawSuccess,
  bondingHeader,
  totalBondAfterTransaction,
  withdrawResultsInfo,
} from "./bondingMessages";
import { Header, Button, Input, Paragraph } from "../../Storybook";
import { Box, Space, Flex, Body } from "../Utility";
import { getPlayerAndTeamDataFromState } from "../../containers/Community/stateGetterHelpers";
import ActiveCommunityTag from "../Community/ActiveCommunityTag";
import MochiProdContract from "../../contracts/Mochi.js";
import MochiStagingContract from "../../contracts/Mochi-NEW.js";

let MochiContract;
if (process.env.REACT_APP_VERSION === "production") MochiContract = MochiProdContract;
else MochiContract = MochiStagingContract;

export const WithdrawStep = ({
  gameModes,
  withdraw,
  showAdvanced = false,
  activeCommunityId,
  authedUserId,
  communityName,
}) => {
  const { address } = useAccount();
  const [withdrawn, setWithdrawn] = useState(false);
  const [amountError, setAmountError] = useState("");
  const [processing, setProcessing] = useState(false);
  const orderedGameModes = gameModes.sortBy((m) => m.get("id"));
  // const { data: signer } = useSigner();

  const communityContractAddress = useSelector((state) =>
    state.community.getIn(["communityInfo", activeCommunityId, "game_contract_address"])
  );
  const { data, isError: isContractError } = useContractRead({
    address: communityContractAddress,
    abi: MochiContract.abi,
    functionName: "getPlayerInfo",
    args: [address],
  });
  const accountLocked = data?.playing || false;
  const currentWeiBalanceBN = data ? data[0] : BigInt(0);
  const {
    value: withdrawAmt,
    bind: bindWithdraw,
    setValue: setWithdrawValue,
  } = useInput(0, fieldValidators.validWithdrawal, true, valueTransformer.ensureValidEth);
  const withdrawAmtWeiBN = parseEther(withdrawAmt.length ? withdrawAmt.toString() : "0");
  const getNewFirmness = (currentBalanceBN, withdrawAmtBN) =>
    orderedGameModes
      .filter((m) => m.get("minimum_stake") <= (currentBalanceBN - withdrawAmtBN).toString())
      .getIn([-1, "id"]);

  const { config } = usePrepareContractWrite({
    address: communityContractAddress,
    abi: MochiContract.abi,
    // signerOrProvider: signer,
    functionName: "withdraw",
    args: [withdrawAmtWeiBN],
  });
  const { writeAsync } = useContractWrite(config);

  const withdrawFromBalance = async () => {
    // eslint-disable-next-line eqeqeq
    if (withdrawAmtWeiBN == 0) setAmountError(cantWithdrawNoBalance);
    else if (withdrawAmtWeiBN > currentWeiBalanceBN)
      setAmountError(cantWithdrawInsufficientBalance);
    else if (accountLocked) setAmountError(cantWithdrawInJourney);
    else {
      try {
        setAmountError("");
        setProcessing(true);
        const { hash } = await writeAsync();
        const newFirmness = getNewFirmness(currentWeiBalanceBN, withdrawAmtWeiBN);
        await withdraw(activeCommunityId, withdrawAmtWeiBN.toString(), hash, newFirmness);
        setWithdrawn(true);
        setProcessing(false);
      } catch (e) {
        setAmountError(transactionFailed);
        setProcessing(false);
      }
    }
  };

  if (!orderedGameModes.size) return <div />;
  if (!communityContractAddress)
    return (
      <Box>
        <Header h2 color="red">
          Cannot Withdraw
        </Header>
        <Paragraph color="red">
          It looks like {communityName} does not have a contract deployed just yet. Don't worry,
          Team Mochi is on it. Once it does, revisit this page to withdraw ether as long as you are
          not in a journey.
        </Paragraph>
      </Box>
    );
  if (isContractError)
    return (
      <Box>
        <Header h2 color="red">
          Error Fetching Eth Balance in Contract
        </Header>
        <Paragraph color="red">
          It looks like we're having an issue fetching your eth balance for {communityName}. Don't
          worry, your eth is safe, we will need to figure out which wires we've crossed.
        </Paragraph>
      </Box>
    );
  return (
    <Box>
      {!withdrawn ? (
        <Box textAlign="left">
          {processing ? (
            <Box>
              <img src={loading} alt="loading" width="100px" />
              <Header h2>{bondingHeader(false, withdrawAmt)}</Header>
              <Body>
                {totalBondAfterTransaction(
                  formatEther(currentWeiBalanceBN - withdrawAmtWeiBN).toString()
                )}
              </Body>
              <Body>{pendingTransaction.beSureToAccept}</Body>
              <Body>{pendingTransaction.wait}</Body>
            </Box>
          ) : (
            <Box>
              <Box width={[1, 1]} textAlign="left">
                <Space mb={40} />
                <Header h2 textAlign="left" mb={3}>
                  Withdrawal Amount
                </Header>
                <Body>
                  {withdrawResultsInfo(
                    formatEther(withdrawAmtWeiBN).toString(),
                    formatEther(currentWeiBalanceBN).toString(),
                    orderedGameModes
                      .filter(
                        (m) =>
                          m.get("minimum_stake") <=
                          (currentWeiBalanceBN - withdrawAmtWeiBN).toString()
                      )
                      .getIn([-1, "name"]),
                    formatEther(currentWeiBalanceBN - withdrawAmtWeiBN).toString()
                  )}
                </Body>
                <Button
                  mb={2}
                  secondary
                  label="Withdraw Max"
                  onClick={() => setWithdrawValue(formatEther(currentWeiBalanceBN.toString()))}
                />
                <Flex alignItems="baseline" flexDirection="row">
                  <Input
                    placeholder="0"
                    submited={processing}
                    textLabel="Withdrawl Amount"
                    icon="ethDarkPrimary"
                    type="number"
                    hideSpin={true}
                    min={0}
                    {...bindWithdraw}
                  />
                </Flex>
              </Box>
              {accountLocked && (
                <Body error mb={20}>
                  You cannot withdraw while your ether is locked. If your journey has ended already
                  please reach out to your community manager or a Mochi Administrator.
                </Body>
              )}
              <Header h3>Community</Header>
              <Body>
                You are withdrawing stake from the{" "}
                <span style={{ fontWeight: "bold" }}>{communityName}</span> community. If you'd like
                to withdraw from another community, select the community from the toggle in the top
                right.
              </Body>
              <ActiveCommunityTag />
              <Space mb={30} />
              {amountError.length > 0 && <Body error>{amountError}</Body>}
              <Button primary arrow label="Withdraw" onClick={() => withdrawFromBalance()} />
            </Box>
          )}
        </Box>
      ) : (
        <Box textAlign="left">
          <Body>{withdrawSuccess}</Body>
          <Space mb={30} />
          {!showAdvanced && (
            <Link to={`/${activeCommunityId}/profile/${authedUserId}`}>
              <Button primary>Return to Profile</Button>
            </Link>
          )}
        </Box>
      )}
    </Box>
  );
};

export default connect(
  (state) => {
    const { playerData } = getPlayerAndTeamDataFromState(state);
    const activeCommunityId = state.community.get("activeCommunityId");
    return {
      activeCommunityId,
      authedUserId: state.community.get("authedUserId"),
      gameModes: state.gameModes.get("all"),
      gameModeId: playerData.getIn(["journeyData", 0, "gameModeId"]) || playerData.get("game_mode"),
      communityName: state.community.getIn(["communityInfo", activeCommunityId, "name"]),
    };
  },
  {
    withdraw,
  }
)(WithdrawStep);
