import { Fragment } from "react";
import { shortenAddress } from "../../utils/mochiHelpers";
import { Text, Space } from "../Utility";

/////////////////////////////
// Errors
/////////////////////////////
export const cantChangeFirmnessInJourney = "You cannot change your firmness while in a journey.";
export const cantChangeToHigherFirmnessInJourney = (gameModeName) =>
  `You can only top up to your original journey bond. The game mode you selected was ${gameModeName}.`;
export const cantWithdrawInJourney = "Cannot withdraw while in a journey.";
export const cantWithdrawNoBalance =
  "Please change the amount you wish to withdraw. No point in paying gas to withdraw nothing!";
export const cantWithdrawInsufficientBalance = "Withdraw amount exceeds current ETH balance.";
export const transactionFailed = "Transaction failed. Did you accept the transaction on MetaMask?";
export const bondTxFailedLowBalance =
  "Transaction failed. Do you have enough ETH to make this deposit?";
export const topUpUpdateFailed =
  "Your bond is safe, however we encountered an issue when updating your player information. Team Pomelo has been notified and will fix this issue shortly.";

/////////////////////////////
// Information
/////////////////////////////
export const firmnessInfo =
  "Firmness dictates how much you will be rewarded or slashed depending how you perform. There are minimum bonding requirements for each level of difficulty.";
export const bondAmountInfo =
  "Your bond amount simply determines the amount you are locking away during your journey. You may want to bond more than the minimum requirement to ensure that when you are slashed you do not fall below the minimum requirement.";
export const ethAddressMismatch = (expected = "", actual = "") => ({
  info: `The ethereum address currently active in MetaMask (${shortenAddress(
    actual
  )}) is not the address that we have on file for your account (${shortenAddress(expected)}).`,
  continueOrCancel:
    "Continue only if you are intentionally topping up with a different address. Otherwise, cancel and change the active address in Metamask before continuing.",
  cantWithdrawWrongAddress:
    "You cannot withdraw into a different ethereum address. To continue, log into the ETH address on file.",
});
export const slashProtectionInfo =
  "Bonding just the minimum makes mochi vulnerable to dropping to the next firmness on the first slash, making it harder to earn tokens. Add protection to protect teammates & tokens.";
export const bondingHeader = (depositing, amount) =>
  depositing ? `You are bonding ${amount} Ether.` : `You are withdrawing ${amount} Ether.`;
export const gameModeInfo = (mode, tokenReward) =>
  mode === "Soft" ? (
    <Fragment>
      <Text fontWeight={400} color="red">
        On Soft, players won't earn any Mochi tokens for reports.
      </Text>
      <Space mb={10} />
      <Text fontWeight={400} color="red">
        Play on a firmer setting to earn tokens.
      </Text>
    </Fragment>
  ) : (
    <Text data-testid={"gameModeInfo"}>
      On {mode}, players earn{" "}
      <Text style={{ color: "#80D183", fontWeight: "900" }}>{tokenReward} Mochi tokens</Text> per
      report. Slashes will result in a sizeable amount of Bond lost.
    </Text>
  );
export const bondingRequirementForGameModeInfo = (name, minStake, diff) =>
  name === "Soft" ? (
    <Text data-testid={"softModeInfo"}>
      You don’t need a bond to play Mochi on Soft, just click the button below to change your
      firmness.
    </Text>
  ) : (
    <Text data-testid={"minStakeText"}>
      In order to play Mochi on <Text fontWeight={800}>{name}</Text>, you need a bond of at least{" "}
      {minStake} ether (
      <span
        style={{
          color: "#80D183",
          fontWeight: "800",
        }}
      >
        + {diff > 0 ? diff : 0}
      </span>{" "}
      ether).
    </Text>
  );

export const slashProtectionAdditionalInfo = (amount, additionalToBond) => (
  <Text data-testid="slashProtectionText">
    You've added {amount} slash protection (
    <span
      style={{
        color: "#80D183",
        fontWeight: "800",
      }}
    >
      + {additionalToBond}
    </span>{" "}
    ether).
  </Text>
);
export const withdrawResultsInfo = (
  withdrawAmount,
  beforeBondAmount,
  afterGameMode,
  afterBondAmount
) => (
  <Text fontWeight={200}>
    {" "}
    By withdrawing{" "}
    <span style={{ color: "#F64444", fontWeight: "800" }}>{withdrawAmount} ether</span> from your
    bond of <span style={{ fontWeight: "800" }}>{beforeBondAmount} ether</span>, your firmness will
    be <Text fontWeight={800}>{afterGameMode}</Text> with a bond of{" "}
    <span style={{ color: "#F64444", fontWeight: "800" }}>{afterBondAmount}</span>.
  </Text>
);
export const totalBondAfterTransaction = (totalBond) =>
  `Your total bond will be ${totalBond} ether.`;
export const pendingTransaction = {
  beSureToAccept: "Be sure to accept the transaction in MetaMask.",
  wait: "After that it may take some time for your transaction to confirm...",
};
export const depositSuccess = "Success! You've bonded with Mochi!";
export const withdrawSuccess = "Success! You’ve successfully withdrawn ETH from your balance";
