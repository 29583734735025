import React from "react";
import { useRouteMatch } from "react-router-dom";

import CurrentlyPlaying from "../components/CommunityPool/CurrentlyPlaying";
import { TabController } from "../components/Utility";
import FreeToPlay from "../components/CommunityPool/FreeToPlay";
import { GridContainer } from "../components/Utility";

const CommunityPoolPage = () => {
  const match = useRouteMatch();
  const tabs = [
    {
      name: "Playing",
      path: match.path,
      url: match.url,
      selected: true,
      component: CurrentlyPlaying,
    },
    {
      name: "Free Players",
      path: `${match.path}/free-players`,
      url: `${match.url}/free-players`,
      selected: false,
      component: FreeToPlay,
    },
  ];

  return (
    <GridContainer justifyContent="center" alignItems="flex-start">
      <TabController tabControllerJustifyContent="flex-start" tabs={tabs} routeInline />
    </GridContainer>
  );
};

export default CommunityPoolPage;
