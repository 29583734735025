import React from "react";
import PropTypes from "prop-types";

import StyledHeader from "../../components/Utility/Header";
import darkTheme from "../../styles/theme";

const Header = ({
  h1,
  h2,
  h3,
  h4,
  h5,
  fontSize,
  label,
  color,
  theme,
  colored,
  children,
  ...props
}) => {
  const overwriteColor =
    !!color && darkTheme.color[color]
      ? darkTheme.color[color]
      : !color
      ? darkTheme.color.darkFGPrimary
      : color;
  return (
    <StyledHeader
      h1={h1}
      h2={h2}
      h3={h3}
      h4={h4}
      h5={h5}
      fontSize={fontSize}
      color={overwriteColor}
      theme={theme}
      colored={colored}
      {...props}
    >
      {children || label}
    </StyledHeader>
  );
};

Header.propTypes = {
  h1: PropTypes.bool,
  h2: PropTypes.bool,
  h3: PropTypes.bool,
  h4: PropTypes.bool,
  h5: PropTypes.bool,
  /**,
   * Pass when it's necessary to make fonts responsive. Only pass an array of font sizes corresponsing to the different breakpoints.
   */
  fontSize: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.oneOf(Object.values(darkTheme.fontSize))),
    PropTypes.string,
  ]),
  /**,
   * Pass a color if the Header should be rendered in that color
   */
  color: PropTypes.string,
  /**
   * Pass `colored` if the header is inside a colored component, and should be rendered in black
   */
  colored: PropTypes.bool,
  /**
   * Pass a label instead of children or string
   */
  label: PropTypes.string,
  /**
   * Pass a label instead of children or string
   */
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.element,
  ]),
};

Header.defaultProps = {
  h1: false,
  h2: false,
  h3: false,
  h4: false,
  h5: false,
  fontSize: null,
  color: null,
  colored: false,
  label: null,
  children: null,
  theme: darkTheme,
};

export default Header;
