import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { Container, Space, Flex } from "../components/Utility";
import { Paragraph } from "../Storybook";
import Web3Auth from "../components/Web3/Web3Auth";
import { getCommunitIdFromRedirectUrl } from "../utils/mochiHelpers";

export const SignInPage = ({ authed, activeCommunityId, currentPlayerId }) => {
  let history = useHistory();
  const [redirectPath, setRedirectPath] = useState(
    `/${activeCommunityId}/profile/${currentPlayerId}`
  );

  useEffect(() => {
    const i = history.location.search.indexOf("=");
    setRedirectPath(history.location.search.substr(i + 1, history.location.search.length - 1));
  }, [history]);

  useEffect(() => {
    if (authed) history.push(redirectPath);
  }, [authed, history, redirectPath]);

  return (
    <Container>
      <Flex justifyContent="center" alignItems="center" flexDirection="column">
        <Paragraph>
          Woah there, Mochi! You're not currently authorized to access this page. Connect your
          wallet to continue.
        </Paragraph>
        <Paragraph mb={20} small fontStyle="italic" mt={20}>
          Please confirm that your web3 provider (i.e Metamask) is connected to the ETH address that
          you last used to play Mochi.
        </Paragraph>
        <Web3Auth
          prompt="Sign In"
          redirectCommunityId={getCommunitIdFromRedirectUrl(history.location.search)}
        />
        <div />
      </Flex>
      <Space mb={4} />
    </Container>
  );
};

export default connect((state) => {
  const authed = state.auth.get("auth");
  const activeCommunityId = state.community.get("activeCommunityId");
  const currentPlayerId = state.auth.get("authedUserId");
  return {
    activeCommunityId,
    currentPlayerId,
    authed,
  };
}, {})(SignInPage);
