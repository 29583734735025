import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Avatar, Header, Paragraph } from "../../Storybook";
import theme from "../../styles/theme";
import { Box, Flex, Notification } from "../Utility";
import MutedCheckmark from "../Utility/MutedCheckmark";
import { backgroundRenderColors, isComplete } from "./utilities";
import moment from "moment";

const CheckInGoal = ({
  communityId,
  playerData,
  playerId,
  goal,
  color,
  selected,
  communityInfo,
  setCheckInGoal,
  nextCheckin,
  lastCheckin,
}) => {
  const completed = isComplete(lastCheckin, nextCheckin);

  const selectGoal = (goal) => {
    if (!completed && !selected) setCheckInGoal(goal);
  };

  const goalColor = backgroundRenderColors(color, selected, completed);

  let report_message = "";
  if (moment(nextCheckin).isSame(moment(), "day")) {
    report_message =
      lastCheckin === nextCheckin ? "Completed Today" : "Due Today";
  } else {
    report_message = moment(nextCheckin).fromNow();
  }

  return (
    <Notification
      bgColor={selected && !completed ? color : "transparent"}
      width={"100%"}
      flexDirection="column"
      alignContent="space-between"
      css="padding: 10px 20px"
      onClick={() => selectGoal(goal)}
      style={{
        cursor: "pointer",
        border: `2px solid ${theme.color[goalColor]}`,
      }}
    >
      <Box mb={2}>
        <Flex justifyContent="flex-start" alignItems="center" mb={2}>
          {completed ? (
            <MutedCheckmark small muted={!selected || completed} />
          ) : (
            <Avatar image={communityInfo.get("icon")} />
          )}
          <Paragraph
            label={communityInfo.get("name")}
            colored={selected && !completed}
            color={selected && !completed ? "darkFGPrimary" : goalColor}
          />
        </Flex>
        <Header
          h3
          mb={2}
          label={goal.get("goal")}
          colored={selected && !completed}
          color={selected && !completed ? "darkFGPrimary" : goalColor}
        />
      </Box>
      <Paragraph
        colored={selected && !completed}
        color={selected && !completed ? "darkFGPrimary" : goalColor}
        label={report_message}
        mb={2}
      />
    </Notification>
  );
};

export default withRouter(
  connect((state, props) => {
    const playerId = state.community.get("authedUserId");
    const playerData = state.community.getIn([
      "players",
      props.communityId,
      playerId,
    ]);
    const communityInfo = state.community.getIn([
      "communityInfo",
      props.communityId,
    ]);
    return {
      communityInfo,
      playerData,
      playerId,
    };
  })(CheckInGoal)
);
