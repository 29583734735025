import React from "react";
import styled, { css } from "styled-components";

import { Paragraph } from "../../Storybook";

const GrayCircle = styled.div`
  width: 36px;
  height: 36px;
  background: #808080;
  display: flex;
  flex-direction: columm;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-right: 8px;
  margin-top: 4px;
  margin-bottom: 4px;
  ${(props) =>
    props.small &&
    css`
      width: 24px;
      height: 24px;
    `}
  ${(props) =>
    props.muted &&
    css`
      background: #221f1f;
    `};
  ${(props) =>
    props.inline &&
    css`
      margin-left: 4px;
      margin-right: 12px;
    `};
`;

const MutedCheckmark = (props) => {
  return (
    <GrayCircle {...props}>
      <Paragraph
        color={"darkBGPrimary"}
        fontSize={!props.small ? "22px" : "16px"}
      >
        &#x2713;
      </Paragraph>
    </GrayCircle>
  );
};

export default MutedCheckmark;
