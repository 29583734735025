import React from "react";

import { GridBox } from "../../Storybook";
import theme from "../../styles/theme";

const BorderedWrapper = ({ children, bgColor, props }) => (
  <GridBox
    style={{
      backgroundColor: bgColor,
      border: "1px solid rgba(255, 255, 255, 0.1)",
      backdropFilter: "blur(6px)",
      borderRadius: theme.pixels.sz2,
    }}
    {...props}
    p={3}
  >
    {children}
  </GridBox>
);

export default BorderedWrapper;
