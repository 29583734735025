import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import { Button, GridBox, Paragraph, Avatar } from "../../Storybook";
import { Box, Flex } from "../Utility";
import CheckInGoals from "./CheckInGoals";
import MutedCheckmark from "../Utility/MutedCheckmark";
import null288 from "../../img/null-288.png";
import theme from "../../styles/theme";

const CheckInSuccess = ({
  currentGoals,
  allComplete,
  activeCommunityId,
  authedPlayerId,
  checkInGoal,
  setCheckInGoal,
  award,
}) => {
  const goalPlural = currentGoals.size > 1 ? "goals" : "goal";
  const awardMessage = award ? ` - here, take ${award} Mochi Tokens` : "";
  const message = `Thanks for submitting a Pearl of Wisdom${awardMessage}!`;
  return (
    <GridBox
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="top"
    >
      <Flex justifyContent="flex-start" alignItems="center" mb={3}>
        {allComplete ? (
          <MutedCheckmark active={true} small inline />
        ) : (
          <Avatar image={null288} large />
        )}
        <Paragraph fontSize={"fz3"} label={message} color={"darkFGPrimary"} />
      </Flex>
      <Flex justifyContent="flex-start" mb={3}>
        {!allComplete ? (
          <Box>
            <CheckInGoals
              checkInGoal={checkInGoal}
              currentGoals={currentGoals}
              setCheckInGoal={setCheckInGoal}
            />
            <Paragraph
              fontSize="fz3"
              label={`You've still got a ${goalPlural} to check in on. Select the goal and press bellow to get started on it.`}
              mb={4}
              mt={4}
              ml={theme.pixels.sz4}
              pl={theme.pixels.sz2}
            />
          </Box>
        ) : (
          <Box ml={theme.pixels.sz4} pl={theme.pixels.sz2}>
            <Paragraph
              fontSize="fz3"
              label="You’ve checked in on all your goals - good luck on your endeavors today & see you next report!"
              mb={4}
            />
            <Link to={`/${activeCommunityId}/profile/${authedPlayerId}`}>
              <Button primary arrow label="Return to Profile" />
            </Link>
          </Box>
        )}
      </Flex>
    </GridBox>
  );
};

export default withRouter(
  connect((state, props) => {
    const authedPlayerId = state.community.get("authedUserId");
    const activeCommunityId = state.community.get("activeCommunityId");
    return {
      authedPlayerId,
      activeCommunityId,
    };
  })(CheckInSuccess)
);
