import moment from "moment";

import { Header, Paragraph } from "../../../Storybook";
import { Box, Notification, Flex, ButtonArrow } from "../../Utility";
import {
  showNTellHeader,
  showNTellMsg,
  daysUntilEndOfJourneyMsg,
} from "./notificationMessages";

const CurrentlyInJourneyNotification = ({ currentJourney }) => {
  const now = moment();

  // get journey info date counts
  const startDate = moment(currentJourney.get("start"), "MM/DD/YYYY");
  const endDate = moment(currentJourney.get("end"), "MM/DD/YYYY").subtract(
    1,
    "days"
  );
  const daysSinceStartOfJourney = now.diff(startDate, "days");
  const daysUntilEndOfJourney = endDate.diff(now, "days");
  let daysUntilNextShowNTell = 0;
  let showNTells = currentJourney.get("showAndTellData");

  // get show & tell countdown
  if (showNTells?.size) {
    showNTells.mapKeys((date, data) => {
      let diffDays = moment(date, "MM/DD/YYYY").diff(now, "days");
      if (diffDays >= 1) daysUntilNextShowNTell = diffDays;
    });
  }

  const upcomingShowNTell = daysUntilNextShowNTell > 0;
  const currentJourneySizing = upcomingShowNTell ? 7 / 15 : 1;
  return (
    <Flex width="100%" alignItems="stretch" flexWrap="wrap">
      <Notification
        data-testid="currentJourneyNotif"
        bgColor={"darkPositive500"}
        width={[1, currentJourneySizing]}
      >
        <Box flexWrap="wrap">
          <Header h5 colored mt={0} mb={1}>
            Current Journey
          </Header>
          <Header h3 colored mt={0} mb={1}>
            Day {daysSinceStartOfJourney}
          </Header>
          <Paragraph data-testid="daysUntilEndOfJourneyMsg" colored>
            {daysUntilEndOfJourneyMsg(daysUntilEndOfJourney)}
          </Paragraph>
        </Box>
      </Notification>
      {upcomingShowNTell && (
        <Notification
          data-testid="showNTellNotif"
          bgColor={"darkCyan300"}
          width={[1, 7 / 15]}
          ml={[0, 2]}
          mt={[2, 0]}
        >
          <Box flexWrap="wrap">
            <Header h5 colored mt={0} mb={1}>
              Show & Tell
            </Header>
            <Header h3 colored mt={0} mb={1}>
              {showNTellHeader(daysUntilNextShowNTell)}
            </Header>
            <Paragraph colored>
              {showNTellMsg(daysUntilNextShowNTell)}
              <ButtonArrow />
            </Paragraph>
          </Box>
        </Notification>
      )}
    </Flex>
  );
};

export default CurrentlyInJourneyNotification;
