import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import useSWR from "swr";

import fetcher from "../../config/fetcher";
import { Paginator, ApiError, Report, Flex } from "../Utility";
import { FreeAgentActions } from "../../containers/FreeAgent";
import { genNewId } from "../../utils/mochiHelpers";
import { Header, Modal, GridBox, Paragraph } from "../../Storybook";
import FreeAgentItem from "./FreeAgentItem";

const FreeAgentPage = () => {
  let history = useHistory();
  let { communityId } = useParams();
  const dispatch = useDispatch();
  const playerCount = useSelector((state) => state.freeAgent.getIn(["userCount", communityId]));
  const playersData = useSelector((state) => state.freeAgent.getIn(["userData", communityId]));

  const pages = playerCount ? Math.ceil(playerCount / 12) : 1;
  const [selectedPage, setPage] = useState(1);
  const [error, setError] = useState("");
  const currentPagePlayers = playersData?.get(selectedPage);

  const { data, isLoading } = useSWR(
    `/community/${communityId}/free-agents/users/?page=${selectedPage}`,
    fetcher
  );

  useEffect(() => {
    if (data) dispatch(FreeAgentActions.setFreeUsers(data, selectedPage, communityId));
  }, [communityId, data, dispatch, selectedPage]);

  if (error) return <div>failed to load</div>;
  if (isLoading) return <div>loading...</div>;

  if (!currentPagePlayers) return <></>;
  return (
    <GridBox
      span={[4, 6]}
      start={[1, 2, 4]}
      justifyContent="center"
      alignItems="center"
      alignContent="center"
    >
      <Header h1 mb={4} textAlign="center" label="Free Players" />
      {error?.length > 0 && (
        <Modal onClose={setError("")}>
          <ApiError msg={error} />
        </Modal>
      )}
      {!!currentPagePlayers?.size ? (
        currentPagePlayers?.map((user) => (
          <FreeAgentItem
            key={genNewId("free-agent")}
            title={user.get("first_name") + " " + user.get("last_name")}
            buttonText={"Visit"}
            callback={() => history.push(`/${communityId}/profile/${user.get("user_id")}`)}
          />
        ))
      ) : (
        <Report mt={5} py={4} borderRadius="4px" justifyContent="center" width="100%">
          <Paragraph
            label={"There aren't players free to play."}
            color="darkFGTertiary"
            fontSize="fz1"
          />
        </Report>
      )}
      <Flex justifyContent="center">
        {playerCount > 0 ? (
          <Paginator
            currentPage={selectedPage}
            totalPages={pages}
            callback={(v) => setPage(v)}
            length={5}
          />
        ) : (
          <Header h4 label="End of results" textAlign="center" mt={5} />
        )}
      </Flex>
    </GridBox>
  );
};

export default FreeAgentPage;
